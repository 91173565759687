import {useEffect} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import routes from './router/routes';
import {CircularProgress} from '@mui/material';
import {useLazyGetProfileDataQuery} from 'store/slices/profile';
import {useAppSelector} from 'store/store';

function App() {
  const router = createBrowserRouter(routes);
  const isAuth = useAppSelector((state) => state.auth.isAuth);

  const [getProfileData, {data, isLoading}] = useLazyGetProfileDataQuery();

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      getProfileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isAuth]);

  if (isLoading) {
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
          fontSize: '50px',
        }}
      />
    );
  }

  return <RouterProvider router={router} />;
}

export default App;
