import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  SnackbarOrigin,
  Tab,
  Tabs,
} from '@mui/material';
import InformationTab from './InformationTab/InformationTab';
import ActivityLogTab from './ActivityLogTab/ActivityLogTab';
import {
  CloseIcon,
  EditIcon,
  GoBackArrowButtonIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import WarningModal from 'components/WarningModal/WarningModal';
import {tab} from 'styles/MUIStyles/common';
import {editDealerButton, goBackButton} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './DealersInformation.module.scss';
import {
  useLazyGetDealerByIdQuery,
  useRecoverDealerMutation,
} from '../../../store/slices/dealers';
import {useUpdateEffect} from '../../../hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DealersInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [getDealerById, {data: dealerData, isFetching}] =
    useLazyGetDealerByIdQuery();

  const [
    recoverDealer,
    {
      isSuccess: isRecoveringDealerSuccess,
      isLoading: isRecoveringDealerLoading,
    },
  ] = useRecoverDealerMutation();

  const [activeTab, setActiveTab] = React.useState(0);
  const [isRecoverDealerModalOpen, setIsRecoverDealerModalOpen] =
    useState(false);
  const [recoverDealerSnackbar, setRecoverDealerSnackbar] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = recoverDealerSnackbar;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (params?.dealerId) {
      getDealerById(params.dealerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.dealerId]);

  useUpdateEffect(() => {
    if (isRecoveringDealerSuccess && params?.dealerId) {
      setRecoverDealerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoverDealerModalOpen(false);
      getDealerById(params.dealerId);
    }
  }, [isRecoveringDealerSuccess]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.dealersInformationTop}>
        <h1 className={styles.dealersInformationTitle}>Dealers</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          {dealerData?.audit?.deleting ? (
            <Button
              variant="contained"
              startIcon={<RecoverIcon />}
              onClick={() => setIsRecoverDealerModalOpen(true)}
              sx={editDealerButton}
            >
              Recover
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                if (params?.dealerId) {
                  navigate('/dealers/edit/' + params.dealerId);
                }
              }}
              sx={editDealerButton}
            >
              Edit Info
            </Button>
          )}
        </div>
      </div>

      <div className={styles.dealersInformationMainContent}>
        {isFetching ? (
          <Box sx={{display: 'flex', height: '100%'}}>
            <CircularProgress
              size={45}
              sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
            />
          </Box>
        ) : (
          <Box sx={{height: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
              }}
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Information" {...a11yProps(0)} />
              <Tab sx={tab} label="Activity Log" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={activeTab} index={0}>
              <InformationTab dealerData={dealerData} />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <ActivityLogTab dealerData={dealerData} />
            </CustomTabPanel>
          </Box>
        )}
      </div>

      {dealerData?.email ? (
        <>
          <WarningModal
            isOpen={isRecoverDealerModalOpen}
            onClose={() => setIsRecoverDealerModalOpen(false)}
            entityText={dealerData.email}
            title="Recover dealer ?"
            textBlocks={['Are you sure you want to recover dealer']}
            confirmButtonText="Yes, recover"
            onConfirmButtonClick={() => {
              if (params?.dealerId) {
                recoverDealer(params.dealerId);
              }
            }}
            loading={isRecoveringDealerLoading}
          />
          <Snackbar
            anchorOrigin={{vertical, horizontal}}
            autoHideDuration={2500}
            open={open}
            onClose={() =>
              setRecoverDealerSnackbar({
                ...recoverDealerSnackbar,
                open: false,
              })
            }
            key="dealerInformation"
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Dealer {`"${dealerData.email}"`} was restored
                <span
                  onClick={() => {
                    setRecoverDealerSnackbar({
                      ...recoverDealerSnackbar,
                      open: false,
                    });
                  }}
                >
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </div>
  );
};

export default DealersInformation;
