import React, {ChangeEvent, FC, useState} from 'react';
import {
  useDebounce,
  useLocalStorage,
  useSortColumn,
  useUpdateEffect,
} from 'hooks';
import {useGetBranchesQuery} from 'store/slices/branches';
import {Button, Pagination, PaginationItem} from '@mui/material';
import {
  CreateIcon,
  PaginationArrowLeft,
  PaginationArrowRight,
} from 'assets/icons';
import BranchesTopToolbar from './BranchesTopToolbar/BranchesTopToolbar';
import BranchesTable from './BranchesTable/BranchesTable';
import BranchesCreate from './BranchesCreate/BranchesCreate';
import {createButton} from 'styles/MUIStyles/buttons';
import {pagination} from 'styles/MUIStyles/common';
import styles from './Branches.module.scss';

const Branches: FC = () => {
  const [pageSize, setPageSize] = useLocalStorage('branchesPageSize', 10);
  const [pageNumber, setPageNumber] = useLocalStorage('branchesPageNumber', 1);
  const [search, setSearch] = useLocalStorage('branchesSearch', '');
  const debouncedSearch = useDebounce(search, 800);
  const [sortConfig, handleColumnClick] = useSortColumn('branchesSortConfig');
  const [isCreateBranchModalOpen, setIsCreateBranchModalOpen] = useState(false);

  const {data: branchesData} = useGetBranchesQuery({
    pageSize,
    pageNumber,
    search: debouncedSearch,
    sortBy: sortConfig,
  });

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  useUpdateEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1);
    }
  }, [debouncedSearch, pageSize]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.branchesTop}>
        <h1 className={styles.branchesTitle}>Branches</h1>
        <Button
          variant="contained"
          startIcon={<CreateIcon />}
          onClick={() => setIsCreateBranchModalOpen(true)}
          sx={createButton}
        >
          Create
        </Button>
      </div>
      <div className={styles.branchesMainContent}>
        <div className={styles.tableFiltersTop}>
          <BranchesTopToolbar
            search={search}
            setSearch={setSearch}
            pageSize={pageSize}
            setPageSize={setPageSize}
            metaData={branchesData?.metadata || null}
          />
        </div>
        <div style={{height: '87%', flexGrow: 1}}>
          <BranchesTable
            branches={branchesData?.data || []}
            metaData={branchesData?.metadata || null}
            search={debouncedSearch}
            sortConfig={sortConfig}
            handleColumnClick={handleColumnClick}
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={branchesData?.metadata?.pageCount || 1}
          page={pageNumber}
          onChange={handleOnPageChange}
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>

      <BranchesCreate
        isCreateBranchModalOpen={isCreateBranchModalOpen}
        setIsCreateBranchModalOpen={setIsCreateBranchModalOpen}
      />
    </div>
  );
};

export default Branches;
