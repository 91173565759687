import {FC} from 'react';
import styles from './404.module.scss';
import {goBackButton} from 'styles/MUIStyles/buttons';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';

const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.pageNotFound}>
      <div className={styles.pageNotFoundContainer}>
        <p className={styles.pageNotFoundHeading}>404</p>
        <p className={styles.pageNotFoundTextPrimary}>Page not found</p>
        <p className={styles.pageNotFoundTextSecondary}>
          The link you followed may be broken, <br /> or the page may have been
          be removed
        </p>
        <Button onClick={() => navigate(-1)} sx={goBackButton}>
          &lt; Go back
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
