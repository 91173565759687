import {FC, useState} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {Navigate, useNavigate} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from 'store/store';
import {loginButton} from 'styles/MUIStyles/buttons';
import {FORGOT_PASSWORD_PAGE_PATH} from 'router/constants';
import eyeOff from 'assets/icons/eyeOff.svg';
import eyeOn from 'assets/icons/eyeOn.svg';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import styles from './Login.module.scss';
import {mainPagesByRole} from 'core/constants';
import {useLoginMutation} from 'store/slices/profile';
import {Alert, Box, Snackbar} from '@mui/material';
import {ISnackbarState} from 'store/models/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
} from 'styles/MUIStyles/snackbars';
import {CloseIcon, ErrorNotification} from 'assets/icons';
import {setEmail} from 'store/slices/authSlice';

interface IFormInput {
  email: string;
  password: string;
}

const Login: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [login, {isLoading}] = useLoginMutation();
  const [errorSnackbar, setErrorSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: errorOpen,
  } = errorSnackbar;

  const handleSnackbarClose = () => {
    setErrorSnackbar({...errorSnackbar, open: false});
    setErrorMessage(null);
  };

  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const role = useAppSelector((state) => state.auth.role);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const onSubmit = async (formData: IFormInput) => {
    dispatch(setEmail(formData.email));
    const res = await login({...formData});

    if (!('error' in res)) {
      navigate('/user/otp-code');
    } else {
      if ('error' in res && 'status' in res.error) {
        if (res.error?.status === 400 || res.error?.status === 404) {
          const errors = res.error.data?.errors;

          for (let key in errors) {
            if (Array.isArray(errors[key])) {
              setErrorMessage(errors[key]);
              setErrorSnackbar({
                vertical: 'top',
                horizontal: 'center',
                open: true,
              });
              break;
            }
          }
        }
      }
    }
  };

  if (isAuth && role) {
    return <Navigate to={'/' + mainPagesByRole[role]} replace={true} />;
  }

  return (
    <div className={styles.loginPageContainer}>
      <div className="container">
        <div className={styles.loginFormContainer}>
          <div className={styles.loginLogoContainer}>
            <img src="/UCS_LOGO.png" alt="UCS" />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2 className={styles.loginTitle}>Log In</h2>
            <div className={styles.loginInputContainer}>
              <label htmlFor="login">Email</label>
              <div>
                <input
                  {...register('email', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="login"
                  placeholder="Enter login"
                  className={
                    errors?.email
                      ? styles.loginInput + ' ' + styles.loginInputError
                      : styles.loginInput
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({message}) => (
                    <span className={styles.loginErrorMessage}>{message}</span>
                  )}
                />
              </div>
            </div>
            <div className={styles.loginPasswordContainer}>
              <label htmlFor="password">Password</label>
              <div>
                <input
                  {...register('password', {
                    required: 'This field is required',
                  })}
                  type={isPasswordVisible ? 'text' : 'password'}
                  id="password"
                  placeholder="Enter password"
                  className={
                    errors?.password
                      ? styles.passwordInput + ' ' + styles.passwordInputError
                      : styles.passwordInput
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({message}) => (
                    <span className={styles.passwordErrorMessage}>
                      {message}
                    </span>
                  )}
                />
                <img
                  src={isPasswordVisible ? eyeOn : eyeOff}
                  alt={isPasswordVisible ? 'hide password' : 'show password'}
                  onClick={togglePasswordVisibility}
                  className={
                    errors?.password
                      ? styles.passwordInputEye +
                        ' ' +
                        styles.passwordInputEyeError
                      : styles.passwordInputEye
                  }
                />
              </div>
            </div>
            <ButtonWithProgress
              type="submit"
              loading={isLoading}
              disabled={isLoading}
              sx={loginButton}
            >
              Log In
            </ButtonWithProgress>
            <div className={styles.forgotPasswordContainer}>
              <span
                onClick={() => navigate('/' + FORGOT_PASSWORD_PAGE_PATH)}
                className={styles.forgotPassword}
              >
                Forgot password?
              </span>
            </div>
          </form>
        </div>
      </div>
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorVertical,
            horizontal: errorHorizontal,
          }}
          autoHideDuration={3500}
          open={errorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default Login;
