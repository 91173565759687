import React, {FC, useState, memo} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetNumberOfElements, useUpdateEffect} from 'hooks';
import {IGetBranchesList as IGetBranch} from 'store/models/branches';
import {IMetadata, ISnackbarState} from 'store/models/common';
import {useDeleteBranchMutation} from 'store/slices/branches';
import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {
  ArrowAsc,
  ArrowDesc,
  CloseIcon,
  DefaultSortIcon,
  DeleteIcon,
  EditIcon,
  ErrorNotification,
  MoreIcon,
  PlusIcon,
  SuccessNotification,
} from 'assets/icons';
import WarningModal from 'components/WarningModal/WarningModal';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {ISortConfig} from '../../../hooks/useSortColumn';
import AssignStationModal from '../AssignStationModal/AssignStationModal';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  branches: IGetBranch[];
  metaData: IMetadata | null;
  sortConfig: ISortConfig;
  search: string;
  handleColumnClick: (column: string) => void;
}

const BranchesTable: FC<Props> = memo((props) => {
  const navigate = useNavigate();

  const [
    deleteBranch,
    {
      isLoading: isDeletingBranchLoading,
      isSuccess: isDeletingBranchSuccess,
      error: isDeletingBranchError,
    },
  ] = useDeleteBranchMutation();

  const itemsOnPage = useGetNumberOfElements(props.metaData);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [clickedBranch, setClickedBranch] = useState<IGetBranch | null>(null);
  const [isDeleteBranchModalOpen, setIsDeleteBranchModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [
    isAssignStationtoBranchModalOpen,
    setIsAssignStationtoBranchModalOpen,
  ] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const [deleteBranchSnackbar, setDeleteBranchSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [deleteBranchSnackbarError, setDeleteBranchSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: deleteVertical,
    horizontal: deleteHorizontal,
    open: deleteOpen,
  } = deleteBranchSnackbar;
  const {
    vertical: deleteErrorVertical,
    horizontal: deleteErrorHorizontal,
    open: deleteErrorOpen,
  } = deleteBranchSnackbarError;

  const handleMoreDotsClose = () => setAnchorEl(null);

  const handleSnackbarClose = () => {
    setDeleteBranchSnackbar({...deleteBranchSnackbar, open: false});
    setDeleteBranchSnackbarError({...deleteBranchSnackbarError, open: false});
    setErrorMessage(null);
  };

  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  useUpdateEffect(() => {
    if (isDeletingBranchSuccess) {
      setDeleteBranchSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteBranchModalOpen(false);
    }
  }, [isDeletingBranchSuccess]);

  useUpdateEffect(() => {
    if (isDeletingBranchError) {
      if (
        'data' in isDeletingBranchError &&
        'status' in isDeletingBranchError
      ) {
        if (isDeletingBranchError.status === 400) {
          const errors = isDeletingBranchError.data?.errors;

          for (let key in errors) {
            if (Array.isArray(errors[key])) {
              setIsDeleteBranchModalOpen(false);
              setErrorMessage(errors[key]);
              setDeleteBranchSnackbarError({
                vertical: 'top',
                horizontal: 'center',
                open: true,
              });
              break;
            }
          }
        }
      }
    }
  }, [isDeletingBranchError]);

  return (
    <div style={{height: '100%'}}>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '22.5%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('name')}
                >
                  <span>Name</span>
                  {getArrowForSortDirection('name')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '22.5%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('companyName')}
                >
                  <span>Company name</span>
                  {getArrowForSortDirection('companyName')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '22.5%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() =>
                    props.handleColumnClick('companyRegistrationNumber')
                  }
                >
                  <span>Registration number</span>
                  {getArrowForSortDirection('companyRegistrationNumber')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '22.5%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('address')}
                >
                  <span>Address</span>
                  {getArrowForSortDirection('address')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>{null}</Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.branches.map((branch, index) => (
              <StyledTableRow
                key={branch.id}
                sx={{cursor: 'pointer'}}
                onClick={() => navigate(`/branches/information/${branch.id}`)}
              >
                <StyledTableCell>
                  {itemsOnPage.length ? itemsOnPage[index] : null}
                </StyledTableCell>
                <StyledTableCell>{branch.name}</StyledTableCell>
                <StyledTableCell>{branch.companyName}</StyledTableCell>
                <StyledTableCell>
                  {branch.companyRegistrationNumber}
                </StyledTableCell>
                <StyledTableCell>{branch.address}</StyledTableCell>
                <StyledTableCell>
                  <Button
                    id="basic-button"
                    aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isMenuOpen ? 'true' : undefined}
                    onClick={(event) => {
                      event.stopPropagation();
                      setClickedBranch(branch);
                      handleMoreDotsClick(event);
                    }}
                  >
                    <MoreIcon />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem
          sx={tableMoreDotsMenu}
          onClick={() => {
            handleMoreDotsClose();
            setIsAssignStationtoBranchModalOpen(true);
          }}
        >
          <PlusIcon />
          <span>Assign station</span>
        </MenuItem>
        <MenuItem
          sx={tableMoreDotsMenu}
          onClick={() => {
            if (clickedBranch) {
              navigate(`/branches/edit/${clickedBranch.id}`);
            }
          }}
        >
          <EditIcon />
          <span>Edit</span>
        </MenuItem>
        <MenuItem
          sx={tableMoreDotsMenu}
          onClick={() => {
            setIsDeleteBranchModalOpen(true);
            handleMoreDotsClose();
          }}
        >
          <DeleteIcon />
          <span>Remove</span>
        </MenuItem>
      </Menu>

      <AssignStationModal
        isOpen={isAssignStationtoBranchModalOpen}
        onClose={() => setIsAssignStationtoBranchModalOpen(false)}
        clickedBranch={clickedBranch}
      />
      {clickedBranch ? (
        <>
          <WarningModal
            isOpen={isDeleteBranchModalOpen}
            onClose={() => setIsDeleteBranchModalOpen(false)}
            entityText={`"${clickedBranch.name}" ?`}
            title={'Remove branch ?'}
            textBlocks={['Are you sure you want to remove branch']}
            confirmButtonText={'Yes, remove'}
            onConfirmButtonClick={() => deleteBranch(clickedBranch.id)}
            loading={isDeletingBranchLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: deleteVertical,
              horizontal: deleteHorizontal,
            }}
            autoHideDuration={2500}
            open={deleteOpen}
            onClose={handleSnackbarClose}
            key={'deleteCompany'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Company {`"${clickedBranch.name}"`} was removed
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: deleteErrorVertical,
            horizontal: deleteErrorHorizontal,
          }}
          autoHideDuration={3500}
          open={deleteErrorOpen}
          onClose={handleSnackbarClose}
          key={'deleteCompany'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
});

export default BranchesTable;
