import React, {FC, memo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetNumberOfElements, useUpdateEffect} from 'hooks';
import {IGetCompaniesList as IGetCompany} from 'store/models/companies';
import {IMetadata, ISnackbarState} from 'store/models/common';
import {useDeleteCompanyMutation} from 'store/slices/companies';
import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import WarningModal from 'components/WarningModal/WarningModal';
import {
  ArrowAsc,
  ArrowDesc,
  CloseIcon,
  DefaultSortIcon,
  DeleteIcon,
  EditIcon,
  ErrorNotification,
  MoreIcon,
  SuccessNotification,
} from 'assets/icons';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {ISortConfig} from '../../../hooks/useSortColumn';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: {...styledTableBody, padding: '12px 16px'},
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  companies: IGetCompany[];
  metaData: IMetadata | null;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
  search: string;
}

const CompaniesTable: FC<Props> = memo((props) => {
  const navigate = useNavigate();

  const [
    deleteCompany,
    {
      isLoading: isDeletingCompanyLoading,
      isSuccess: isDeletingCompanySuccess,
      error: isDeletingCompanyError,
    },
  ] = useDeleteCompanyMutation();

  const itemsOnPage = useGetNumberOfElements(props.metaData);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [clickedCompany, setClickedCompany] = useState<IGetCompany | null>(
    null,
  );
  const [isDeleteCompanyModalOpen, setIsDeleteCompanyModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [deleteCompanySnackbar, setDeleteCompanySnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [deleteCompanySnackbarError, setDeleteCompanySnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: deleteVertical,
    horizontal: deleteHorizontal,
    open: deleteOpen,
  } = deleteCompanySnackbar;
  const {
    vertical: deleteErrorVertical,
    horizontal: deleteErrorHorizontal,
    open: deleteErrorOpen,
  } = deleteCompanySnackbarError;

  const handleMoreDotsClose = () => setAnchorEl(null);

  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSnackbarClose = () => {
    setDeleteCompanySnackbar({...deleteCompanySnackbar, open: false});
    setDeleteCompanySnackbarError({
      ...deleteCompanySnackbarError,
      open: false,
    });
    setErrorMessage(null);
  };

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  useUpdateEffect(() => {
    if (isDeletingCompanySuccess) {
      setDeleteCompanySnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteCompanyModalOpen(false);
    }
  }, [isDeletingCompanySuccess]);

  useUpdateEffect(() => {
    if (isDeletingCompanyError) {
      if (
        'data' in isDeletingCompanyError &&
        'status' in isDeletingCompanyError
      ) {
        if (isDeletingCompanyError.status === 400) {
          const errors = isDeletingCompanyError.data?.errors;

          for (let key in errors) {
            if (Array.isArray(errors[key])) {
              setIsDeleteCompanyModalOpen(false);
              setErrorMessage(errors[key]);
              setDeleteCompanySnackbarError({
                vertical: 'top',
                horizontal: 'center',
                open: true,
              });
              break;
            }
          }
        }
      }
    }
  }, [isDeletingCompanyError]);

  return (
    <div style={{height: '100%'}}>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '10%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('name')}
                >
                  <span>Name</span>
                  {getArrowForSortDirection('name')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '25%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('registrationNumber')}
                >
                  <span>Registration number</span>
                  {getArrowForSortDirection('registrationNumber')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '40%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('address')}
                >
                  <span>Address</span>
                  {getArrowForSortDirection('address')}
                </Box>
              </StyledTableCell>
              <StyledTableCell style={{width: '5%'}}>
                <Box sx={tableHeadCell}>{null}</Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.companies.map((company, index) => (
              <StyledTableRow
                key={company.id}
                sx={{cursor: 'pointer'}}
                onClick={() => navigate(`/companies/information/${company.id}`)}
              >
                <StyledTableCell>
                  {itemsOnPage.length ? itemsOnPage[index] : null}
                </StyledTableCell>
                <StyledTableCell>{company.name}</StyledTableCell>
                <StyledTableCell>{company.registrationNumber}</StyledTableCell>
                <StyledTableCell>{company.address}</StyledTableCell>
                <StyledTableCell>
                  {company.name !== 'All' ? (
                    <Button
                      id="basic-button"
                      aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isMenuOpen ? 'true' : undefined}
                      onClick={(event) => {
                        event.stopPropagation();
                        setClickedCompany(company);
                        handleMoreDotsClick(event);
                      }}
                    >
                      <MoreIcon />
                    </Button>
                  ) : null}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem
          sx={tableMoreDotsMenu}
          onClick={() => {
            if (clickedCompany) {
              navigate(`/companies/edit/${clickedCompany.id}`);
            }
          }}
        >
          <EditIcon />
          <span>Edit</span>
        </MenuItem>
        <MenuItem
          sx={tableMoreDotsMenu}
          onClick={() => {
            setIsDeleteCompanyModalOpen(true);
            handleMoreDotsClose();
          }}
        >
          <DeleteIcon />
          <span>Remove</span>
        </MenuItem>
      </Menu>

      {clickedCompany ? (
        <>
          <WarningModal
            isOpen={isDeleteCompanyModalOpen}
            onClose={() => setIsDeleteCompanyModalOpen(false)}
            entityText={`"${clickedCompany.name}" ?`}
            title={'Remove company ?'}
            textBlocks={['Are you sure you want to remove company']}
            confirmButtonText={'Yes, remove'}
            onConfirmButtonClick={() => deleteCompany(clickedCompany.id)}
            loading={isDeletingCompanyLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: deleteVertical,
              horizontal: deleteHorizontal,
            }}
            autoHideDuration={2500}
            open={deleteOpen}
            onClose={handleSnackbarClose}
            key={'deleteCompany'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Company {`"${clickedCompany.name}"`} was removed
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: deleteErrorVertical,
            horizontal: deleteErrorHorizontal,
          }}
          autoHideDuration={3500}
          open={deleteErrorOpen}
          onClose={handleSnackbarClose}
          key={'deleteCompany'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
});

export default CompaniesTable;
