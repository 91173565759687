import React, {FC, useState} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Divider,
  Modal,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {CloseIcon, SuccessNotification} from 'assets/icons';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {modal} from 'styles/MUIStyles/modal';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {useCreateDealerMutation} from 'store/slices/dealers';
import {useUpdateEffect} from 'hooks';
import styles from './DealersCreate.module.scss';

interface Props {
  isCreateDealersModalOpen: boolean;
  setIsCreateDealersModalOpen: (isOpen: boolean) => void;
}

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  additionalInformation: string;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const DealersCreate: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      additionalInformation: '',
    },
  });

  const [
    createDealer,
    {
      isLoading: isCreatingDealerLoading,
      isSuccess: isCreatingDealerSuccess,
      error: createDealerError,
    },
  ] = useCreateDealerMutation();

  const [dealerEmail, setDealerEmail] = useState<string | null>(null);
  const [snackbar, setSnackbar] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = snackbar;

  const onSubmit = async (formData: IFormData) => {
    const response = createDealer({
      ...formData,
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      email: formData.email.trim(),
      phoneNumber: formData.phoneNumber.trim(),
      additionalInformation: formData.additionalInformation.trim(),
    });

    if (response) {
      if (!('error' in response)) {
        setDealerEmail(formData.email);
      }
    }
  };

  const handleClose = () => {
    setDealerEmail(null);
    setSnackbar({...snackbar, open: false});
  };

  const clearDataAndErrors = () => {
    clearErrors();
    reset();
    props.setIsCreateDealersModalOpen(false);
  };

  useUpdateEffect(() => {
    if (isCreatingDealerSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      props.setIsCreateDealersModalOpen(false);
      reset();
    }
  }, [isCreatingDealerSuccess]);

  useUpdateEffect(() => {
    if (createDealerError) {
      if ('data' in createDealerError) {
        const errors = createDealerError.data?.errors;
        if (errors?.email?.[0] || errors?.Email?.[0]) {
          setError('email', {
            type: 'custom',
            message: errors?.email?.[0] || errors?.Email?.[0],
          });
        }

        if (errors?.firstName?.[0] || errors?.FirstName?.[0]) {
          setError('firstName', {
            type: 'custom',
            message: errors?.firstName?.[0] || errors?.FirstName?.[0],
          });
        }

        if (errors?.lastName?.[0] || errors?.LastName?.[0]) {
          setError('lastName', {
            type: 'custom',
            message: errors?.lastName?.[0] || errors?.LastName?.[0],
          });
        }

        if (errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0]) {
          setError('phoneNumber', {
            type: 'custom',
            message: errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0],
          });
        }
      }
    }
  }, [createDealerError, setError]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{vertical, horizontal}}
        autoHideDuration={4000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            Password sent to {dealerEmail}
            <span onClick={handleClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={props.isCreateDealersModalOpen}
      >
        <Modal
          open={props.isCreateDealersModalOpen}
          onClose={clearDataAndErrors}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={modal}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '15px',
                }}
              >
                <h2 className={styles.dealersCreateTitle}>Create Dealer</h2>
                <div className={styles.closeIcon} onClick={clearDataAndErrors}>
                  <CloseIcon />
                </div>
              </Box>

              <div className={styles.firstNameInputContainer}>
                <label htmlFor="firstName">First Name</label>
                <input
                  {...register('firstName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  className={`${
                    errors?.firstName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter First Name"
                />
                <ErrorMessage
                  name="firstName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.lastNameInputContainer}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  {...register('lastName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  className={`${
                    errors?.lastName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter Last Name"
                />
                <ErrorMessage
                  name="lastName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.emailInputContainer}>
                <label htmlFor="email">Email</label>
                <input
                  {...register('email', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address',
                    },
                  })}
                  type="text"
                  id="email"
                  autoComplete="off"
                  className={`${errors?.email ? 'input inputError' : 'input'}`}
                  placeholder="Enter Email"
                />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.phoneNumberInputContainer}>
                <label htmlFor="phoneNumber">Phone number</label>
                <input
                  {...register('phoneNumber', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="phoneNumber"
                  autoComplete="off"
                  className={`${
                    errors?.phoneNumber ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter phone number"
                />
                <ErrorMessage
                  name="phoneNumber"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.additionalInfoInputContainer}>
                <label htmlFor="additionalInformation">
                  Additional information - Optional
                </label>
                <input
                  {...register('additionalInformation')}
                  type="text"
                  id="additionalInformation"
                  autoComplete="off"
                  className={`input`}
                  placeholder="Enter additional information"
                />
              </div>
              <Divider
                sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}}
              />
              <div className={styles.buttonsContainer}>
                <Button sx={cancelButton} onClick={clearDataAndErrors}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  type="submit"
                  disabled={isCreatingDealerLoading}
                  loading={isCreatingDealerLoading}
                  sx={createButtonModal}
                >
                  Create
                </ButtonWithProgress>
              </div>
            </Box>
          </form>
        </Modal>
      </Backdrop>
    </div>
  );
};

export default DealersCreate;
