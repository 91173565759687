import {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './ResetPassword.module.scss';
import {ErrorMessage} from '@hookform/error-message';
import {useForm} from 'react-hook-form';
import eyeOff from 'assets/icons/eyeOff.svg';
import eyeOn from 'assets/icons/eyeOn.svg';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {loginButton} from 'styles/MUIStyles/buttons';
import {CloseIcon, ErrorNotification, InfoIcon} from 'assets/icons';
import {
  useCheckResetPwdTokenMutation,
  useResetProfilePasswordMutation,
} from 'store/slices/profile';
import {Alert, Box, CircularProgress, Snackbar} from '@mui/material';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
} from 'styles/MUIStyles/snackbars';
import {ISnackbarState} from 'store/models/common';

function useGetResetToken() {
  const {search} = useLocation();
  return search.split('=')[1];
}

interface IFormInput {
  newPassword: string;
  repeatedNewPassword: string;
}

const ResetPassword = () => {
  const token = useGetResetToken();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues: {
      newPassword: '',
      repeatedNewPassword: '',
    },
    mode: 'onTouched',
  });

  const [isPwdVisible, setIsPwdVisible] = useState(false);
  const [isConfirmPwdVisible, setIsConfirmPwdVisible] = useState(false);

  const togglePwdVisibility = () => {
    setIsPwdVisible((prevState) => !prevState);
  };

  const toggleConfirmPwdVisibility = () => {
    setIsConfirmPwdVisible((prevState) => !prevState);
  };

  const [resetPassword, {isLoading}] = useResetProfilePasswordMutation();
  const [
    checkResetPwdToken,
    {data: tokenData, isError: isTokenError, isLoading: isTokenLoading},
  ] = useCheckResetPwdTokenMutation();

  useEffect(() => {
    checkResetPwdToken({
      resetToken: token,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [errorSnackbar, setErrorSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: errorOpen,
  } = errorSnackbar;

  const handleSnackbarClose = () => {
    setErrorSnackbar({...errorSnackbar, open: false});
    setErrorMessage(null);
  };

  const onSubmit = async (formData: IFormInput) => {
    const res = await resetPassword({
      resetToken: token,
      newPassword: formData.newPassword,
      repeatedNewPassword: formData.repeatedNewPassword,
    });
    if (!('error' in res)) {
      navigate('/user/login');
    } else {
      if ('error' in res && 'status' in res.error) {
        const errors = res.error.data?.errors;

        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            setErrorMessage(errors[key]);
            setErrorSnackbar({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  };

  if (isTokenLoading) {
    return (
      <CircularProgress
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px',
          fontSize: '50px',
        }}
      />
    );
  }

  return (
    <div className={styles.resetPageContainer}>
      <div className="container">
        <div className={styles.resetFormContainer}>
          {(tokenData?.isResetTokenExpired || isTokenError) && (
            <h1 className={styles.resetTitle}>
              Your reset-token has been expired. Try to request another.
            </h1>
          )}
          {!tokenData?.isResetTokenExpired && !isTokenError && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h1 className={styles.resetTitle}>Reset Password</h1>

              <div className={styles.inputContainer}>
                <label htmlFor="newPassword">New Password</label>
                <div>
                  <input
                    {...register('newPassword', {
                      required: 'This field is required',
                    })}
                    type={isPwdVisible ? 'text' : 'password'}
                    id="newPassword"
                    placeholder="Enter new password"
                    className={
                      errors?.newPassword
                        ? styles.input + ' ' + styles.inputError
                        : styles.input
                    }
                  />
                  <ErrorMessage
                    errors={errors}
                    name="newPassword"
                    render={({message}) => (
                      <span className={styles.errorMessage}>{message}</span>
                    )}
                  />
                  <img
                    src={isPwdVisible ? eyeOn : eyeOff}
                    alt={isPwdVisible ? 'hide password' : 'show password'}
                    onClick={togglePwdVisibility}
                    className={
                      errors?.newPassword
                        ? styles.inputEye + ' ' + styles.inputEyeError
                        : styles.inputEye
                    }
                  />
                </div>
              </div>

              <div className={styles.inputContainer}>
                <label htmlFor="repeatedNewPassword">Confirm Password</label>
                <div>
                  <input
                    {...register('repeatedNewPassword', {
                      required: 'This field is required',
                      validate: (value: string, formValues: IFormInput) => {
                        return (
                          value === formValues.newPassword ||
                          'Passwords must match'
                        );
                      },
                    })}
                    type={isConfirmPwdVisible ? 'text' : 'password'}
                    id="confirmPassword"
                    placeholder="Confirm password"
                    className={
                      errors?.repeatedNewPassword
                        ? styles.input + ' ' + styles.inputError
                        : styles.input
                    }
                  />
                  <ErrorMessage
                    errors={errors}
                    name="repeatedNewPassword"
                    render={({message}) => (
                      <span className={styles.errorMessage}>{message}</span>
                    )}
                  />
                  <img
                    src={isConfirmPwdVisible ? eyeOn : eyeOff}
                    alt={
                      isConfirmPwdVisible ? 'hide password' : 'show password'
                    }
                    onClick={toggleConfirmPwdVisibility}
                    className={
                      errors?.repeatedNewPassword
                        ? styles.inputEye + ' ' + styles.inputEyeError
                        : styles.inputEye
                    }
                  />
                </div>
              </div>

              <ButtonWithProgress
                type="submit"
                loading={isLoading}
                disabled={isLoading}
                sx={loginButton}
              >
                Save
              </ButtonWithProgress>

              <div className={styles.pwdPolicy}>
                <div className={styles.pwdPolicy_title}>
                  <InfoIcon />
                  <h4>Password must:</h4>
                </div>
                <ul className={styles.pwdPolicy_list}>
                  <li>Have at least 12 characters length</li>
                  <li>Have at least one digit number</li>
                  <li>Have at least one upper-case letter</li>
                  <li>Have at least one lower-case letter</li>
                  <li>Have at least one non alphanumeric symbol</li>
                </ul>
              </div>
            </form>
          )}
        </div>
      </div>
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorVertical,
            horizontal: errorHorizontal,
          }}
          autoHideDuration={3500}
          open={errorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default ResetPassword;
