import {
  useRef,
  useState,
  MutableRefObject,
  FormEvent,
  ClipboardEvent,
  useEffect,
} from 'react';
import styles from './OtpInput.module.scss';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {loginButton} from 'styles/MUIStyles/buttons';

interface IOTPInputProps {
  numberOfDigits?: number;
  onSubmit: (code: number) => void;
  isLoading: boolean;
}
function OtpInput({numberOfDigits = 4, onSubmit, isLoading}: IOTPInputProps) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));
  const otpBoxReference = useRef([]) as MutableRefObject<HTMLDivElement[]>;

  function handleChange(e: any, index: number) {
    let newArr = [...otp];
    newArr[index] = e.target.value;
    setOtp(newArr);

    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling?.focus();
    }
  }

  function handlePaste(e: ClipboardEvent<HTMLInputElement>) {
    const pastedData = e.clipboardData?.getData('text/plain');
    if (pastedData) {
      setOtp([...pastedData.slice(0, 4).split('')]);
      otpBoxReference.current[3]?.focus();
    }
  }

  function handleBackspaceAndEnter(e: any, index: number) {
    if (e.key === 'Backspace' && !e.target.value && e.target.previousSibling) {
      e.target.previousSibling?.focus();
    }
    if (e.key === 'Enter' && e.target.value && e.target.nextSibling) {
      e.target.nextSibling?.focus();
    }
    if (otp[index] && e.target.nextSibling) {
      e.target.nextSibling?.focus();
    }
  }

  const handleSubmitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(+otp.join(''));
  };

  useEffect(() => {
    otpBoxReference.current[0]?.focus();
  }, []);

  return (
    <article className={styles.otp_container}>
      <form onSubmit={handleSubmitForm}>
        <div className={styles.otp_box}>
          {otp.map((digit, index) => (
            <input
              key={index}
              value={digit}
              maxLength={1}
              onPaste={handlePaste}
              onChange={(e) => handleChange(e, index)}
              onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
              ref={(reference) => {
                otpBoxReference.current[index] = reference as HTMLDivElement;
              }}
              className={styles.otp_box_input}
            />
          ))}
        </div>
        <ButtonWithProgress
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          sx={loginButton}
        >
          Submit
        </ButtonWithProgress>
      </form>
    </article>
  );
}

export default OtpInput;
