import {Box, Button, Modal, Table, TableHead} from '@mui/material';
import {CloseIcon, MarkIcon, ReplaceIcon} from 'assets/icons';

import EditFilesTable from './EditFilesTable';
import {FC} from 'react';
import {IGetMainBackupsListModified} from 'store/models/backups';
import {modal} from 'styles/MUIStyles/modal';
import styles from './EditFiles.module.scss';
import {tableMoreDotsMenu} from 'styles/MUIStyles/common';
import {useUpdateEffect} from 'hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  modilfiedFiles: IGetMainBackupsListModified[];
  setModifiedMainBackups: any;
  selectedBackupFile?: any;
  openAssignBackupModalHandler: (file: any) => void;
  setEditingFiles: (b: any) => void;
  editingFiles: IGetMainBackupsListModified[];
  openMarkAsDeletedModalHandler: any;
}

const EditBackupModalFiles: FC<Props> = ({
  isOpen,
  onClose,
  modilfiedFiles,
  setModifiedMainBackups,
  openAssignBackupModalHandler,
  setEditingFiles,
  editingFiles,
  openMarkAsDeletedModalHandler,
}) => {
  const handleClose = () => {
    onClose();
  };

  useUpdateEffect(() => {
    if (isOpen) {
    }
  }, [isOpen]);
  
  const checkDates = (array: any[]) => {
    return array.every((item) => typeof item.deletedDateTime === 'string');
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...modal, width: '80%'}}>
          <div className={styles.modalTop}>
            <h2 className={styles.title}>Backup files</h2>
            <CloseIcon onClick={handleClose} />
          </div>
          <Box sx={{height: '70vh'}}>
            <EditFilesTable
              downloadFileHandler={() => null}
              mainBackupHandleColumnClick={() => <></>}
              mainBackupSortConfig={{column: '', direction: 'desc'}}
              mainBackupsDebouncedSearch=""
              metaData={null}
              modifiedMainBackups={modilfiedFiles}
              editingFiles={editingFiles}
              openAssignBackupModalHandler={() => null}
              openInNewTabHandler={() => null}
              openMarkAsDeletedModalHandler={() => {}}
              setModifiedMainBackups={setModifiedMainBackups}
              setEditingFiles={setEditingFiles}
            />
          </Box>
          <Box sx={{display: 'flex', gap: '15px', mt: '1.5rem'}}>
            <Button
              sx={tableMoreDotsMenu}
              onClick={() => openAssignBackupModalHandler(editingFiles)}
            >
              <ReplaceIcon />
              <span>Assign to another station</span>
            </Button>
            <Button
              sx={tableMoreDotsMenu}
              onClick={() => openMarkAsDeletedModalHandler(editingFiles)}
            >
              <MarkIcon />
              <span>
                {checkDates(editingFiles) ? 'Recover' : 'Mark as deleted'}
              </span>
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EditBackupModalFiles;
