import React, {FC} from 'react';
import {useNavigate} from 'react-router-dom';
import {IGetManifestsList} from 'store/models/manifest';
import {IMetadata} from 'store/models/common';
import {useGetNumberOfElements} from 'hooks';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import styles from '../Manifest.module.scss';

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    wordBreak: 'normal',
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: {
    ...styledTableBody,
    padding: '18px 16px 18px 16px',
  },
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  manifests: IGetManifestsList[];
  metadata: IMetadata | null;
}

const ManifestsTable: FC<Props> = (props) => {
  const navigate = useNavigate();

  const itemsOnPage = useGetNumberOfElements(props.metadata);

  return (
    <div style={{height: '100%'}}>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '25%'}}>
                <Box sx={tableHeadCell}>
                  <span>Type</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '10%'}}>
                <Box sx={tableHeadCell}>
                  <span>Station Type</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '60%'}}>
                <Box sx={tableHeadCell}>
                  <span>Stations</span>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.manifests.map((manifest, index) => (
              <StyledTableRow
                sx={{cursor: 'pointer'}}
                onClick={() =>
                  navigate(`/manifests/information/${manifest.id}`)
                }
                key={manifest.id}
              >
                <StyledTableCell>
                  {itemsOnPage.length ? itemsOnPage[index] : null}
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {manifest.type === 'Default' ? (
                      <span className={styles.manifestDefaultType}>
                        {manifest.type}
                      </span>
                    ) : (
                      manifest.type
                    )}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  {manifest.stationTypeName || '---'}
                </StyledTableCell>
                <StyledTableCell>
                  <Box sx={{display: 'inline-block'}}>
                    <CustomWidthTooltip
                      title={manifest.stations.join(', ')}
                      arrow
                    >
                      <span className={styles.manifestStation}>
                        {manifest.stations.length}
                      </span>
                    </CustomWidthTooltip>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManifestsTable;
