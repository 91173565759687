import {api} from './api';
import {IGetBackupFileTypes} from '../models/enums';

const enums = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getReportTypes: builder.query({
        query: () => {
          return {
            url: 'enums/report-types',
            method: 'get',
          };
        },
        keepUnusedDataFor: 0,
      }),
      getMainBackupFileTypes: builder.query<IGetBackupFileTypes[], void>({
        query: () => {
          return {
            url: 'enums/main-backup-file-types',
            method: 'get',
          };
        },
        keepUnusedDataFor: 0,
      }),
    };
  },
});

export const {useGetReportTypesQuery, useGetMainBackupFileTypesQuery} = enums;
