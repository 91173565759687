import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {Alert, Box, Button, CircularProgress, Snackbar} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {
  cancelButton,
  warningModalButton as createButton,
} from 'styles/MUIStyles/buttons';
import {
  useEditCompanyMutation,
  useLazyGetCompanyByIdQuery,
} from 'store/slices/companies';
import styles from './CompaniesEdit.module.scss';
import {ISnackbarState} from '../../../store/models/common';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from '../../../styles/MUIStyles/snackbars';
import {CloseIcon, SaveIcon, SuccessNotification} from '../../../assets/icons';
import {useUpdateEffect} from '../../../hooks';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';

interface IFormData {
  name: string;
  address: string;
  registrationNumber: string;
  vat: string;
  bank: string;
  bankAccount: string;
  bankCode: string;
  phone: string;
}

const CompaniesEdit: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      name: '',
      address: '',
      registrationNumber: '',
      vat: '',
      bank: '',
      bankCode: '',
      bankAccount: '',
      phone: '',
    },
  });

  const [
    getCompanyById,
    {data: companyInfo, isFetching: isGettingCompanyByIdLoading},
  ] = useLazyGetCompanyByIdQuery();

  const [
    editCompany,
    {
      isLoading: isEditingCompanyLoading,
      isSuccess: isEditCompanySuccess,
      error: editCompanyErrors,
    },
  ] = useEditCompanyMutation();

  const [successEditSnackbar, setSuccessEditSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: editVertical,
    horizontal: editHorizontal,
    open: editOpen,
  } = successEditSnackbar;

  const onSubmit = (formData: IFormData) => {
    if (params?.companyId) {
      editCompany({
        ...formData,
        id: params.companyId,
        name: formData.name.trim(),
        address: formData.address.trim(),
        registrationNumber: formData.registrationNumber.trim(),
        vat: formData.vat.trim(),
        bank: formData.bank.trim(),
        bankCode: formData.bankCode.trim(),
        bankAccount: formData.bankAccount.trim(),
        phone: formData.phone.trim(),
      });
    }
  };

  const handleSnackbarClose = () => {
    setSuccessEditSnackbar({...successEditSnackbar, open: false});
  };

  useEffect(() => {
    if (params?.companyId) {
      getCompanyById(params.companyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.companyId]);

  useEffect(() => {
    if (companyInfo) {
      setValue('name', companyInfo.name);
      setValue('registrationNumber', companyInfo.registrationNumber || '');
      setValue('address', companyInfo.address || '');
      setValue('vat', companyInfo.vat || '');
      setValue('bank', companyInfo.bank || '');
      setValue('bankCode', companyInfo.bankCode || '');
      setValue('bankAccount', companyInfo.bankAccount || '');
      setValue('phone', companyInfo.phone || '');
    }
  }, [companyInfo, setValue]);

  useUpdateEffect(() => {
    if (isEditCompanySuccess) {
      setSuccessEditSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [isEditCompanySuccess]);

  useEffect(() => {
    if (editCompanyErrors) {
      if ('data' in editCompanyErrors) {
        const errors = editCompanyErrors.data?.errors;
        if (errors?.name?.[0] || errors?.Name?.[0]) {
          setError('name', {
            type: 'custom',
            message: errors?.name?.[0] || errors?.Name?.[0],
          });
        }
        if (
          errors?.registrationNumber?.[0] ||
          errors?.RegistrationNumber?.[0]
        ) {
          setError('registrationNumber', {
            type: 'custom',
            message:
              errors?.registrationNumber?.[0] ||
              errors?.RegistrationNumber?.[0],
          });
        }
      }
    }
  }, [editCompanyErrors, setError]);

  return (
    <div style={{height: '100%'}}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.companiesEditForm}
      >
        {isGettingCompanyByIdLoading ? (
          <CircularProgress
            size={45}
            sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
          />
        ) : (
          <>
            <div className={styles.companiesEditTop}>
              <h1 className={styles.companiesEditTitle}>Edit Company</h1>
              <div className={styles.companiesButtons}>
                <Button onClick={() => navigate(-1)} sx={cancelButton}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  startIcon={
                    isEditingCompanyLoading ? (
                      <LoadingIconSpacer width={'16px'} height={'16px'} />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  disabled={isEditingCompanyLoading}
                  loading={isEditingCompanyLoading}
                  type="submit"
                  sx={createButton}
                >
                  Save
                </ButtonWithProgress>
              </div>
            </div>
            <div className={styles.companiesEditMainContent}>
              <div className={styles.nameInputContainer}>
                <label htmlFor="name">Name</label>
                <input
                  {...register('name', {required: 'This field is required'})}
                  type="text"
                  id="name"
                  autoComplete="off"
                  className={`${errors?.name ? 'input inputError' : 'input'}`}
                  placeholder="Enter company name"
                />
                <ErrorMessage
                  name="name"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.registrationNumberInputContainer}>
                <label htmlFor="registrationNumber">Registration number</label>
                <input
                  {...register('registrationNumber')}
                  type="text"
                  id="registrationNumber"
                  autoComplete="off"
                  className={`${
                    errors?.registrationNumber ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter registration number"
                />
                <ErrorMessage
                  name="registrationNumber"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.addressInputContainer}>
                <label htmlFor="address">Address</label>
                <input
                  {...register('address')}
                  type="text"
                  id="address"
                  autoComplete="off"
                  className={`${
                    errors?.address ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter address"
                />
                <ErrorMessage
                  name="address"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="vat">VAT number</label>
                <input
                  {...register('vat')}
                  type="text"
                  id="vat"
                  autoComplete="off"
                  className={`${errors?.vat ? 'input inputError' : 'input'}`}
                  placeholder="Enter VAT number"
                />
                <ErrorMessage
                  name="vat"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.addressInputContainer}>
                <label htmlFor="bank">Bank</label>
                <input
                  {...register('bank')}
                  type="text"
                  id="bank"
                  autoComplete="off"
                  className={`${errors?.bank ? 'input inputError' : 'input'}`}
                  placeholder="Enter bank"
                />
                <ErrorMessage
                  name="bank"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="bankCode">Bank Code</label>
                <input
                  {...register('bankCode')}
                  type="text"
                  id="bankCode"
                  autoComplete="off"
                  className={`${
                    errors?.bankCode ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter bank code"
                />
                <ErrorMessage
                  name="bankCode"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="bankAccount">Bank Account</label>
                <input
                  {...register('bankAccount')}
                  type="text"
                  id="bankAccount"
                  autoComplete="off"
                  className={`${
                    errors?.bankAccount ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter bank account"
                />
                <ErrorMessage
                  name="bankAccount"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="phone">Phone</label>
                <input
                  {...register('phone')}
                  type="text"
                  id="phone"
                  autoComplete="off"
                  className={`${errors?.phone ? 'input inputError' : 'input'}`}
                  placeholder="Enter phone"
                />
                <ErrorMessage
                  name="phone"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
            </div>
          </>
        )}
        <Snackbar
          anchorOrigin={{vertical: editVertical, horizontal: editHorizontal}}
          autoHideDuration={2500}
          open={editOpen}
          onClose={handleSnackbarClose}
          key={'editSnackbar'}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              The data was successfully saved
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      </form>
    </div>
  );
};

export default CompaniesEdit;
