import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Modal,
  Snackbar,
  SnackbarOrigin,
  Stack,
  styled,
  Switch,
} from '@mui/material';
import {CloseIcon, SaveIcon, SuccessNotification} from 'assets/icons';
import {
  cancelButton,
  resetPasswordButtonModal,
  warningModalButton as createButton,
} from 'styles/MUIStyles/buttons';
import {switchLabel} from 'styles/MUIStyles/common';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {modal} from 'styles/MUIStyles/modal';
import styles from './DealersEdit.module.scss';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';
import {
  useEditDealerMutation,
  useLazyGetDealerByIdQuery,
  useResetPasswordMutation,
} from '../../../store/slices/dealers';
import {useUpdateEffect} from '../../../hooks';

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  additionalInformation: string;
  isActive: boolean;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const AndroidSwitch = styled(Switch)(() => ({
  padding: 8,
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: 'rgba(96, 93, 236, 1)',
    opacity: 1,
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'gray',
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

const DealersEdit: FC = () => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      additionalInformation: '',
      isActive: false,
    },
  });

  const [getDealerById, {data: dealerData, isFetching}] =
    useLazyGetDealerByIdQuery();

  const [
    editDealer,
    {
      isLoading: isEditingDealerLoading,
      isSuccess: isEditingDealerSuccess,
      error: editDealerError,
    },
  ] = useEditDealerMutation();

  const [
    resetDealerPassword,
    {
      isLoading: isResettingPasswordLoading,
      isSuccess: isResettingPasswordSuccess,
    },
  ] = useResetPasswordMutation();

  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [snackbar, setSnackbar] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [successEditSnackbar, setSuccessEditSnackbar] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = snackbar;
  const {
    vertical: editVertical,
    horizontal: editHorizontal,
    open: editOpen,
  } = successEditSnackbar;

  const onSubmit = async (dealersData: IFormData) => {
    if (dealerData?.id) {
      editDealer({
        ...dealersData,
        id: dealerData.id,
        firstName: dealersData.firstName.trim(),
        lastName: dealersData.lastName.trim(),
        email: dealersData.email.trim(),
        phoneNumber: dealersData.phoneNumber.trim(),
        additionalInformation: dealersData.additionalInformation.trim(),
      });
    }
  };

  const handleClose = () => {
    setSnackbar({...snackbar, open: false});
    setSuccessEditSnackbar({...successEditSnackbar, open: false});
  };

  const handleResetPasswordModalClose = () => {
    setIsResetPasswordModalOpen(false);
  };

  useEffect(() => {
    if (params?.dealerId) {
      getDealerById(params.dealerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.dealerId]);

  useEffect(() => {
    if (dealerData) {
      setValue('firstName', dealerData.firstName);
      setValue('lastName', dealerData.lastName);
      setValue('email', dealerData.email);
      setValue('phoneNumber', dealerData.phoneNumber);
      setValue('additionalInformation', dealerData.additionalInformation || '');
      setValue('isActive', dealerData.isActive);
    }
  }, [dealerData, setValue]);

  useUpdateEffect(() => {
    if (isEditingDealerSuccess) {
      setSuccessEditSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }

    if (isResettingPasswordSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      handleResetPasswordModalClose();
    }
  }, [isResettingPasswordSuccess, isEditingDealerSuccess]);

  useUpdateEffect(() => {
    if (editDealerError) {
      if ('data' in editDealerError) {
        const errors = editDealerError.data?.errors;
        if (errors?.email?.[0] || errors?.Email?.[0]) {
          setError('email', {
            type: 'custom',
            message: errors?.email?.[0] || errors?.Email?.[0],
          });
        }

        if (errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0]) {
          setError('phoneNumber', {
            type: 'custom',
            message: errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0],
          });
        }
      }
    }
  }, [editDealerError, setError]);

  return (
    <div style={{height: '100%'}}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.dealersEditForm}
      >
        {isFetching ? (
          <CircularProgress
            size={45}
            sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
          />
        ) : (
          <>
            <div className={styles.dealersEditTop}>
              <h1 className={styles.dealersEditTitle}>Edit dealer</h1>
              <div className={styles.dealersButtons}>
                <Button onClick={() => navigate(-1)} sx={cancelButton}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  startIcon={
                    isEditingDealerLoading ? (
                      <LoadingIconSpacer width={'16px'} height={'16px'} />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  disabled={isEditingDealerLoading}
                  loading={isEditingDealerLoading}
                  type="submit"
                  sx={createButton}
                >
                  Save
                </ButtonWithProgress>
              </div>
            </div>
            <div className={styles.dealersEditMainContent}>
              <div className={styles.firstNameInputContainer}>
                <label htmlFor="firstName">First Name</label>
                <input
                  {...register('firstName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="firstName"
                  className={`${
                    errors?.firstName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter First Name"
                />
                <ErrorMessage
                  name="firstName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.lastNameInputContainer}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  {...register('lastName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="lastName"
                  className={`${
                    errors?.lastName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter Last Name"
                />
                <ErrorMessage
                  name="lastName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.emailInputContainer}>
                <label htmlFor="email">Email</label>
                <input
                  {...register('email', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address',
                    },
                  })}
                  type="text"
                  id="email"
                  autoComplete="off"
                  className={`${errors?.email ? 'input inputError' : 'input'}`}
                  placeholder="Enter Email"
                />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.phoneNumberInputContainer}>
                <label htmlFor="phoneNumber">Phone number</label>
                <input
                  {...register('phoneNumber', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="phoneNumber"
                  className={`${
                    errors?.phoneNumber ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter phone number"
                />
                <ErrorMessage
                  name="phoneNumber"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.additionalInfoInputContainer}>
                <label htmlFor="additionalInformation">
                  Additional information - Optional
                </label>
                <input
                  {...register('additionalInformation')}
                  type="text"
                  id="additionalInformation"
                  className={`input`}
                  placeholder="Enter additional information"
                />
              </div>

              <div className={styles.dealerEditControls}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Controller
                    name={'isActive'}
                    control={control}
                    render={({field}) => (
                      <FormGroup>
                        <FormControlLabel
                          sx={switchLabel}
                          control={
                            <AndroidSwitch {...field} checked={field.value} />
                          }
                          label={field.value ? 'active' : 'inactive'}
                        />
                      </FormGroup>
                    )}
                  />
                </Stack>
                <Button
                  onClick={() => setIsResetPasswordModalOpen(true)}
                  sx={cancelButton}
                >
                  Reset Password
                </Button>
              </div>
            </div>
          </>
        )}
      </form>

      <Modal
        open={isResetPasswordModalOpen}
        onClose={handleResetPasswordModalClose}
      >
        <Box sx={{...modal, width: '45%'}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '10px',
            }}
          >
            <h2 className={styles.dealersResetPasswordMainTitle}>
              Reset Password ?
            </h2>
            <div
              className={styles.closeIcon}
              onClick={handleResetPasswordModalClose}
            >
              <CloseIcon />
            </div>
          </Box>
          <div className={styles.resetPasswordTextContainer}>
            <span>Are you sure you want to reset dealer's password ?</span>
            <span>Password reset link will be sent to email</span>
            <span>{dealerData?.email}</span>
          </div>
          <Divider sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}} />
          <div className={styles.buttonsContainer}>
            <Button sx={cancelButton} onClick={handleResetPasswordModalClose}>
              Cancel
            </Button>
            <ButtonWithProgress
              type="button"
              disabled={isResettingPasswordLoading}
              loading={isResettingPasswordLoading}
              onClick={() => {
                if (params?.dealerId) {
                  resetDealerPassword(params.dealerId);
                }
              }}
              sx={resetPasswordButtonModal}
            >
              Yes, reset
            </ButtonWithProgress>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{vertical, horizontal}}
        autoHideDuration={2500}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            Password sent to email {dealerData?.email}
            <span onClick={handleClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{vertical: editVertical, horizontal: editHorizontal}}
        autoHideDuration={2500}
        open={editOpen}
        onClose={handleClose}
        key={'editSnackbar'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            The data was successfully saved
            <span onClick={handleClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DealersEdit;
