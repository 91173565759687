import React, {FC} from 'react';
import {IMetadata} from 'store/models/clients';
import {
  createTheme,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
} from '@mui/material';
import {pageSizes} from 'core/constants';
import {SelectPopupIcon} from 'assets/icons';
import search from 'assets/icons/search.svg';
import {selectRowsPaperProps} from 'styles/MUIStyles/common';
import styles from '../Companies.module.scss';

interface Props {
  search: string;
  setSearch: (search: string) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  metaData: IMetadata | null;
}

const CompaniesTopToolbar: FC<Props> = (props) => {
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: '#8A8894',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
              borderRadius: '12px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #3152C7',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div style={{width: '100%', display: 'flex', gap: '20px'}}>
        <div className={styles.companiesTopToolbarSearch}>
          <input
            name="companisTopToolbarSearch"
            type="text"
            className="input"
            placeholder="Search"
            autoComplete="off"
            value={props.search}
            onChange={(e) => props.setSearch(e.target.value)}
          />
          <img className={styles.searchIcon} src={search} alt="search icon" />
        </div>
        <div className={styles.topToolbarSelectContainer}>
          {props.metaData && (
            <>
              <span>Show rows:</span>
              <FormControl size="small">
                <Select
                  name="companiesTopToolbarPageCount"
                  value={+props.pageSize}
                  onChange={(e) => {
                    props.setPageSize(+e.target.value);
                  }}
                  IconComponent={(props) => <SelectPopupIcon {...props} />}
                  MenuProps={{
                    MenuListProps: {disablePadding: true},
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: selectRowsPaperProps,
                  }}
                >
                  {pageSizes.map((page) => {
                    return (
                      <MenuItem key={page} value={page}>
                        {page}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <span>{`${props?.metaData?.firstItemOnPage}-${props?.metaData?.lastItemOnPage} of ${props?.metaData?.totalItemCount}`}</span>
            </>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default CompaniesTopToolbar;
