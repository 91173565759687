import React, {FC, memo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetNumberOfElements, useUpdateEffect} from 'hooks';
import {ISortConfig} from 'hooks/useSortColumn';
import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  SnackbarOrigin,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {
  ICompanyOwnersList,
  ICompanyOwnersList as ICompanyOwner,
} from 'store/models/companyOwners';
import CompanyOwnersAddCompany from '../CompanyOwnersAddCompany/CompanyOwnersAddCompany';
import WarningModal from 'components/WarningModal/WarningModal';
import {
  ArrowAsc,
  ArrowDesc,
  CloseIcon,
  DefaultSortIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  PlusIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {IMetadata} from 'store/models/common';
import {
  useDeleteCompanyOwnerMutation,
  useRecoverCompanyOwnerMutation,
} from 'store/slices/companyOwners';
import styles from './CompanyOwnersTable.module.scss';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  companyOwners: ICompanyOwnersList[];
  metaData: IMetadata | null;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
  search: string;
  isRequestWithDeletedStatusSuccess: boolean;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const CompanyOwnersTable: FC<Props> = memo((props) => {
  const navigate = useNavigate();

  const [
    deleteCompanyOwner,
    {
      isLoading: isDeletingCompanyOwnerLoading,
      isSuccess: isDeletingCompanyOwnerSuccess,
    },
  ] = useDeleteCompanyOwnerMutation();

  const [
    recoverCompanyOwner,
    {
      isLoading: isRecoveringCompanyOwnerLoading,
      isSuccess: isRecoveringCompanyOwnerSuccess,
    },
  ] = useRecoverCompanyOwnerMutation();

  const itemsOnPage = useGetNumberOfElements(props.metaData);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [isRecoverCompanyOwnerModalOpen, setIsRecoveryCompanyOwnerModalOpen] =
    useState(false);
  const [isAddCompanyToOwnerModalOpen, setIsAddCompanyToOwnerModalOpen] =
    useState(false);
  const [clickedCompanyOwner, setClickedCompanyOwner] =
    useState<ICompanyOwner | null>(null);
  const [isDeleteCompanyOwnerModalOpen, setIsDeleteCompanyOwnerModal] =
    useState(false);
  const [deleteCompanyOwnerSnackbar, setDeleteCompanyOwnerSnackbar] =
    useState<State>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [recoverCompanyOwnerSnackbar, setRecoverCompanyOwnerSnackbar] =
    useState<State>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const {
    vertical: deleteVertical,
    horizontal: deleteHorizontal,
    open: deleteOpen,
  } = deleteCompanyOwnerSnackbar;
  const {
    vertical: recoverVertical,
    horizontal: recoverHorizontal,
    open: recoverOpen,
  } = recoverCompanyOwnerSnackbar;

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleIsAddCompanyToOwnerModalOpen = () =>
    setIsAddCompanyToOwnerModalOpen(true);
  const handleIsAddCompanyToOwnerModalClose = () =>
    setIsAddCompanyToOwnerModalOpen(false);
  const handleMoreDotsClose = () => setAnchorEl(null);

  const handleSnackbarClose = () => {
    setDeleteCompanyOwnerSnackbar({
      ...deleteCompanyOwnerSnackbar,
      open: false,
    });
    setRecoverCompanyOwnerSnackbar({
      ...recoverCompanyOwnerSnackbar,
      open: false,
    });
  };

  useUpdateEffect(() => {
    if (isDeletingCompanyOwnerSuccess) {
      setDeleteCompanyOwnerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteCompanyOwnerModal(false);
    }

    if (isRecoveringCompanyOwnerSuccess) {
      setRecoverCompanyOwnerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoveryCompanyOwnerModalOpen(false);
    }
  }, [isDeletingCompanyOwnerSuccess, isRecoveringCompanyOwnerSuccess]);

  return (
    <div style={{height: '100%'}}>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>
                <Box>№</Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '25%'}}>
                <Box
                  onClick={() => props.handleColumnClick('email')}
                  sx={tableHeadCell}
                >
                  <span>Email</span>
                  {getArrowForSortDirection('email')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  onClick={() => props.handleColumnClick('firstName')}
                  sx={tableHeadCell}
                >
                  <span>First Name</span>
                  {getArrowForSortDirection('firstName')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  onClick={() => props.handleColumnClick('lastName')}
                  sx={tableHeadCell}
                >
                  <span>Last Name</span>
                  {getArrowForSortDirection('lastName')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '15%'}}>
                <Box
                  onClick={() => props.handleColumnClick('phoneNumber')}
                  sx={tableHeadCell}
                >
                  <span>Phone Number</span>
                  {getArrowForSortDirection('phoneNumber')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '10%'}}>
                <Box
                  onClick={() => props.handleColumnClick('isActive')}
                  sx={tableHeadCell}
                >
                  <span>Status</span>
                  {getArrowForSortDirection('isActive')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '5%'}}>{null}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.companyOwners.map((companyOwner, index) => (
              <StyledTableRow
                key={companyOwner.id}
                sx={{cursor: 'pointer'}}
                onClick={() =>
                  navigate(`/companyOwners/information/${companyOwner.id}`)
                }
              >
                <StyledTableCell>
                  {itemsOnPage.length ? itemsOnPage[index] : null}
                </StyledTableCell>
                <StyledTableCell>{companyOwner.email}</StyledTableCell>
                <StyledTableCell>{companyOwner.firstName}</StyledTableCell>
                <StyledTableCell>{companyOwner.lastName}</StyledTableCell>
                <StyledTableCell>{companyOwner.phoneNumber}</StyledTableCell>
                <StyledTableCell>
                  {props.isRequestWithDeletedStatusSuccess ? (
                    <span
                      className={`${styles.companyOwnerStatus} ${styles.deleted}`}
                    >
                      Removed
                    </span>
                  ) : (
                    <span
                      className={
                        companyOwner.isActive
                          ? `${styles.companyOwnerStatus} ${styles.active}`
                          : `${styles.companyOwnerStatus} ${styles.inactive}`
                      }
                    >
                      {companyOwner.isActive ? 'Active' : 'Inactive'}
                    </span>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    id="basic-button"
                    aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isMenuOpen ? 'true' : undefined}
                    onClick={(event) => {
                      event.stopPropagation();
                      setClickedCompanyOwner(companyOwner);
                      handleMoreDotsClick(event);
                    }}
                  >
                    <MoreIcon />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {props.isRequestWithDeletedStatusSuccess ? (
          <MenuItem
            sx={tableMoreDotsMenu}
            onClick={() => {
              handleMoreDotsClose();
              setIsRecoveryCompanyOwnerModalOpen(true);
            }}
          >
            <RecoverIcon />
            <span>Recover</span>
          </MenuItem>
        ) : (
          <div>
            <MenuItem
              sx={tableMoreDotsMenu}
              onClick={() => {
                if (clickedCompanyOwner) {
                  navigate('/companyOwners/edit/' + clickedCompanyOwner.id);
                }
              }}
            >
              <EditIcon />
              <span>Edit</span>
            </MenuItem>
            <MenuItem
              sx={tableMoreDotsMenu}
              onClick={() => {
                handleMoreDotsClose();
                handleIsAddCompanyToOwnerModalOpen();
              }}
            >
              <PlusIcon />
              <span>Add company</span>
            </MenuItem>
            <MenuItem
              sx={{...tableMoreDotsMenu, marginBottom: '0'}}
              onClick={() => {
                handleMoreDotsClose();
                setIsDeleteCompanyOwnerModal(true);
              }}
            >
              <DeleteIcon />
              <span>Remove</span>
            </MenuItem>
          </div>
        )}
      </Menu>
      {clickedCompanyOwner ? (
        <>
          <WarningModal
            isOpen={
              props.isRequestWithDeletedStatusSuccess
                ? isRecoverCompanyOwnerModalOpen
                : isDeleteCompanyOwnerModalOpen
            }
            onClose={
              props.isRequestWithDeletedStatusSuccess
                ? () => setIsRecoveryCompanyOwnerModalOpen(false)
                : () => setIsDeleteCompanyOwnerModal(false)
            }
            entityText={clickedCompanyOwner.email}
            title={`${
              props.isRequestWithDeletedStatusSuccess ? 'Recover' : 'Remove'
            } company owner ?`}
            textBlocks={[
              `Are you sure you want to ${
                props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
              } company owner`,
            ]}
            confirmButtonText={`Yes, ${
              props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
            }`}
            onConfirmButtonClick={
              props.isRequestWithDeletedStatusSuccess
                ? () => recoverCompanyOwner(clickedCompanyOwner.id)
                : () => deleteCompanyOwner(clickedCompanyOwner.id)
            }
            loading={
              isDeletingCompanyOwnerLoading || isRecoveringCompanyOwnerLoading
            }
          />
          <Snackbar
            anchorOrigin={{
              vertical: props.isRequestWithDeletedStatusSuccess
                ? recoverVertical
                : deleteVertical,
              horizontal: props.isRequestWithDeletedStatusSuccess
                ? recoverHorizontal
                : deleteHorizontal,
            }}
            autoHideDuration={2500}
            open={
              props.isRequestWithDeletedStatusSuccess ? recoverOpen : deleteOpen
            }
            onClose={handleSnackbarClose}
            key={`${
              props.isRequestWithDeletedStatusSuccess
                ? 'recoverCompanyOwner'
                : 'deleteCompanyOwner'
            }`}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Company owner {`"${clickedCompanyOwner.email}"`} was{' '}
                {props.isRequestWithDeletedStatusSuccess
                  ? 'restored'
                  : 'removed'}
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
      <CompanyOwnersAddCompany
        isOpen={isAddCompanyToOwnerModalOpen}
        onClose={handleIsAddCompanyToOwnerModalClose}
        clickedCompanyOwner={clickedCompanyOwner}
      />
    </div>
  );
});

export default CompanyOwnersTable;
