import React, {FC} from 'react';

import Guard from 'components/Guard/Guard';
import {IGetBranchById} from 'store/models/branches';
import {Link} from 'react-router-dom';
import {LinkIcon} from 'assets/icons';
import {UserRole} from 'enums';
import styles from '../BranchesInformation.module.scss';

interface Props {
  branchInfo: IGetBranchById | undefined;
}

const InformationTab: FC<Props> = (props) => {
  return (
    <div>
      <div className={styles.branchUserInfo}>
        {props?.branchInfo?.name ? (
          <div>
            <span>Name</span>
            <span>{props?.branchInfo?.name}</span>
          </div>
        ) : null}

        {props?.branchInfo?.address ? (
          <div>
            <span>Address</span>
            <span>{props?.branchInfo?.address}</span>
          </div>
        ) : null}

        {props?.branchInfo?.companyName ? (
          <div>
            <span>Company name</span>
            <span>
              {props?.branchInfo?.companyName}{' '}
              <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
                <Link
                  to={`/companies/information/${props?.branchInfo?.companyId}`}
                >
                  <LinkIcon style={{height: '15px', width: '15px'}} />
                </Link>
              </Guard>
            </span>
          </div>
        ) : null}

        {props?.branchInfo?.stations.length ? (
          <div className={styles.branchStationsList}>
            <h3>Stations</h3>
            {props?.branchInfo?.stations.map((station) => (
              <span key={station.id}>
                {station.name}{' '}
                <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
                  <Link to={`/stations/information/${station.id}`}>
                    <LinkIcon style={{height: '15px', width: '15px'}} />
                  </Link>
                </Guard>
              </span>
            ))}
          </div>
        ) : null}

        {props?.branchInfo?.clients.length ? (
          <div className={styles.branchStationsList}>
            <h3>Users</h3>
            {props?.branchInfo?.clients.map((client) => (
              <span key={client.id}>
                {client.firstName} {client.lastName}{' '}
                <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
                  <Link to={`/users/information/${client.id}`}>
                    <LinkIcon style={{height: '15px', width: '15px'}} />
                  </Link>
                </Guard>
              </span>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InformationTab;
