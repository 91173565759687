import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {useUpdateEffect} from 'hooks';
import {ISnackbarState} from 'store/models/common';
import {
  useEditBranchMutation,
  useLazyGetBranchByIdQuery,
  useLazyGetCompaniesToCreateBranchQuery,
} from 'store/slices/branches';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  TextField,
} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {
  CloseIcon,
  SaveIcon,
  SelectPopupIcon,
  SuccessNotification,
} from 'assets/icons';
import {
  cancelButton,
  warningModalButton as createButton,
} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {searchAndSelect, searchAndSelectError} from 'styles/MUIStyles/common';
import styles from './BranchesEdit.module.scss';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';

interface IFormData {
  name: string;
  address: string;
  company: {
    id: string;
    name: string;
  };
}

const BranchesEdit: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      name: '',
      address: '',
      company: {
        id: '',
        name: '',
      },
    },
  });

  const [
    getCompanies,
    {data: companies, isFetching: isGettingCompaniesLoading},
  ] = useLazyGetCompaniesToCreateBranchQuery();

  const [
    getBranchById,
    {data: branchInfo, isFetching: isGettingBranchLoading},
  ] = useLazyGetBranchByIdQuery();

  const [
    editBranch,
    {isLoading: isEditingBranchLoading, isSuccess: isEditingBranchSuccess},
  ] = useEditBranchMutation();

  const [successEditSnackbar, setSuccessEditSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: editVertical,
    horizontal: editHorizontal,
    open: editOpen,
  } = successEditSnackbar;

  const onSubmit = (formData: IFormData) => {
    if (params?.branchId) {
      editBranch({
        id: params.branchId,
        companyId: formData.company.id,
        name: formData.name.trim(),
        address: formData.address.trim(),
      });
    }
  };

  const handleSnackbarClose = () => {
    setSuccessEditSnackbar({...successEditSnackbar, open: false});
  };

  useEffect(() => {
    if (params?.branchId) {
      getBranchById(params.branchId);
      getCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.branchId]);

  useEffect(() => {
    if (branchInfo) {
      setValue('name', branchInfo.name);
      setValue('address', branchInfo.address || '');
      if (companies && companies?.length) {
        const company = companies.find(
          (company) => company.id === branchInfo.companyId,
        );

        if (company) {
          setValue('company', company);
        }
      }
    }
  }, [branchInfo, setValue, companies]);

  useUpdateEffect(() => {
    if (isEditingBranchSuccess) {
      setSuccessEditSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [isEditingBranchSuccess]);

  return (
    <div style={{height: '100%'}}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.branchesEditForm}
      >
        {isGettingBranchLoading ? (
          <CircularProgress
            size={45}
            sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
          />
        ) : (
          <>
            <div className={styles.branchesEditTop}>
              <h1 className={styles.branchesEditTitle}>Edit Branch</h1>
              <div className={styles.branchesButtons}>
                <Button onClick={() => navigate(-1)} sx={cancelButton}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  startIcon={
                    isEditingBranchLoading ? (
                      <LoadingIconSpacer width={'16px'} height={'16px'} />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  disabled={isEditingBranchLoading}
                  loading={isEditingBranchLoading}
                  type="submit"
                  sx={createButton}
                >
                  Save
                </ButtonWithProgress>
              </div>
            </div>
            <div className={styles.branchesEditMainContent}>
              <div className={styles.nameInputContainer}>
                <label htmlFor="name">Name</label>
                <input
                  {...register('name', {required: 'This field is required'})}
                  type="text"
                  id="name"
                  autoComplete="off"
                  className={`${errors?.name ? 'input inputError' : 'input'}`}
                  placeholder="Enter name"
                />
                <ErrorMessage
                  name="name"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="address">Address</label>
                <input
                  {...register('address')}
                  type="text"
                  id="address"
                  autoComplete="off"
                  className={`${
                    errors?.address ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter address"
                />
                <ErrorMessage
                  name="address"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div>
                <span className={styles.companyLabel}>Company</span>
                <Controller
                  name={'company'}
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value?.id) {
                        return 'This field is required';
                      }
                    },
                  }}
                  render={({field}) => (
                    <Autocomplete
                      {...field}
                      options={companies || []}
                      loading={isGettingCompaniesLoading}
                      popupIcon={<SelectPopupIcon />}
                      PaperComponent={(props) => (
                        <Paper
                          sx={{
                            borderRadius: '12px',
                            boxShadow: '0px 4px 20px 0px #4444441F',
                            marginTop: '8px',
                            cursor: 'pointer',
                          }}
                          {...props}
                        />
                      )}
                      sx={{width: '35%'}}
                      ListboxProps={{
                        style: {maxHeight: '200px'},
                        className: 'autoCompleteListBox',
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value.name === '' ? true : option.id === value.id
                      }
                      getOptionLabel={(option) =>
                        option?.name ? option.name : ''
                      }
                      onChange={(_, value) => {
                        if (value) {
                          field.onChange(value);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      renderOption={(props: any, option) => (
                        <span {...props} className={styles.dropDownItem}>
                          {option?.name}
                        </span>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select company name"
                          sx={
                            errors?.company
                              ? searchAndSelectError
                              : searchAndSelect
                          }
                        />
                      )}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name={'company'}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
            </div>
          </>
        )}
        <Snackbar
          anchorOrigin={{vertical: editVertical, horizontal: editHorizontal}}
          autoHideDuration={2500}
          open={editOpen}
          onClose={handleSnackbarClose}
          key={'editSnackbar'}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              The data was successfully saved
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      </form>
    </div>
  );
};

export default BranchesEdit;
