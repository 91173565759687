import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {
  useDebounce,
  useLocalStorage,
  useSortColumn,
  useUpdateEffect,
} from 'hooks';
import {Button, Pagination, PaginationItem} from '@mui/material';
import CompanyOwnersTable from './CompanyOwnersTable/CompanyOwnersTable';
import CompanyOwnersCreate from './CompanyOwnersCreate/CompanyOwnersCreate';
import CompanyOwnersTopToolbar from './CompanyOwnersTopToolbar/CompanyOwnersTopToolbar';
import {
  CreateIcon,
  PaginationArrowLeft,
  PaginationArrowRight,
} from 'assets/icons';
import {createButton} from 'styles/MUIStyles/buttons';
import {pagination} from 'styles/MUIStyles/common';
import styles from './CompanyOwners.module.scss';
import {useGetCompanyOwnersQuery} from 'store/slices/companyOwners';

const CompanyOwners: FC = () => {
  const [
    isRequestWithDeletedStatusSuccess,
    setIsRequestWithDeletedStatusSuccess,
  ] = useState(false);
  const [search, setSearch] = useLocalStorage<string>(
    'companyOwnersSearch',
    '',
  );
  const debouncedSearch = useDebounce(search, 800);
  const [status, setStatus] = useLocalStorage('companyOwnersStatus', null);
  const [sortConfig, handleColumnClick] = useSortColumn(
    'companyOwnersSortConfig',
  );
  const [pageNumber, setPageNumber] = useLocalStorage(
    'companyOwnersPageNumber',
    1,
  );
  const [pageSize, setPageSize] = useLocalStorage('companyOwnersPageSize', 10);
  const [isCreateCompanyOwnersModalOpen, setIsCreateCompanyOwnersModalOpen] =
    useState(false);

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const {
    data: companyOwnersData,
    originalArgs,
    isSuccess,
  } = useGetCompanyOwnersQuery({
    status,
    pageNumber,
    pageSize,
    search: debouncedSearch,
    sortBy: sortConfig,
  });

  useEffect(() => {
    if (
      originalArgs &&
      originalArgs?.status?.queryName === 'isDeleted' &&
      isSuccess
    ) {
      setIsRequestWithDeletedStatusSuccess(true);
    } else {
      setIsRequestWithDeletedStatusSuccess(false);
    }
  }, [originalArgs, isSuccess]);

  useUpdateEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1);
    }
  }, [debouncedSearch, status, pageSize]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.companyOwnersTop}>
        <h1 className={styles.companyOwnersTitle}>Company owners</h1>
        <Button
          variant="contained"
          startIcon={<CreateIcon />}
          onClick={() => setIsCreateCompanyOwnersModalOpen(true)}
          sx={createButton}
        >
          Create
        </Button>
      </div>
      <div className={styles.companyOwnersMainContent}>
        <div className={styles.tableFiltersTop}>
          <CompanyOwnersTopToolbar
            search={search}
            setSearch={setSearch}
            status={status}
            setStatus={setStatus}
            pageSize={pageSize}
            setPageSize={setPageSize}
            metaData={companyOwnersData?.metadata || null}
          />
        </div>
        <div style={{height: '87%', flexGrow: 1}}>
          <CompanyOwnersTable
            companyOwners={companyOwnersData?.data || []}
            metaData={companyOwnersData?.metadata || null}
            handleColumnClick={handleColumnClick}
            sortConfig={sortConfig}
            search={debouncedSearch}
            isRequestWithDeletedStatusSuccess={
              isRequestWithDeletedStatusSuccess
            }
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={companyOwnersData?.metadata?.pageCount || 1}
          page={pageNumber}
          onChange={handleOnPageChange}
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>
      <CompanyOwnersCreate
        isCreateCompanyOwnersModalOpen={isCreateCompanyOwnersModalOpen}
        setIsCreateCompanyOwnersModalOpen={setIsCreateCompanyOwnersModalOpen}
        search={debouncedSearch}
        status={status}
        sortConfig={sortConfig}
        pageNumber={pageNumber}
        pageSize={pageSize}
      />
    </div>
  );
};

export default CompanyOwners;
