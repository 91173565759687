import {
  IGetDCCollectorVersionListArgs,
  IGetDCCollectorVersionListResponse,
} from 'store/models/dataCollector';
import {api} from './api';

const dataCollector = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getDataCollectorVersionsList: builder.query<
        IGetDCCollectorVersionListResponse,
        IGetDCCollectorVersionListArgs
      >({
        query: (options) => {
          const params = new URLSearchParams();

          if (options.pageNumber) {
            params.append('pageNumber', String(options.pageNumber));
          }

          if (options.pageSize) {
            params.append('pageSize', String(options.pageSize));
          }
          if (options.orderBy?.column && options.orderBy.direction) {
            const column = options.orderBy?.column;
            const direction = options.orderBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `updater/versions?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['DataCollector'],
      }),
      uploadDCVersion: builder.mutation<number, FormData>({
        query: (formData) => {
          return {
            url: 'updater/add-version',
            method: 'post',
            data: formData,
            formData: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        },
        invalidatesTags: () => ['DataCollector'],
      }),
      pingDataCollector: builder.query<{isAlive: boolean}, string>({
        query: (stationId) => {
          return {
            url: `data-collectors/is-alive?stationId=${stationId}`,
            method: 'get',
          };
        },
      }),
    };
  },
});

export const {
  useGetDataCollectorVersionsListQuery,
  useUploadDCVersionMutation,
  useLazyPingDataCollectorQuery,
} = dataCollector;
