export enum UserRole {
  Admin = 'Admin',
  Dealer = 'Dealer',
  Owner = 'Owner',
  Client = 'Client',
}

export enum Status {
  Active = 'Active',
  Inactive = 'Inactive',
  Removed = 'Removed',
  Attached = 'Yes',
  NotAttached = 'No',
}

export enum Level {
  Fatal = 'Fatal',
  Information = 'Information',
  Error = 'Error',
  Warning = 'Warning',
  Debug = 'Debug',
  Verbose = 'Verbose',
}
