import React, {FC} from 'react';
import {IGetBranchById} from 'store/models/branches';
import dayjs from 'dayjs';
import styles from '../BranchesInformation.module.scss';

interface Props {
  branchInfo: IGetBranchById | undefined;
}

const ActivityLogTab: FC<Props> = (props) => {
  return (
    <div className={styles.branchActivityLogTab}>
      {props?.branchInfo?.audit?.creating ? (
        <div>
          <span className={styles.auditTitle}>Created at</span>
          <span className={styles.auditText}>
            {dayjs(props?.branchInfo?.audit.creating?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.branchInfo?.audit.creating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.branchInfo?.audit.creating?.userFullName}
          </span>
        </div>
      ) : null}
      {props?.branchInfo?.audit?.updating ? (
        <div>
          <span className={styles.auditTitle}>Updated at</span>
          <span className={styles.auditText}>
            {dayjs(props?.branchInfo?.audit.updating?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.branchInfo?.audit.updating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.branchInfo?.audit.updating?.userFullName}
          </span>
        </div>
      ) : null}
      {props?.branchInfo?.audit?.deleting ? (
        <div className={styles.auditTitle}>
          <span>Deleted at</span>
          <span className={styles.auditText}>
            {dayjs(props?.branchInfo?.audit.deleting?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.branchInfo?.audit.deleting?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.branchInfo?.audit.deleting?.userFullName}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ActivityLogTab;
