import React, {FC} from 'react';
import {IGetUpdaterAlertsList} from 'store/models/logs';
import {IMetadata} from 'store/models/common';
import {ISortConfig} from 'hooks/useSortColumn';
import AlertsTable from './AlertsTable/AlertsTable';
import AlertsTopToolbar from './AlertsTopToolbar/AlertsTopToolbar';
import {useLocalStorage} from 'hooks';
import styles from '../Logs.module.scss';
import {IGetCompaniesListItem} from 'store/models/companies';
import {IGetBranchesListItem} from 'store/models/branches';
import {StationsListItem} from 'store/models/stations';

interface Props {
  updaterAlertsData: IGetUpdaterAlertsList[];
  posChassisNumber: string;
  setPosChassisNumber: (search: string) => void;
  fiscalDeviceId: string;
  setFiscalDeviceId: (search: string) => void;
  alertsMessage: string;
  setAlertsMessage: (search: string) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
  alertsStartTime: string;
  setAlertsStartTime: (date: string | null) => void;
  alertsEndTime: string;
  setAlertsEndTime: (date: string | null) => void;
  metaData: IMetadata | null;
  companies: IGetCompaniesListItem[];
  companiesForAlerts: IGetCompaniesListItem[];
  setCompaniesForAlerts: (companies: IGetCompaniesListItem[]) => void;
  branches: IGetBranchesListItem[];
  branchesForAlerts: IGetBranchesListItem[];
  setBranchesForAlerts: (branches: IGetBranchesListItem[]) => void;
  stations: StationsListItem[];
  stationsForAlerts: StationsListItem[];
  setStationsForAlerts: (stations: StationsListItem[]) => void;
}

const Alerts: FC<Props> = (props) => {
  const [isShowAlertsFilterButtonOpen, setIsShowAlertsFilterButtonOpen] =
    useLocalStorage('isShowAlertsFilterButtonOpen', true);

  const clearAlertsFilters = () => {
    props.setAlertsStartTime(null);
    props.setAlertsEndTime(null);
    props.setPosChassisNumber('');
    props.setFiscalDeviceId('');
    props.setCompaniesForAlerts([]);
    props.setBranchesForAlerts([]);
    props.setStationsForAlerts([]);
  };

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.tableFiltersTop}>
        <AlertsTopToolbar
          posChassisNumber={props.posChassisNumber}
          setPosChassisNumber={props.setPosChassisNumber}
          pageSize={props.pageSize}
          setPageSize={props.setPageSize}
          fiscalDeviceId={props.fiscalDeviceId}
          setFiscalDeviceId={props.setFiscalDeviceId}
          alertsMessage={props.alertsMessage}
          setAlertsMessage={props.setAlertsMessage}
          alertsStartTime={props.alertsStartTime}
          setAlertsStartTime={props.setAlertsStartTime}
          alertsEndTime={props.alertsEndTime}
          setAlertsEndTime={props.setAlertsEndTime}
          isShowAlertsFilterButtonOpen={isShowAlertsFilterButtonOpen}
          setIsShowAlertsFilterButtonOpen={setIsShowAlertsFilterButtonOpen}
          metaData={props.metaData}
          clearAlertsFilters={clearAlertsFilters}
          companies={props.companies}
          companiesForAlerts={props.companiesForAlerts}
          setCompaniesForAlerts={props.setCompaniesForAlerts}
          branches={props.branches}
          branchesForAlerts={props.branchesForAlerts}
          setBranchesForAlerts={props.setBranchesForAlerts}
          stations={props.stations}
          stationsForAlerts={props.stationsForAlerts}
          setStationsForAlerts={props.setStationsForAlerts}
        />
      </div>
      <div
        style={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <AlertsTable
          updaterAlertsData={props.updaterAlertsData}
          metaData={props.metaData}
          sortConfig={props.sortConfig}
          handleColumnClick={props.handleColumnClick}
        />
      </div>
    </div>
  );
};

export default Alerts;
