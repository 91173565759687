import React, {FC, memo} from 'react';
import {IGetUpdaterAlertsList} from 'store/models/logs';
import {IMetadata} from 'store/models/common';
import {ISortConfig} from 'hooks/useSortColumn';
import {useGetNumberOfElements} from '../../../../hooks';
import {ArrowAsc, ArrowDesc, DefaultSortIcon} from '../../../../assets/icons';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from '../../../../styles/MUIStyles/table';
import styles from '../../LogsTable.module.scss';
import dayjs from 'dayjs';
import {TableComponents, TableVirtuoso} from 'react-virtuoso';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    wordBreak: 'normal',
  },
});

interface Props {
  updaterAlertsData: IGetUpdaterAlertsList[];
  metaData: IMetadata | null;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
}

const VirtuosoTableComponents: TableComponents<any> = {
  Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
    <TableContainer
      component={Paper}
      {...props}
      ref={ref}
      sx={tableContainer}
    />
  )),
  Table: (props) => <Table {...props} sx={{borderCollapse: 'separate'}} />,
  TableHead,
  TableRow: ({item: _item, ...props}) => {
    return <StyledTableRow {...props} key={_item.id} />;
  },
  TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const columns = [
  {label: '№', dataKey: 'number'},
  {label: 'Message', dataKey: 'message'},
  {label: 'Created date time', dataKey: 'createdDateTime'},
  {label: 'PosChassis number', dataKey: 'posChassicNumber'},
  {label: 'FiscalDevice id', dataKey: 'fiscalDeviceId'},
  {label: 'AdditionalInformation', dataKey: 'additionalInformation'},
];

const headerContent = (
  getArrowForSortDirection: (column: string) => React.ReactNode,
  handleColumnClick: (column: string) => void,
) => {
  return (
    <TableRow>
      {columns.map((column) => {
        switch (column.label) {
          case '№': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
            );
          }
          case 'Message': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '25%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => handleColumnClick('message')}
                >
                  <span>Message</span>
                  {getArrowForSortDirection('message')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'Created date time': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '18%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => handleColumnClick('createdDateTime')}
                >
                  <span>Created date time</span>
                  {getArrowForSortDirection('createdDateTime')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'PosChassis number': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '18%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => handleColumnClick('posChassicNumber')}
                >
                  <span>PosChassis number</span>
                  {getArrowForSortDirection('posChassicNumber')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'FiscalDevice id': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '14%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => handleColumnClick('fiscalDeviceId')}
                >
                  <span>FiscalDevice id</span>
                  {getArrowForSortDirection('fiscalDeviceId')}
                </Box>
              </StyledTableCell>
            );
          }
          case 'AdditionalInformation': {
            return (
              <StyledTableCell key={column.dataKey} sx={{width: '20%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => handleColumnClick('additionalInformation')}
                >
                  <span>Additional information</span>
                  {getArrowForSortDirection('additionalInformation')}
                </Box>
              </StyledTableCell>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </TableRow>
  );
};

const rowContent = (
  index: number,
  alert: IGetUpdaterAlertsList,
  itemsOnPage: number[],
) => {
  return (
    <React.Fragment>
      {columns.map((column) => {
        switch (column.label) {
          case '№': {
            return (
              <StyledTableCell key={column.dataKey}>
                {itemsOnPage.length ? itemsOnPage[index] : null}
              </StyledTableCell>
            );
          }
          case 'Message': {
            return (
              <StyledTableCell key={column.dataKey}>
                <Box sx={{display: 'inline-block'}}>
                  <CustomWidthTooltip title={alert.message} arrow>
                    <span className={styles.logsMessage}>{alert.message}</span>
                  </CustomWidthTooltip>
                </Box>
              </StyledTableCell>
            );
          }
          case 'Created date time': {
            return (
              <StyledTableCell key={column.dataKey}>
                {alert.createdDateTime
                  ? dayjs(alert.createdDateTime).format('DD.MM.YYYY HH:mm')
                  : null}
              </StyledTableCell>
            );
          }
          case 'PosChassis number': {
            return (
              <StyledTableCell key={column.dataKey}>
                {alert.posChassicNumber}
              </StyledTableCell>
            );
          }
          case 'FiscalDevice id': {
            return (
              <StyledTableCell key={column.dataKey}>
                {alert.fiscalDeviceId}
              </StyledTableCell>
            );
          }
          case 'AdditionalInformation': {
            return (
              <StyledTableCell key={column.dataKey}>
                <Box sx={{display: 'inline-block'}}>
                  <CustomWidthTooltip title={alert.additionalInformation} arrow>
                    <span className={styles.logsMessage}>
                      {alert.additionalInformation}
                    </span>
                  </CustomWidthTooltip>
                </Box>
              </StyledTableCell>
            );
          }
          default: {
            return null;
          }
        }
      })}
    </React.Fragment>
  );
};

const AlertsTable: FC<Props> = memo((props) => {
  const itemsOnPage = useGetNumberOfElements(props.metaData);

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  return (
    <div style={{height: '100%'}}>
      <TableVirtuoso
        data={props.updaterAlertsData}
        components={VirtuosoTableComponents}
        fixedHeaderContent={() =>
          headerContent(getArrowForSortDirection, props.handleColumnClick)
        }
        itemContent={(index, alert) => rowContent(index, alert, itemsOnPage)}
      />
    </div>
  );
});

export default AlertsTable;
