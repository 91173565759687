export const snackbarSuccessAlert = {
  fontFamily: 'Poppins, sans-serif',
  color: '#212121',
  fontWeight: '400',
  border: '1px solid #C0ECD4',
  background: '#F1FFF4',
  fontSize: '16px',
  lineHeight: '22.4px',
  borderRadius: '8px',
};

export const snackbarErrorAlert = {
  fontFamily: 'Poppins, sans-serif',
  color: '#212121',
  fontWeight: '400',
  border: '1px solid #FFD8D8',
  background: '#FFF5F5',
  fontSize: '16px',
  lineHeight: '22.4px',
  borderRadius: '8px',
};

export const snackbarAlertContent = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '& svg:last-child': {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    filter:
      'invert(0%) sepia(6%) saturate(24%) hue-rotate(268deg) brightness(107%) contrast(107%)',
  },
};

//snackbar Тоже можно в коммон закинуть
