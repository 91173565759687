import React, {FC} from 'react';
import {
  pageSizes,
  stationAttachedStatus,
  stationStatuses,
} from 'core/constants';
import {IMetadata} from 'store/models/clients';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  createTheme,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import search from 'assets/icons/search.svg';
import {
  CheckedCheckboxIcon,
  CloseIcon,
  SelectPopupIcon,
  UncheckedCheckboxIcon,
} from 'assets/icons';
import {
  chipStyles,
  searchAndSelect,
  selectRowsPaperProps,
} from 'styles/MUIStyles/common';
import styles from '../Stations.module.scss';
import {Status, UserRole} from '../../../enums';
import {useAppSelector} from '../../../store/store';
import {IGetCompaniesListItem} from 'store/models/companies';
import {IGetBranchesListItem} from 'store/models/branches';
import {IStationType} from 'store/models/stations';

interface Props {
  search: string;
  setSearch: (search: string) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  status: {
    label: string;
    value: boolean;
    queryName: string;
  };
  setStatus: (
    status: {
      label: string;
      value: boolean;
      queryName: string;
    } | null,
  ) => void;
  stationAttachedStatus: {
    label: string;
    value: boolean;
    queryName: string;
  };
  setStationAttachedStatus: (
    status: {
      label: string;
      value: boolean;
      queryName: string;
    } | null,
  ) => void;
  metaData: IMetadata | null;
  companies: IGetCompaniesListItem[];
  companiesForStations: IGetCompaniesListItem[];
  setCompaniesForStations: (companies: IGetCompaniesListItem[]) => void;
  branches: IGetBranchesListItem[];
  branchesForStations: IGetBranchesListItem[];
  setBranchesForStations: (branches: IGetBranchesListItem[]) => void;
  stationTypesData: IStationType[];
  stationTypes: IStationType[];
  setStationTypes: (stationTypes: IStationType[]) => void;
}

const StationsTopToolbar: FC<Props> = (props) => {
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: '#8A8894',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
              borderRadius: '12px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #3152C7',
            },
          },
        },
      },
    },
  });

  const userRole = useAppSelector((state) => state.auth.role);

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <div className={styles.branchesTopToolbarSearch}>
            <input
              name="stationsSearch"
              type="text"
              className="input"
              placeholder="Search"
              autoComplete="off"
              value={props.search}
              onChange={(e) => props.setSearch(e.target.value)}
            />
            <img className={styles.searchIcon} src={search} alt="search icon" />
          </div>
          <div className={styles.topToolbarSelectContainer}>
            {props.metaData && (
              <>
                <span>Show rows:</span>
                <FormControl size="small">
                  <Select
                    name="stationsPageCount"
                    value={+props.pageSize}
                    onChange={(e) => {
                      props.setPageSize(+e.target.value);
                    }}
                    IconComponent={(props) => <SelectPopupIcon {...props} />}
                    MenuProps={{
                      MenuListProps: {disablePadding: true},
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: selectRowsPaperProps,
                    }}
                  >
                    {pageSizes.map((page) => {
                      return (
                        <MenuItem key={page} value={page} sx={{padding: 0}}>
                          {page}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <span>{`${props?.metaData?.firstItemOnPage}-${props?.metaData?.lastItemOnPage} of ${props?.metaData?.totalItemCount}`}</span>
              </>
            )}
          </div>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <Autocomplete
            disablePortal
            options={stationStatuses.filter(
              (status) =>
                !(
                  status.label === Status.Removed && userRole === UserRole.Owner
                ),
            )}
            value={props.status}
            onChange={(event, value) => {
              if (value) {
                props.setStatus(value);
              } else {
                props.setStatus(null);
              }
            }}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{flex: '1'}}
            ListboxProps={{
              style: {maxHeight: '200px', padding: 0},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value.label === '' ? true : option.label === value.label
            }
            popupIcon={<SelectPopupIcon />}
            renderOption={(props: any, option) => {
              return (
                <span {...props} className={styles.dropDownItem}>
                  {option.label}
                </span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select status"
                sx={searchAndSelect}
              />
            )}
          />
          <Autocomplete
            disablePortal
            options={stationAttachedStatus}
            value={props.stationAttachedStatus}
            onChange={(event, value) => {
              if (value) {
                props.setStationAttachedStatus(value);
              } else {
                props.setStationAttachedStatus(null);
              }
            }}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{flex: '1'}}
            ListboxProps={{
              style: {maxHeight: '200px', padding: 0},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value.label === '' ? true : option.label === value.label
            }
            popupIcon={<SelectPopupIcon />}
            renderOption={(props: any, option) => {
              return (
                <span {...props} className={styles.dropDownItem}>
                  {option.label}
                </span>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select is attached"
                sx={searchAndSelect}
              />
            )}
          />
          <Autocomplete
            multiple
            limitTags={1}
            options={props.companies || []}
            loading={false}
            size="small"
            popupIcon={<SelectPopupIcon />}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{flex: '1'}}
            value={props.companiesForStations}
            ListboxProps={{
              style: {maxHeight: '300px'},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value.name === '' ? true : option.id === value.id
            }
            getOptionLabel={(option) => (option?.name ? option.name : '')}
            onChange={(_, value) => {
              props.setBranchesForStations([]);

              if (value) {
                props.setCompaniesForStations(value);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({index})}
                  size="small"
                  label={option.name}
                  sx={chipStyles}
                  deleteIcon={
                    <CloseIcon style={{width: '24px', height: '24px'}} />
                  }
                />
              ))
            }
            renderOption={(props: any, option, {selected}) => (
              <span {...props} className={styles.dropDownItem}>
                <Checkbox
                  icon={<UncheckedCheckboxIcon />}
                  checkedIcon={<CheckedCheckboxIcon />}
                  style={{marginRight: 8}}
                  checked={selected}
                />
                {option.name}
              </span>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select company"
                sx={searchAndSelect}
              />
            )}
          />
          <Autocomplete
            multiple
            limitTags={1}
            options={props.branches || []}
            loading={false}
            size="small"
            popupIcon={<SelectPopupIcon />}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{flex: '1'}}
            value={props.branchesForStations}
            ListboxProps={{
              style: {maxHeight: '300px'},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value.name === '' ? true : option.id === value.id
            }
            getOptionLabel={(option) => (option?.name ? option.name : '')}
            onChange={(_, value) => {
              if (value) {
                props.setBranchesForStations(value);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({index})}
                  size="small"
                  label={option.name}
                  sx={chipStyles}
                  deleteIcon={
                    <CloseIcon style={{width: '24px', height: '24px'}} />
                  }
                />
              ))
            }
            renderOption={(props: any, option, {selected}) => (
              <span {...props} className={styles.dropDownItem}>
                <Checkbox
                  icon={<UncheckedCheckboxIcon />}
                  checkedIcon={<CheckedCheckboxIcon />}
                  style={{marginRight: 8}}
                  checked={selected}
                />
                {option.name}
              </span>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select branch"
                sx={searchAndSelect}
              />
            )}
          />
          <Autocomplete
            multiple
            limitTags={1}
            options={props.stationTypesData || []}
            loading={false}
            size="small"
            popupIcon={<SelectPopupIcon />}
            PaperComponent={(props) => (
              <Paper
                sx={{
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px #4444441F',
                  marginTop: '8px',
                  cursor: 'pointer',
                }}
                {...props}
              />
            )}
            sx={{flex: '1'}}
            value={props.stationTypes}
            ListboxProps={{
              style: {maxHeight: '300px'},
              className: 'autoCompleteListBox',
            }}
            isOptionEqualToValue={(option, value) =>
              value.name === '' ? true : option.id === value.id
            }
            getOptionLabel={(option) => (option?.name ? option.name : '')}
            onChange={(_, value) => {
              if (value) {
                props.setStationTypes(value);
              }
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({index})}
                  size="small"
                  label={option.name}
                  sx={chipStyles}
                  deleteIcon={
                    <CloseIcon style={{width: '24px', height: '24px'}} />
                  }
                />
              ))
            }
            renderOption={(props: any, option, {selected}) => (
              <span {...props} className={styles.dropDownItem}>
                <Checkbox
                  icon={<UncheckedCheckboxIcon />}
                  checkedIcon={<CheckedCheckboxIcon />}
                  style={{marginRight: 8}}
                  checked={selected}
                />
                {option.name}
              </span>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                placeholder="Select station types"
                sx={searchAndSelect}
              />
            )}
          />
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default StationsTopToolbar;
