import {ChangeEvent, FC} from 'react';
import styles from './DataCollector.module.scss';
import {
  FormControl,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import {
  PaginationArrowLeft,
  PaginationArrowRight,
  SelectPopupIcon,
} from 'assets/icons';
import {useGetDataCollectorVersionsListQuery} from 'store/slices/dataCollector';
import {useLocalStorage, useSortColumn} from 'hooks';
import {
  pagination,
  selectRowsPaperProps,
  muiSelectElementsCount,
} from 'styles/MUIStyles/common';
import DCTable from './DCTable/DCTable';
import {pageSizes} from 'core/constants';
import UploadDCVersionButton from './UploadDCVersionButton/UploadDCVersionButton';

interface IDataCollectorProps {}

const DataCollector: FC<IDataCollectorProps> = () => {
  const theme = createTheme({...muiSelectElementsCount});
  const [pageSize, setPageSize] = useLocalStorage('dcVersionsPageSize', 10);
  const [pageNumber, setPageNumber] = useLocalStorage(
    'dcVersionsPageNumber',
    1,
  );

  const [sortConfig, handleColumnClick] = useSortColumn(
    'dcCollectorVersionsSortConfig',
    {
      column: 'createdDateTime',
      direction: 'desc',
    },
  );

  const {data: dcVersionsListData} = useGetDataCollectorVersionsListQuery({
    pageSize,
    pageNumber,
    orderBy: sortConfig,
  });

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.dcTop}>
        <h1 className={styles.dcTitle}>DataCollector versions</h1>
        <UploadDCVersionButton />
      </div>
      <div className={styles.dcMainContent}>
        <div className={styles.tableFiltersTop}>
          <ThemeProvider theme={theme}>
            <div className={styles.topToolbarSelectContainer}>
              {dcVersionsListData?.metadata && (
                <>
                  <span>Show rows:</span>
                  <FormControl size="small">
                    <Select
                      name="stationsPageCount"
                      value={+pageSize}
                      onChange={(e) => {
                        setPageSize(+e.target.value);
                      }}
                      IconComponent={(props) => <SelectPopupIcon {...props} />}
                      MenuProps={{
                        MenuListProps: {disablePadding: true},
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        PaperProps: selectRowsPaperProps,
                      }}
                    >
                      {pageSizes.map((page) => {
                        return (
                          <MenuItem key={page} value={page} sx={{padding: 0}}>
                            {page}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <span>{`${dcVersionsListData?.metadata?.firstItemOnPage}-${dcVersionsListData?.metadata?.lastItemOnPage} of ${dcVersionsListData?.metadata?.totalItemCount}`}</span>
                </>
              )}
            </div>
          </ThemeProvider>
        </div>
        <div style={{height: '87%', flexGrow: 1}}>
          <DCTable
            data={dcVersionsListData?.data ? dcVersionsListData.data : []}
            metaData={dcVersionsListData?.metadata || null}
            handleColumnClick={handleColumnClick}
            sortConfig={sortConfig}
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={dcVersionsListData?.metadata?.pageCount || 1}
          page={pageNumber}
          onChange={handleOnPageChange}
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>
    </div>
  );
};

export default DataCollector;
