import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useLazyGetClientByIdQuery,
  useRecoverClientMutation,
} from 'store/slices/clients';
import {useUpdateEffect} from 'hooks';
import {ISnackbarState} from 'store/models/common';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Tab,
  Tabs,
} from '@mui/material';
import {
  CloseIcon,
  EditIcon,
  GoBackArrowButtonIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import InformationTab from './InformationTab/InformationTab';
import ActivityLogTab from './ActivityLogTab/ActivityLogTab';
import WarningModal from 'components/WarningModal/WarningModal';
import {tab} from 'styles/MUIStyles/common';
import {editDealerButton, goBackButton} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './ClientsInformation.module.scss';
import Guard from '../../../components/Guard/Guard';
import {UserRole} from '../../../enums';
import {checkAllowedRoles} from '../../../core/utils';
import {useAppSelector} from '../../../store/store';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ClientsInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.role);

  const [getClientById, {data: clientInfo, isFetching}] =
    useLazyGetClientByIdQuery();

  const [
    recoverClient,
    {isSuccess: isRecoverClientSuccess, isLoading: isRecoveringClientLoading},
  ] = useRecoverClientMutation();

  const [activeTab, setActiveTab] = useState(0);
  const [isRecoverClientModalOpen, setIsRecoverClientModal] = useState(false);
  const [recoverClientSnackbar, setRecoverClientSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: recoverVertical,
    horizontal: recoverHorizontal,
    open: recoverOpen,
  } = recoverClientSnackbar;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const isAllowedRoleFor = checkAllowedRoles(
    [UserRole.Admin, UserRole.Dealer],
    userRole,
  );

  useEffect(() => {
    if (params?.userId) {
      getClientById(params.userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.userId]);

  useUpdateEffect(() => {
    if (isRecoverClientSuccess && params?.userId) {
      setRecoverClientSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoverClientModal(false);
      getClientById(params.userId);
    }
  }, [isRecoverClientSuccess]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.clientInformationTop}>
        <h1 className={styles.clientInformationTitle}>User</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          {clientInfo?.audit?.deleting ? (
            isAllowedRoleFor && (
              <Button
                variant="contained"
                startIcon={<RecoverIcon />}
                onClick={() => {
                  if (params?.userId) {
                    setIsRecoverClientModal(true);
                  }
                }}
                sx={editDealerButton}
              >
                Recover
              </Button>
            )
          ) : (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                if (params?.userId) {
                  navigate('/users/edit/' + params.userId);
                }
              }}
              sx={editDealerButton}
            >
              Edit Info
            </Button>
          )}
        </div>
      </div>

      <div className={styles.clientInformationMainContent}>
        {isFetching ? (
          <Box sx={{display: 'flex', height: '100%'}}>
            <CircularProgress
              size={45}
              sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
            />
          </Box>
        ) : (
          <Box sx={{height: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
              }}
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Information" {...a11yProps(0)} />
              {isAllowedRoleFor && (
                <Tab sx={tab} label="Activity Log" {...a11yProps(1)} />
              )}
            </Tabs>
            <CustomTabPanel value={activeTab} index={0}>
              <InformationTab clientInfo={clientInfo} />
            </CustomTabPanel>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <CustomTabPanel value={activeTab} index={1}>
                <ActivityLogTab clientInfo={clientInfo} />
              </CustomTabPanel>
            </Guard>
          </Box>
        )}
      </div>

      {clientInfo?.email && params?.userId ? (
        <>
          <WarningModal
            isOpen={isRecoverClientModalOpen}
            onClose={() => setIsRecoverClientModal(false)}
            entityText={clientInfo.email}
            title="Recover user ?"
            textBlocks={['Are you sure you want to recover user']}
            confirmButtonText="Yes, recover"
            onConfirmButtonClick={() => {
              if (params?.userId) {
                recoverClient(params.userId);
              }
            }}
            loading={isRecoveringClientLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: recoverVertical,
              horizontal: recoverHorizontal,
            }}
            autoHideDuration={2500}
            open={recoverOpen}
            onClose={() =>
              setRecoverClientSnackbar({
                ...recoverClientSnackbar,
                open: false,
              })
            }
            key="companyOwnerInformation"
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                User {`"${clientInfo.email}"`} was restored
                <span
                  onClick={() =>
                    setRecoverClientSnackbar({
                      ...recoverClientSnackbar,
                      open: false,
                    })
                  }
                >
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </div>
  );
};

export default ClientsInformation;
