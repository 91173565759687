import {api} from './api';
import {
  IDealerInfo,
  IDealersArgs,
  IDealersCreateArgs,
  IDealersSuccessRes,
  IEditDealerArgs,
} from '../models/dealers';

export const dealers = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getDealers: builder.query<IDealersSuccessRes, IDealersArgs>({
        query: (dealersParams) => {
          const params = new URLSearchParams();

          if (dealersParams.search) {
            params.append('term', dealersParams.search);
          }

          if (dealersParams.status) {
            params.append(
              dealersParams.status.queryName,
              String(dealersParams.status.value),
            );
          }

          if (dealersParams.pageNumber) {
            params.append('pageNumber', String(dealersParams.pageNumber));
          }

          if (dealersParams.pageSize) {
            params.append('pageSize', String(dealersParams.pageSize));
          }

          if (dealersParams.sortBy?.column && dealersParams.sortBy.direction) {
            const column = dealersParams.sortBy?.column;
            const direction = dealersParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }
          return {
            url: `dealers?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Dealers'],
      }),
      getDealerById: builder.query({
        query: (dealerId) => {
          return {
            url: `dealers/${dealerId}`,
            method: 'get',
          };
        },
      }),
      createDealer: builder.mutation<string, IDealersCreateArgs>({
        query: (dealerData) => {
          return {
            url: 'dealers',
            method: 'post',
            data: dealerData,
          };
        },
        invalidatesTags: ['Dealers'],
      }),
      editDealer: builder.mutation<IDealerInfo, IEditDealerArgs>({
        query: (dealerData) => {
          return {
            url: 'dealers',
            method: 'put',
            data: dealerData,
          };
        },
        invalidatesTags: ['Dealers'],
      }),
      deleteDealer: builder.mutation<string, string>({
        query: (dealerId) => {
          return {
            url: 'dealers',
            method: 'delete',
            data: {userId: dealerId},
          };
        },
        invalidatesTags: ['Dealers'],
      }),
      recoverDealer: builder.mutation<string, string>({
        query: (dealerId) => {
          return {
            url: 'dealers/restore',
            method: 'post',
            data: {userId: dealerId},
          };
        },
        invalidatesTags: ['Dealers'],
      }),
      resetPassword: builder.mutation<string, string>({
        query: (dealerId) => {
          return {
            url: 'dealers/reset-password',
            method: 'post',
            data: {id: dealerId},
          };
        },
      }),
    };
  },
});

export const {
  useGetDealersQuery,
  useLazyGetDealerByIdQuery,
  useCreateDealerMutation,
  useEditDealerMutation,
  useDeleteDealerMutation,
  useRecoverDealerMutation,
  useResetPasswordMutation,
} = dealers;
