import React, {FC} from 'react';
import {
  Autocomplete,
  Box,
  Button,
  createTheme,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import search from 'assets/icons/search.svg';
import {
  calendarStyles,
  muiSelectElementsCount,
  searchAndSelect,
  selectRowsPaperProps,
  stationsChipClear,
  stationsChipClearIcon,
} from 'styles/MUIStyles/common';
import {logsStatuses, pageSizes} from 'core/constants';
import {
  CalendarIcon,
  CloseIcon,
  FilterIcon,
  SelectPopupIcon,
} from 'assets/icons';
import {DateTimePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {ISystemLogsTopToolbarProps} from 'store/models/logs';
import styles from '../../Logs.module.scss';
import {clearFiltersButton} from '../../../../styles/MUIStyles/buttons';

const SystemLogsTopToolbar: FC<ISystemLogsTopToolbarProps> = (props) => {
  const theme = createTheme({...muiSelectElementsCount});

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'space-between',
            marginBottom: '14px',
          }}
        >
          <Box
            sx={{
              width: '75%',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <div className={styles.logsTopToolbarSearch}>
              <input
                name="logsSearch"
                type="text"
                className="input"
                placeholder="Search"
                autoComplete="off"
                value={props.search}
                onChange={(e) => props.setSearch(e.target.value)}
              />
              <img
                className={styles.searchIcon}
                src={search}
                alt="search icon"
              />
            </div>
            <Button
              variant="text"
              startIcon={
                <FilterIcon
                  style={{
                    width: '16px',
                    height: '16px',
                    filter:
                      'invert(25%) sepia(81%) saturate(1632%) hue-rotate(213deg) brightness(95%) contrast(95%)',
                  }}
                />
              }
              sx={clearFiltersButton}
              onClick={() => {
                props.setIsShowFilterButtonOpen((prevState) => !prevState);
              }}
            >
              {props.isShowFilterButtonOpen ? 'Hide filters' : 'Show filters'}
            </Button>
          </Box>

          <div className={styles.topToolbarSelectContainer}>
            {props.metaData && (
              <>
                <span>Show rows:</span>
                <FormControl size="small">
                  <Select
                    name="logsPageCount"
                    value={+props.pageSize}
                    onChange={(e) => {
                      props.setPageSize(+e.target.value);
                    }}
                    IconComponent={(props) => <SelectPopupIcon {...props} />}
                    MenuProps={{
                      MenuListProps: {disablePadding: true},
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: selectRowsPaperProps,
                    }}
                  >
                    {pageSizes.map((page) => {
                      return (
                        <MenuItem key={page} value={page}>
                          {page}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <span>{`${props.metaData?.firstItemOnPage}-${props.metaData?.lastItemOnPage} of ${props.metaData?.totalItemCount}`}</span>
              </>
            )}
          </div>
        </Box>

        {props.isShowFilterButtonOpen && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              gap: '14px',
            }}
          >
            <Box
              sx={{
                width: '75%',
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <div className={styles.systemLogsDateTimePicker}>
                <span>dd.mm.yy hh:mm</span>
                <DateTimePicker
                  value={props.logsStartTime}
                  inputFormat="DD.MM.YY HH:mm"
                  ampm={false}
                  onChange={(newValue) => {
                    if (dayjs(newValue).isValid()) {
                      props.setLogsStartTime(dayjs(newValue).utc().format());
                    }

                    if (newValue === null) {
                      props.setLogsStartTime(null);
                    }
                  }}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                      style: {justifyContent: 'flex-end'},
                    },
                  }}
                  minutesStep={1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{
                        ...calendarStyles,
                        width: '100%',
                        padding: '2px 0 0 0',
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Select start date',
                        autoComplete: 'off',
                      }}
                    />
                  )}
                />
              </div>

              <div className={styles.systemLogsDateTimePicker}>
                <span>dd.mm.yy hh:mm</span>
                <DateTimePicker
                  inputFormat="DD.MM.YY HH:mm"
                  value={props.logsEndTime}
                  ampm={false}
                  onChange={(newValue) => {
                    if (dayjs(newValue).isValid()) {
                      props.setLogsEndTime(dayjs(newValue).utc().format());
                    }

                    if (newValue === null) {
                      props.setLogsEndTime(null);
                    }
                  }}
                  components={{
                    OpenPickerIcon: CalendarIcon,
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: ['clear'],
                      style: {justifyContent: 'flex-end'},
                    },
                  }}
                  minutesStep={1}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{...calendarStyles, width: '100%'}}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'Select end date',
                        autoComplete: 'off',
                      }}
                    />
                  )}
                />
              </div>

              <div className={styles.systemLogsDateTimePicker}>
                <span className={styles.invisible} />
                <Autocomplete
                  disablePortal
                  options={logsStatuses}
                  value={props.status}
                  onChange={(event, value) => {
                    if (value) {
                      props.setStatus(value);
                    } else {
                      props.setStatus(null);
                    }
                  }}
                  PaperComponent={(props) => (
                    <Paper
                      sx={{
                        borderRadius: '12px',
                        boxShadow: '0px 4px 20px 0px #4444441F',
                        marginTop: '8px',
                        cursor: 'pointer',
                      }}
                      {...props}
                    />
                  )}
                  sx={{width: '100%'}}
                  ListboxProps={{
                    style: {maxHeight: '200px', padding: 0},
                    className: 'autoCompleteListBox',
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value.label === '' ? true : option.label === value.label
                  }
                  popupIcon={<SelectPopupIcon />}
                  renderOption={(props: any, option) => {
                    return (
                      <span {...props} className={styles.dropDownItem}>
                        {option.label}
                      </span>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select level"
                      sx={searchAndSelect}
                    />
                  )}
                />
              </div>
            </Box>
            <Button
              variant="text"
              startIcon={<CloseIcon style={stationsChipClearIcon} />}
              sx={{...stationsChipClear, marginLeft: 'auto'}}
              onClick={props.clearSystemLogsFilters}
            >
              Clear
            </Button>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default SystemLogsTopToolbar;
