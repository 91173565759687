import React, {FC, useState} from 'react';
import {IGetDefaultManifest, IGetStationById} from 'store/models/stations';
import {UseFormGetValues} from 'react-hook-form';
import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import ManifestEditTask from './ManifestEditTask/ManifestEditTask';
import {
  CloseIcon,
  CreateIconBlue,
  DeleteIcon,
  EditIcon,
  ErrorNotification,
  MoreIcon,
  RevertToDefaultManifestIcon,
  SuccessNotification,
} from 'assets/icons';
import {createButtonOutlinedWithoutLoading} from 'styles/MUIStyles/buttons';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import styles from '../StationsEdit.module.scss';
import WarningModal from '../../../../components/WarningModal/WarningModal';
import {useEditStationMutation} from '../../../../store/slices/stations';
import {useUpdateEffect} from '../../../../hooks';
import {ISnackbarState} from '../../../../store/models/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from '../../../../styles/MUIStyles/snackbars';
import ManifestCreateTask from './ManifestCreateTask/ManifestCreateTask';
import Guard from '../../../../components/Guard/Guard';
import {UserRole} from '../../../../enums';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: {
    ...styledTableBody,
    padding: '18px 16px 18px 16px',
  },
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  stationData: IGetStationById | undefined;
  getDefaultManifest: any;
  defaultManifest: IGetDefaultManifest | undefined;
  isGettingDefaultManifestLoading: boolean;
  isGettingDefaultManifestSuccess: boolean;
  getDataFromMainInfo: UseFormGetValues<{
    name: string;
    posChassicNumber: string;
    fiscalDeviceId: string;
    isActive: boolean;
  }>;
}

interface IManifestEditTask {
  name: string;
  id: string;
  isMain: boolean;
  include: {
    directory: string;
  }[];
  exclude:
    | {
        directory: string;
      }[]
    | null;
  isIncrementalBackupSettingsChecked: boolean;
  isExcludeDirectoryChecked: boolean;
  isPreBackupScriptChecked: boolean;
  isPostBackupScriptChecked: boolean;
  preBackupScripts: {script: string}[] | null;
  postBackupScripts: {script: string}[] | null;
  keepFullCopy: number;
  fullBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
  incrementalBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
}

interface ICommand {
  id: string;
  name: string;
  isMain: boolean;
  isActive?: boolean;
  include: string[];
  exclude: string[] | null;
  preBackupScripts: string[] | null;
  postBackupScripts: string[] | null;
  keepFullCopy: number;
  fullBackupSettings: null | {
    periodType: string | null;
    periodCount: number;
  };
  incrementalBackupSettings: null | {
    periodType: string | null;
    periodCount: number;
  };
}

const ManifestTab: FC<Props> = (props) => {
  const [
    editStation,
    {
      isLoading: isEditingStationLoading,
      isSuccess: isEditingStationSuccess,
      error: editStationError,
    },
  ] = useEditStationMutation();

  const [deleteManifestTaskErrorMessage, setDeleteManifestTaskErrorMessage] =
    useState<null | string>(null);
  const [clickedManifestTask, setClickedManifestTask] =
    useState<null | IManifestEditTask>(null);
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [isRevertToDefaultModalOpen, setIsRevertToDefaultModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [
    deleteManifestTaskSnackbarSuccess,
    setDeleteManifestTaskSnackbarSuccess,
  ] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [deleteManifestTaskSnackbarError, setDeleteManifestTaskSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const {
    vertical: deleteManifestTaskSuccessVertical,
    horizontal: deleteManifestTaskSuccessHorizontal,
    open: isDeleteManifestTaskSuccessOpen,
  } = deleteManifestTaskSnackbarSuccess;
  const {
    vertical: deleteManifestTaskErrorVertical,
    horizontal: deleteManifestTaskErrorHorizontal,
    open: isDeleteManifestTaskErrorOpen,
  } = deleteManifestTaskSnackbarError;

  const manifestCommands: ICommand[] =
    props.defaultManifest?.commands ||
    props?.stationData?.manifest?.commands ||
    [];

  const handleMoreDotsClose = () => setAnchorEl(null);

  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const deleteManifestTask = () => {
    if (props.stationData && clickedManifestTask) {
      const deletableManifestTask = props.stationData.manifest.commands.find(
        (task) => task.id === clickedManifestTask.id,
      );

      if (deletableManifestTask) {
        editStation({
          ...props.stationData,
          manifest: {
            commands: [
              ...props.stationData.manifest.commands.filter(
                (task) => task.id !== deletableManifestTask.id,
              ),
            ],
            id: props.stationData.manifest.id,
          },
        });
      }
    }
  };

  const handleSnackbarClose = () => {
    setDeleteManifestTaskSnackbarSuccess({
      ...deleteManifestTaskSnackbarSuccess,
      open: false,
    });
    setDeleteManifestTaskSnackbarError({
      ...deleteManifestTaskSnackbarError,
      open: false,
    });
    setDeleteManifestTaskErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (isEditingStationSuccess) {
      setDeleteManifestTaskSnackbarSuccess({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteTaskModalOpen(false);
    }
  }, [isEditingStationSuccess]);

  useUpdateEffect(() => {
    if (props.isGettingDefaultManifestSuccess) {
      setIsRevertToDefaultModalOpen(false);
    }
  }, [props.isGettingDefaultManifestSuccess]);

  useUpdateEffect(() => {
    if (
      editStationError &&
      'data' in editStationError &&
      'status' in editStationError
    ) {
      if (editStationError.status === 400 || editStationError.status === 404) {
        const errors = editStationError.data?.errors;
        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            setDeleteManifestTaskErrorMessage(errors[key]);
            setDeleteManifestTaskSnackbarError({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  }, [editStationError]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          marginBottom: '24px',
          gap: '8px',
        }}
      >
        {props?.stationData?.manifest.type === 'Custom' ? (
          <Guard allowedRoles={[UserRole.Admin]}>
            <Button
              sx={createButtonOutlinedWithoutLoading}
              startIcon={<RevertToDefaultManifestIcon />}
              onClick={() => setIsRevertToDefaultModalOpen(true)}
            >
              Revert to default
            </Button>
          </Guard>
        ) : null}
        <Guard allowedRoles={[UserRole.Admin]}>
          <Button
            sx={createButtonOutlinedWithoutLoading}
            startIcon={<CreateIconBlue />}
            onClick={() => setIsCreateTaskModalOpen(true)}
          >
            Create task
          </Button>
        </Guard>
      </Box>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>№</StyledTableCell>
              <StyledTableCell sx={{width: '90%'}}>
                <Box sx={tableHeadCell}>
                  <span>Name task</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '5%'}}>{null}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[
              ...manifestCommands.filter((command) => command.isMain),
              ...manifestCommands.filter((command) => !command.isMain),
            ].map((command, index) => (
              <StyledTableRow
                key={command.id}
                sx={{
                  background: '#fff',
                  border: 0,
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {command.isMain ? (
                      <span className={styles.mainTask}>Main task</span>
                    ) : null}
                    {command.name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Guard allowedRoles={[UserRole.Admin]}>
                    <Button
                      id="basic-button"
                      aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isMenuOpen ? 'true' : undefined}
                      onClick={(event) => {
                        handleMoreDotsClick(event);
                        setClickedManifestTask({
                          name: command.name,
                          id: command.id,
                          isMain: command.isMain,
                          include: command.include.map((dir) => ({
                            directory: dir,
                          })),
                          exclude: command?.exclude
                            ? command?.exclude?.map((dir) => ({
                                directory: dir,
                              }))
                            : null,
                          preBackupScripts: command?.preBackupScripts
                            ? command?.preBackupScripts?.map((script) => ({
                                script: script,
                              }))
                            : null,
                          postBackupScripts: command?.postBackupScripts
                            ? command?.postBackupScripts?.map((script) => ({
                                script: script,
                              }))
                            : null,
                          isPreBackupScriptChecked: Boolean(
                            command.preBackupScripts?.length,
                          ),
                          isPostBackupScriptChecked: Boolean(
                            command.postBackupScripts?.length,
                          ),
                          isIncrementalBackupSettingsChecked: Boolean(
                            command?.incrementalBackupSettings?.periodType,
                          ),
                          isExcludeDirectoryChecked: Boolean(
                            command?.exclude?.length,
                          ),
                          fullBackupSettings: command.fullBackupSettings,
                          incrementalBackupSettings:
                            command.incrementalBackupSettings,
                          keepFullCopy: command.keepFullCopy,
                        });
                      }}
                    >
                      <MoreIcon />
                    </Button>
                  </Guard>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ManifestEditTask
        stationData={props.stationData}
        getDataFromMainInfo={props.getDataFromMainInfo}
        clickedManifestTask={clickedManifestTask}
        isEditTaskModalOpen={isEditTaskModalOpen}
        setIsEditTaskModalOpen={setIsEditTaskModalOpen}
      />

      <ManifestCreateTask
        stationData={props.stationData}
        isManifestCreateTaskModalOpen={isCreateTaskModalOpen}
        setIsManifestCreateTaskModalOpen={setIsCreateTaskModalOpen}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Guard allowedRoles={[UserRole.Admin]}>
          <MenuItem
            sx={tableMoreDotsMenu}
            onClick={() => {
              handleMoreDotsClose();
              setIsEditTaskModalOpen(true);
            }}
          >
            <EditIcon />
            <span>Edit</span>
          </MenuItem>
        </Guard>
        <Guard allowedRoles={[UserRole.Admin]}>
          {!clickedManifestTask?.isMain ? (
            <MenuItem
              sx={tableMoreDotsMenu}
              onClick={() => {
                handleMoreDotsClose();
                setIsDeleteTaskModalOpen(true);
              }}
            >
              <DeleteIcon />
              <span>Remove</span>
            </MenuItem>
          ) : null}
        </Guard>
      </Menu>

      {clickedManifestTask ? (
        <>
          <WarningModal
            isOpen={isDeleteTaskModalOpen}
            onClose={() => setIsDeleteTaskModalOpen(false)}
            entityText={''}
            title={'Remove task ?'}
            textBlocks={['Are you sure you want to remove task']}
            confirmButtonText={'Yes, remove'}
            onConfirmButtonClick={deleteManifestTask}
            loading={isEditingStationLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: deleteManifestTaskSuccessVertical,
              horizontal: deleteManifestTaskSuccessHorizontal,
            }}
            autoHideDuration={2500}
            open={isDeleteManifestTaskSuccessOpen}
            onClose={handleSnackbarClose}
            key={'deleteManifestTaskSuccess'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Task was removed
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {deleteManifestTaskErrorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: deleteManifestTaskErrorVertical,
            horizontal: deleteManifestTaskErrorHorizontal,
          }}
          autoHideDuration={3500}
          open={isDeleteManifestTaskErrorOpen}
          onClose={handleSnackbarClose}
          key={'deleteManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {deleteManifestTaskErrorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}

      <WarningModal
        isOpen={isRevertToDefaultModalOpen}
        onClose={() => setIsRevertToDefaultModalOpen(false)}
        entityText={''}
        title={'Revert to default'}
        textBlocks={[
          'Are you sure you want to revert to the default manifest settings ?',
        ]}
        confirmButtonText={'Yes, revert'}
        onConfirmButtonClick={() =>
          props.getDefaultManifest(props.stationData?.stationTypeId)
        }
        loading={props.isGettingDefaultManifestLoading}
      />
    </div>
  );
};

export default ManifestTab;
