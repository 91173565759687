import React, {FC, memo, ReactNode, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ISortConfig} from 'hooks/useSortColumn';
import {IDealersList, IDealersList as IDealer} from 'store/models/dealers';
import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  SnackbarOrigin,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import WarningModal from 'components/WarningModal/WarningModal';
import {
  ArrowAsc,
  ArrowDesc,
  CloseIcon,
  DefaultSortIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './DealersTable.module.scss';
import {useGetNumberOfElements, useUpdateEffect} from 'hooks';
import {IMetadata} from 'store/models/common';
import {
  useDeleteDealerMutation,
  useRecoverDealerMutation,
} from 'store/slices/dealers';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  dealers: IDealersList[];
  metaData: IMetadata | null;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
  search: string;
  pageNumber: number;
  pageSize: number;
  isRequestWithDeletedStatusSuccess: boolean;
  status: {
    label: string;
    value: boolean;
    queryName: string;
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
}

interface IMenuItem {
  label: string;
  icon: ReactNode;
  sx: any;
  onClick: () => void;
}

const DealersTable: FC<Props> = memo((props) => {
  const navigate = useNavigate();

  const [
    deleteDealer,
    {isSuccess: isDeletingDealerSuccess, isLoading: isDeletingDealerLoading},
  ] = useDeleteDealerMutation();

  const [
    recoverDealer,
    {
      isSuccess: isRecoveringDealerSuccess,
      isLoading: isRecoveringDealerLoading,
    },
  ] = useRecoverDealerMutation();

  const itemsOnPage = useGetNumberOfElements(props.metaData);
  const [isDeleteDealerModalOpen, setIsDeleteDealerModalOpen] = useState(false);
  const [isRecoverDealerModalOpen, setIsRecoverDealerModalOpen] =
    useState(false);
  const [clickedDealer, setClickedDealer] = useState<IDealer | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [deleteDealerSnackbar, setDeleteDealerSnackbar] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [recoverDealerSnackbar, setRecoverDealerSnackbar] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {
    vertical: dVertical,
    horizontal: dHorizontal,
    open: dOpen,
  } = deleteDealerSnackbar;
  const {
    vertical: rVertical,
    horizontal: rHorizontal,
    open: rOpen,
  } = recoverDealerSnackbar;

  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreDotsClose = () => setAnchorEl(null);

  const handleSnackbarClose = () => {
    setDeleteDealerSnackbar({...deleteDealerSnackbar, open: false});
    setRecoverDealerSnackbar({...recoverDealerSnackbar, open: false});
  };

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  useUpdateEffect(() => {
    if (isDeletingDealerSuccess) {
      setDeleteDealerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteDealerModalOpen(false);
    }

    if (isRecoveringDealerSuccess) {
      setRecoverDealerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoverDealerModalOpen(false);
    }
  }, [isDeletingDealerSuccess, isRecoveringDealerSuccess]);

  const menuItems: IMenuItem[] = [
    {
      sx: tableMoreDotsMenu,
      onClick: () => {
        if (clickedDealer) {
          navigate('/dealers/edit/' + clickedDealer.id);
        }
      },
      label: 'Edit',
      icon: <EditIcon />,
    },
    {
      sx: {...tableMoreDotsMenu, marginBottom: '0'},
      onClick: () => {
        handleMoreDotsClose();
        setIsDeleteDealerModalOpen(true);
      },
      label: 'Remove',
      icon: <DeleteIcon />,
    },
  ];

  return (
    <div style={{height: '100%'}}>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>
                <Box>№</Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '25%'}}>
                <Box
                  onClick={() => props.handleColumnClick('email')}
                  sx={tableHeadCell}
                >
                  <span>Email</span>
                  {getArrowForSortDirection('email')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  onClick={() => props.handleColumnClick('firstName')}
                  sx={tableHeadCell}
                >
                  <span>First Name</span>
                  {getArrowForSortDirection('firstName')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  onClick={() => props.handleColumnClick('lastName')}
                  sx={tableHeadCell}
                >
                  <span>Last Name</span>
                  {getArrowForSortDirection('lastName')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '15%'}}>
                <Box
                  onClick={() => props.handleColumnClick('phoneNumber')}
                  sx={tableHeadCell}
                >
                  <span>Phone Number</span>
                  {getArrowForSortDirection('phoneNumber')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '10%'}}>
                <Box
                  onClick={() => props.handleColumnClick('isActive')}
                  sx={tableHeadCell}
                >
                  <span>Status</span>
                  {getArrowForSortDirection('isActive')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '5%'}}>{null}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dealers.map((dealer, index) => (
              <StyledTableRow
                key={dealer.id}
                sx={{cursor: 'pointer'}}
                onClick={() => navigate(`/dealers/information/${dealer.id}`)}
              >
                <StyledTableCell>
                  {itemsOnPage.length ? itemsOnPage[index] : null}
                </StyledTableCell>
                <StyledTableCell>
                  <span>{dealer.email}</span>
                </StyledTableCell>
                <StyledTableCell>{dealer.firstName}</StyledTableCell>
                <StyledTableCell>{dealer.lastName}</StyledTableCell>
                <StyledTableCell>{dealer.phoneNumber}</StyledTableCell>
                <StyledTableCell>
                  {props.isRequestWithDeletedStatusSuccess ? (
                    <span
                      className={`${styles.dealersStatus} ${styles.deleted}`}
                    >
                      Removed
                    </span>
                  ) : (
                    <span
                      className={
                        dealer.isActive
                          ? `${styles.dealersStatus} ${styles.active}`
                          : `${styles.dealersStatus} ${styles.inactive}`
                      }
                    >
                      {dealer.isActive ? 'Active' : 'Inactive'}
                    </span>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    id="basic-button"
                    aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isMenuOpen ? 'true' : undefined}
                    onClick={(event) => {
                      event.stopPropagation();
                      setClickedDealer(dealer);
                      handleMoreDotsClick(event);
                    }}
                  >
                    <MoreIcon />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {padding: '4px 8px 4px 8px'},
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {props.isRequestWithDeletedStatusSuccess ? (
          <MenuItem
            sx={tableMoreDotsMenu}
            onClick={() => {
              handleMoreDotsClose();
              setIsRecoverDealerModalOpen(true);
            }}
          >
            <RecoverIcon />
            <span>Recover</span>
          </MenuItem>
        ) : (
          menuItems.map((menuItem, index) => (
            <MenuItem key={index} sx={menuItem.sx} onClick={menuItem.onClick}>
              {menuItem.icon}
              <span>{menuItem.label}</span>
            </MenuItem>
          ))
        )}
      </Menu>

      {clickedDealer ? (
        <>
          <WarningModal
            isOpen={
              props.isRequestWithDeletedStatusSuccess
                ? isRecoverDealerModalOpen
                : isDeleteDealerModalOpen
            }
            onClose={
              props.isRequestWithDeletedStatusSuccess
                ? () => setIsRecoverDealerModalOpen(false)
                : () => setIsDeleteDealerModalOpen(false)
            }
            entityText={clickedDealer.email}
            title={`${
              props.isRequestWithDeletedStatusSuccess ? 'Recover' : 'Remove'
            } dealer ?`}
            textBlocks={[
              `Are you sure you want to ${
                props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
              } dealer ?`,
            ]}
            confirmButtonText={`Yes, ${
              props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
            }`}
            onConfirmButtonClick={
              props.isRequestWithDeletedStatusSuccess
                ? () => recoverDealer(clickedDealer.id)
                : () => deleteDealer(clickedDealer.id)
            }
            loading={isDeletingDealerLoading || isRecoveringDealerLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: props.isRequestWithDeletedStatusSuccess
                ? rVertical
                : dVertical,
              horizontal: props.isRequestWithDeletedStatusSuccess
                ? rHorizontal
                : dHorizontal,
            }}
            autoHideDuration={2500}
            open={props.isRequestWithDeletedStatusSuccess ? rOpen : dOpen}
            onClose={handleSnackbarClose}
            key={`${
              props.isRequestWithDeletedStatusSuccess
                ? 'recoverDealer'
                : 'deleteDealer'
            }`}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Dealer {`"${clickedDealer.email}"`} was{' '}
                {props.isRequestWithDeletedStatusSuccess
                  ? 'restored'
                  : 'removed'}
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </div>
  );
});

export default DealersTable;
