import React, {FC} from 'react';
import {IMetadata} from 'store/models/clients';
import {pageSizes} from 'core/constants';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  createTheme,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import {
  CheckedCheckboxIcon,
  CloseIcon,
  SelectPopupIcon,
  UncheckedCheckboxIcon,
} from 'assets/icons';
import {
  chipStyles,
  muiSelectElementsCount,
  searchAndSelect,
  selectRowsPaperProps,
} from 'styles/MUIStyles/common';
import styles from '../Manifest.module.scss';
import {IGetCompaniesListItem} from 'store/models/companies';
import {IGetBranchesListItem} from 'store/models/branches';
import {IStationType, StationsListItem} from 'store/models/stations';

interface Props {
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  metaData: IMetadata | null;
  companies: IGetCompaniesListItem[];
  companiesForManifest: IGetCompaniesListItem[];
  setCompaniesForManifest: (companies: IGetCompaniesListItem[]) => void;
  branches: IGetBranchesListItem[];
  branchesForManifest: IGetBranchesListItem[];
  setBranchesForManifest: (branches: IGetBranchesListItem[]) => void;
  stations: StationsListItem[];
  stationsForManifest: StationsListItem[];
  setStationsForManifest: (stations: StationsListItem[]) => void;
  stationTypesData: IStationType[];
  stationTypes: IStationType[];
  setStationTypes: (stationTypes: IStationType[]) => void;
}

const ManifestTopToolbar: FC<Props> = (props) => {
  const theme = createTheme({...muiSelectElementsCount});

  return (
    <ThemeProvider theme={theme}>
      <div style={{width: '100%'}}>
        <div className={styles.topToolbarSelectContainer}>
          <Box
            width="75%"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            gap="12px"
          >
            <Autocomplete
              multiple
              limitTags={1}
              options={props.companies}
              loading={false}
              size="small"
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{flex: '1'}}
              value={props.companiesForManifest}
              ListboxProps={{
                style: {maxHeight: '300px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                props.setCompaniesForManifest([]);

                if (value) {
                  props.setCompaniesForManifest(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={option.name}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select company"
                  sx={searchAndSelect}
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={1}
              options={props.branches}
              loading={false}
              size="small"
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{flex: '1'}}
              value={props.branchesForManifest}
              ListboxProps={{
                style: {maxHeight: '300px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                if (value) {
                  props.setBranchesForManifest(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={option.name}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select branch"
                  sx={searchAndSelect}
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={1}
              options={props.stations}
              value={props.stationsForManifest}
              loading={false}
              size="small"
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '12px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{flex: '1'}}
              disableCloseOnSelect
              ListboxProps={{
                style: {maxHeight: '200px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) =>
                `${option.name}/${option.posChassicNumber}`
              }
              onChange={(_, value) => {
                if (value) {
                  props.setStationsForManifest(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={`${option.name}/${option.posChassicNumber}`}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name} / {option.posChassicNumber}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select station/posChassisNumber"
                  sx={searchAndSelect}
                />
              )}
            />
            <Autocomplete
              multiple
              limitTags={1}
              options={props.stationTypesData}
              loading={false}
              size="small"
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{flex: '1'}}
              value={props.stationTypes}
              ListboxProps={{
                style: {maxHeight: '300px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                if (value) {
                  props.setStationTypes(value);
                }
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    {...getTagProps({index})}
                    size="small"
                    label={option.name}
                    sx={chipStyles}
                    deleteIcon={
                      <CloseIcon style={{width: '24px', height: '24px'}} />
                    }
                  />
                ))
              }
              renderOption={(props: any, option, {selected}) => (
                <span {...props} className={styles.dropDownItem}>
                  <Checkbox
                    icon={<UncheckedCheckboxIcon />}
                    checkedIcon={<CheckedCheckboxIcon />}
                    style={{marginRight: 8}}
                    checked={selected}
                  />
                  {option.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select station type"
                  sx={searchAndSelect}
                />
              )}
            />
          </Box>

          {props.metaData && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap="12px"
            >
              <span>Show rows:</span>
              <FormControl size="small">
                <Select
                  name="branchesPageCount"
                  value={+props.pageSize}
                  onChange={(e) => {
                    props.setPageSize(+e.target.value);
                  }}
                  IconComponent={(props) => <SelectPopupIcon {...props} />}
                  MenuProps={{
                    MenuListProps: {disablePadding: true},
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: selectRowsPaperProps,
                  }}
                >
                  {pageSizes.map((page) => {
                    return (
                      <MenuItem key={page} value={page}>
                        {page}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <span>{`${props?.metaData?.firstItemOnPage}-${props?.metaData?.lastItemOnPage} of ${props?.metaData?.totalItemCount}`}</span>
            </Box>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ManifestTopToolbar;
