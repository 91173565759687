import React, {FC, useMemo} from 'react';
import {useAppSelector} from 'store/store';
import {Navigate, useLocation} from 'react-router-dom';
import {LOGIN_PAGE_PATH} from '../../router/constants';

interface Props {
  allowedRoles: string[];
  children: React.ReactElement;
}

const RequireAuth: FC<Props> = ({allowedRoles, children}) => {
  const location = useLocation();
  const token = localStorage.getItem('accessToken');

  const role = useAppSelector((state) => state.auth.role);
  const isAuth = useAppSelector((state) => state.auth.isAuth);

  const hasRequiredRoles = useMemo(() => {
    if (!allowedRoles.length || !role) {
      return true;
    }

    if (role) {
      return allowedRoles.some((allowedRole) => allowedRole === role);
    }
  }, [allowedRoles, role]);

  if (!hasRequiredRoles && token) {
    return (
      <Navigate to={'/notFoundPage'} state={{from: location}} replace={true} />
    );
  }

  if (!isAuth && !token) {
    return (
      <Navigate
        to={'/' + LOGIN_PAGE_PATH}
        state={{from: location}}
        replace={true}
      />
    );
  }

  return children;
};

export default RequireAuth;
