export const tableMoreDotsMenu = {
  fontFamily: 'Poppins, sans-serif',
  borderRadius: '8px',
  backgroundColor: '#fff',
  display: 'flex',
  gap: '10px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '400',
  color: '#212121',
  marginBottom: '4px',
  '& svg': {
    width: '16px',
    height: '16px',
  },
  '&:hover': {
    backgroundColor: '#F1F6FF',
    color: '#3152C7',
  },
  '&:hover svg': {
    filter:
      'invert(26%) sepia(25%) saturate(5553%) hue-rotate(217deg) brightness(94%) contrast(91%)',
  },
};

export const tableMoreDotsMenuPaper = {
  '& .MuiMenu-paper': {
    boxShadow: '0px 8px 24px rgba(149, 157, 165, 0.2)',
    borderRadius: '8px',
  },
};

export const searchAndSelect = {
  input: {
    color: '#212121',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    width: '100%',
    height: '100%',
  },
  '& input::placeholder': {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    color: '#8A8894',
    opacity: 1,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: '#8a8894',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '12px',
    border: '1px solid #BDBCDB',
  },

  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: '1px solid #BDBCDB',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #3152C7',
    },
  },
  '.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: '#f93232',
  },
};

export const searchAndSelectError = {
  ...searchAndSelect,
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '12px',
    border: '1px solid #F93232',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: '1px solid #F93232',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #F93232',
    },
  },
};

export const pagination = {
  '& .MuiPaginationItem-root': {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '14px',
    color: '#212121',
  },
  '& .Mui-selected': {
    backgroundColor: '#3152C7 !important',
    color: '#fff !important',
    borderRadius: '3px',
    border: '1px solid #3152C7',
    padding: '4px 3px 4px 3px',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
};

export const tab = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '21.6px',
  borderBottom: '2px solid rgba(244, 243, 254, 1)',
  textTransform: 'capitalize',
  color: 'rgba(33, 33, 33, 1)',
  '&.Mui-selected': {
    color: 'rgba(49, 82, 199, 1)',
  },
};

export const switchLabel = {
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
  },
};

export const createTaskBackupSwitchLabel = {
  '& .MuiFormControlLabel-label': {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8A8894',
  },
};

export const androidSwitch = {
  padding: 8,
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: 'rgba(96, 93, 236, 1)',
    opacity: 1,
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'gray',
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
};

export const androidSwitchDisabled = {
  padding: 8,
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#E5E4EF',
    opacity: 1,
  },
  '& .MuiSwitch-track': {
    backgroundColor: 'gray',
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
};

export const selectRowsPaperProps = {
  sx: {
    marginTop: '8px',
    maxHeight: '200px',
    borderRadius: '12px',
    fontFamily: 'Poppins, sans-serif',
    boxShadow: '0px 4px 20px 0px #4444441F',
    '& .MuiMenuItem-root': {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: '400',
      padding: '8px 36px 8px 25px',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#212121',
    },
    '& .MuiMenuItem-root.Mui-selected': {
      backgroundColor: '#F1F6FF',
    },
    '& .MuiMenuItem-root:hover': {
      backgroundColor: '#F1F6FF',
      color: '#3152C7',
    },
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E0E0F1',
      borderRadius: '100px',
    },
  },
};

export const avatarMenuItemStyle = {
  color: '#212121',
  gap: '8px',
  borderRadius: '8px',
  '&:hover': {
    background: '#F5F8FF',
    '& img': {
      filter:
        'invert(22%) sepia(98%) saturate(1707%) hue-rotate(218deg) brightness(95%) contrast(90%)',
    },
    '& span': {
      filter:
        'invert(22%) sepia(98%) saturate(1707%) hue-rotate(218deg) brightness(95%) contrast(90%)',
    },
  },
};

export const calendarStyles = {
  width: '20%',
  '& input': {
    fontFamily: 'Poppins, sans-serif',
    color: '#212121',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    borderColor: '#BDBCDB',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '12px',
    border: '1px solid #BDBCDB',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: '1px solid #BDBCDB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#3152C7',
    },
  },
  padding: '5px 0',
};

export const allBackupsStationsMenu = {
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  fontFamily: 'Poppins !important',
  minWidth: '18%',
  marginTop: '3px',
  borderRadius: '12px',
  '& .MuiMenu-paper': {
    fontFamily: 'Poppins !important',
  },
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E0E0F1',
    borderRadius: '100px',
  },
};

export const allBackupsStationsMenuItem = {
  fontFamily: 'Poppins, sans-serif !important',
  color: '#212121',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '24px',
  padding: 0,
};

export const badgeStyles = {
  '& > span': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins, sans-serif',
    padding: '8px',
    borderRadius: '100%',
    backgroundColor: '#3152C7',
    fontSize: '10px',
    color: '#fff',
    lineHeight: '15px',
    fontWeight: '400',
  },
};

export const chipStyles = {
  minWidth: '118px',
  maxHeight: '24px',
  color: '#000000',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '20px',
  borderRadius: '10px',
  boxShadow: 'none',
  backgroundColor: '#EBF2FF',
};

export const stationsChipClear = {
  fontFamily: 'Poppins, sans-serif',
  color: '#3152C7',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '400',
  textTransform: 'capitalize',
};

export const stationsChipClearIcon = {
  width: '24px',
  height: '24px',
  filter:
    'invert(26%) sepia(31%) saturate(4223%) hue-rotate(215deg) brightness(96%) contrast(93%)',
};

export const muiSelectElementsCount = {
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          color: '#8A8894',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '24px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #BDBCDB',
            borderRadius: '12px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #BDBCDB',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #3152C7',
          },
        },
      },
    },
  },
};
