// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StationsTable_stationStatus__W6Ejl {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  border-radius: 12px;
  padding: 4px 8px 4px 8px;
}

.StationsTable_active__G5jzs {
  color: #31aa27;
  background-color: #ebf6eb;
}

.StationsTable_inactive__bMDqW {
  color: #7774B6;
  background-color: #DFE4FD;
}

.StationsTable_deleted__ACUez {
  color: #f93232;
  background-color: #fff0f0;
}

.StationsTable_notAttached__JnxKL {
  color: #D27E00;
  background-color: #FFD699;
}

.StationsTable_attachedYesIndicator__LbNCn {
  color: #439F6E;
}

.StationsTable_attachedNoIndicator__iXEuB {
  color: #F93232;
}

.StationsTable_closeIcon__F5y9D {
  cursor: pointer;
}

.StationsTable_removeTextContainer__3TpZN {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.StationsTable_removeTextContainer__3TpZN > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
}

.StationsTable_buttonsContainer__J2wro {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/Stations/StationsTable/StationsTable.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,wBAAA;AACJ;;AAEA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;AACR;;AAGA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAAJ","sourcesContent":[".stationStatus {\n    display: inline-block;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 20px;\n    border-radius: 12px;\n    padding: 4px 8px 4px 8px;\n}\n\n.active {\n    color: #31aa27;\n    background-color: #ebf6eb;\n}\n\n.inactive {\n    color: #7774B6;\n    background-color: #DFE4FD;\n}\n\n.deleted {\n    color: #f93232;\n    background-color: #fff0f0;\n}\n\n.notAttached {\n    color: #D27E00;\n    background-color: #FFD699;\n}\n\n.attachedYesIndicator {\n    color: #439F6E;\n}\n\n.attachedNoIndicator {\n    color: #F93232;\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n\n.removeTextContainer {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 24px;\n\n    & > span {\n        font-weight: 400;\n        font-size: 18px;\n        line-height: 25.2px;\n    }\n}\n\n.buttonsContainer {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stationStatus": `StationsTable_stationStatus__W6Ejl`,
	"active": `StationsTable_active__G5jzs`,
	"inactive": `StationsTable_inactive__bMDqW`,
	"deleted": `StationsTable_deleted__ACUez`,
	"notAttached": `StationsTable_notAttached__JnxKL`,
	"attachedYesIndicator": `StationsTable_attachedYesIndicator__LbNCn`,
	"attachedNoIndicator": `StationsTable_attachedNoIndicator__iXEuB`,
	"closeIcon": `StationsTable_closeIcon__F5y9D`,
	"removeTextContainer": `StationsTable_removeTextContainer__3TpZN`,
	"buttonsContainer": `StationsTable_buttonsContainer__J2wro`
};
export default ___CSS_LOADER_EXPORT___;
