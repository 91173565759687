import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'store/store';

import styles from './ProfileEdit.module.scss';
import {Alert, Box, Button, Snackbar} from '@mui/material';
import {
  CloseIcon,
  ErrorNotification,
  InfoIcon,
  SaveIcon,
  SuccessNotification,
} from 'assets/icons';
import {cancelButton, createButton} from 'styles/MUIStyles/buttons';
import {ErrorMessage} from '@hookform/error-message';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {useEffect, useState} from 'react';
import {ISnackbarState} from 'store/models/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {checkAllowedRoles} from 'core/utils';
import {UserRole} from 'enums';
import {useEditProfileMutation} from 'store/slices/profile';

interface IFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
}

const ProfileEdit = () => {
  const [edit, {isLoading, error, isError}] = useEditProfileMutation();
  const {firstName, lastName, email, phoneNumber, role} = useAppSelector(
    (state) => state.auth,
  );
  const navigate = useNavigate();

  const showInfoBox = checkAllowedRoles(
    [UserRole.Client, UserRole.Dealer, UserRole.Owner],
    role,
  );

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
    },
  });

  const saveProfile = async (formData: IFormData) => {
    const res = await edit({
      firstName: formData.firstName,
      lastName: formData.lastName,
      phoneNumber: formData.phoneNumber,
    });
    if (!('error' in res)) {
      setSuccessEditSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      //   navigate(-1);
      //   dispatch(getAuthorizedUserInfo());
    }
  };

  useEffect(() => {
    if (isError) {
      setErrorMessage(JSON.stringify(error));
      setErrorEditSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [error, isError]);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [errorEditSnackbar, setErrorEditSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: isErrorOpen,
  } = errorEditSnackbar;

  const [successEditSnackbar, setSuccessEditSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const {
    vertical: editVertical,
    horizontal: editHorizontal,
    open: isEditOpen,
  } = successEditSnackbar;

  const handleSnackbarClose = () => {
    setSuccessEditSnackbar({...successEditSnackbar, open: false});
    setErrorEditSnackbar({...errorEditSnackbar, open: false});
  };

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.profileTop}>
        <h1 className={styles.profileTitle}>My account</h1>
        <div className={styles.buttonsContainer}>
          <Button
            // startIcon={<CloseIcon style={{width: '20px', height: '20px'}} />}
            sx={cancelButton}
            onClick={() => {
              navigate(-1);
            }}
          >
            Close
          </Button>
          <ButtonWithProgress
            disabled={isLoading}
            loading={isLoading}
            type="button"
            startIcon={<SaveIcon />}
            sx={createButton}
            onClick={handleSubmit(saveProfile)}
          >
            Save
          </ButtonWithProgress>
        </div>
      </div>
      <div className={styles.informationMainContent}>
        {showInfoBox && (
          <div className={styles.infoBox}>
            <InfoIcon style={{width: '20px', height: '20px'}} />
            <p>If you want to change your email you need to contact admin</p>
          </div>
        )}
        <div className={styles.inputContainer}>
          <label htmlFor="firstName">First Name</label>
          <input
            {...register('firstName', {
              required: 'This field is required',
            })}
            type="text"
            id="firstName"
            autoComplete="off"
            className={`${errors?.firstName ? 'input inputError' : 'input'}`}
            placeholder="Enter First Name"
          />
          <ErrorMessage
            name="firstName"
            errors={errors}
            render={({message}) => (
              <span className={styles.inputErrorMessage}>{message}</span>
            )}
          />
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="lastName">Last Name</label>
          <input
            {...register('lastName', {
              required: 'This field is required',
            })}
            type="text"
            id="lastName"
            autoComplete="off"
            className={`${errors?.lastName ? 'input inputError' : 'input'}`}
            placeholder="Enter Last Name"
          />
          <ErrorMessage
            name="lastName"
            errors={errors}
            render={({message}) => (
              <span className={styles.inputErrorMessage}>{message}</span>
            )}
          />
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            autoComplete="off"
            value={email}
            disabled={true}
            className="input"
            placeholder="Enter Email"
          />
        </div>
        <div className={styles.inputContainer}>
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            {...register('phoneNumber', {
              required: 'This field is required',
            })}
            type="text"
            id="phoneNumber"
            autoComplete="off"
            className={`${errors?.phoneNumber ? 'input inputError' : 'input'}`}
            placeholder="Enter Phone Number"
          />
          <ErrorMessage
            name="phoneNumber"
            errors={errors}
            render={({message}) => (
              <span className={styles.inputErrorMessage}>{message}</span>
            )}
          />
        </div>
      </div>
      <Snackbar
        anchorOrigin={{vertical: editVertical, horizontal: editHorizontal}}
        autoHideDuration={2500}
        open={isEditOpen}
        onClose={handleSnackbarClose}
        key={'editSnackbar'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            Your changes were saved!
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorVertical,
            horizontal: errorHorizontal,
          }}
          autoHideDuration={3500}
          open={isErrorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default ProfileEdit;
