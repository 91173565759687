// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WarningModal_warningModalMainTitle__TXETa {
  font-weight: 600;
  line-height: 40px;
  font-size: 24px;
  color: #212121;
  margin: 0;
}

.WarningModal_warningModalTextContainer__wG9Tu {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.WarningModal_warningModalTextContainer__wG9Tu > span {
  font-weight: 400;
  font-size: 16px;
  line-height: 25.2px;
}
.WarningModal_warningModalTextContainer__wG9Tu > span:first-child {
  margin-bottom: 10px;
}

.WarningModal_closeIcon__8S22D {
  cursor: pointer;
}

.WarningModal_buttonsContainer__cn764 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/WarningModal/WarningModal.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,SAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AADF","sourcesContent":[".warningModalMainTitle {\n  font-weight: 600;\n  line-height: 40px;\n  font-size: 24px;\n  color: #212121;\n  margin: 0;\n}\n\n.warningModalTextContainer {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n\n  & > span {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 25.2px;\n  }\n\n  & > span:first-child {\n    margin-bottom: 10px;\n  }\n}\n\n.closeIcon {\n  cursor: pointer;\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warningModalMainTitle": `WarningModal_warningModalMainTitle__TXETa`,
	"warningModalTextContainer": `WarningModal_warningModalTextContainer__wG9Tu`,
	"closeIcon": `WarningModal_closeIcon__8S22D`,
	"buttonsContainer": `WarningModal_buttonsContainer__cn764`
};
export default ___CSS_LOADER_EXPORT___;
