import React, {FC} from 'react';
import styles from '../DealersInformation.module.scss';
import {IDealerInfo} from 'store/models/dealers';

interface Props {
  dealerData: IDealerInfo | undefined;
}

const InformationTab: FC<Props> = (props) => {
  return (
    <div className={styles.dealersInformationTab}>
      {props?.dealerData?.audit?.deleting ? (
        <span className={`${styles.dealerStatus} ${styles.deleted}`}>
          Removed
        </span>
      ) : (
        <span
          className={
            props?.dealerData?.isActive
              ? `${styles.dealerStatus} ${styles.active}`
              : `${styles.dealerStatus} ${styles.inactive}`
          }
        >
          {props?.dealerData?.isActive ? 'Active' : 'Inactive'}
        </span>
      )}
      <div>
        <span>First Name</span>
        <span>{props?.dealerData?.firstName}</span>
      </div>
      <div>
        <span>Last Name</span>
        <span>{props?.dealerData?.lastName}</span>
      </div>
      <div>
        <span>Email</span>
        <span>{props?.dealerData?.email}</span>
      </div>
      <div>
        <span>Number</span>
        <span>{props?.dealerData?.phoneNumber}</span>
      </div>
      {props?.dealerData?.additionalInformation ? (
        <div>
          <span>Additional information - Optional</span>
          <span>{props?.dealerData?.additionalInformation}</span>
        </div>
      ) : null}
    </div>
  );
};

export default InformationTab;
