// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssignBackup_modalTop__iu4TH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.AssignBackup_modalTop__iu4TH svg {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.AssignBackup_title__r8kl1 {
  font-weight: 700;
  line-height: 24px;
  font-size: 18px;
  color: #000;
  margin: 0;
}

.AssignBackup_buttonsContainer__Fezc- {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.AssignBackup_dropDownItem__QV-Jw {
  font-family: "Poppins", sans-serif;
  display: block;
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  color: #212121;
  line-height: 24px;
  font-weight: 400;
}
.AssignBackup_dropDownItem__QV-Jw:hover {
  background: #f5f8ff;
}`, "",{"version":3,"sources":["webpack://./src/views/Backups/AssignBackup/AssignBackup.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;EACA,WAAA;EACA,YAAA;AACR;;AAGA;EACI,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;AAAJ;;AAGA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAAJ;;AAGA;EACI,kCAAA;EACA,cAAA;EACA,4BAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,mBAAA;AAAR","sourcesContent":[".modalTop {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 15px;\n\n    & svg {\n        cursor: pointer;\n        width: 36px;\n        height: 36px;\n    }\n}\n\n.title {\n    font-weight: 700;\n    line-height: 24px;\n    font-size: 18px;\n    color: #000;\n    margin: 0;\n}\n\n.buttonsContainer {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 16px;\n}\n\n.dropDownItem {\n    font-family: 'Poppins', sans-serif;\n    display: block;\n    padding: 12px 16px 12px 16px;\n    font-size: 14px;\n    color: #212121;\n    line-height: 24px;\n    font-weight: 400;\n\n    &:hover {\n        background: #f5f8ff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTop": `AssignBackup_modalTop__iu4TH`,
	"title": `AssignBackup_title__r8kl1`,
	"buttonsContainer": `AssignBackup_buttonsContainer__Fezc-`,
	"dropDownItem": `AssignBackup_dropDownItem__QV-Jw`
};
export default ___CSS_LOADER_EXPORT___;
