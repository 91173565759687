export const modal = {
  fontFamily: 'Poppins, sans-serif',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  outline: 'none',
  width: '40%',
  bgcolor: '#fff',
  borderRadius: '12px',
  padding: '24px 20px 20px 20px',
  maxHeight: '90%',
  '&::-webkit-scrollbar': {
    width: '5px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E0E0F1',
    borderRadius: '100px',
  },
  overflow: 'auto',
};
