import {FC, ReactElement} from 'react';
import {UserRole} from 'enums';
import {useAppSelector} from 'store/store';

interface Props {
  children: ReactElement | null;
  allowedRoles: UserRole[];
}

const Guard: FC<Props> = ({allowedRoles, children}) => {
  const userRole = useAppSelector((state) => state.auth.role);

  return allowedRoles.some((allowedRole) => allowedRole === userRole)
    ? children
    : null;
};

export default Guard;
