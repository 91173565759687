import {useState} from 'react';
import styles from './ChangePassword.module.scss';
import {Alert, Box, Button, Snackbar} from '@mui/material';
import {
  CloseIcon,
  ErrorNotification,
  GoBackArrowButtonIcon,
  InfoIcon,
  SaveIcon,
  SuccessNotification,
} from 'assets/icons';
import {createButton, goBackButton} from 'styles/MUIStyles/buttons';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {ErrorMessage} from '@hookform/error-message';
import eyeOff from 'assets/icons/eyeOff.svg';
import eyeOn from 'assets/icons/eyeOn.svg';
import {ISnackbarState} from 'store/models/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {useChangeProfilePasswordMutation} from 'store/slices/profile';
import {useUpdateEffect} from 'hooks';

interface IFormInput {
  oldPassword: string;
  newPassword: string;
  repeatedNewPassword: string;
}

const ChangePassword = () => {
  const [isOldPwdVisible, setOldPwdVisible] = useState(false);
  const [isPwdVisible, setPwdVisible] = useState(false);
  const [isConfirmPwdVisible, setConfirmPwdVisible] = useState(false);
  const toggleOldPwdVisibility = () => {
    setOldPwdVisible((prevState) => !prevState);
  };
  const togglePwdVisibility = () => {
    setPwdVisible((prevState) => !prevState);
  };
  const toggleConfirmPwdVisibility = () => {
    setConfirmPwdVisible((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      repeatedNewPassword: '',
    },
  });

  const [changePassword, {isLoading, isSuccess}] =
    useChangeProfilePasswordMutation();
  const [successSnackbar, setSuccessSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [errorSnackbar, setErrorSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {vertical, horizontal, open} = successSnackbar;

  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: errorOpen,
  } = errorSnackbar;

  const handleSnackbarClose = () => {
    setErrorSnackbar({...errorSnackbar, open: false});
    setSuccessSnackbar({...successSnackbar, open: false});
    setErrorMessage(null);
  };

  const onSubmit = async (formData: IFormInput) => {
    const res = await changePassword(formData);
    if ('error' in res && 'status' in res.error) {
      const errors = res.error.data?.errors;

      for (let key in errors) {
        if (Array.isArray(errors[key])) {
          setErrorMessage(errors[key]);
          setErrorSnackbar({
            vertical: 'top',
            horizontal: 'center',
            open: true,
          });
          break;
        }
      }
    }
  };

  useUpdateEffect(() => {
    if (isSuccess) {
      setSuccessSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [isSuccess]);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <div className={styles.profileTop}>
        <h1 className={styles.profileTitle}>Change Password</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <ButtonWithProgress
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            sx={createButton}
            startIcon={<SaveIcon />}
            form="changePasswordForm"
          >
            Save
          </ButtonWithProgress>
        </div>
      </div>
      <div className={styles.informationMainContent}>
        <form
          id="changePasswordForm"
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.inputContainer}>
            <label htmlFor="oldPassword">Old Password</label>
            <div>
              <input
                {...register('oldPassword', {
                  required: 'This field is required',
                })}
                type={isOldPwdVisible ? 'text' : 'password'}
                id="oldPassword"
                placeholder="Enter current password"
                className={
                  errors?.newPassword
                    ? styles.input + ' ' + styles.inputError
                    : styles.input
                }
              />
              <ErrorMessage
                errors={errors}
                name="oldPassword"
                render={({message}) => (
                  <span className={styles.errorMessage}>{message}</span>
                )}
              />
              <img
                src={isOldPwdVisible ? eyeOn : eyeOff}
                alt={isOldPwdVisible ? 'hide password' : 'show password'}
                onClick={toggleOldPwdVisibility}
                className={
                  errors?.oldPassword
                    ? styles.inputEye + ' ' + styles.inputEyeError
                    : styles.inputEye
                }
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="newPassword">New Password</label>
            <div>
              <input
                {...register('newPassword', {
                  required: 'This field is required',
                })}
                type={isPwdVisible ? 'text' : 'password'}
                id="newPassword"
                placeholder="Enter new password"
                className={
                  errors?.newPassword
                    ? styles.input + ' ' + styles.inputError
                    : styles.input
                }
              />
              <ErrorMessage
                errors={errors}
                name="newPassword"
                render={({message}) => (
                  <span className={styles.errorMessage}>{message}</span>
                )}
              />
              <img
                src={isPwdVisible ? eyeOn : eyeOff}
                alt={isPwdVisible ? 'hide password' : 'show password'}
                onClick={togglePwdVisibility}
                className={
                  errors?.newPassword
                    ? styles.inputEye + ' ' + styles.inputEyeError
                    : styles.inputEye
                }
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label htmlFor="repeatedNewPassword">Confirm New Password</label>
            <div>
              <input
                {...register('repeatedNewPassword', {
                  required: 'This field is required',
                })}
                type={isConfirmPwdVisible ? 'text' : 'password'}
                id="repeatedNewPassword"
                placeholder="Enter new password again"
                className={
                  errors?.newPassword
                    ? styles.input + ' ' + styles.inputError
                    : styles.input
                }
              />
              <ErrorMessage
                errors={errors}
                name="repeatedNewPassword"
                render={({message}) => (
                  <span className={styles.errorMessage}>{message}</span>
                )}
              />
              <img
                src={isConfirmPwdVisible ? eyeOn : eyeOff}
                alt={isConfirmPwdVisible ? 'hide password' : 'show password'}
                onClick={toggleConfirmPwdVisibility}
                className={
                  errors?.repeatedNewPassword
                    ? styles.inputEye + ' ' + styles.inputEyeError
                    : styles.inputEye
                }
              />
            </div>
          </div>
        </form>
        <div className={styles.pwdPolicy}>
          <div className={styles.pwdPolicy_title}>
            <InfoIcon />
            <h4>Password must:</h4>
          </div>
          <ul className={styles.pwdPolicy_list}>
            <li>Have at least 12 characters length</li>
            <li>Have at least one digit number</li>
            <li>Have at least one upper-case letter</li>
            <li>Have at least one lower-case letter</li>
            <li>Have at least one non alphanumeric symbol</li>
          </ul>
        </div>
      </div>
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorVertical,
            horizontal: errorHorizontal,
          }}
          autoHideDuration={3500}
          open={errorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
      <Snackbar
        anchorOrigin={{vertical, horizontal}}
        autoHideDuration={2500}
        open={open}
        onClose={handleSnackbarClose}
        key={'editSnackbar'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            Password successfully changed!
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChangePassword;
