// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogsTable_closeIcon__EjfaM {
  cursor: pointer;
}

.LogsTable_branchName__ophPI {
  cursor: pointer;
}

.LogsTable_removeTextContainer__6\\+jDW {
  display: flex;
  flex-direction: column;
}
.LogsTable_removeTextContainer__6\\+jDW > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
}

.LogsTable_buttonsContainer__vJoGV {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.LogsTable_logsLevel__iA7n7 {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  border-radius: 12px;
  padding: 4px 8px;
  box-sizing: border-box;
}

.LogsTable_logsLevel__iA7n7.LogsTable_error__IXVlJ {
  color: #990b0b;
  background-color: #fbd3d3;
}

.LogsTable_logsLevel__iA7n7.LogsTable_verbose__YCh8V {
  color: #6133FF;
  background-color: #EEE5FF;
}

.LogsTable_logsLevel__iA7n7.LogsTable_debug__fJXR0 {
  color: #029EBE;
  background-color: #029EBE;
}

.LogsTable_logsLevel__iA7n7.LogsTable_fatal__NzK8o {
  color: #D27E00;
  background-color: #FFD699;
}

.LogsTable_logsLevel__iA7n7.LogsTable_information__Dorr3 {
  color: #487bff;
  background-color: #DDE7FF;
}

.LogsTable_logsLevel__iA7n7.LogsTable_warning__2NPUv {
  color: #bd0e0e;
  background-color: #fbdcdc;
}

.LogsTable_logsMessage__rY640 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  cursor: pointer;
  text-overflow: ellipsis;
  word-break: break-all;
}`, "",{"version":3,"sources":["webpack://./src/views/Logs/LogsTable.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAAF;;AAGA;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;AAAF;;AAGA;EACE,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,oBAAA;EACA,4BAAA;EACA,qBAAA;EACA,eAAA;EACA,uBAAA;EACA,qBAAA;AAAF","sourcesContent":[".closeIcon {\n  cursor: pointer;\n}\n\n.branchName {\n  cursor: pointer;\n}\n\n.removeTextContainer {\n  display: flex;\n  flex-direction: column;\n\n  & > span {\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 25.2px;\n  }\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px;\n}\n\n.logsLevel {\n  display: inline-block;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  border-radius: 12px;\n  padding: 4px 8px;\n  box-sizing: border-box;\n}\n\n.logsLevel.error {\n  color: #990b0b;\n  background-color: #fbd3d3;\n}\n\n.logsLevel.verbose {\n  color: #6133FF;\n  background-color: #EEE5FF;\n}\n\n.logsLevel.debug {\n  color: #029EBE;\n  background-color: #029EBE;\n}\n\n.logsLevel.fatal {\n  color: #D27E00;\n  background-color: #FFD699;\n}\n\n.logsLevel.information {\n  color: #487bff;\n  background-color: #DDE7FF;\n}\n\n.logsLevel.warning {\n  color: #bd0e0e;\n  background-color: #fbdcdc;\n}\n\n.logsMessage {\n  overflow: hidden;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 3;\n  cursor: pointer;\n  text-overflow: ellipsis;\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeIcon": `LogsTable_closeIcon__EjfaM`,
	"branchName": `LogsTable_branchName__ophPI`,
	"removeTextContainer": `LogsTable_removeTextContainer__6+jDW`,
	"buttonsContainer": `LogsTable_buttonsContainer__vJoGV`,
	"logsLevel": `LogsTable_logsLevel__iA7n7`,
	"error": `LogsTable_error__IXVlJ`,
	"verbose": `LogsTable_verbose__YCh8V`,
	"debug": `LogsTable_debug__fJXR0`,
	"fatal": `LogsTable_fatal__NzK8o`,
	"information": `LogsTable_information__Dorr3`,
	"warning": `LogsTable_warning__2NPUv`,
	"logsMessage": `LogsTable_logsMessage__rY640`
};
export default ___CSS_LOADER_EXPORT___;
