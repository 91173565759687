import {FC} from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from '@mui/material';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import {ArrowAsc, ArrowDesc, DefaultSortIcon} from 'assets/icons';
import {IMetadata} from 'store/models/common';
import {ISortConfig} from 'hooks/useSortColumn';
import {IDCCollectorVersion} from 'store/models/dataCollector';
import {useGetNumberOfElements} from 'hooks';
import dayjs from 'dayjs';

interface DCTableProps {
  data: IDCCollectorVersion[];
  metaData: IMetadata | null;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: {...styledTableBody, padding: '18px 16px'},
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

const DCTable: FC<DCTableProps> = (props) => {
  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  const itemsOnPage = useGetNumberOfElements(props.metaData);

  return (
    <TableContainer component={Paper} sx={tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{width: '5%'}}>
              <Box sx={tableHeadCell}>
                <span>№</span>
              </Box>
            </StyledTableCell>
            <StyledTableCell sx={{width: '40%'}}>
              <Box
                sx={tableHeadCell}
                onClick={() => props.handleColumnClick('path')}
              >
                <span>File Name</span>
                {getArrowForSortDirection('path')}
              </Box>
            </StyledTableCell>
            <StyledTableCell sx={{width: '25%'}}>
              <Box
                sx={tableHeadCell}
                onClick={() => props.handleColumnClick('id')}
              >
                <span>Version</span>
                {getArrowForSortDirection('id')}
              </Box>
            </StyledTableCell>
            <StyledTableCell sx={{width: '30%'}}>
              <Box
                sx={tableHeadCell}
                onClick={() => props.handleColumnClick('createdDateTime')}
              >
                <span>Created date time</span>
                {getArrowForSortDirection('createdDateTime')}
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>
                {itemsOnPage.length ? itemsOnPage[index] : null}
              </StyledTableCell>
              <StyledTableCell>{row.path}</StyledTableCell>
              <StyledTableCell>{row.id}</StyledTableCell>
              <StyledTableCell>
                {row.createdDateTime
                  ? dayjs(row.createdDateTime).format('DD.MM.YYYY HH:mm')
                  : null}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DCTable;
