// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DealersCreate_dealersCreateTitle__o7nx5 {
  font-weight: 600;
  line-height: 40px;
  font-size: 24px;
  color: #212121;
  margin: 0;
}

.DealersCreate_closeIcon__ps0\\+F {
  cursor: pointer;
}

.DealersCreate_firstNameInputContainer__pRg8U,
.DealersCreate_lastNameInputContainer__bhBhf,
.DealersCreate_emailInputContainer__QT5vG,
.DealersCreate_phoneNumberInputContainer__X-oQz,
.DealersCreate_additionalInfoInputContainer__GwkLU {
  margin-bottom: 6px;
}
.DealersCreate_firstNameInputContainer__pRg8U label,
.DealersCreate_lastNameInputContainer__bhBhf label,
.DealersCreate_emailInputContainer__QT5vG label,
.DealersCreate_phoneNumberInputContainer__X-oQz label,
.DealersCreate_additionalInfoInputContainer__GwkLU label {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #8a8894;
  margin-left: 12px;
  margin-bottom: 6px;
}
.DealersCreate_firstNameInputContainer__pRg8U .DealersCreate_inputErrorMessage__Zlh9Q,
.DealersCreate_lastNameInputContainer__bhBhf .DealersCreate_inputErrorMessage__Zlh9Q,
.DealersCreate_emailInputContainer__QT5vG .DealersCreate_inputErrorMessage__Zlh9Q,
.DealersCreate_phoneNumberInputContainer__X-oQz .DealersCreate_inputErrorMessage__Zlh9Q,
.DealersCreate_additionalInfoInputContainer__GwkLU .DealersCreate_inputErrorMessage__Zlh9Q {
  display: inline-block;
  font-size: 12px;
  color: #f93232;
  line-height: 16px;
  margin-left: 12px;
  margin-top: 4px;
}

.DealersCreate_buttonsContainer__zkfsJ {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/Dealers/DealersCreate/DealersCreate.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;;;;;EAKE,kBAAA;AACF;AAAE;;;;;EACE,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AAMJ;AAHE;;;;;EACE,qBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AASJ;;AALA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAQF","sourcesContent":[".dealersCreateTitle {\n  font-weight: 600;\n  line-height: 40px;\n  font-size: 24px;\n  color: #212121;\n  margin: 0;\n}\n\n.closeIcon {\n  cursor: pointer;\n}\n\n.firstNameInputContainer,\n.lastNameInputContainer,\n.emailInputContainer,\n.phoneNumberInputContainer,\n.additionalInfoInputContainer {\n  margin-bottom: 6px;\n  & label {\n    display: inline-block;\n    font-size: 12px;\n    line-height: 18px;\n    font-weight: 400;\n    color: #8a8894;\n    margin-left: 12px;\n    margin-bottom: 6px;\n  }\n\n  & .inputErrorMessage {\n    display: inline-block;\n    font-size: 12px;\n    color: #f93232;\n    line-height: 16px;\n    margin-left: 12px;\n    margin-top: 4px;\n  }\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dealersCreateTitle": `DealersCreate_dealersCreateTitle__o7nx5`,
	"closeIcon": `DealersCreate_closeIcon__ps0+F`,
	"firstNameInputContainer": `DealersCreate_firstNameInputContainer__pRg8U`,
	"lastNameInputContainer": `DealersCreate_lastNameInputContainer__bhBhf`,
	"emailInputContainer": `DealersCreate_emailInputContainer__QT5vG`,
	"phoneNumberInputContainer": `DealersCreate_phoneNumberInputContainer__X-oQz`,
	"additionalInfoInputContainer": `DealersCreate_additionalInfoInputContainer__GwkLU`,
	"inputErrorMessage": `DealersCreate_inputErrorMessage__Zlh9Q`,
	"buttonsContainer": `DealersCreate_buttonsContainer__zkfsJ`
};
export default ___CSS_LOADER_EXPORT___;
