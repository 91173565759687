// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ErrorFallback_errorPage__k4s9X {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 32px);
  width: 100%;
}
.ErrorFallback_errorPage__k4s9X .ErrorFallback_errorPageContainer__Enb1\\+ {
  width: 60%;
  height: 60%;
  text-align: center;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}
.ErrorFallback_errorPage__k4s9X .ErrorFallback_errorPageHeading__v6qvE {
  margin-top: 65px;
  font-size: 52px;
  font-weight: 600;
  line-height: 78px;
  letter-spacing: 0em;
  color: #3152c7;
}
.ErrorFallback_errorPage__k4s9X .ErrorFallback_errorPageText__AW73z {
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  color: #8a8894;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorFallback/ErrorFallback.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,0BAAA;EACA,WAAA;AACJ;AACI;EACI,UAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AACR;AAEI;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AAAR;AAGI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AADR","sourcesContent":[".errorPage {\n    background: #fff;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    height: calc(100vh - 32px);\n    width: 100%;\n\n    .errorPageContainer {\n        width: 60%;\n        height: 60%;\n        text-align: center;\n        align-items: center;\n        padding: 20px;\n        border-radius: 10px;\n    }\n\n    .errorPageHeading {\n        margin-top: 65px;\n        font-size: 52px;\n        font-weight: 600;\n        line-height: 78px;\n        letter-spacing: 0em;\n        color: #3152c7;\n    }\n\n    .errorPageText {\n        font-size: 32px;\n        font-weight: 400;\n        line-height: 48px;\n        letter-spacing: 0px;\n        text-align: center;\n        color: #8a8894;\n        margin-bottom: 24px;\n    }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorPage": `ErrorFallback_errorPage__k4s9X`,
	"errorPageContainer": `ErrorFallback_errorPageContainer__Enb1+`,
	"errorPageHeading": `ErrorFallback_errorPageHeading__v6qvE`,
	"errorPageText": `ErrorFallback_errorPageText__AW73z`
};
export default ___CSS_LOADER_EXPORT___;
