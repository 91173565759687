import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useLazyGetStationByIdQuery,
  useRecoverStationMutation,
} from 'store/slices/stations';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Tab,
  Tabs,
} from '@mui/material';
import MainInfoTab from './MainInfoTab/MainInfoTab';
import ActivityLogTab from './ActivityLogTab/ActivityLogTab';
import ManifestTab from './ManifestTab/ManifestTab';
import {
  CloseIcon,
  EditIcon,
  GoBackArrowButtonIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import {editDealerButton, goBackButton} from 'styles/MUIStyles/buttons';
import {tab} from 'styles/MUIStyles/common';
import styles from './StationInformation.module.scss';
import WarningModal from '../../../components/WarningModal/WarningModal';
import {ISnackbarState} from '../../../store/models/common';
import {useUpdateEffect} from '../../../hooks';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from '../../../styles/MUIStyles/snackbars';
import {UserRole} from '../../../enums';
import Guard from '../../../components/Guard/Guard';
import {useAppSelector} from 'store/store';
import {checkAllowedRoles} from 'core/utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0 0 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StationInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.role);
  const [
    getStationById,
    {data: stationData, isLoading: isGettingStationDataLoading},
  ] = useLazyGetStationByIdQuery();

  const isAllowedRoleFor = checkAllowedRoles(
    [UserRole.Admin, UserRole.Dealer],
    userRole,
  );

  const [
    recoverStation,
    {
      isLoading: isRecoveringStationLoading,
      isSuccess: isRecoveringStationSuccess,
    },
  ] = useRecoverStationMutation();

  const [activeTab, setActiveTab] = useState(0);
  const [isRecoverStationModalOpen, setIsRecoverStationModalOpen] =
    useState(false);
  const [recoverStationSnackbar, setRecoverStationSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: recoverVertical,
    horizontal: recoverHorizontal,
    open: recoverOpen,
  } = recoverStationSnackbar;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSnackbarClose = () => {
    setRecoverStationSnackbar({...recoverStationSnackbar, open: false});
  };

  useEffect(() => {
    if (params?.stationId) {
      getStationById(params.stationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.stationId]);

  useUpdateEffect(() => {
    if (isRecoveringStationSuccess && params?.stationId) {
      setRecoverStationSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoverStationModalOpen(false);
      getStationById(params.stationId);
    }
  }, [isRecoveringStationSuccess]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.stationInformationTop}>
        <h1 className={styles.stationInformationTitle}>Station</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          {stationData?.audit?.deleting ? (
            <Button
              variant="contained"
              startIcon={<RecoverIcon />}
              onClick={() => setIsRecoverStationModalOpen(true)}
              sx={editDealerButton}
            >
              Recover
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                if (params?.stationId) {
                  navigate('/stations/edit/' + params.stationId);
                }
              }}
              sx={editDealerButton}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className={styles.stationInformationMainContent}>
        {isGettingStationDataLoading ? (
          <Box sx={{display: 'flex', height: '100%'}}>
            <CircularProgress
              size={45}
              sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
            />
          </Box>
        ) : (
          <Box sx={{height: '100%', width: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {
                  backgroundColor: 'rgba(49, 82, 199, 1)',
                },
              }}
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Main info" {...a11yProps(0)} />
              {isAllowedRoleFor && (
                <Tab sx={tab} label="Manifest" {...a11yProps(1)} />
              )}
              {isAllowedRoleFor && (
                <Tab sx={tab} label="Activity Log" {...a11yProps(2)} />
              )}
            </Tabs>
            <CustomTabPanel value={activeTab} index={0}>
              <MainInfoTab
                stationData={stationData}
                getStationById={getStationById}
              />
            </CustomTabPanel>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <CustomTabPanel value={activeTab} index={1}>
                <ManifestTab stationData={stationData} />
              </CustomTabPanel>
            </Guard>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <CustomTabPanel value={activeTab} index={2}>
                <ActivityLogTab stationData={stationData} />
              </CustomTabPanel>
            </Guard>
          </Box>
        )}
      </div>

      {stationData ? (
        <>
          <WarningModal
            isOpen={isRecoverStationModalOpen}
            onClose={() => setIsRecoverStationModalOpen(false)}
            entityText={''}
            title={'Recover station ?'}
            textBlocks={[
              `Are you sure you want to recover station ${stationData?.name}`,
            ]}
            confirmButtonText={'Yes, recover'}
            onConfirmButtonClick={() => {
              if (params?.stationId) {
                recoverStation(params?.stationId);
              }
            }}
            loading={isRecoveringStationLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: recoverVertical,
              horizontal: recoverHorizontal,
            }}
            autoHideDuration={2500}
            open={recoverOpen}
            onClose={handleSnackbarClose}
            key={`recoverStation`}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Station {`"${stationData.name}"`} was recovered
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </div>
  );
};

export default StationInformation;
