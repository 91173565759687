import {
  IGetAllBackupsResponse,
  IGetBackupsArgs,
  IGetMainBackupsResponse,
} from '../models/backups';

import {IGetStationsResponse} from '../models/stations';
import {UserRole} from 'enums';
import {api} from './api';
import dayjs from 'dayjs';

export const backups = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMainBackups: builder.query<IGetMainBackupsResponse, IGetBackupsArgs>({
        query: (mainBackupsParams) => {
          const params = new URLSearchParams();

          if (mainBackupsParams.editDateTime) {
            params.append(
              'startLastModifyDateTime',
              dayjs(mainBackupsParams.editDateTime).format(
                'YYYY-MM-DD HH:mm:ss',
              ),
            );
          }

          if (mainBackupsParams.editEndDateTime) {
            params.append(
              'endLastModifyDateTime',

              dayjs(mainBackupsParams.editEndDateTime).format(
                'YYYY-MM-DD HH:mm:ss',
              ),
            );
          }

          if (mainBackupsParams.search) {
            params.append('term', mainBackupsParams.search);
          }

          if (mainBackupsParams.pageNumber) {
            params.append('pageNumber', String(mainBackupsParams.pageNumber));
          }

          if (mainBackupsParams.pageSize) {
            params.append('pageSize', String(mainBackupsParams.pageSize));
          }

          if (mainBackupsParams.startDateTime) {
            params.append(
              'startDateTime',
              dayjs(mainBackupsParams.startDateTime)
                .utc()
                .format('YYYY-MM-DD HH:mm:ss'),
            );
          }

          if (mainBackupsParams.endDateTime) {
            params.append(
              'endDateTime',
              dayjs(mainBackupsParams.endDateTime)
                .utc()
                .format('YYYY-MM-DD HH:mm:ss'),
            );
          }

          if (mainBackupsParams.mainBackupFileType?.length) {
            for (
              let i = 0;
              i < mainBackupsParams.mainBackupFileType.length;
              i++
            ) {
              params.append(
                `types`,
                mainBackupsParams.mainBackupFileType[i].name,
              );
            }
          }

          if (mainBackupsParams?.companies?.length) {
            mainBackupsParams.companies.forEach((company) => {
              params.append('companyIds', company.id);
            });
          }
          if (mainBackupsParams?.branches?.length) {
            mainBackupsParams.branches.forEach((branch) => {
              params.append('branchIds', branch.id);
            });
          }
          if (mainBackupsParams?.stations?.length) {
            mainBackupsParams.stations.forEach((station) => {
              params.append('stationIds', station.id);
            });
          }

          if (
            mainBackupsParams.sortBy?.column &&
            mainBackupsParams.sortBy.direction
          ) {
            const column = mainBackupsParams.sortBy?.column;
            const direction = mainBackupsParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }
          if (
            [UserRole.Owner, UserRole.Client].includes(
              mainBackupsParams.userRole,
            )
          ) {
            params.append('areDeletedFilesVisible', 'false');
          } else {
            params.append('areDeletedFilesVisible', 'true');
          }

          return {
            url: `backups/main-backup?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Backups'],
      }),
      getAllBackups: builder.query<IGetAllBackupsResponse, IGetBackupsArgs>({
        query: (allBackupsParams) => {
          const params = new URLSearchParams();

          if (allBackupsParams.search) {
            params.append('term', allBackupsParams.search);
          }

          if (allBackupsParams.pageNumber) {
            params.append('pageNumber', String(allBackupsParams.pageNumber));
          }

          if (allBackupsParams.pageSize) {
            params.append('pageSize', String(allBackupsParams.pageSize));
          }

          if (allBackupsParams.startDateTime) {
            params.append(
              'startDateTime',
              dayjs(allBackupsParams.startDateTime)
                .utc()
                // .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (allBackupsParams.endDateTime) {
            params.append(
              'endDateTime',
              dayjs(allBackupsParams.endDateTime)
                .utc()
                // .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          // if (allBackupsParams.allBackupFileType) {
          //   params.append('type', allBackupsParams.allBackupFileType.name);
          // }

          if (allBackupsParams?.companies?.length) {
            allBackupsParams.companies.forEach((company) => {
              params.append('companyIds', company.id);
            });
          }
          if (allBackupsParams?.branches?.length) {
            allBackupsParams.branches.forEach((branch) => {
              params.append('branchIds', branch.id);
            });
          }
          if (allBackupsParams?.stations?.length) {
            allBackupsParams.stations.forEach((station) => {
              params.append('stationIds', station.id);
            });
          }

          if (
            allBackupsParams.sortBy?.column &&
            allBackupsParams.sortBy.direction
          ) {
            const column = allBackupsParams.sortBy?.column;
            const direction = allBackupsParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `backups/all-backups?${params.toString()}`,
            method: 'get',
          };
        },
      }),
      getStationForAllBackups: builder.query<IGetStationsResponse, void>({
        query: () => {
          return {
            url: 'stations?isDeleted=false',
            method: 'get',
          };
        },
      }),
      getBackupDownloadLinks: builder.query<string[], number[]>({
        query: (backupIds) => {
          const params = new URLSearchParams();

          if (backupIds.length) {
            backupIds.forEach((id: number) => {
              params.append('ids', String(id));
            });
          }

          return {
            url: `backups/download-backup-links?${params.toString()}`,
            method: 'get',
          };
        },
      }),
      assignFileToAnotherStation: builder.mutation<
        void,
        {fileIds: number[]; stationId: string}
      >({
        query: (data) => {
          return {
            url: 'backups/assign-files-to-another-station',
            method: 'post',
            data: data,
          };
        },
        invalidatesTags: () => ['Backups'],
      }),
      markAsDeleted: builder.mutation<
        void,
        {fileId: number; isDelete: boolean}
      >({
        query: (data) => {
          const isObject = (value: any) => {
            return (
              value !== null &&
              typeof value === 'object' &&
              !Array.isArray(value)
            );
          };
          return {
            url: 'backups/soft-delete-backup-files',
            method: 'patch',
            data: {softDeleteBackups: isObject(data) ? [data] : data},
          };
        },
        invalidatesTags: () => ['Backups'],
      }),
    };
  },
});

export const {
  useGetMainBackupsQuery,
  useGetAllBackupsQuery,
  useGetStationForAllBackupsQuery,
  useLazyGetBackupDownloadLinksQuery,
  useAssignFileToAnotherStationMutation,
  useMarkAsDeletedMutation,
} = backups;
