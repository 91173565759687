import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {ErrorMessage} from '@hookform/error-message';
import {ISnackbarState} from 'store/models/common';
import {
  useClientResetPasswordMutation,
  useDeleteBranchFromClientMutation,
  useEditClientMutation,
  useLazyGetClientByIdQuery,
} from 'store/slices/clients';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Stack,
  styled,
  Switch,
} from '@mui/material';
import WarningModal from 'components/WarningModal/WarningModal';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {CloseIcon, PlusIcon, SaveIcon, SuccessNotification} from 'assets/icons';
import {
  addBranchInEdit,
  cancelButton,
  warningModalButton as createButton,
} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {androidSwitch, switchLabel} from 'styles/MUIStyles/common';
import styles from './ClientsEdit.module.scss';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';
import ClientsAddBranch from '../ClientsAddBranch/ClientsAddBranch';

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  isActive: boolean;
  additionalInformation: string;
}

const AndroidSwitch = styled(Switch)(() => androidSwitch);

const ClientsEdit = () => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      additionalInformation: '',
    },
  });

  const [getClientById, {data: clientData, isFetching}] =
    useLazyGetClientByIdQuery();
  const [
    deleteBranchFromClient,
    {
      isLoading: isDeletingBranchFromClientLoading,
      isSuccess: isDeleteBranchFromClientSuccess,
    },
  ] = useDeleteBranchFromClientMutation();

  const [
    editClient,
    {
      isLoading: isEditingClientLoading,
      isSuccess: isEditClientSuccess,
      error: editClientErrors,
    },
  ] = useEditClientMutation();

  const [
    resetPassword,
    {
      isLoading: isResettingClientPasswordLoading,
      isSuccess: isResetClientPasswordSuccess,
    },
  ] = useClientResetPasswordMutation();

  const [isAddBranchToClientModalOpen, setIsAddBranchToClientModalOpen] =
    useState(false);
  const [isDeleteBranchModalOpen, setIsDeleteBranchModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [deleteBranch, setDeleteBranch] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [resetClientSnackbar, setResetClientSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [successEditSnackbar, setSuccessEditSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: resetVertical,
    horizontal: resetHorizontal,
    open: resetOpen,
  } = resetClientSnackbar;
  const {
    vertical: editVertical,
    horizontal: editHorizontal,
    open: editOpen,
  } = successEditSnackbar;

  const onSubmit = (formData: IFormData) => {
    if (params?.userId) {
      editClient({
        ...formData,
        id: params.userId,
        firstName: formData.firstName.trim(),
        lastName: formData.lastName.trim(),
        email: formData.email.trim(),
        phoneNumber: formData.phoneNumber.trim(),
        additionalInformation: formData.additionalInformation.trim(),
      });
    }
  };

  const handleSnackbarClose = () => {
    setResetClientSnackbar({...resetClientSnackbar, open: false});
    setSuccessEditSnackbar({...successEditSnackbar, open: false});
    // setDeleteCompanyFromCompanyOwnerSnackbar({...deleteCompanyFromCompanyOwnerSnackbar, open: false});
  };

  useEffect(() => {
    if (params?.userId) {
      getClientById(params.userId);
      setIsDeleteBranchModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.userId, isDeleteBranchFromClientSuccess]);

  useEffect(() => {
    if (clientData) {
      setValue('firstName', clientData.firstName);
      setValue('lastName', clientData.lastName);
      setValue('email', clientData.email);
      setValue('phoneNumber', clientData.phoneNumber);
      setValue('isActive', clientData.isActive);
      setValue(
        'additionalInformation',
        clientData?.additionalInformation || '',
      );
    }
  }, [clientData, setValue]);

  useEffect(() => {
    if (isResetClientPasswordSuccess) {
      setResetClientSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsResetPasswordModalOpen(false);
    }

    if (isEditClientSuccess) {
      setSuccessEditSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [isResetClientPasswordSuccess, isEditClientSuccess]);

  useEffect(() => {
    if (editClientErrors) {
      if ('data' in editClientErrors) {
        const errors = editClientErrors.data?.errors;
        if (errors?.email?.[0] || errors?.Email?.[0]) {
          setError('email', {
            type: 'custom',
            message: errors?.email?.[0] || errors?.Email?.[0],
          });
        }

        if (errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0]) {
          setError('phoneNumber', {
            type: 'custom',
            message: errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0],
          });
        }
      }
    }
  }, [editClientErrors, setError]);

  return (
    <div style={{height: '100%'}}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.clientEditForm}>
        {isFetching ? (
          <CircularProgress
            size={45}
            sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
          />
        ) : (
          <>
            <div className={styles.clientEditTop}>
              <h1 className={styles.clientEditTitle}>Edit User</h1>
              <div className={styles.clientButtons}>
                <Button onClick={() => navigate(-1)} sx={cancelButton}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  startIcon={
                    isEditingClientLoading ? (
                      <LoadingIconSpacer width={'16px'} height={'16px'} />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  disabled={isEditingClientLoading}
                  loading={isEditingClientLoading}
                  type="submit"
                  sx={createButton}
                >
                  Save
                </ButtonWithProgress>
              </div>
            </div>
            <div className={styles.clientEditMainContent}>
              <div className={styles.firstNameInputContainer}>
                <label htmlFor="firstName">First Name</label>
                <input
                  {...register('firstName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  className={`${
                    errors?.firstName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter First Name"
                />
                <ErrorMessage
                  name="firstName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.lastNameInputContainer}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  {...register('lastName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  className={`${
                    errors?.lastName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter Last Name"
                />
                <ErrorMessage
                  name="lastName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.emailInputContainer}>
                <label htmlFor="email">Email</label>
                <input
                  {...register('email', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address',
                    },
                  })}
                  type="text"
                  id="email"
                  autoComplete="off"
                  className={`${errors?.email ? 'input inputError' : 'input'}`}
                  placeholder="Enter Email"
                />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.phoneNumberInputContainer}>
                <label htmlFor="phoneNumber">Phone number</label>
                <input
                  {...register('phoneNumber', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="phoneNumber"
                  autoComplete="off"
                  className={`${
                    errors?.phoneNumber ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter phone number"
                />
                <ErrorMessage
                  name="phoneNumber"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.additionalInfoInputContainer}>
                <label htmlFor="additionalInformation">
                  Additional information - Optional
                </label>
                <input
                  {...register('additionalInformation')}
                  type="text"
                  id="additionalInformation"
                  autoComplete="off"
                  className={`input`}
                  placeholder="Enter additional information"
                />
              </div>

              {clientData?.branches.length ? (
                <div className={styles.clientBranchesList}>
                  <h3>Branches</h3>
                  {clientData.branches.map((branch) => (
                    <div key={branch.id} className={styles.branchesListItem}>
                      <span>{branch.name}</span>
                      <Button
                        variant="text"
                        onClick={() => {
                          setDeleteBranch(branch);
                          setIsDeleteBranchModalOpen(true);
                        }}
                        sx={{
                          fontFamily: 'Poppins, sans-serif',
                          textTransform: 'capitalize',
                          color: '#3152C7',
                          lineHeight: '16px',
                          fontSize: '14px',
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              ) : null}

              <Button
                variant="text"
                startIcon={<PlusIcon />}
                sx={addBranchInEdit}
                onClick={() => setIsAddBranchToClientModalOpen(true)}
              >
                Add branch
              </Button>

              <div className={styles.clientEditControls}>
                {clientData && (
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Controller
                      name={'isActive'}
                      control={control}
                      render={({field}) => (
                        <FormGroup>
                          <FormControlLabel
                            sx={switchLabel}
                            control={
                              <AndroidSwitch
                                {...field}
                                checked={Boolean(field.value)}
                              />
                            }
                            label={field.value ? 'active' : 'inactive'}
                          />
                        </FormGroup>
                      )}
                    />
                  </Stack>
                )}
                <Button
                  onClick={() => setIsResetPasswordModalOpen(true)}
                  sx={cancelButton}
                >
                  Reset Password
                </Button>
              </div>
            </div>
          </>
        )}
      </form>

      {deleteBranch && params?.userId ? (
        <>
          <WarningModal
            isOpen={isDeleteBranchModalOpen}
            onClose={() => setIsDeleteBranchModalOpen(false)}
            entityText={''}
            title={'Remove branch ?'}
            textBlocks={[
              `Are you sure you want to remove branch ${deleteBranch.name} ?`,
            ]}
            confirmButtonText={'Yes, remove'}
            onConfirmButtonClick={() => {
              if (params?.userId) {
                deleteBranchFromClient({
                  branchId: deleteBranch.id,
                  clientId: params.userId,
                });
              }
            }}
            loading={isDeletingBranchFromClientLoading}
          />
        </>
      ) : null}

      {clientData && params?.userId ? (
        <>
          <WarningModal
            isOpen={isResetPasswordModalOpen}
            onClose={() => setIsResetPasswordModalOpen(false)}
            entityText={clientData.email}
            title={'Reset password ?'}
            textBlocks={[
              `Are you sure you want to reset users's password ?`,
              `Password reset link will be sent to email`,
            ]}
            confirmButtonText={'Yes, reset'}
            onConfirmButtonClick={() => {
              if (params?.userId) {
                resetPassword(params.userId);
              }
            }}
            loading={isResettingClientPasswordLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: resetVertical,
              horizontal: resetHorizontal,
            }}
            autoHideDuration={2500}
            open={resetOpen}
            onClose={handleSnackbarClose}
            key={'resetClientPassword'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Password sent to email {clientData.email}
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
      <Snackbar
        anchorOrigin={{vertical: editVertical, horizontal: editHorizontal}}
        autoHideDuration={2500}
        open={editOpen}
        onClose={handleSnackbarClose}
        key={'editSnackbar'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            The data was successfully saved
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>

      {clientData && (
        <ClientsAddBranch
          isOpen={isAddBranchToClientModalOpen}
          onClose={() => setIsAddBranchToClientModalOpen(false)}
          clickedClient={{
            id: clientData.id,
            firstName: clientData.firstName,
            lastName: clientData.lastName,
            email: clientData.email,
            phoneNumber: clientData.email,
            additionalInformation: clientData.additionalInformation,
            isActive: clientData.isActive,
            isLockedOut: clientData.isLockedOut,
          }}
        />
      )}
    </div>
  );
};

export default ClientsEdit;
