import {FC} from 'react';
import {IGetCompanyById} from 'store/models/companies';
import styles from '../CompaniesInformation.module.scss';
import {Link} from 'react-router-dom';
import {LinkIcon} from 'assets/icons';
import Guard from 'components/Guard/Guard';
import {UserRole} from 'enums';

interface Props {
  companyInfo: IGetCompanyById | undefined;
}

const InformationTab: FC<Props> = (props) => {
  return (
    <div>
      <div className={styles.companyUserInfo}>
        {props?.companyInfo?.name ? (
          <div>
            <span>Name</span>
            <span>{props?.companyInfo?.name}</span>
          </div>
        ) : null}

        {props?.companyInfo?.registrationNumber ? (
          <div>
            <span>Registration number</span>
            <span>{props?.companyInfo?.registrationNumber}</span>
          </div>
        ) : null}

        {props?.companyInfo?.address ? (
          <div>
            <span>Address</span>
            <span>{props?.companyInfo?.address}</span>
          </div>
        ) : null}
        {props?.companyInfo?.vat ? (
          <div>
            <span>VAT number</span>
            <span>{props?.companyInfo?.vat}</span>
          </div>
        ) : null}
        {props?.companyInfo?.bank ? (
          <div>
            <span>Address</span>
            <span>{props?.companyInfo?.bank}</span>
          </div>
        ) : null}
        {props?.companyInfo?.bankCode ? (
          <div>
            <span>Bank Code</span>
            <span>{props?.companyInfo?.bankCode}</span>
          </div>
        ) : null}
        {props?.companyInfo?.bankAccount ? (
          <div>
            <span>Bank Account</span>
            <span>{props?.companyInfo?.bankAccount}</span>
          </div>
        ) : null}
        {props?.companyInfo?.phone ? (
          <div>
            <span>Phone</span>
            <span>{props?.companyInfo?.phone}</span>
          </div>
        ) : null}
      </div>
      {props?.companyInfo?.branches?.length ? (
        <div className={styles.list}>
          <h3>Assigned branches</h3>
          {props?.companyInfo?.branches.map((branch) => (
            <span key={branch.id}>
              {branch.name}{' '}
              <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
                <Link to={`/branches/information/${branch.id}`}>
                  <LinkIcon style={{height: '15px', width: '15px'}} />
                </Link>
              </Guard>
            </span>
          ))}
        </div>
      ) : null}

      {props?.companyInfo?.owners.length ? (
        <div className={styles.list}>
          <h3>Company Owners</h3>
          {props?.companyInfo?.owners.map((owner) => (
            <span key={owner.id}>
              {owner.firstName} {owner.lastName}{' '}
              <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
                <Link to={`/companyOwners/information/${owner.id}`}>
                  <LinkIcon style={{height: '15px', width: '15px'}} />
                </Link>
              </Guard>
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default InformationTab;
