// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_appWrapper__oPJDT {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f8f8fa;
}

.Layout_contentWrapper__fC43W {
  margin: 0 auto;
  width: 100%;
  display: flex;
  padding: 0 32px 0 0;
  gap: 16px;
}
.Layout_contentWrapper__fC43W aside {
  height: 100vh;
  position: sticky;
  left: 0;
  background-color: #ffffff;
  transition: all 0.5s ease;
}
.Layout_contentWrapper__fC43W main {
  padding-top: 32px;
  flex: 1 1;
}`, "",{"version":3,"sources":["webpack://./src/views/Layout/Layout.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,yBAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;AADF;AAGE;EACE,aAAA;EACA,gBAAA;EACA,OAAA;EACA,yBAAA;EACA,yBAAA;AADJ;AAIE;EACE,iBAAA;EACA,SAAA;AAFJ","sourcesContent":["@import 'styles/variables';\n\n.appWrapper {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  background-color: #f8f8fa;\n}\n\n.contentWrapper {\n  margin: 0 auto;\n  width: 100%;\n  display: flex;\n  padding: 0 32px 0 0;\n  gap: 16px;\n\n  aside {\n    height: 100vh;\n    position: sticky;\n    left: 0;\n    background-color: #ffffff;\n    transition: all 0.5s ease;\n  }\n\n  main {\n    padding-top: 32px;\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appWrapper": `Layout_appWrapper__oPJDT`,
	"contentWrapper": `Layout_contentWrapper__fC43W`
};
export default ___CSS_LOADER_EXPORT___;
