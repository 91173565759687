// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompaniesCreate_companiesCreateModalTop__u7dqb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.CompaniesCreate_companiesCreateModalTop__u7dqb .CompaniesCreate_companiesCreateTitle__WMWLH {
  font-weight: 600;
  line-height: 40px;
  font-size: 24px;
  color: #212121;
  margin: 0;
}
.CompaniesCreate_companiesCreateModalTop__u7dqb svg {
  cursor: pointer;
}

.CompaniesCreate_closeIcon__Sq6hS {
  cursor: pointer;
}

.CompaniesCreate_nameInputContainer__zCFKX,
.CompaniesCreate_registrationNumberInputContainer__s-tn3,
.CompaniesCreate_addressInputContainer__jiTo5 {
  margin-bottom: 6px;
}
.CompaniesCreate_nameInputContainer__zCFKX label,
.CompaniesCreate_registrationNumberInputContainer__s-tn3 label,
.CompaniesCreate_addressInputContainer__jiTo5 label {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #8a8894;
  margin-left: 12px;
  margin-bottom: 6px;
}
.CompaniesCreate_nameInputContainer__zCFKX .CompaniesCreate_inputErrorMessage__wNc3V,
.CompaniesCreate_registrationNumberInputContainer__s-tn3 .CompaniesCreate_inputErrorMessage__wNc3V,
.CompaniesCreate_addressInputContainer__jiTo5 .CompaniesCreate_inputErrorMessage__wNc3V {
  display: inline-block;
  font-size: 12px;
  color: #f93232;
  line-height: 16px;
  margin-left: 12px;
  margin-top: 4px;
}

.CompaniesCreate_buttonsContainer__dA2\\+w {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/Companies/CompaniesCreate/CompaniesCreate.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,SAAA;AACJ;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,eAAA;AADF;;AAIA;;;EAGE,kBAAA;AADF;AAEE;;;EACE,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AAEJ;AACE;;;EACE,qBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AAGJ;;AACA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAEF","sourcesContent":[".companiesCreateModalTop {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 15px;\n\n  & .companiesCreateTitle {\n    font-weight: 600;\n    line-height: 40px;\n    font-size: 24px;\n    color: #212121;\n    margin: 0;\n  }\n\n  & svg {\n    cursor: pointer;\n  }\n}\n\n.closeIcon {\n  cursor: pointer;\n}\n\n.nameInputContainer,\n.registrationNumberInputContainer,\n.addressInputContainer {\n  margin-bottom: 6px;\n  & label {\n    display: inline-block;\n    font-size: 12px;\n    line-height: 18px;\n    font-weight: 400;\n    color: #8a8894;\n    margin-left: 12px;\n    margin-bottom: 6px;\n  }\n\n  & .inputErrorMessage {\n    display: inline-block;\n    font-size: 12px;\n    color: #f93232;\n    line-height: 16px;\n    margin-left: 12px;\n    margin-top: 4px;\n  }\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companiesCreateModalTop": `CompaniesCreate_companiesCreateModalTop__u7dqb`,
	"companiesCreateTitle": `CompaniesCreate_companiesCreateTitle__WMWLH`,
	"closeIcon": `CompaniesCreate_closeIcon__Sq6hS`,
	"nameInputContainer": `CompaniesCreate_nameInputContainer__zCFKX`,
	"registrationNumberInputContainer": `CompaniesCreate_registrationNumberInputContainer__s-tn3`,
	"addressInputContainer": `CompaniesCreate_addressInputContainer__jiTo5`,
	"inputErrorMessage": `CompaniesCreate_inputErrorMessage__wNc3V`,
	"buttonsContainer": `CompaniesCreate_buttonsContainer__dA2+w`
};
export default ___CSS_LOADER_EXPORT___;
