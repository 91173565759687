import {FC, useState} from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {useAppSelector} from 'store/store';
import {UserRole} from 'enums';
import {Alert, Box, Button, Snackbar} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {DEALERS_PAGE_PATH, LOGIN_PAGE_PATH} from 'router/constants';
import {
  ArrowLeftIcon,
  CloseIcon,
  ErrorNotification,
  SuccessNotification,
} from 'assets/icons';
import {forgotPasswordBackButton, loginButton} from 'styles/MUIStyles/buttons';
import styles from './ForgotPassword.module.scss';
import {useForgotPasswordMutation} from 'store/slices/profile';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {ISnackbarState} from 'store/models/common';
import {useUpdateEffect} from 'hooks';

interface IFormInput {
  email: string;
}

const ForgotPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<IFormInput>({
    defaultValues: {
      email: '',
    },
  });
  const navigate = useNavigate();
  const [forgotPassword, {isLoading, error, isSuccess: forgotPasswordSuccess}] =
    useForgotPasswordMutation();
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const role = useAppSelector((state) => state.auth.role);

  const [successSnackbar, setSuccessSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [errorSnackbar, setErrorSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {vertical, horizontal, open} = successSnackbar;
  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: errorOpen,
  } = errorSnackbar;

  const handleSnackbarClose = () => {
    setSuccessSnackbar({...successSnackbar, open: false});
    setErrorSnackbar({...errorSnackbar, open: false});
    setErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (error && 'data' in error && 'status' in error) {
      if (error.status === 400 || error.status === 404) {
        const errors = error.data?.errors;

        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            setErrorMessage(errors[key]);
            setErrorSnackbar({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  }, [error]);

  useUpdateEffect(() => {
    if (forgotPasswordSuccess) {
      setSuccessSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [forgotPasswordSuccess]);

  const onSubmit = (formData: IFormInput) => {
    forgotPassword({email: formData.email});
  };

  if (isAuth && role === UserRole.Admin) {
    return <Navigate to={'/' + DEALERS_PAGE_PATH} />;
  }

  return (
    <div className={styles.forgotPasswordContainer}>
      <div className="container">
        <div className={styles.forgotPasswordFormContainer}>
          <h2 className={styles.forgotPasswordTitle}>Forgot password ?</h2>
          <span className={styles.forgotPasswordInstructions}>
            Instructions to reset your password will be sent to your email
          </span>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.loginEmailContainer}>
              <label htmlFor="email">Email</label>
              <div>
                <input
                  {...register('email', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="email"
                  placeholder="Enter email"
                  className={
                    errors?.email
                      ? styles.emailInput + ' ' + styles.emailInputError
                      : styles.emailInput
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({message}) => (
                    <span className={styles.emailErrorMessage}>{message}</span>
                  )}
                />
              </div>
            </div>
            <ButtonWithProgress
              type="submit"
              variant="contained"
              loading={isLoading}
              disabled={isLoading}
              sx={loginButton}
            >
              Send
            </ButtonWithProgress>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                variant="text"
                onClick={() => navigate('/' + LOGIN_PAGE_PATH)}
                startIcon={<ArrowLeftIcon />}
                sx={forgotPasswordBackButton}
              >
                Back
              </Button>
            </Box>
          </form>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{vertical, horizontal}}
        autoHideDuration={2500}
        open={open}
        onClose={handleSnackbarClose}
        key={'editSnackbar'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            Instructions were sent to your email!
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorVertical,
            horizontal: errorHorizontal,
          }}
          autoHideDuration={3500}
          open={errorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default ForgotPassword;
