import React, {FC} from 'react';
import {Box, Button, Divider, Modal} from '@mui/material';
import {modal} from 'styles/MUIStyles/modal';
import {CloseIcon} from 'assets/icons';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {cancelButton, warningModalButton} from 'styles/MUIStyles/buttons';
import styles from './WarningModal.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  entityText: string;
  title: string;
  textBlocks: string[];
  confirmButtonText: string;
  onConfirmButtonClick: () => void;
  loading: boolean;
}

const WarningModal: FC<Props> = (props) => {
  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Box sx={{...modal, width: '45%'}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <h2 className={styles.warningModalMainTitle}>{props.title}</h2>
          <div className={styles.closeIcon} onClick={props.onClose}>
            <CloseIcon />
          </div>
        </Box>
        <div className={styles.warningModalTextContainer}>
          {props.textBlocks.map((text, index) => (
            <span key={index}>{text}</span>
          ))}
          <span>{props.entityText}</span>
        </div>
        <Divider sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}} />
        <div className={styles.buttonsContainer}>
          <Button sx={cancelButton} onClick={props.onClose}>
            Cancel
          </Button>
          <ButtonWithProgress
            type="button"
            disabled={props.loading}
            loading={props.loading}
            onClick={props.onConfirmButtonClick}
            sx={warningModalButton}
          >
            {props.confirmButtonText}
          </ButtonWithProgress>
        </div>
      </Box>
    </Modal>
  );
};

export default WarningModal;
