import React from 'react';
import {RouteObject} from 'react-router-dom';
import NotFoundPage from 'views/404/404';
import Login from 'views/Login/Login';
import Layout from 'views/Layout/Layout';
import Dealers from 'views/Dealers/Dealers';
import ForgotPassword from 'views/ForgotPassword/ForgotPassword';
import CompanyOwners from 'views/CompanyOwners/CompanyOwners';
import Clients from 'views/Clients/Clients';
import Companies from 'views/Companies/Companies';
import Branches from 'views/Branches/Branches';
import Stations from 'views/Stations/Stations';
import Backups from 'views/Backups/Backups';
import Reports from 'views/Reports/Reports';
import RequireAuth from 'components/RequireAuth/RequireAuth';
import DealersEdit from 'views/Dealers/DealersEdit/DealersEdit';
import DealersInformation from 'views/Dealers/DealersInformation/DealersInformation';
import CompanyOwnersEdit from 'views/CompanyOwners/CompanyOwnersEdit/CompanyOwnersEdit';
import CompanyOwnersInformation from 'views/CompanyOwners/CompanyOnwersInformation/CompanyOwnersInformation';
import ClientsInformation from 'views/Clients/ClientsInformation/ClientsInformation';
import ClientsEdit from 'views/Clients/ClientsEdit/ClientsEdit';
import CompaniesInformation from 'views/Companies/CompaniesInformation/CompaniesInformation';
import CompaniesEdit from 'views/Companies/CompaniesEdit/CompaniesEdit';
import BranchesInformation from 'views/Branches/BranchesInformation/BranchesInformation';
import BranchesEdit from 'views/Branches/BranchesEdit/BranchesEdit';
import StationsCreate from 'views/Stations/StationsCreate/StationsCreate';
import Profile from 'views/Profile/Profile';
import StationInformation from 'views/Stations/StationInformation/StationInformation';
import Logs from 'views/Logs/Logs';
import StationsEdit from 'views/Stations/StationsEdit/StationsEdit';
import Manifest from 'views/Manifest/Manifest';
import ManifestsInformation from 'views/Manifest/ManifestsInformation/ManifestsInformation';
import DataCollector from 'views/DataCollector/DataCollector';
import ProfileEdit from 'views/Profile/ProfileEdit/ProfileEdit';
import ChangePassword from 'views/Profile/ProfileChangePassword/ChangePassword';
import ResetPassword from 'views/ResetPassword/ResetPassword';
import OTPCode from 'views/OTPCode/OTPCode';
import {UserRole} from 'enums';
import {
  MAIN_PAGE_PATH,
  FORGOT_PASSWORD_PAGE_PATH,
  DEALERS_PAGE_PATH,
  LOGIN_PAGE_PATH,
  COMPANY_OWNERS_PATH,
  CLIENTS_PATH,
  COMPANIES_PATH,
  BRANCHES_PATH,
  STATIONS_PATH,
  BACKUPS_PATH,
  REPORTS_PATH,
  DEALERS_EDIT_PATH,
  DEALERS_DETAIL_INFORMATION_PATH,
  COMPANY_OWNERS_EDIT_PATH,
  COMPANY_OWNERS_INFORMATION_PATH,
  CLIENTS_DETAIL_INFORMATION_PATH,
  CLIENTS_EDIT_PATH,
  COMPANIES_DETAIL_INFORMATION_PATH,
  COMPANIES_EDIT_PATH,
  BRANCHES_DETAIL_INFORMATION_PATH,
  BRANCHES_EDIT_PATH,
  STATIONS_CREATE_PATH,
  NOT_FOUND_PAGE_PATH,
  PROFILE_PATH,
  STATIONS_DETAIL_INFORMATION_PATH,
  STATIONS_EDIT_PATH,
  MANIFEST_PATH,
  MANIFEST_DETAIL_INFORMATION_PATH,
  LOGS_PATH,
  DATA_COLLECTOR_PATH,
  PROFILE_EDIT_PATH,
  RESET_PASSWORD_PAGE_PATH,
  PROFILE_CHANGE_PASSWORD_PATH,
  OTP_CODE_PATH,
} from './constants';

const routes: RouteObject[] = [
  {
    path: MAIN_PAGE_PATH,
    element: <Layout />,
    children: [
      {
        path: DEALERS_PAGE_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin]}>
            <Dealers />
          </RequireAuth>
        ),
      },
      {
        path: DEALERS_EDIT_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin]}>
            <DealersEdit />
          </RequireAuth>
        ),
      },
      {
        path: DEALERS_DETAIL_INFORMATION_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin]}>
            <DealersInformation />
          </RequireAuth>
        ),
      },
      {
        path: COMPANY_OWNERS_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <CompanyOwners />
          </RequireAuth>
        ),
      },
      {
        path: COMPANY_OWNERS_EDIT_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <CompanyOwnersEdit />
          </RequireAuth>
        ),
      },
      {
        path: COMPANY_OWNERS_INFORMATION_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <CompanyOwnersInformation />
          </RequireAuth>
        ),
      },
      {
        path: CLIENTS_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <Clients />
          </RequireAuth>
        ),
      },
      {
        path: CLIENTS_DETAIL_INFORMATION_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <ClientsInformation />
          </RequireAuth>
        ),
      },
      {
        path: CLIENTS_EDIT_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <ClientsEdit />
          </RequireAuth>
        ),
      },
      {
        path: COMPANIES_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <Companies />
          </RequireAuth>
        ),
      },
      {
        path: COMPANIES_DETAIL_INFORMATION_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <CompaniesInformation />
          </RequireAuth>
        ),
      },
      {
        path: COMPANIES_EDIT_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <CompaniesEdit />
          </RequireAuth>
        ),
      },
      {
        path: BRANCHES_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <Branches />
          </RequireAuth>
        ),
      },
      {
        path: BRANCHES_DETAIL_INFORMATION_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <BranchesInformation />
          </RequireAuth>
        ),
      },
      {
        path: BRANCHES_EDIT_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <BranchesEdit />
          </RequireAuth>
        ),
      },
      {
        path: STATIONS_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <Stations />
          </RequireAuth>
        ),
      },
      {
        path: STATIONS_CREATE_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <StationsCreate />
          </RequireAuth>
        ),
      },
      {
        path: STATIONS_DETAIL_INFORMATION_PATH,
        element: (
          <RequireAuth
            allowedRoles={[UserRole.Admin, UserRole.Dealer, UserRole.Owner]}
          >
            <StationInformation />
          </RequireAuth>
        ),
      },
      {
        path: STATIONS_EDIT_PATH,
        element: (
          //   <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
          <StationsEdit />
          //   </RequireAuth>
        ),
      },
      {
        path: BACKUPS_PATH,
        element: (
          <RequireAuth
            allowedRoles={[
              UserRole.Admin,
              UserRole.Dealer,
              UserRole.Owner,
              UserRole.Client,
            ]}
          >
            <Backups />
          </RequireAuth>
        ),
      },
      {
        path: MANIFEST_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <Manifest />
          </RequireAuth>
        ),
      },
      {
        path: MANIFEST_DETAIL_INFORMATION_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <ManifestsInformation />
          </RequireAuth>
        ),
      },
      {
        path: REPORTS_PATH,
        element: (
          <RequireAuth
            allowedRoles={[
              UserRole.Admin,
              UserRole.Dealer,
              UserRole.Owner,
              UserRole.Client,
            ]}
          >
            <Reports />
          </RequireAuth>
        ),
      },
      {
        path: PROFILE_PATH,
        element: (
          <RequireAuth
            allowedRoles={[
              UserRole.Admin,
              UserRole.Owner,
              UserRole.Dealer,
              UserRole.Client,
            ]}
          >
            <Profile />
          </RequireAuth>
        ),
      },
      {
        path: PROFILE_EDIT_PATH,
        element: (
          <RequireAuth
            allowedRoles={[
              UserRole.Admin,
              UserRole.Owner,
              UserRole.Dealer,
              UserRole.Client,
            ]}
          >
            <ProfileEdit />
          </RequireAuth>
        ),
      },
      {
        path: PROFILE_CHANGE_PASSWORD_PATH,
        element: (
          <RequireAuth
            allowedRoles={[
              UserRole.Admin,
              UserRole.Owner,
              UserRole.Dealer,
              UserRole.Client,
            ]}
          >
            <ChangePassword />
          </RequireAuth>
        ),
      },
      {
        path: LOGS_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <Logs />
          </RequireAuth>
        ),
      },
      {
        path: DATA_COLLECTOR_PATH,
        element: (
          <RequireAuth allowedRoles={[UserRole.Admin]}>
            <DataCollector />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: LOGIN_PAGE_PATH,
    element: <Login />,
  },
  {
    path: OTP_CODE_PATH,
    element: <OTPCode />,
  },
  {
    path: RESET_PASSWORD_PAGE_PATH,
    element: <ResetPassword />,
  },
  {
    path: FORGOT_PASSWORD_PAGE_PATH,
    element: <ForgotPassword />,
  },
  {
    path: NOT_FOUND_PAGE_PATH,
    element: <NotFoundPage />,
  },
];

export default routes;
