// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DealersTable_dealersStatus__\\+rvAE {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  border-radius: 12px;
  padding: 4px 8px 4px 8px;
}

.DealersTable_dealersStatus__\\+rvAE.DealersTable_active__U2i1b {
  color: #31aa27;
  background-color: #ebf6eb;
}

.DealersTable_dealersStatus__\\+rvAE.DealersTable_inactive__7CQGK {
  color: #7774B6;
  background-color: #DFE4FD;
}

.DealersTable_deleted__ni0tP {
  color: #f93232;
  background-color: #fff0f0;
}

.DealersTable_dealersResetPasswordMainTitle__sQ2qW {
  font-weight: 600;
  line-height: 40px;
  font-size: 24px;
  color: #212121;
  margin: 0;
}

.DealersTable_closeIcon__WUBk0 {
  cursor: pointer;
}

.DealersTable_removeTextContainer__nq2\\+K {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.DealersTable_removeTextContainer__nq2\\+K > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
}

.DealersTable_buttonsContainer__dZnOg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/Dealers/DealersTable/DealersTable.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,wBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,SAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACJ;;AAGA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAAF","sourcesContent":[".dealersStatus {\n  display: inline-block;\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  border-radius: 12px;\n  padding: 4px 8px 4px 8px;\n}\n\n.dealersStatus.active {\n  color: #31aa27;\n  background-color: #ebf6eb;\n}\n\n.dealersStatus.inactive {\n  color: #7774B6;\n  background-color: #DFE4FD;\n}\n\n.deleted {\n  color: #f93232;\n  background-color: #fff0f0;\n}\n\n.dealersResetPasswordMainTitle {\n  font-weight: 600;\n  line-height: 40px;\n  font-size: 24px;\n  color: #212121;\n  margin: 0;\n}\n\n.closeIcon {\n  cursor: pointer;\n}\n\n.removeTextContainer {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 24px;\n\n  & > span {\n    font-weight: 400;\n    font-size: 18px;\n    line-height: 25.2px;\n  }\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dealersStatus": `DealersTable_dealersStatus__+rvAE`,
	"active": `DealersTable_active__U2i1b`,
	"inactive": `DealersTable_inactive__7CQGK`,
	"deleted": `DealersTable_deleted__ni0tP`,
	"dealersResetPasswordMainTitle": `DealersTable_dealersResetPasswordMainTitle__sQ2qW`,
	"closeIcon": `DealersTable_closeIcon__WUBk0`,
	"removeTextContainer": `DealersTable_removeTextContainer__nq2+K`,
	"buttonsContainer": `DealersTable_buttonsContainer__dZnOg`
};
export default ___CSS_LOADER_EXPORT___;
