import React, {FC} from 'react';
import {useLocalStorage} from 'hooks';
import {ISortConfig} from 'hooks/useSortColumn';
import SystemLogsTopToolbar from './SystemLogsTopToolbar/SystemLogsTopToolbar';
import SystemLogsTable from './SystemLogsTable/SystemLogsTable';
import {IGetLogsResponse} from 'store/models/logs';
import styles from '../Logs.module.scss';

interface Props {
  logsData: IGetLogsResponse | undefined;
  search: string;
  debouncedSearch: string;
  setSearch: (search: string) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  status: {label: string; value: string; queryName: string};
  setStatus: (
    status: {label: string; value: string; queryName: string} | null,
  ) => void;
  logsStartTime: string | null;
  setLogsStartTime: (date: string | null) => void;
  logsEndTime: string | null;
  setLogsEndTime: (date: string | null) => void;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
}

const SystemLogs: FC<Props> = (props) => {
  const [isShowFilterButtonOpen, setIsShowFilterButtonOpen] = useLocalStorage(
    'isShowFilterButtonOpen',
    true,
  );

  const clearSystemLogsFilters = () => {
    props.setLogsStartTime(null);
    props.setLogsEndTime(null);
    props.setStatus(null);
  };

  //ТУТ ТОЖЕ НУЖНО ПМОЕНЯТЬ НА systemLogsPageSize и так далее,

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.tableFiltersTop}>
        <SystemLogsTopToolbar
          search={props.search}
          setSearch={props.setSearch}
          status={props.status}
          setStatus={props.setStatus}
          pageSize={props.pageSize}
          setPageSize={props.setPageSize}
          logsStartTime={props.logsStartTime}
          setLogsStartTime={props.setLogsStartTime}
          logsEndTime={props.logsEndTime}
          setLogsEndTime={props.setLogsEndTime}
          isShowFilterButtonOpen={isShowFilterButtonOpen}
          setIsShowFilterButtonOpen={setIsShowFilterButtonOpen}
          metaData={props?.logsData?.metadata || null}
          clearSystemLogsFilters={clearSystemLogsFilters}
        />
      </div>
      <div
        style={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <SystemLogsTable
          logs={props.logsData?.data || []}
          metaData={props.logsData?.metadata || null}
          sortConfig={props.sortConfig}
          search={props.debouncedSearch}
          handleColumnClick={props.handleColumnClick}
        />
      </div>
    </div>
  );
};

export default SystemLogs;
