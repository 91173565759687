import {
  BACKUPS_PATH,
  CLIENTS_PATH,
  COMPANY_OWNERS_PATH,
  DEALERS_PAGE_PATH,
} from 'router/constants';
import {Level, Status, UserRole} from '../enums';

export const pageSizes = [10, 25, 50, 100];

export const statuses = [
  {label: Status.Active, value: true, queryName: 'isActive'},
  {label: Status.Inactive, value: false, queryName: 'isActive'},
  {label: Status.Removed, value: true, queryName: 'isDeleted'},
];

export const stationStatuses = [
  {label: Status.Active, value: true, queryName: 'isActive'},
  {label: Status.Inactive, value: false, queryName: 'isActive'},
  {label: Status.Removed, value: true, queryName: 'isDeleted'},
];

export const stationAttachedStatus = [
  {label: Status.Attached, value: true, queryName: 'isAttached'},
  {label: Status.NotAttached, value: false, queryName: 'isAttached'},
];

export const logsStatuses = [
  {label: Level.Verbose, value: 'Verbose', queryName: 'level'},
  {label: Level.Debug, value: 'Debug', queryName: 'level'},
  {label: Level.Information, value: 'Information', queryName: 'level'},
  {label: Level.Warning, value: 'Warning', queryName: 'level'},
  {label: Level.Error, value: 'Error', queryName: 'level'},
  {label: Level.Fatal, value: 'Fatal', queryName: 'level'},
];

export const backupPeriodTypes = ['Hour', 'Day', 'Week', 'Month', 'Year'];

export const mainPagesByRole: Record<string, string> = {
  [UserRole.Admin]: DEALERS_PAGE_PATH,
  [UserRole.Dealer]: COMPANY_OWNERS_PATH,
  [UserRole.Owner]: CLIENTS_PATH,
  [UserRole.Client]: BACKUPS_PATH,
} as const;
