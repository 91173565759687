import React, {FC} from 'react';
import dayjs from 'dayjs';
import {ICompanyOwnerGetByIdServerResponse} from 'store/models/companyOwners';
import styles from '../CompanyOwnersInformation.module.scss';

interface Props {
  companyOwnerData: ICompanyOwnerGetByIdServerResponse | undefined;
}

const ActivityLogTab: FC<Props> = (props) => {
  return (
    <div className={styles.companyOwnersActivityLogTab}>
      {props?.companyOwnerData?.audit?.creating ? (
        <div>
          <span className={styles.auditTitle}>Created at</span>
          <span className={styles.auditText}>
            {dayjs(
              props?.companyOwnerData?.audit.creating?.actionDateTime,
            ).format('DD.MM.YYYY HH:mm:ss') || null}
          </span>
          <span className={styles.auditText}>
            {props?.companyOwnerData?.audit.creating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.companyOwnerData?.audit.creating?.userFullName}
          </span>
        </div>
      ) : null}
      {props?.companyOwnerData?.audit?.updating ? (
        <div>
          <span className={styles.auditTitle}>Updated at</span>
          <span className={styles.auditText}>
            {dayjs(
              props?.companyOwnerData?.audit.updating?.actionDateTime,
            ).format('DD.MM.YYYY HH:mm:ss') || null}
          </span>
          <span className={styles.auditText}>
            {props?.companyOwnerData?.audit.updating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.companyOwnerData?.audit.updating?.userFullName}
          </span>
        </div>
      ) : null}

      {props?.companyOwnerData?.audit?.deleting ? (
        <div>
          <span className={styles.auditTitle}>Deleted at</span>
          <span className={styles.auditText}>
            {dayjs(
              props?.companyOwnerData?.audit.deleting?.actionDateTime,
            ).format('DD.MM.YYYY HH:mm:ss') || null}
          </span>
          <span className={styles.auditText}>
            {props?.companyOwnerData?.audit.deleting?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.companyOwnerData?.audit.deleting?.userFullName}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ActivityLogTab;
