import React, {FC} from 'react';
import {IGetStationById} from 'store/models/stations';
import dayjs from 'dayjs';
import styles from '../StationInformation.module.scss';

interface Props {
  stationData: IGetStationById | undefined;
}

const ActivityLogTab: FC<Props> = (props) => {
  return (
    <div className={styles.stationActivityLogTab}>
      {props?.stationData?.audit?.creating ? (
        <div>
          <span className={styles.auditTitle}>Created at</span>
          <span className={styles.auditText}>
            {dayjs(props?.stationData?.audit.creating?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.stationData?.audit.creating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.stationData?.audit.creating?.userFullName}
          </span>
        </div>
      ) : null}
      {props?.stationData?.audit?.updating ? (
        <div>
          <span className={styles.auditTitle}>Updated at</span>
          <span className={styles.auditText}>
            {dayjs(props?.stationData?.audit.updating?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.stationData?.audit.updating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.stationData?.audit.updating?.userFullName}
          </span>
        </div>
      ) : null}
      {props?.stationData?.audit?.deleting ? (
        <div>
          <span className={styles.auditTitle}>Deleted at</span>
          <span className={styles.auditText}>
            {dayjs(props?.stationData?.audit.deleting?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.stationData?.audit.deleting?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.stationData?.audit.deleting?.userFullName}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ActivityLogTab;
