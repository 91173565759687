import {useCallback} from 'react';
import useLocalStorage from './useLocalStorage';

export interface ISortConfig {
  column: null | string;
  direction: null | 'asc' | 'desc';
}

const useSortColumn = (
  tableSortKey: string,
  defaultSortConfig?: ISortConfig,
) => {
  const [sortConfig, setSortConfig] = useLocalStorage<ISortConfig>(
    tableSortKey,
    defaultSortConfig?.column && defaultSortConfig?.direction
      ? defaultSortConfig
      : {
          column: null,
          direction: null,
        },
  );

  const handleColumnClick = useCallback(
    (column: string) => {
      if (sortConfig.column === column) {
        if (sortConfig.direction === 'asc') {
          setSortConfig({column, direction: 'desc'});
        } else if (sortConfig.direction === 'desc') {
          setSortConfig({column: '', direction: 'asc'});
        }
      } else {
        setSortConfig({column, direction: 'asc'});
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableSortKey, sortConfig, setSortConfig],
  );

  return [sortConfig, handleColumnClick];
};

export default useSortColumn;
