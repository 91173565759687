import {Alert, Box, Snackbar} from '@mui/material';
import {
  CloseIcon,
  DownloadIcon,
  ErrorNotification,
  RefreshIcon,
} from 'assets/icons';
import React, {FC, MutableRefObject, useEffect, useRef, useState} from 'react';
import {
  disabledCreateButtonModal as disabledDownloadButton,
  createButton as downloadButton,
  goBackButton,
} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
} from 'styles/MUIStyles/snackbars';
import {useLocalStorage, useUpdateEffect} from 'hooks';
import {utils, writeFileXLSX} from 'xlsx';

import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {IGetBranchesListItem} from 'store/models/branches';
import {IGetCompaniesListItem} from 'store/models/companies';
import {ISnackbarState} from 'store/models/common';
import LoadingIconSpacer from '../../components/LoadingIconSpacer/LoadingIconSpacer';
import {ReportTypesItem} from 'store/models/reports';
import ReportsTable from './ReportsTable/ReportsTable';
import ReportsTopToolbar from './ReportsTopToolbar/ReportsTopToolbar';
import {StationsListItem} from 'store/models/stations';
import dayjs from 'dayjs';
import styles from './Reports.module.scss';
import {useGetBranchesListQuery} from 'store/slices/branches';
import {useGetCompaniesListQuery} from 'store/slices/companies';
import {useGetReportTypesQuery} from 'store/slices/enums';
import {useGetStationsListQuery} from 'store/slices/stations';
import {useLazyGetReportByStationIdQuery} from 'store/slices/reports';

const Reports: FC = () => {
  const {data: reportTypes} = useGetReportTypesQuery({});

  const [reportsStartDateTime, setReportsStartDateTime] = useLocalStorage<
    string | null
  >(
    'reportsStartDateTime',
    dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).utc().format(),
  );
  const [reportsEndDateTime, setReportsEndDateTime] = useLocalStorage<
    string | null
  >(
    'reportsEndDateTime',
    dayjs().set('hour', 23).set('minute', 59).utc().format(),
  );


  // dealerBackupReportFilters
  const [
    dealerBackupReportFiltersLastZReportDateFrom,
    setDealerBackupReportFiltersLastZReportDateFrom,
  ] = useState<string | null>(null);
  const [
    dealerBackupReportFiltersLastZReportDateTo,
    setDealerBackupReportFiltersLastZReportDateTo,
  ] = useState<string | null>(null);
  const [
    dealerBackupReportFiltersLastReceivedMainBackupDateFrom,
    setDealerBackupReportFiltersLastReceivedMainBackupDateFrom,
  ] = useState<string | null>(null);
  const [
    dealerBackupReportFiltersLastReceivedMainBackupDateTo,
    setDealerBackupReportFiltersLastReceivedMainBackupDateTo,
  ] = useState<string | null>(null);
  const [
    dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom,
    setDealerBackupReportFiltersLastReceivedNonMainBackupDateFrom,
  ] = useState<string | null>(null);
  const [
    dealerBackupReportFiltersLastReceivedNonMainBackupDateTo,
    setDealerBackupReportFiltersLastReceivedNonMainBackupDateTo,
  ] = useState<string | null>(null);
  const [
    dealerBackupReportFiltersLastDCRequestDateFrom,
    setDealerBackupReportFiltersLastDCRequestDateFrom,
  ] = useState<string | null>(null);
  const [
    dealerBackupReportFiltersLastDCRequestDateTo,
    setDealerBackupReportFiltersLastDCRequestDateTo,
  ] = useState<string | null>(null);

  const [shouldSaveReportType, setShouldSaveReportType] = useLocalStorage(
    'shouldSaveReportType',
    true,
  );
  const [reportType, setReportType] = useLocalStorage<ReportTypesItem | null>(
    'reportType',
    null,
  );
  const [reportCompanies, setReportCompanies] = useState<
    IGetCompaniesListItem[]
  >([]);
  const [reportBranches, setReportBranches] = useState<IGetBranchesListItem[]>(
    [],
  );
  const [reportStations, setReportStations] = useLocalStorage<
    StationsListItem[]
  >('reportsStations', []);

  const [isActive, setIsActive] = useState<boolean>(false);

  const {data: companies} = useGetCompaniesListQuery();
  const {data: branches} = useGetBranchesListQuery(reportCompanies);
  const {data: stationsList} = useGetStationsListQuery({
    branches: reportBranches,
    companies: reportCompanies,
  });

  const [
    trigger,
    {
      data: reportsData,
      error: reportsError,
      isFetching: isReportsDataFetching,
      isError: isReportsDataError,
    },
  ] = useLazyGetReportByStationIdQuery();

  const tableRef = useRef() as MutableRefObject<HTMLDivElement>;

  useUpdateEffect(() => {
    setReportStations([]);
  }, [reportType]);

  useEffect(() => {
    if (
      (reportStations.length <= 0 || !reportType) &&
      reportType?.name !== 'DEALER_BACKUP_REPORT'
    )
      return;

    fetchTableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportStations, reportType, reportsStartDateTime, reportsEndDateTime]);

  const fetchTableData = async () => {
    await trigger(
      {
        reportType: reportType?.name,
        stationIds: reportStations,
        startDateTime: reportsStartDateTime,
        endDateTime: reportsEndDateTime,
        dealerBackupReportFiltersLastZReportDateFrom:
          dealerBackupReportFiltersLastZReportDateFrom,
        dealerBackupReportFiltersLastZReportDateTo:
          dealerBackupReportFiltersLastZReportDateTo,
        dealerBackupReportFiltersLastReceivedMainBackupDateFrom:
          dealerBackupReportFiltersLastReceivedMainBackupDateFrom,
        dealerBackupReportFiltersLastReceivedMainBackupDateTo:
          dealerBackupReportFiltersLastReceivedMainBackupDateTo,
        dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom:
          dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom,
        dealerBackupReportFiltersLastReceivedNonMainBackupDateTo:
          dealerBackupReportFiltersLastReceivedNonMainBackupDateTo,
        dealerBackupReportFiltersLastDCRequestDateFrom:
          dealerBackupReportFiltersLastDCRequestDateFrom,
        dealerBackupReportFiltersLastDCRequestDateTo:
          dealerBackupReportFiltersLastDCRequestDateTo,
        isActive: isActive,
      },
      false,
    );
  };

  const generateReportFileName = () => {
    const fileNameArray = [reportType?.name];

    if (reportStations.length === 1) {
      fileNameArray.push(reportStations[0].posChassicNumber.slice(0, 8));
    } else {
      fileNameArray.push(
        reportStations.reduce((acc: string, station: StationsListItem) => {
          return `${acc}${
            acc.length ? '-' : ''
          }${station.posChassicNumber.slice(0, 8)}`;
        }, ''),
      );
    }
    if (reportsStartDateTime) {
      fileNameArray.push(dayjs(reportsStartDateTime).format('DDMMYYYY'));
    }
    if (reportsEndDateTime) {
      fileNameArray.push(dayjs(reportsEndDateTime).format('DDMMYYYY'));
    }

    return fileNameArray.join('_') + '.xlsx';
  };

  const downloadReportFile = () => {
    const fileName = generateReportFileName();
    const elt = tableRef?.current.getElementsByTagName('TABLE')[0];
    const wb = utils?.table_to_book(elt);

    writeFileXLSX(wb, fileName);
  };

  const [reportsErrorMessage, setReportsErrorMessage] = useState<string | null>(
    null,
  );

  const [reportsSnackbarError, setReportsSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const {
    vertical: reportsErrorVertical,
    horizontal: reportsErrorHorizontal,
    open: isReportsErrorOpen,
  } = reportsSnackbarError;

  const handleSnackbarClose = () => {
    setReportsSnackbarError({
      ...reportsSnackbarError,
      open: false,
    });
    setReportsErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (reportsError && 'data' in reportsError && 'status' in reportsError) {
      if (reportsError.status === 400 || reportsError.status === 404) {
        const errors = reportsError.data?.errors;

        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            //@ts-ignore
            setReportsErrorMessage(errors[key]);
            setReportsSnackbarError({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  }, [reportsError]);

  useUpdateEffect(() => {
    if (reportTypes?.length) {
      const defaultReportType = reportTypes?.find(
        (type: ReportTypesItem) => type.name === 'EKJ_REPORT',
      );

      if (defaultReportType && !reportType && shouldSaveReportType) {
        setReportType(defaultReportType);
      }
    }
  }, [reportTypes]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.reportsTop}>
        <h1 className={styles.reportsTitle}>Reports</h1>
        <div className={styles.buttonsContainer}>
          <ButtonWithProgress
            loading={isReportsDataFetching}
            disabled={isReportsDataFetching}
            startIcon={
              isReportsDataFetching ? (
                <LoadingIconSpacer width={'16px'} height={'16px'} />
              ) : (
                <RefreshIcon
                  style={{
                    width: '16px',
                    height: '16px',
                    filter:
                      'invert(24%) sepia(76%) saturate(2109%) hue-rotate(218deg) brightness(91%) contrast(90%)',
                  }}
                />
              )
            }
            sx={{
              ...goBackButton,
              '.MuiCircularProgress-root': {
                color: '#1976d2 !important',
              },
            }}
            onClick={fetchTableData}
          >
            Refresh
          </ButtonWithProgress>

          <ButtonWithProgress
            loading={isReportsDataFetching}
            // disabled={
            //   !reportsData || isReportsDataError || isReportsDataFetching
            // }
            startIcon={
              <DownloadIcon
                style={{
                  width: '16px',
                  height: '16px',
                  filter:
                    'invert(99%) sepia(98%) saturate(5%) hue-rotate(251deg) brightness(106%) contrast(100%)',
                }}
              />
            }
            onClick={downloadReportFile}
            sx={
              !reportsData || isReportsDataError || isReportsDataFetching
                ? disabledDownloadButton
                : downloadButton
            }
          >
            Download
          </ButtonWithProgress>
        </div>
      </div>
      <div className={styles.reportsMainContent}>
        <div className={styles.tableFiltersTop}>
          <ReportsTopToolbar
            setReportStations={setReportStations}
            stations={reportStations}
            stationsList={stationsList || []}
            setReportType={setReportType}
            reportTypes={reportTypes}
            reportType={reportType}
            setReportsStartDateTime={setReportsStartDateTime}
            reportsStartDateTime={reportsStartDateTime}
            setReportsEndDateTime={setReportsEndDateTime}
            reportsEndDateTime={reportsEndDateTime}
            fetchTableData={fetchTableData}
            isReportsDataFetching={isReportsDataFetching}
            companies={companies || []}
            reportCompanies={reportCompanies}
            setReportCompanies={setReportCompanies}
            branches={branches || []}
            reportBranches={reportBranches}
            setReportBranches={setReportBranches}
            setShouldSaveReportType={setShouldSaveReportType}
            isActive={isActive}
            setIsActive={setIsActive}
            dealerBackupReportFiltersLastZReportDateFrom={
              dealerBackupReportFiltersLastZReportDateFrom
            }
            setDealerBackupReportFiltersLastZReportDateFrom={
              setDealerBackupReportFiltersLastZReportDateFrom
            }
            dealerBackupReportFiltersLastZReportDateTo={
              dealerBackupReportFiltersLastZReportDateTo
            }
            setDealerBackupReportFiltersLastZReportDateTo={
              setDealerBackupReportFiltersLastZReportDateTo
            }
            dealerBackupReportFiltersLastReceivedMainBackupDateFrom={
              dealerBackupReportFiltersLastReceivedMainBackupDateFrom
            }
            setDealerBackupReportFiltersLastReceivedMainBackupDateFrom={
              setDealerBackupReportFiltersLastReceivedMainBackupDateFrom
            }
            dealerBackupReportFiltersLastReceivedMainBackupDateTo={
              dealerBackupReportFiltersLastReceivedMainBackupDateTo
            }
            setDealerBackupReportFiltersLastReceivedMainBackupDateTo={
              setDealerBackupReportFiltersLastReceivedMainBackupDateTo
            }
            dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom={
              dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom
            }
            setDealerBackupReportFiltersLastReceivedNonMainBackupDateFrom={
              setDealerBackupReportFiltersLastReceivedNonMainBackupDateFrom
            }
            dealerBackupReportFiltersLastReceivedNonMainBackupDateTo={
              dealerBackupReportFiltersLastReceivedNonMainBackupDateTo
            }
            setDealerBackupReportFiltersLastReceivedNonMainBackupDateTo={
              setDealerBackupReportFiltersLastReceivedNonMainBackupDateTo
            }
            dealerBackupReportFiltersLastDCRequestDateFrom={
              dealerBackupReportFiltersLastDCRequestDateFrom
            }
            setDealerBackupReportFiltersLastDCRequestDateFrom={
              setDealerBackupReportFiltersLastDCRequestDateFrom
            }
            dealerBackupReportFiltersLastDCRequestDateTo={
              dealerBackupReportFiltersLastDCRequestDateTo
            }
            setDealerBackupReportFiltersLastDCRequestDateTo={
              setDealerBackupReportFiltersLastDCRequestDateTo
            }
          />
        </div>

        <div className={styles.reportsTable}>
          <ReportsTable
            tableRef={tableRef}
            reportsData={isReportsDataError ? '' : reportsData}
            isFetching={isReportsDataFetching}
            isError={isReportsDataError}
          />
        </div>
      </div>
      {reportsErrorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: reportsErrorVertical,
            horizontal: reportsErrorHorizontal,
          }}
          autoHideDuration={3500}
          open={isReportsErrorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {reportsErrorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default Reports;
