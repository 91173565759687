// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AssignStationModal_modalTop__rbJI2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.AssignStationModal_modalTop__rbJI2 svg {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.AssignStationModal_stationsAddBranchTitle__\\+KNLN {
  font-weight: 700;
  line-height: 24px;
  font-size: 18px;
  color: #000;
  margin: 0;
}

.AssignStationModal_dropDownItem__cSIeK {
  font-family: "Poppins", sans-serif;
  display: block;
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  color: #212121;
  line-height: 24px;
  font-weight: 400;
}
.AssignStationModal_dropDownItem__cSIeK:hover {
  background: #f5f8ff;
}

.AssignStationModal_buttonsContainer__l50kW {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.AssignStationModal_inputErrorMessage__q153S {
  display: inline-block;
  font-size: 12px;
  color: #f93232;
  line-height: 16px;
  margin-left: 12px;
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/views/Branches/AssignStationModal/AssignStationModal.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;EACA,WAAA;EACA,YAAA;AACR;;AAGA;EACI,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;AAAJ;;AAGA;EACI,kCAAA;EACA,cAAA;EACA,4BAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,mBAAA;AAAR;;AAIA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AADJ;;AAIA;EACI,qBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AADJ","sourcesContent":[".modalTop {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 15px;\n\n    & svg {\n        cursor: pointer;\n        width: 36px;\n        height: 36px;\n    }\n}\n\n.stationsAddBranchTitle {\n    font-weight: 700;\n    line-height: 24px;\n    font-size: 18px;\n    color: #000;\n    margin: 0;\n}\n\n.dropDownItem {\n    font-family: 'Poppins', sans-serif;\n    display: block;\n    padding: 12px 16px 12px 16px;\n    font-size: 14px;\n    color: #212121;\n    line-height: 24px;\n    font-weight: 400;\n\n    &:hover {\n        background: #f5f8ff;\n    }\n}\n\n.buttonsContainer {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 16px;\n}\n\n.inputErrorMessage {\n    display: inline-block;\n    font-size: 12px;\n    color: #f93232;\n    line-height: 16px;\n    margin-left: 12px;\n    margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTop": `AssignStationModal_modalTop__rbJI2`,
	"stationsAddBranchTitle": `AssignStationModal_stationsAddBranchTitle__+KNLN`,
	"dropDownItem": `AssignStationModal_dropDownItem__cSIeK`,
	"buttonsContainer": `AssignStationModal_buttonsContainer__l50kW`,
	"inputErrorMessage": `AssignStationModal_inputErrorMessage__q153S`
};
export default ___CSS_LOADER_EXPORT___;
