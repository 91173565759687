import {api} from './api';
import {
  IEditStationArgs,
  IGetManifestByIdData,
  IGetManifestsArgs,
  IGetManifestsResponse,
} from '../models/manifest';

export const manifests = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getAllManifests: builder.query<IGetManifestsResponse, IGetManifestsArgs>({
        query: (manifestsParams) => {
          const params = new URLSearchParams();

          if (manifestsParams.pageNumber) {
            params.append('pageNumber', String(manifestsParams.pageNumber));
          }

          if (manifestsParams.pageSize) {
            params.append('pageSize', String(manifestsParams.pageSize));
          }
          if (manifestsParams?.companies?.length) {
            manifestsParams.companies.forEach((company) => {
              params.append('companyIds', company.id);
            });
          }
          if (manifestsParams?.branches?.length) {
            manifestsParams.branches.forEach((branch) => {
              params.append('branchIds', branch.id);
            });
          }
          if (manifestsParams?.stations?.length) {
            manifestsParams.stations.forEach((station) => {
              params.append('stationIds', station.id);
            });
          }
          if (manifestsParams?.stationTypes?.length) {
            manifestsParams.stationTypes.forEach((type) => {
              params.append('stationTypeIds', `${type.id}`);
            });
          }

          return {
            url: `manifests?${params.toString()}`,
            method: 'get',
          };
        },
      }),
      getManifestById: builder.query<IGetManifestByIdData, string>({
        query: (manifestId) => {
          return {
            url: `manifests/${manifestId}`,
            method: 'get',
          };
        },
        providesTags: () => ['Manifests'],
      }),
      editManifest: builder.mutation<string, IEditStationArgs>({
        query: (manifestData) => {
          return {
            url: 'manifests',
            method: 'put',
            data: manifestData,
          };
        },
        invalidatesTags: ['Manifests'],
      }),
      createManifestTask: builder.mutation<string, IEditStationArgs>({
        query: (manifestData) => {
          return {
            url: 'manifests',
            method: 'put',
            data: manifestData,
          };
        },
        invalidatesTags: ['Manifests'],
      }),
    };
  },
});

export const {
  useGetAllManifestsQuery,
  useLazyGetManifestByIdQuery,
  useEditManifestMutation,
  useCreateManifestTaskMutation,
} = manifests;
