import React, {FC} from 'react';
import {IGetCompanyById} from 'store/models/companies';
import dayjs from 'dayjs';
import styles from '../CompaniesInformation.module.scss';

interface Props {
  companyInfo: IGetCompanyById | undefined;
}

const ActivityLog: FC<Props> = (props) => {
  return (
    <div className={styles.companyActivityLogTab}>
      {props?.companyInfo?.audit?.creating ? (
        <div>
          <span className={styles.auditTitle}>Created at</span>
          <span className={styles.auditText}>
            {dayjs(props?.companyInfo?.audit.creating?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.companyInfo?.audit.creating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.companyInfo?.audit.creating?.userFullName}
          </span>
        </div>
      ) : null}
      {props?.companyInfo?.audit?.updating ? (
        <div>
          <span className={styles.auditTitle}>Updated at</span>
          <span className={styles.auditText}>
            {dayjs(props?.companyInfo?.audit.updating?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.companyInfo?.audit.updating?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.companyInfo?.audit.updating?.userFullName}
          </span>
        </div>
      ) : null}
      {props?.companyInfo?.audit?.deleting ? (
        <div>
          <span className={styles.auditTitle}>Deleted at</span>
          <span className={styles.auditText}>
            {dayjs(props?.companyInfo?.audit.deleting?.actionDateTime).format(
              'DD.MM.YYYY HH:mm:ss',
            ) || null}
          </span>
          <span className={styles.auditText}>
            {props?.companyInfo?.audit.deleting?.userEmail}
          </span>
          <span className={styles.auditText}>
            {props?.companyInfo?.audit.deleting?.userFullName}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default ActivityLog;
