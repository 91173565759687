import React, {FC} from 'react';
import {Box, Button, Divider, Modal} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {CloseIcon} from 'assets/icons';
import {modal} from 'styles/MUIStyles/modal';
import {cancelButton, warningModalButton} from 'styles/MUIStyles/buttons';
import styles from '../../../Stations/StationsEdit/StationsEdit.module.scss';

interface Props {
  isNestedModalOpen: boolean;
  setIsNestedModalOpen: (isOpen: boolean) => void;
  manifestTaskFormId: string;
  loading: boolean;
  disabled: boolean;
  confirmButtonText: string;
}

const ShouldSaveChanges: FC<Props> = (props) => {
  return (
    <Modal
      open={props.isNestedModalOpen}
      onClose={() => {
        props.setIsNestedModalOpen(false);
      }}
      hideBackdrop={true}
    >
      <Box sx={{...modal, width: '35%'}}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '8.5px',
          }}
        >
          <h2 className={styles.createManifestTask}>
            Want to save the changes ?
          </h2>
          <div
            className={styles.closeIcon}
            onClick={() => props.setIsNestedModalOpen(false)}
          >
            <CloseIcon />
          </div>
        </Box>
        <div>
          <span>
            The changes you have made will be applied to all tethered stations
          </span>
        </div>
        <Divider sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}} />
        <div className={styles.createTaskButtonsContainer}>
          <Button
            sx={cancelButton}
            onClick={() => props.setIsNestedModalOpen(false)}
          >
            Cancel
          </Button>
          <ButtonWithProgress
            type="submit"
            form={props.manifestTaskFormId}
            loading={props.loading}
            disabled={props.disabled}
            sx={warningModalButton}
          >
            {props.confirmButtonText}
          </ButtonWithProgress>
        </div>
      </Box>
    </Modal>
  );
};

export default ShouldSaveChanges;
