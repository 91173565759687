import React, {FC, useEffect, useState} from 'react';
import {IGetStationById} from 'store/models/stations';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import styles from '../../StationsCreate/StationsCreate.module.scss';
import ManifestTaskViewModal from './ManifestTaskViewModal/ManifestTaskViewModal';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: {
    ...styledTableBody,
    padding: '18px 16px 18px 16px',
  },
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  stationData: IGetStationById | undefined;
}

interface IManifestEditTask {
  name: string;
  id: string;
  isMain: boolean;
  include: {
    directory: string;
  }[];
  exclude:
    | {
        directory: string;
      }[]
    | null;
  isIncrementalBackupSettingsChecked: boolean;
  isExcludeDirectoryChecked: boolean;
  keepFullCopy: number;
  isPreBackupScriptChecked: boolean;
  isPostBackupScriptChecked: boolean;
  preBackupScripts: {script: string}[] | null;
  postBackupScripts: {script: string}[] | null;
  fullBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
  incrementalBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
}

interface Commands {
  id: string;
  name: string;
  isMain: boolean;
  include: string[];
  exclude: string[] | null;
  preBackupScripts: string[] | null;
  postBackupScripts: string[] | null;
  keepFullCopy: number;
  fullBackupSettings: null | {
    periodType: string | null;
    periodCount: number;
  };
  incrementalBackupSettings: null | {
    periodType: string | null;
    periodCount: number;
  };
}

const ManifestTab: FC<Props> = (props) => {
  const [commands, setCommands] = useState<Commands[]>([]);
  const [isManifestTaskViewModalOpen, setIsManifestTaskViewModalOpen] =
    useState(false);
  const [clickedManifestTask, setClickedManifestTask] =
    useState<null | IManifestEditTask>(null);

  useEffect(() => {
    if (props?.stationData?.manifest?.commands?.length) {
      const notMainCommands = props.stationData?.manifest?.commands.filter(
        (command) => !command.isMain,
      );
      const mainCommand = props.stationData?.manifest?.commands.filter(
        (command) => command.isMain,
      );

      setCommands([...mainCommand, ...notMainCommands]);
    }
  }, [props?.stationData?.manifest?.commands]);

  return (
    <div>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>№</StyledTableCell>
              <StyledTableCell sx={{width: '95%'}}>
                <Box sx={tableHeadCell}>
                  <span>Task name</span>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commands.map((command, index) => (
              <StyledTableRow
                key={command.id}
                sx={{
                  cursor: 'pointer',
                  background: '#fff',
                  border: 0,
                }}
                onClick={() => {
                  setIsManifestTaskViewModalOpen(true);
                  setClickedManifestTask({
                    name: command.name,
                    id: command.id,
                    isMain: command.isMain,
                    include: command.include.map((dir) => ({
                      directory: dir,
                    })),
                    exclude: command?.exclude
                      ? command?.exclude?.map((dir) => ({directory: dir}))
                      : null,
                    preBackupScripts: command?.preBackupScripts
                      ? command?.preBackupScripts?.map((script) => ({
                          script: script,
                        }))
                      : null,
                    postBackupScripts: command?.postBackupScripts
                      ? command?.postBackupScripts?.map((script) => ({
                          script: script,
                        }))
                      : null,
                    isPreBackupScriptChecked: Boolean(
                      command.preBackupScripts?.length,
                    ),
                    isPostBackupScriptChecked: Boolean(
                      command.postBackupScripts?.length,
                    ),
                    isIncrementalBackupSettingsChecked: Boolean(
                      command?.incrementalBackupSettings?.periodType,
                    ),
                    isExcludeDirectoryChecked: Boolean(
                      command?.exclude?.length,
                    ),
                    keepFullCopy: command.keepFullCopy,
                    fullBackupSettings: command.fullBackupSettings,
                    incrementalBackupSettings:
                      command.incrementalBackupSettings,
                  });
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {command.isMain ? (
                      <span className={styles.mainTask}>Main task</span>
                    ) : null}
                    {command.name}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ManifestTaskViewModal
        isManifestTaskViewModalOpen={isManifestTaskViewModalOpen}
        setIsManifestTaskViewModalOpen={setIsManifestTaskViewModalOpen}
        clickedManifestTask={clickedManifestTask}
      />
    </div>
  );
};

export default ManifestTab;
