import React, {FC, useEffect, useState} from 'react';
import {IGetManifestByIdData} from 'store/models/manifest';
import {
  Box,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import Menu from '@mui/material/Menu';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import MenuItem from '@mui/material/MenuItem';
import {DeleteIcon, EditIcon, MoreIcon} from 'assets/icons';
import styles from '../../ManifestsInformation/ManifestInformation.module.scss';
import ManifestEditTask from '../ManifestEditTask/ManifestEditTask';
import ManifestTaskViewModal from 'views/Stations/StationInformation/ManifestTab/ManifestTaskViewModal/ManifestTaskViewModal';
import ManifestCreateTask from '../ManifestCreateTask/ManifestCreateTask';
import ManifestDeleteTask from '../ManifestDeleteTask/ManifestDeleteTask';
import {UserRole} from '../../../../enums';
import {useAppSelector} from '../../../../store/store';

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
  <Tooltip {...props} classes={{popper: className}} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 800,
    wordBreak: 'normal',
  },
});
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: {
    ...styledTableBody,
    padding: '18px 16px 18px 16px',
  },
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface IManifestEditTask {
  name: string;
  id: string;
  isMain: boolean;
  include: {
    directory: string;
  }[];
  exclude:
    | {
        directory: string;
      }[]
    | null;
  isPreBackupScriptChecked: boolean;
  isPostBackupScriptChecked: boolean;
  preBackupScripts: {script: string}[] | null;
  postBackupScripts: {script: string}[] | null;
  isIncrementalBackupSettingsChecked: boolean;
  isExcludeDirectoryChecked: boolean;
  keepFullCopy: number;
  fullBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
  incrementalBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
}

interface Props {
  manifestData: IGetManifestByIdData | null;
  isManifestCreateTaskModalOpen: boolean;
  setIsManifestCreateTaskModalOpen: (isOpen: boolean) => void;
}

interface Commands {
  id: string;
  name: string;
  isMain: boolean;
  include: string[];
  exclude: string[] | null;
  preBackupScripts: string[] | null;
  postBackupScripts: string[] | null;
  keepFullCopy: number;
  fullBackupSettings: null | {
    periodType: string | null;
    periodCount: number;
  };
  incrementalBackupSettings: null | {
    periodType: string | null;
    periodCount: number;
  };
}

const ManifestsTable: FC<Props> = (props) => {
  const userRole = useAppSelector((state) => state.auth.role);

  const [commands, setCommands] = useState<Commands[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [iesViewTaskModalOpen, setIsViewTaskModalOpen] = useState(false);
  const [isEditTaskModalOpen, setIsEditTaskModalOpen] = useState(false);
  const [isDeleteTaskModalOpen, setIsDeleteTaskModalOpen] = useState(false);
  const [clickedManifestTask, setClickedManifestTask] =
    useState<IManifestEditTask | null>(null);

  const handleMoreDotsClose = () => setAnchorEl(null);
  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    if (props?.manifestData?.commands?.length) {
      const notMainCommands = props.manifestData.commands.filter(
        (command) => !command.isMain,
      );
      const mainCommand = props.manifestData.commands.filter(
        (command) => command.isMain,
      );

      setCommands([...mainCommand, ...notMainCommands]);
    }
  }, [props?.manifestData?.commands]);

  return (
    <div style={{height: '100%'}}>
      <div>
        <div className={styles.manifestTypeContainer}>
          <span>Type:</span>
          <span>{props?.manifestData?.type}</span>
        </div>
        <div className={styles.manifestStations}>
          <span>Stations: </span>
          <CustomWidthTooltip
            title={props.manifestData?.stations.join(', ')}
            arrow
            placement="right"
          >
            <span>{props.manifestData?.stations?.length}</span>
          </CustomWidthTooltip>
        </div>
      </div>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>
                  <span>№</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '90%'}}>
                <Box sx={tableHeadCell}>
                  <span>Task name</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '5%'}}>
                <Box sx={tableHeadCell}>
                  <span>{null}</span>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commands.map((command, index) => (
              <StyledTableRow
                key={command.id}
                onClick={() => {
                  setIsViewTaskModalOpen(true);
                  setClickedManifestTask({
                    name: command.name,
                    id: command.id,
                    isMain: command.isMain,
                    keepFullCopy: command.keepFullCopy,
                    include: command.include.map((dir) => ({
                      directory: dir,
                    })),
                    exclude: command?.exclude
                      ? command?.exclude?.map((dir) => ({directory: dir}))
                      : null,
                    preBackupScripts: command?.preBackupScripts
                      ? command?.preBackupScripts?.map((script) => ({
                          script: script,
                        }))
                      : null,
                    postBackupScripts: command?.postBackupScripts
                      ? command?.postBackupScripts?.map((script) => ({
                          script: script,
                        }))
                      : null,
                    isPreBackupScriptChecked: Boolean(
                      command.preBackupScripts?.length,
                    ),
                    isPostBackupScriptChecked: Boolean(
                      command.postBackupScripts?.length,
                    ),
                    isIncrementalBackupSettingsChecked: Boolean(
                      command?.incrementalBackupSettings?.periodType,
                    ),
                    isExcludeDirectoryChecked: Boolean(
                      command?.exclude?.length,
                    ),
                    fullBackupSettings: command.fullBackupSettings,
                    incrementalBackupSettings:
                      command.incrementalBackupSettings,
                  });
                }}
                sx={{cursor: 'pointer'}}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {command.isMain ? (
                      <span className={styles.mainTask}>Main task</span>
                    ) : null}
                    {command.name}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  {userRole === UserRole.Admin ? (
                    <Button
                      id="basic-button"
                      aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={isMenuOpen ? 'true' : undefined}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleMoreDotsClick(event);
                        setClickedManifestTask({
                          name: command.name,
                          id: command.id,
                          isMain: command.isMain,
                          include: command.include.map((dir) => ({
                            directory: dir,
                          })),
                          exclude: command?.exclude
                            ? command?.exclude?.map((dir) => ({directory: dir}))
                            : null,
                          preBackupScripts: command?.preBackupScripts
                            ? command?.preBackupScripts?.map((script) => ({
                                script: script,
                              }))
                            : null,
                          postBackupScripts: command?.postBackupScripts
                            ? command?.postBackupScripts?.map((script) => ({
                                script: script,
                              }))
                            : null,
                          isPreBackupScriptChecked: Boolean(
                            command.preBackupScripts?.length,
                          ),
                          isPostBackupScriptChecked: Boolean(
                            command.postBackupScripts?.length,
                          ),
                          isIncrementalBackupSettingsChecked: Boolean(
                            command?.incrementalBackupSettings?.periodType,
                          ),
                          isExcludeDirectoryChecked: Boolean(
                            command?.exclude?.length,
                          ),
                          keepFullCopy: command.keepFullCopy,
                          fullBackupSettings: command.fullBackupSettings,
                          incrementalBackupSettings:
                            command.incrementalBackupSettings,
                        });
                      }}
                    >
                      <MoreIcon />
                    </Button>
                  ) : null}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ManifestCreateTask
        manifestData={props.manifestData}
        isManifestCreateTaskModalOpen={props.isManifestCreateTaskModalOpen}
        setIsManifestCreateTaskModalOpen={
          props.setIsManifestCreateTaskModalOpen
        }
      />
      <ManifestTaskViewModal
        isManifestTaskViewModalOpen={iesViewTaskModalOpen}
        setIsManifestTaskViewModalOpen={setIsViewTaskModalOpen}
        clickedManifestTask={clickedManifestTask}
      />
      <ManifestEditTask
        manifestData={props.manifestData}
        clickedManifestTask={clickedManifestTask}
        isEditTaskModalOpen={isEditTaskModalOpen}
        setIsEditTaskModalOpen={setIsEditTaskModalOpen}
      />
      <ManifestDeleteTask
        manifestData={props.manifestData}
        clickedManifestTask={clickedManifestTask}
        isDeleteTaskModalOpen={isDeleteTaskModalOpen}
        setIsDeleteTaskModalOpen={setIsDeleteTaskModalOpen}
      />
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <MenuItem
          sx={tableMoreDotsMenu}
          onClick={() => {
            handleMoreDotsClose();
            setIsEditTaskModalOpen(true);
          }}
        >
          <EditIcon />
          <span>Edit</span>
        </MenuItem>
        {!clickedManifestTask?.isMain ? (
          <MenuItem
            sx={tableMoreDotsMenu}
            onClick={() => {
              handleMoreDotsClose();
              setIsDeleteTaskModalOpen(true);
            }}
          >
            <DeleteIcon />
            <span>Remove</span>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default ManifestsTable;
