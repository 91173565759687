import React, {FC, memo, useState} from 'react';
import {useGetNumberOfElements, useUpdateEffect} from 'hooks';
import {useNavigate} from 'react-router-dom';
import {
  IGetClientsList,
  IGetClientsList as IClient,
  IMetadata,
} from 'store/models/clients';
import {
  Alert,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  ArrowAsc,
  ArrowDesc,
  CloseIcon,
  DefaultSortIcon,
  DeleteIcon,
  EditIcon,
  MoreIcon,
  PlusIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import styles from './ClientsTable.module.scss';
import {
  tableMoreDotsMenu,
  tableMoreDotsMenuPaper,
} from 'styles/MUIStyles/common';
import ClientsAddBranch from '../ClientsAddBranch/ClientsAddBranch';
import {ISnackbarState} from 'store/models/common';
import WarningModal from 'components/WarningModal/WarningModal';
import {
  useDeleteClientMutation,
  useRecoverClientMutation,
} from 'store/slices/clients';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {ISortConfig} from '../../../hooks/useSortColumn';
import Guard from '../../../components/Guard/Guard';
import {UserRole} from '../../../enums';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: styledTableBody,
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  clients: IGetClientsList[];
  metaData: IMetadata | null;
  isRequestWithDeletedStatusSuccess: boolean;
  sortConfig: ISortConfig;
  handleColumnClick: (column: string) => void;
  search: string;
}

const ClientsTable: FC<Props> = memo((props) => {
  const navigate = useNavigate();

  const [
    deleteClient,
    {isSuccess: isDeleteClientSuccess, isLoading: isDeletingClientLoading},
  ] = useDeleteClientMutation();

  const [
    recoverClient,
    {isSuccess: isRecoverClientSuccess, isLoading: isRecoveringClientLoading},
  ] = useRecoverClientMutation();

  const itemsOnPage = useGetNumberOfElements(props.metaData);
  const [isAddBranchToClientModalOpen, setIsAddBranchToClientModalOpen] =
    useState(false);
  const [isRecoverModalOpen, setIsRecoverModalOpen] = useState(false);
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [clickedClient, setClickedClient] = useState<IClient | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const [deleteClientSnackbar, setDeleteClientSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [recoverClientSnackbar, setRecoverClientSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: deleteVertical,
    horizontal: deleteHorizontal,
    open: deleteOpen,
  } = deleteClientSnackbar;
  const {
    vertical: recoverVertical,
    horizontal: recoverHorizontal,
    open: recoverOpen,
  } = recoverClientSnackbar;

  const handleMoreDotsClose = () => setAnchorEl(null);
  const handleIsAddBranchToClientModalClose = () =>
    setIsAddBranchToClientModalOpen(false);

  const handleMoreDotsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSnackbarClose = () => {
    setDeleteClientSnackbar({...deleteClientSnackbar, open: false});
    setRecoverClientSnackbar({...recoverClientSnackbar, open: false});
  };

  const getArrowForSortDirection = (column: string) => {
    if (props.sortConfig.column !== column) {
      return <DefaultSortIcon />;
    }

    return props.sortConfig.direction === 'asc' ? <ArrowAsc /> : <ArrowDesc />;
  };

  useUpdateEffect(() => {
    if (isDeleteClientSuccess) {
      setDeleteClientSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteClientModalOpen(false);
    }

    if (isRecoverClientSuccess) {
      setRecoverClientSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoverModalOpen(false);
    }
  }, [isDeleteClientSuccess, isRecoverClientSuccess]);

  return (
    <div style={{height: '100%'}}>
      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5%'}}>
                <Box>№</Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '25%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('email')}
                >
                  <span>Email</span>
                  {getArrowForSortDirection('email')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('firstName')}
                >
                  <span>First Name</span>
                  {getArrowForSortDirection('firstName')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '20%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('lastName')}
                >
                  <span>Last Name</span>
                  {getArrowForSortDirection('lastName')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '15%'}}>
                <Box
                  sx={tableHeadCell}
                  onClick={() => props.handleColumnClick('phoneNumber')}
                >
                  <span>Phone number</span>
                  {getArrowForSortDirection('phoneNumber')}
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '10%'}}>
                <Box sx={tableHeadCell}>
                  <span>Status</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell sx={{width: '5%'}}>{null}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.clients.map((client, index) => (
              <StyledTableRow
                key={client.id}
                sx={{cursor: 'pointer'}}
                onClick={() => navigate(`/users/information/${client.id}`)}
              >
                <StyledTableCell>
                  {itemsOnPage.length ? itemsOnPage[index] : null}
                </StyledTableCell>
                <StyledTableCell>{client.email}</StyledTableCell>
                <StyledTableCell>{client.firstName}</StyledTableCell>
                <StyledTableCell>{client.lastName}</StyledTableCell>
                <StyledTableCell>{client.phoneNumber}</StyledTableCell>
                <StyledTableCell>
                  {props.isRequestWithDeletedStatusSuccess ? (
                    <span
                      className={`${styles.clientStatus} ${styles.deleted}`}
                    >
                      Removed
                    </span>
                  ) : (
                    <span
                      className={
                        client.isActive
                          ? `${styles.clientStatus} ${styles.active}`
                          : `${styles.clientStatus} ${styles.inactive}`
                      }
                    >
                      {client.isActive ? 'Active' : 'Inactive'}
                    </span>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    id="basic-button"
                    aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={isMenuOpen ? 'true' : undefined}
                    onClick={(event) => {
                      event.stopPropagation();
                      setClickedClient(client);
                      handleMoreDotsClick(event);
                    }}
                  >
                    <MoreIcon />
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMoreDotsClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            padding: '4px 8px 4px 8px',
          },
        }}
        sx={tableMoreDotsMenuPaper}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {props.isRequestWithDeletedStatusSuccess ? (
          <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
            <MenuItem
              sx={tableMoreDotsMenu}
              onClick={() => {
                handleMoreDotsClose();
                setIsRecoverModalOpen(true);
              }}
            >
              <RecoverIcon />
              <span>Recover</span>
            </MenuItem>
          </Guard>
        ) : (
          <div>
            <MenuItem
              sx={tableMoreDotsMenu}
              onClick={() => {
                if (clickedClient) {
                  navigate('/users/edit/' + clickedClient.id);
                }
              }}
            >
              <EditIcon />
              <span>Edit</span>
            </MenuItem>
            <MenuItem
              sx={tableMoreDotsMenu}
              onClick={() => {
                handleMoreDotsClose();
                setIsAddBranchToClientModalOpen(true);
              }}
            >
              <PlusIcon />
              <span>Add branch</span>
            </MenuItem>
            <MenuItem
              sx={{...tableMoreDotsMenu, marginBottom: '0'}}
              onClick={() => {
                handleMoreDotsClose();
                setIsDeleteClientModalOpen(true);
              }}
            >
              <DeleteIcon />
              <span>Remove</span>
            </MenuItem>
          </div>
        )}
      </Menu>

      {clickedClient ? (
        <>
          <WarningModal
            isOpen={isDeleteClientModalOpen || isRecoverModalOpen}
            onClose={
              props.isRequestWithDeletedStatusSuccess
                ? () => setIsRecoverModalOpen(false)
                : () => setIsDeleteClientModalOpen(false)
            }
            entityText={`"${clickedClient.email} ?"`}
            title={`${
              props.isRequestWithDeletedStatusSuccess ? 'Recover' : 'Remove'
            } user ?`}
            textBlocks={[
              `Are you sure you want to ${
                props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
              } user`,
            ]}
            confirmButtonText={`Yes, ${
              props.isRequestWithDeletedStatusSuccess ? 'recover' : 'remove'
            }`}
            onConfirmButtonClick={() =>
              props.isRequestWithDeletedStatusSuccess
                ? recoverClient(clickedClient.id)
                : deleteClient(clickedClient.id)
            }
            loading={isDeletingClientLoading || isRecoveringClientLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: props.isRequestWithDeletedStatusSuccess
                ? recoverVertical
                : deleteVertical,
              horizontal: props.isRequestWithDeletedStatusSuccess
                ? recoverHorizontal
                : deleteHorizontal,
            }}
            autoHideDuration={2500}
            open={
              props.isRequestWithDeletedStatusSuccess ? recoverOpen : deleteOpen
            }
            onClose={handleSnackbarClose}
            key={`${
              props.isRequestWithDeletedStatusSuccess
                ? 'recoverClient'
                : 'deleteClient'
            }`}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                User {`"${clickedClient.email}"`} was{' '}
                {props.isRequestWithDeletedStatusSuccess
                  ? 'restored'
                  : 'removed'}
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}

      <ClientsAddBranch
        isOpen={isAddBranchToClientModalOpen}
        onClose={handleIsAddBranchToClientModalClose}
        clickedClient={clickedClient}
      />
    </div>
  );
});

export default ClientsTable;
