import React, {FC, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {useUpdateEffect} from 'hooks';
import {ISnackbarState} from 'store/models/common';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Snackbar,
  TextField,
} from '@mui/material';
import {CloseIcon, SelectPopupIcon, SuccessNotification} from 'assets/icons';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {
  useCreateBranchMutation,
  useLazyGetCompaniesToCreateBranchQuery,
} from 'store/slices/branches';
import {modal} from 'styles/MUIStyles/modal';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {searchAndSelect, searchAndSelectError} from 'styles/MUIStyles/common';
import styles from './BranchesCraete.module.scss';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';

interface Props {
  isCreateBranchModalOpen: boolean;
  setIsCreateBranchModalOpen: (isOpen: boolean) => void;
}

interface IFormData {
  name: string;
  address: string;
  company: {
    id: string;
    name: string;
    address: string | null;
    registrationNumber: string | null;
  };
}

const BranchesCreate: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    control,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      name: '',
      address: '',
      company: {
        id: '',
        name: '',
        address: null,
        registrationNumber: null,
      },
    },
  });

  const [
    getCompanies,
    {data: companies, isFetching: isGettingCompaniesLoading},
  ] = useLazyGetCompaniesToCreateBranchQuery();

  const [
    createBranch,
    {isLoading: isCreatingBranchLoading, isSuccess: isCreatingBranchSuccess},
  ] = useCreateBranchMutation();

  const [branchName, setBranchName] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {vertical, horizontal, open} = snackbar;

  const onSubmit = async (formData: IFormData) => {
    const response = await createBranch({
      name: formData.name.trim(),
      companyId: formData.company.id,
      address: formData.address.trim(),
    });

    if (response) {
      if (!('error' in response)) {
        setBranchName(formData.name);
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar({...snackbar, open: false});
  };

  const clearDataAndErrors = () => {
    clearErrors();
    reset();
    props.setIsCreateBranchModalOpen(false);
  };

  useUpdateEffect(() => {
    if (props.isCreateBranchModalOpen) {
      getCompanies();
    }
  }, [props.isCreateBranchModalOpen]);

  useUpdateEffect(() => {
    if (isCreatingBranchSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      props.setIsCreateBranchModalOpen(false);
      reset();
    }
  }, [isCreatingBranchSuccess]);

  return (
    <div>
      {branchName ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Company {`"${branchName}"`} successfully added
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={props.isCreateBranchModalOpen}
      >
        <Modal
          open={props.isCreateBranchModalOpen}
          onClose={clearDataAndErrors}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={modal}>
              <div className={styles.branchesCreateModalTop}>
                <h2 className={styles.branchesCreateTitle}>Create branch</h2>
                <CloseIcon onClick={clearDataAndErrors} />
              </div>

              <div className={styles.nameInputContainer}>
                <label htmlFor="name">Name</label>
                <input
                  {...register('name', {required: 'This field is required'})}
                  type="text"
                  id="name"
                  autoComplete="off"
                  className={`${errors?.name ? 'input inputError' : 'input'}`}
                  placeholder="Enter name"
                />
                <ErrorMessage
                  name="name"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="address">Address</label>
                <input
                  {...register('address')}
                  type="text"
                  id="address"
                  autoComplete="off"
                  className={`${
                    errors?.address ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter address"
                />
                <ErrorMessage
                  name="address"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div>
                <span className={styles.companyLabel}>Company</span>
                <Controller
                  name={'company'}
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value?.id) {
                        return 'This field is required';
                      }
                    },
                  }}
                  render={({field}) => (
                    <Autocomplete
                      {...field}
                      options={companies || []}
                      loading={isGettingCompaniesLoading}
                      popupIcon={<SelectPopupIcon />}
                      PaperComponent={(props) => (
                        <Paper
                          sx={{
                            borderRadius: '12px',
                            boxShadow: '0px 4px 20px 0px #4444441F',
                            marginTop: '8px',
                            cursor: 'pointer',
                          }}
                          {...props}
                        />
                      )}
                      sx={{width: '100%'}}
                      ListboxProps={{
                        style: {maxHeight: '200px'},
                        className: 'autoCompleteListBox',
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value.name === '' ? true : option.id === value.id
                      }
                      getOptionLabel={(option) =>
                        option?.name ? option.name : ''
                      }
                      onChange={(_, value) => {
                        if (value) {
                          field.onChange(value);
                        } else {
                          field.onChange(null);
                        }
                      }}
                      renderOption={(props: any, option) => (
                        <span {...props} className={styles.dropDownItem}>
                          {option?.name}
                        </span>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select company name"
                          sx={
                            errors?.company
                              ? searchAndSelectError
                              : {...searchAndSelect, marginBottom: '16px'}
                          }
                        />
                      )}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name={'company'}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <Divider sx={{backgroundColor: '#E4E4EF', margin: '15px 0'}} />
              <div className={styles.buttonsContainer}>
                <Button sx={cancelButton} onClick={clearDataAndErrors}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  type="submit"
                  disabled={isCreatingBranchLoading}
                  loading={isCreatingBranchLoading}
                  sx={createButtonModal}
                >
                  Create
                </ButtonWithProgress>
              </div>
            </Box>
          </form>
        </Modal>
      </Backdrop>
    </div>
  );
};

export default BranchesCreate;
