import React, {FC} from 'react';
import {ICompanyOwnerGetByIdServerResponse} from 'store/models/companyOwners';
import styles from '../CompanyOwnersInformation.module.scss';
import {Link} from 'react-router-dom';
import {LinkIcon} from 'assets/icons';
import Guard from 'components/Guard/Guard';
import {UserRole} from 'enums';

interface Props {
  companyOwnerData: ICompanyOwnerGetByIdServerResponse | undefined;
}

const InformationTab: FC<Props> = (props) => {
  return (
    <div>
      <div className={styles.companyOwnerUserInfo}>
        {props?.companyOwnerData?.audit?.deleting ? (
          <span className={`${styles.companyOwnerStatus} ${styles.deleted}`}>
            Removed
          </span>
        ) : (
          <span
            className={
              props?.companyOwnerData?.isActive
                ? `${styles.companyOwnerStatus} ${styles.active}`
                : `${styles.companyOwnerStatus} ${styles.inactive}`
            }
          >
            {props?.companyOwnerData?.isActive ? 'Active' : 'Inactive'}
          </span>
        )}
        <div>
          <span>First Name</span>
          <span>{props?.companyOwnerData?.firstName}</span>
        </div>

        <div>
          <span>Last Name</span>
          <span>{props?.companyOwnerData?.lastName}</span>
        </div>

        <div>
          <span>Email</span>
          <span>{props?.companyOwnerData?.email}</span>
        </div>

        <div>
          <span>Number</span>
          <span>{props?.companyOwnerData?.phoneNumber}</span>
        </div>

        {props?.companyOwnerData?.additionalInformation ? (
          <div>
            <span>Additional information - Optional</span>
            <span>{props?.companyOwnerData.additionalInformation}</span>
          </div>
        ) : null}
      </div>

      <div className={styles.companyOwnerCompanies}>
        {props?.companyOwnerData?.companies.length ? <h3>Companies</h3> : null}
        {props?.companyOwnerData?.companies.map((company) => (
          <span key={company.id}>
            {company.name}{' '}
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <Link to={`/companies/information/${company.id}`}>
                <LinkIcon style={{height: '12px', width: '12px'}} />
              </Link>
            </Guard>
          </span>
        ))}
      </div>
    </div>
  );
};

export default InformationTab;
