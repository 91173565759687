import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {api} from 'store/slices/api';
import {setIsAuth} from 'store/slices/authSlice';

const IDLE_TIME = 3_600_000;

const IdleTimer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let timeout: NodeJS.Timeout | null = null;
  const resetAuth = () => {
    dispatch(setIsAuth(false));
    localStorage.clear();
    dispatch(api.util.resetApiState());
  };
  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      resetAuth();
    }, IDLE_TIME);
  };
  const onMouseMove = () => {
    restartAutoReset();
  };
  useEffect(() => {
    // initiate timeout
    restartAutoReset();

    // listen for mouse events
    window.addEventListener('mousemove', onMouseMove);

    // cleanup
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        window.removeEventListener('mousemove', onMouseMove);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return <div />;
};

export default IdleTimer;
