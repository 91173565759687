import {FC} from 'react';
import {ErrorMessage} from '@hookform/error-message';
import {Controller, useFormContext} from 'react-hook-form';
import styles from '../StationsCreate.module.scss';
import {Autocomplete, Paper, TextField} from '@mui/material';
import {SelectPopupIcon} from 'assets/icons';
import {useGetStationTypesListQuery} from 'store/slices/stations';
import {searchAndSelectError, searchAndSelect} from 'styles/MUIStyles/common';

const MainInfo: FC = () => {
  const {
    control,
    register,
    formState: {errors},
  } = useFormContext();

  const {data: stationTypesData, isLoading} = useGetStationTypesListQuery();
  return (
    <div>
      <div className={styles.nameInputContainer}>
        <label htmlFor="name">Name station</label>
        <input
          {...register('name', {required: 'This field is required'})}
          type="text"
          id="name"
          autoComplete="off"
          className={`${errors?.name ? 'input inputError' : 'input'}`}
          placeholder="Enter name station"
        />
        <ErrorMessage
          name="name"
          errors={errors}
          render={({message}) => (
            <span className={styles.inputErrorMessage}>{message}</span>
          )}
        />
      </div>

      <div className={styles.fiscalDeviceIdInputContainer}>
        <label htmlFor="fiscalDeviceId">Fiscal device id</label>
        <input
          {...register('fiscalDeviceId', {required: 'This field is required'})}
          type="text"
          id="fiscalDeviceId"
          autoComplete="off"
          className={`${errors?.fiscalDeviceId ? 'input inputError' : 'input'}`}
          placeholder="Enter fiscal device id"
        />
        <ErrorMessage
          name="fiscalDeviceId"
          errors={errors}
          render={({message}) => (
            <span className={styles.inputErrorMessage}>{message}</span>
          )}
        />
      </div>

      <div className={styles.passChassicNumberInputContainer}>
        <label htmlFor="posChassicNumber">PosChassis number</label>
        <input
          {...register('posChassicNumber', {
            required: 'This field is required',
          })}
          type="text"
          id="posChassicNumber"
          autoComplete="off"
          className={`${
            errors?.posChassicNumber ? 'input inputError' : 'input'
          }`}
          placeholder="Enter posChassis number"
        />
        <ErrorMessage
          name="posChassicNumber"
          errors={errors}
          render={({message}) => (
            <span className={styles.inputErrorMessage}>{message}</span>
          )}
        />
      </div>
      <div className={styles.passChassicNumberInputContainer}>
        <label htmlFor="stationType">Station Type</label>
        <Controller
          name={'stationType'}
          control={control}
          rules={{
            validate: (value) => {
              if (!value?.id) {
                return 'This field is required';
              }
            },
          }}
          render={({field}) => (
            <Autocomplete
              {...field}
              id={'stationType'}
              options={stationTypesData || []}
              loading={isLoading}
              popupIcon={<SelectPopupIcon />}
              PaperComponent={(props) => (
                <Paper
                  sx={{
                    borderRadius: '12px',
                    boxShadow: '0px 4px 20px 0px #4444441F',
                    marginTop: '8px',
                    cursor: 'pointer',
                  }}
                  {...props}
                />
              )}
              sx={{width: '100%'}}
              ListboxProps={{
                style: {maxHeight: '200px'},
                className: 'autoCompleteListBox',
              }}
              isOptionEqualToValue={(option, value) =>
                value.name === '' ? true : option.id === value.id
              }
              getOptionLabel={(option) => (option?.name ? option.name : '')}
              onChange={(_, value) => {
                if (value) {
                  field.onChange(value);
                } else {
                  field.onChange(null);
                }
              }}
              renderOption={(props: any, option) => (
                <span {...props} className={styles.dropDownItem}>
                  {option?.name}
                </span>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Select stations type"
                  sx={
                    errors?.company
                      ? searchAndSelectError
                      : {...searchAndSelect, marginBottom: '16px'}
                  }
                />
              )}
            />
          )}
        />
        <ErrorMessage
          name="posChassicNumber"
          errors={errors}
          render={({message}) => (
            <span className={styles.inputErrorMessage}>{message}</span>
          )}
        />
      </div>
    </div>
  );
};

export default MainInfo;
