import {useEffect, useState} from 'react';
import {calculateItemNumbers} from 'core/utils';

export interface IMetadata {
  pageCount: number;
  totalItemCount: number;
  pageNumber: number;
  pageSize: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  isFirstPage: boolean;
  isLastPage: boolean;
  firstItemOnPage: number;
  lastItemOnPage: number;
}

const useGetNumberOfElements = (metaData: IMetadata | null) => {
  const [itemsOnThePage, setItemsOnPage] = useState<number[]>([]);

  useEffect(() => {
    if (!metaData) {
      setItemsOnPage([]);
    }

    if (metaData && metaData.firstItemOnPage && metaData.lastItemOnPage) {
      setItemsOnPage(
        calculateItemNumbers(metaData.firstItemOnPage, metaData.lastItemOnPage),
      );
    }
  }, [metaData]);

  return itemsOnThePage;
};

export default useGetNumberOfElements;
