import {configureStore} from '@reduxjs/toolkit';
import authReducer from 'store/slices/authSlice';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {api} from './slices/api';

const store = configureStore({
  reducer: {
    auth: authReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
