// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OTPCode_pageContainer__6m6gq {
  background: #f1f6ff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OTPCode_title__uy9uV {
  font-weight: 700;
  font-size: 24px;
  margin-top: 12px;
}

.OTPCode_text__xyFor {
  font-weight: 400;
  font-size: 14px;
  color: #8a8894;
  margin-bottom: 16px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/views/OTPCode/OTPCode.module.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAGA;EACI,gBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,eAAA;EACA,cAAA;EACA,mBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".pageContainer {\n    background: #f1f6ff;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n\n.title {\n    font-weight: 700;\n    font-size: 24px;\n    margin-top: 12px;\n}\n\n.text {\n    font-weight: 400;\n    font-size: 14px;\n    color: #8a8894;\n    margin-bottom: 16px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `OTPCode_pageContainer__6m6gq`,
	"title": `OTPCode_title__uy9uV`,
	"text": `OTPCode_text__xyFor`
};
export default ___CSS_LOADER_EXPORT___;
