import React, {FC, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {useUpdateEffect} from 'hooks';
import {useCreateCompanyMutation} from 'store/slices/companies';
import {ISnackbarState} from 'store/models/common';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Divider,
  Modal,
  Snackbar,
} from '@mui/material';
import {CloseIcon, SuccessNotification} from 'assets/icons';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {modal} from 'styles/MUIStyles/modal';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './CompaniesCreate.module.scss';

interface Props {
  isCreateCompanyModalOpen: boolean;
  setIsCreateCompanyModalOpen: (isOpen: boolean) => void;
}

interface IFormData {
  name: string;
  address: string;
  registrationNumber: string;
  vat: string;
  bank: string;
  bankAccount: string;
  bankCode: string;
  phone: string;
}

const CompaniesCreate: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    reset,
    setError,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      name: '',
      address: '',
      registrationNumber: '',
      vat: '',
      bank: '',
      bankCode: '',
      bankAccount: '',
      phone: '',
    },
  });

  const [
    addCompany,
    {
      isLoading: isAddingCompanyLoading,
      isSuccess: isAddingCompanySuccess,
      error: createCompanyErrors,
    },
  ] = useCreateCompanyMutation();

  const [companyName, setCompanyName] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {vertical, horizontal, open} = snackbar;

  const onSubmit = async (formData: IFormData) => {
    const response = await addCompany({
      ...formData,
      name: formData.name.trim(),
      address: formData.address.trim(),
      registrationNumber: formData.registrationNumber.trim(),
      vat: formData.vat.trim(),
      bank: formData.bank.trim(),
      bankCode: formData.bankCode.trim(),
      bankAccount: formData.bankAccount.trim(),
      phone: formData.phone.trim(),
    });

    if (response) {
      if (!('error' in response)) {
        setCompanyName(formData.name);
      }
    }
  };

  const clearDataAndErrors = () => {
    clearErrors();
    reset();
    props.setIsCreateCompanyModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setCompanyName(null);
    setSnackbar({...snackbar, open: false});
  };

  useUpdateEffect(() => {
    if (isAddingCompanySuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      props.setIsCreateCompanyModalOpen(false);
      reset();
    }
  }, [isAddingCompanySuccess]);

  useEffect(() => {
    if (createCompanyErrors) {
      if ('data' in createCompanyErrors) {
        const errors = createCompanyErrors.data?.errors;
        if (errors?.name?.[0] || errors?.Name?.[0]) {
          setError('name', {
            type: 'custom',
            message: errors?.name?.[0] || errors?.Name?.[0],
          });
        }
        if (
          errors?.registrationNumber?.[0] ||
          errors?.RegistrationNumber?.[0]
        ) {
          setError('registrationNumber', {
            type: 'custom',
            message:
              errors?.registrationNumber?.[0] ||
              errors?.RegistrationNumber?.[0],
          });
        }
      }
    }
  }, [createCompanyErrors, setError]);

  return (
    <div>
      {companyName ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Company {`"${companyName}"`} successfully added
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
      <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={props.isCreateCompanyModalOpen}
      >
        <Modal
          open={props.isCreateCompanyModalOpen}
          onClose={clearDataAndErrors}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={modal}>
              <div className={styles.companiesCreateModalTop}>
                <h2 className={styles.companiesCreateTitle}>Create company</h2>
                <CloseIcon onClick={clearDataAndErrors} />
              </div>

              <div className={styles.nameInputContainer}>
                <label htmlFor="name">Name</label>
                <input
                  {...register('name', {required: 'This field is required'})}
                  type="text"
                  id="name"
                  autoComplete="off"
                  className={`${errors?.name ? 'input inputError' : 'input'}`}
                  placeholder="Enter company name"
                />
                <ErrorMessage
                  name="name"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.registrationNumberInputContainer}>
                <label htmlFor="registrationNumber">Registration number</label>
                <input
                  {...register('registrationNumber')}
                  type="text"
                  id="registrationNumber"
                  autoComplete="off"
                  className={`${
                    errors?.registrationNumber ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter registration number"
                />
                <ErrorMessage
                  name="registrationNumber"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.addressInputContainer}>
                <label htmlFor="address">Address</label>
                <input
                  {...register('address')}
                  type="text"
                  id="address"
                  autoComplete="off"
                  className={`${
                    errors?.address ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter address"
                />
                <ErrorMessage
                  name="address"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="vat">VAT number</label>
                <input
                  {...register('vat')}
                  type="text"
                  id="vat"
                  autoComplete="off"
                  className={`${errors?.vat ? 'input inputError' : 'input'}`}
                  placeholder="Enter VAT number"
                />
                <ErrorMessage
                  name="vat"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.addressInputContainer}>
                <label htmlFor="bank">Bank</label>
                <input
                  {...register('bank')}
                  type="text"
                  id="bank"
                  autoComplete="off"
                  className={`${errors?.bank ? 'input inputError' : 'input'}`}
                  placeholder="Enter bank"
                />
                <ErrorMessage
                  name="bank"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="bankCode">Bank Code</label>
                <input
                  {...register('bankCode')}
                  type="text"
                  id="bankCode"
                  autoComplete="off"
                  className={`${
                    errors?.bankCode ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter bank code"
                />
                <ErrorMessage
                  name="bankCode"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="bankAccount">Bank Account</label>
                <input
                  {...register('bankAccount')}
                  type="text"
                  id="bankAccount"
                  autoComplete="off"
                  className={`${
                    errors?.bankAccount ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter bank account"
                />
                <ErrorMessage
                  name="bankAccount"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
              <div className={styles.addressInputContainer}>
                <label htmlFor="phone">Phone</label>
                <input
                  {...register('phone')}
                  type="text"
                  id="phone"
                  autoComplete="off"
                  className={`${errors?.phone ? 'input inputError' : 'input'}`}
                  placeholder="Enter phone"
                />
                <ErrorMessage
                  name="phone"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <Divider
                sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}}
              />
              <div className={styles.buttonsContainer}>
                <Button sx={cancelButton} onClick={clearDataAndErrors}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  type="submit"
                  disabled={isAddingCompanyLoading}
                  loading={isAddingCompanyLoading}
                  sx={createButtonModal}
                >
                  Create
                </ButtonWithProgress>
              </div>
            </Box>
          </form>
        </Modal>
      </Backdrop>
    </div>
  );
};

export default CompaniesCreate;
