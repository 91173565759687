import {Box} from '@mui/material';
import {useUpdateEffect} from 'hooks';
import {FC, MutableRefObject, useState} from 'react';
import {read, utils} from 'xlsx';

interface ReportsTableProps {
  reportsData: string;
  tableRef: MutableRefObject<HTMLDivElement>;
  isFetching: boolean;
  isError: boolean;
}

const ReportsTable: FC<ReportsTableProps> = ({
  reportsData,
  tableRef,
  isFetching,
  isError,
}) => {
  const [__html, setHtml] = useState('');

  useUpdateEffect(() => {
    if (reportsData) {
      const wb = read(reportsData);
      const ws = wb.Sheets[wb.SheetNames[0]];
      const data = utils.sheet_to_html(ws);
      setHtml(data);
    } else {
      setHtml('');
    }
  }, [reportsData, isFetching, isError]);
  return (
    <>
      {reportsData && __html && !isFetching ? (
        <div ref={tableRef} dangerouslySetInnerHTML={{__html}} />
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <p>Select report type, station and other filters to retrieve data.</p>
        </Box>
      )}
    </>
  );
};

export default ReportsTable;
