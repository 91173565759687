import React, {FC, useState} from 'react';
import {
  Controller,
  useFieldArray,
  useForm,
  UseFormGetValues,
} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {useUpdateEffect} from 'hooks';
import {IGetDefaultManifest, IStationType} from 'store/models/stations';
import {useCreateStationMutation} from 'store/slices/stations';
import {backupPeriodTypes} from 'core/constants';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Modal,
  Paper,
  Snackbar,
  Stack,
  styled,
  Switch,
  TextField,
} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {
  CloseIcon,
  CreateIcon,
  ErrorNotification,
  SelectPopupIcon,
  SuccessNotification,
} from 'assets/icons';
import {
  androidSwitch,
  createTaskBackupSwitchLabel,
  searchAndSelect,
  searchAndSelectError,
} from 'styles/MUIStyles/common';
import {
  addDirectoryButton,
  cancelButton,
  createTaskBackupButtons,
  warningModalButton,
} from 'styles/MUIStyles/buttons';
import {modal} from 'styles/MUIStyles/modal';
import styles from '../StationsCreate.module.scss';
import {ISnackbarState} from '../../../../store/models/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from '../../../../styles/MUIStyles/snackbars';

interface Props {
  isCreateTaskModalOpen: boolean;
  setIsCreateTaskModalOpen: (isOpen: boolean) => void;
  getDataFromMainInfo: UseFormGetValues<{
    name: string;
    posChassicNumber: string;
    fiscalDeviceId: string;
    stationType: IStationType;
  }>;
  defaultManifest: IGetDefaultManifest | undefined;
}

interface IManifestCreateTask {
  name: string;
  include: {
    directory: string;
  }[];
  exclude:
    | {
        directory: string;
      }[]
    | null;
  isIncrementalBackupSettingsChecked: boolean;
  isExcludeDirectoryChecked: boolean;
  keepFullCopy: number;
  isPreBackupScriptChecked: boolean;
  isPostBackupScriptChecked: boolean;
  preBackupScripts: {script: string}[] | null;
  postBackupScripts: {script: string}[] | null;
  fullBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
  incrementalBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
}

const AndroidSwitch = styled(Switch)(() => androidSwitch);

const CreateTask: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    clearErrors,
    reset,
    formState: {errors},
  } = useForm<IManifestCreateTask>({
    defaultValues: {
      name: '',
      include: [{directory: ''}],
      exclude: [{directory: ''}],
      isIncrementalBackupSettingsChecked: false,
      isExcludeDirectoryChecked: false,
      isPreBackupScriptChecked: false,
      isPostBackupScriptChecked: false,
      preBackupScripts: [{script: ''}],
      postBackupScripts: [{script: ''}],
      keepFullCopy: 1,
      fullBackupSettings: {
        periodType: null,
        periodCount: 1,
      },
      incrementalBackupSettings: {
        periodType: null,
        periodCount: 1,
      },
    },
  });

  const {
    fields: includeDirectories,
    append: appendIncludeDirectory,
    remove: deleteIncludeDirectory,
  } = useFieldArray<IManifestCreateTask>({
    control,
    name: 'include',
  });

  const {
    fields: excludeDirectories,
    append: appendExcludeDirectory,
    remove: deleteExcludeDirectory,
  } = useFieldArray<IManifestCreateTask>({
    control,
    name: 'exclude',
  });

  const {
    fields: preScripts,
    append: appendPreScript,
    remove: deletePreScript,
  } = useFieldArray<IManifestCreateTask>({
    control,
    name: 'preBackupScripts',
  });

  const {
    fields: postScripts,
    append: appendPostScript,
    remove: deletePostScript,
  } = useFieldArray<IManifestCreateTask>({
    control,
    name: 'postBackupScripts',
  });

  const [
    createStation,
    {
      isSuccess: isCreatingStationSuccess,
      isLoading: isCreatingStationLoading,
      error: createStationError,
    },
  ] = useCreateStationMutation();

  const [createStationErrorMessage, setCreateStationErrorMessage] = useState<
    string | null
  >(null);
  const [createStationSnackbarSuccess, setCreateStationSnackbarSuccess] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [createStationSnackbarError, setCreateStationSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const {
    vertical: createStationSuccessVertical,
    horizontal: createStationSuccessHorizontal,
    open: isCreateStationSuccessOpen,
  } = createStationSnackbarSuccess;
  const {
    vertical: createStationErrorVertical,
    horizontal: createStationErrorHorizontal,
    open: isCreateStationErrorOpen,
  } = createStationSnackbarError;

  const onSubmit = (data: IManifestCreateTask) => {
    if (props.defaultManifest) {
      const mainManifest = props.defaultManifest.commands.find(
        (command) => command.isMain,
      );

      if (mainManifest) {
        createStation({
          name: props.getDataFromMainInfo().name,
          posChassicNumber: props.getDataFromMainInfo().posChassicNumber,
          fiscalDeviceId: props.getDataFromMainInfo().fiscalDeviceId,
          stationTypeId: props.getDataFromMainInfo().stationType.id,
          manifest: {
            commands: [
              ...props.defaultManifest.commands,
              {
                id: '',
                name: data.name,
                isMain: false,
                include: [...data.include.map((path) => path.directory)],
                exclude: data.exclude?.[0]?.directory
                  ? [...data.exclude.map((path) => path.directory)]
                  : null,
                preBackupScripts: data.preBackupScripts?.[0]?.script
                  ? [...data.preBackupScripts.map((path) => path.script)]
                  : null,
                postBackupScripts: data.postBackupScripts?.[0]?.script
                  ? [...data.postBackupScripts.map((path) => path.script)]
                  : null,
                keepFullCopy: data.keepFullCopy,
                fullBackupSettings: data.fullBackupSettings,
                incrementalBackupSettings: data?.incrementalBackupSettings
                  ?.periodType
                  ? data.incrementalBackupSettings
                  : null,
              },
            ],
          },
        });
      }
    }
  };

  const fullBackupSettingsPeriodCount = watch('fullBackupSettings.periodCount');
  const incrementalBackupSettingsPeriodCount = watch(
    'incrementalBackupSettings.periodCount',
  );
  const keepFullBackupCopyCount = watch('keepFullCopy');
  const isIncrementalBackupSettingsChecked = watch(
    'isIncrementalBackupSettingsChecked',
  );
  const isExcludeDirectoryChecked = watch('isExcludeDirectoryChecked');
  const isPreBackupScriptChecked = watch('isPreBackupScriptChecked');
  const isPostBackupScriptChecked = watch('isPostBackupScriptChecked');

  const closeCreateTaskModalAndClearErrors = () => {
    clearErrors();
    reset();
    props.setIsCreateTaskModalOpen(false);
  };

  const handleSnackbarClose = () => {
    setCreateStationSnackbarSuccess({
      ...createStationSnackbarSuccess,
      open: false,
    });
    setCreateStationSnackbarError({
      ...createStationSnackbarError,
      open: false,
    });
    setCreateStationErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (!isIncrementalBackupSettingsChecked) {
      setValue('incrementalBackupSettings', {
        periodType: null,
        periodCount: 1,
      });
    }

    if (!isExcludeDirectoryChecked) {
      setValue('exclude', [{directory: ''}]);
    }

    if (!isPreBackupScriptChecked) {
      setValue('preBackupScripts', [{script: ''}]);
    }

    if (!isPostBackupScriptChecked) {
      setValue('postBackupScripts', [{script: ''}]);
    }
  }, [
    isIncrementalBackupSettingsChecked,
    isExcludeDirectoryChecked,
    isPreBackupScriptChecked,
    isPostBackupScriptChecked,
  ]);

  useUpdateEffect(() => {
    if (isCreatingStationSuccess) {
      setCreateStationSnackbarSuccess({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      props.setIsCreateTaskModalOpen(false);
      reset();
    }
  }, [isCreatingStationSuccess]);

  useUpdateEffect(() => {
    if (
      createStationError &&
      'data' in createStationError &&
      'status' in createStationError
    ) {
      if (
        createStationError.status === 400 ||
        createStationError.status === 404
      ) {
        const errors = createStationError.data?.errors;

        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            //@ts-ignore
            setCreateStationErrorMessage(errors[key]);
            setCreateStationSnackbarError({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  }, [createStationError]);

  return (
    <div>
      <Modal
        open={props.isCreateTaskModalOpen}
        onClose={closeCreateTaskModalAndClearErrors}
      >
        <Box sx={modal}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8.5px',
              }}
            >
              <h2 className={styles.createManifestTask}>Create task</h2>
              <div
                className={styles.closeIcon}
                onClick={closeCreateTaskModalAndClearErrors}
              >
                <CloseIcon />
              </div>
            </Box>
            <div
              className={
                styles.nameInputContainer + ' ' + styles.createTaskName
              }
            >
              <label htmlFor="name">Name</label>
              <input
                {...register('name', {required: 'This field is required'})}
                type="text"
                id="name"
                autoComplete="off"
                className={`${errors?.name ? 'input inputError' : 'input'}`}
                placeholder="Enter name"
              />
              <ErrorMessage
                name="name"
                errors={errors}
                render={({message}) => (
                  <span className={styles.inputErrorMessage}>{message}</span>
                )}
              />
            </div>
            <h3 className={styles.createManifestCommands}>Commands:</h3>
            {includeDirectories.map((includeDirectory, index) => (
              <div
                className={
                  styles.includeInputContainer + ' ' + styles.includeDirectory
                }
                key={includeDirectory.id}
              >
                {index === 0 && <label htmlFor="include">Include</label>}
                <div className={styles.createAndDeleteDirectoryContainer}>
                  <input
                    {...register(`include.${index}.directory`, {
                      required: 'This field is required',
                    })}
                    type="text"
                    id="include"
                    autoComplete="off"
                    className={`${
                      errors?.include?.[index]?.directory
                        ? 'input inputError'
                        : 'input'
                    }`}
                    placeholder="Write directories"
                  />
                  {index === 0 && (
                    <Button
                      type="button"
                      sx={addDirectoryButton}
                      onClick={() => {
                        appendIncludeDirectory({directory: ''});
                      }}
                    >
                      <CreateIcon />
                    </Button>
                  )}
                  {index > 0 && (
                    <Button
                      type="button"
                      sx={{
                        color: '#3152C7',
                        borderRadius: '4px',
                        minWidth: '40px',
                        minHeight: '40px',
                      }}
                      onClick={() => {
                        deleteIncludeDirectory(index);
                      }}
                    >
                      <span className={styles.deleteDirectory} />
                    </Button>
                  )}
                </div>
                <ErrorMessage
                  name={`include.${index}.directory`}
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
            ))}

            <div className={styles.createTaskBackupFull}>
              <span>Backup-full</span>
              <div className={styles.createTaskFullBackupControls}>
                <span>Every</span>
                <div className={styles.fullBackupButtonsContainer}>
                  <Button
                    disabled={fullBackupSettingsPeriodCount === 1}
                    sx={createTaskBackupButtons}
                    onClick={() => {
                      if (fullBackupSettingsPeriodCount > 1) {
                        setValue(
                          'fullBackupSettings.periodCount',
                          fullBackupSettingsPeriodCount - 1,
                        );
                      }
                    }}
                  >
                    -
                  </Button>
                  <div className={styles.fullBackupSettingsPeriodCount}>
                    {fullBackupSettingsPeriodCount}
                  </div>
                  <Button
                    disabled={fullBackupSettingsPeriodCount === 15}
                    sx={createTaskBackupButtons}
                    onClick={() => {
                      if (fullBackupSettingsPeriodCount < 15) {
                        setValue(
                          'fullBackupSettings.periodCount',
                          fullBackupSettingsPeriodCount + 1,
                        );
                      }
                    }}
                  >
                    +
                  </Button>
                </div>
                <Controller
                  name={'fullBackupSettings.periodType'}
                  control={control}
                  rules={{
                    validate: (value) => {
                      if (!value) {
                        return 'This field cannot be empty';
                      }
                    },
                  }}
                  render={({field}) => (
                    <Autocomplete
                      {...field}
                      options={backupPeriodTypes}
                      value={field.value}
                      onChange={(_, data) => field.onChange(data)}
                      PaperComponent={(props) => (
                        <Paper
                          sx={{
                            borderRadius: '12px',
                            boxShadow: '0px 4px 20px 0px #4444441F',
                            marginTop: '8px',
                            cursor: 'pointer',
                          }}
                          {...props}
                        />
                      )}
                      sx={{width: '20%', flexGrow: 2}}
                      ListboxProps={{
                        style: {maxHeight: '200px'},
                        className: 'autoCompleteListBox',
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value === '' ? true : option === value
                      }
                      popupIcon={<SelectPopupIcon />}
                      renderOption={(props: any, option) => {
                        return (
                          <span {...props} className={styles.dropDownItem}>
                            {option}
                          </span>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          placeholder="Select time period"
                          sx={
                            errors?.fullBackupSettings
                              ? searchAndSelectError
                              : {...searchAndSelect}
                          }
                        />
                      )}
                    />
                  )}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name={'fullBackupSettings.periodType'}
                render={({message}) => (
                  <span className={styles.inputErrorMessage}>{message}</span>
                )}
              />
            </div>

            <div className={styles.createTaskBackupKeepFullCopy}>
              <div className={styles.createTaskBackupKeepFullCopyControls}>
                <span>Keep</span>
                <div className={styles.fullBackupButtonsContainer}>
                  <Button
                    disabled={keepFullBackupCopyCount === 1}
                    sx={createTaskBackupButtons}
                    onClick={() => {
                      if (keepFullBackupCopyCount > 1) {
                        setValue('keepFullCopy', keepFullBackupCopyCount - 1);
                      }
                    }}
                  >
                    -
                  </Button>
                  <div className={styles.fullBackupSettingsPeriodCount}>
                    {keepFullBackupCopyCount}
                  </div>
                  <Button
                    disabled={keepFullBackupCopyCount === 10}
                    sx={createTaskBackupButtons}
                    onClick={() => {
                      if (keepFullBackupCopyCount < 10) {
                        setValue('keepFullCopy', keepFullBackupCopyCount + 1);
                      }
                    }}
                  >
                    +
                  </Button>
                </div>
                <span style={{width: '20%', flexGrow: '2'}}>full copy</span>
              </div>
            </div>

            <Stack direction="row" spacing={1} alignItems="center">
              <Controller
                name={'isIncrementalBackupSettingsChecked'}
                control={control}
                render={({field}) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={createTaskBackupSwitchLabel}
                      control={
                        <AndroidSwitch {...field} checked={field.value} />
                      }
                      label={'Backup-incremental'}
                    />
                  </FormGroup>
                )}
              />
            </Stack>

            {isIncrementalBackupSettingsChecked ? (
              <div className={styles.createTaskBackupIncremental}>
                <div className={styles.createTaskIncrementalBackupControls}>
                  <span>Every</span>
                  <div className={styles.incrementalBackupButtonsContainer}>
                    <Button
                      disabled={incrementalBackupSettingsPeriodCount === 1}
                      sx={createTaskBackupButtons}
                      onClick={() => {
                        if (incrementalBackupSettingsPeriodCount > 1) {
                          setValue(
                            'incrementalBackupSettings.periodCount',
                            incrementalBackupSettingsPeriodCount - 1,
                          );
                        }
                      }}
                    >
                      -
                    </Button>
                    <div className={styles.fullBackupSettingsPeriodCount}>
                      {incrementalBackupSettingsPeriodCount}
                    </div>
                    <Button
                      disabled={incrementalBackupSettingsPeriodCount === 15}
                      sx={createTaskBackupButtons}
                      onClick={() => {
                        if (incrementalBackupSettingsPeriodCount < 15) {
                          setValue(
                            'incrementalBackupSettings.periodCount',
                            incrementalBackupSettingsPeriodCount + 1,
                          );
                        }
                      }}
                    >
                      +
                    </Button>
                  </div>
                  <Controller
                    name={'incrementalBackupSettings.periodType'}
                    control={control}
                    rules={{
                      validate: (value) => {
                        if (!value && isIncrementalBackupSettingsChecked) {
                          return 'This field is required';
                        }
                      },
                    }}
                    render={({field}) => (
                      <Autocomplete
                        {...field}
                        options={backupPeriodTypes}
                        value={field.value}
                        onChange={(_, data) => field.onChange(data)}
                        PaperComponent={(props) => (
                          <Paper
                            sx={{
                              borderRadius: '12px',
                              boxShadow: '0px 4px 20px 0px #4444441F',
                              marginTop: '8px',
                              cursor: 'pointer',
                            }}
                            {...props}
                          />
                        )}
                        sx={{width: '20%', flexGrow: 2}}
                        ListboxProps={{
                          style: {maxHeight: '200px'},
                          className: 'autoCompleteListBox',
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === '' ? true : option === value
                        }
                        popupIcon={<SelectPopupIcon />}
                        renderOption={(props: any, option) => {
                          return (
                            <span {...props} className={styles.dropDownItem}>
                              {option}
                            </span>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="Select time period"
                            sx={
                              errors?.incrementalBackupSettings
                                ? searchAndSelectError
                                : {...searchAndSelect}
                            }
                          />
                        )}
                      />
                    )}
                  />
                </div>
                <ErrorMessage
                  errors={errors}
                  name={'incrementalBackupSettings.periodType'}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>
            ) : null}

            <Stack direction="row" spacing={1} alignItems="center">
              <Controller
                name={'isExcludeDirectoryChecked'}
                control={control}
                render={({field}) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={createTaskBackupSwitchLabel}
                      control={
                        <AndroidSwitch {...field} checked={field.value} />
                      }
                      label={'Exclude'}
                    />
                  </FormGroup>
                )}
              />
            </Stack>

            {isExcludeDirectoryChecked ? (
              <>
                {excludeDirectories.map((excludeDirectory, index) => (
                  <div
                    className={
                      styles.includeInputContainer +
                      ' ' +
                      styles.includeDirectory
                    }
                    key={excludeDirectory.id}
                  >
                    <div className={styles.createAndDeleteDirectoryContainer}>
                      <input
                        {...register(`exclude.${index}.directory`, {
                          required: isExcludeDirectoryChecked
                            ? 'This field is required'
                            : false,
                        })}
                        type="text"
                        autoComplete="off"
                        className={`${
                          errors?.exclude?.[index]?.directory
                            ? 'input inputError'
                            : 'input'
                        }`}
                        placeholder="Write directories"
                      />
                      {index === 0 && (
                        <Button
                          type="button"
                          sx={addDirectoryButton}
                          onClick={() => {
                            appendExcludeDirectory({directory: ''});
                          }}
                        >
                          <CreateIcon />
                        </Button>
                      )}
                      {index > 0 && (
                        <Button
                          type="button"
                          sx={{
                            color: '#3152C7',
                            borderRadius: '4px',
                            minWidth: '40px',
                            minHeight: '40px',
                          }}
                          onClick={() => {
                            deleteExcludeDirectory(index);
                          }}
                        >
                          <span className={styles.deleteDirectory} />
                        </Button>
                      )}
                    </div>
                    <ErrorMessage
                      name={`exclude.${index}.directory`}
                      errors={errors}
                      render={({message}) => (
                        <span className={styles.inputErrorMessage}>
                          {message}
                        </span>
                      )}
                    />
                  </div>
                ))}
              </>
            ) : null}

            <Stack direction="row" spacing={1} alignItems="center">
              <Controller
                name={'isPreBackupScriptChecked'}
                control={control}
                render={({field}) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={createTaskBackupSwitchLabel}
                      control={
                        <AndroidSwitch {...field} checked={field.value} />
                      }
                      label={'Pre-backup scripts'}
                    />
                  </FormGroup>
                )}
              />
            </Stack>

            {isPreBackupScriptChecked ? (
              <>
                {preScripts.map((preScript, index) => (
                  <div
                    className={styles.preScriptInputContainer}
                    key={preScript.id}
                  >
                    <div className={styles.createAndDeleteScriptContainer}>
                      <textarea
                        {...register(`preBackupScripts.${index}.script`)}
                        cols={30}
                        rows={2}
                        className={`${
                          errors?.preBackupScripts?.[index]?.script
                            ? 'input inputError'
                            : 'input'
                        }`}
                        placeholder="Write script"
                      />
                      {index === 0 && (
                        <Button
                          type="button"
                          sx={addDirectoryButton}
                          onClick={() => {
                            appendPreScript({script: ''});
                          }}
                        >
                          <CreateIcon />
                        </Button>
                      )}
                      {index > 0 && (
                        <Button
                          type="button"
                          sx={{
                            color: '#3152C7',
                            borderRadius: '4px',
                            minWidth: '40px',
                            minHeight: '40px',
                          }}
                          onClick={() => {
                            deletePreScript(index);
                          }}
                        >
                          <span className={styles.deleteScript} />
                        </Button>
                      )}
                    </div>
                    <ErrorMessage
                      name={`preBackupScripts.${index}.script`}
                      errors={errors}
                      render={({message}) => (
                        <span className={styles.inputErrorMessage}>
                          {message}
                        </span>
                      )}
                    />
                  </div>
                ))}
              </>
            ) : null}

            <Stack direction="row" spacing={1} alignItems="center">
              <Controller
                name={'isPostBackupScriptChecked'}
                control={control}
                render={({field}) => (
                  <FormGroup>
                    <FormControlLabel
                      sx={createTaskBackupSwitchLabel}
                      control={
                        <AndroidSwitch {...field} checked={field.value} />
                      }
                      label={'Post-backup scripts'}
                    />
                  </FormGroup>
                )}
              />
            </Stack>

            {isPostBackupScriptChecked ? (
              <>
                {postScripts.map((postScript, index) => (
                  <div
                    className={styles.postScriptInputContainer}
                    key={postScript.id}
                  >
                    <div className={styles.createAndDeleteScriptContainer}>
                      <textarea
                        {...register(`postBackupScripts.${index}.script`)}
                        cols={30}
                        rows={2}
                        className={`${
                          errors?.postBackupScripts?.[index]?.script
                            ? 'input inputError'
                            : 'input'
                        }`}
                        placeholder="Write script"
                      />
                      {index === 0 && (
                        <Button
                          type="button"
                          sx={addDirectoryButton}
                          onClick={() => {
                            appendPostScript({script: ''});
                          }}
                        >
                          <CreateIcon />
                        </Button>
                      )}
                      {index > 0 && (
                        <Button
                          type="button"
                          sx={{
                            color: '#3152C7',
                            borderRadius: '4px',
                            minWidth: '40px',
                            minHeight: '40px',
                          }}
                          onClick={() => {
                            deletePostScript(index);
                          }}
                        >
                          <span className={styles.deleteScript} />
                        </Button>
                      )}
                    </div>
                    <ErrorMessage
                      name={`postBackupScripts.${index}.script`}
                      errors={errors}
                      render={({message}) => (
                        <span className={styles.inputErrorMessage}>
                          {message}
                        </span>
                      )}
                    />
                  </div>
                ))}
              </>
            ) : null}

            <Divider
              sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}}
            />
            <div className={styles.createTaskButtonsContainer}>
              <Button
                sx={cancelButton}
                onClick={closeCreateTaskModalAndClearErrors}
              >
                Cancel
              </Button>
              <ButtonWithProgress
                type="submit"
                loading={isCreatingStationLoading}
                disabled={isCreatingStationLoading}
                sx={warningModalButton}
              >
                Create
              </ButtonWithProgress>
            </div>
          </form>
        </Box>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: createStationSuccessVertical,
          horizontal: createStationSuccessHorizontal,
        }}
        autoHideDuration={2500}
        open={isCreateStationSuccessOpen}
        onClose={handleSnackbarClose}
        key={'createStationSuccess'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            Station was successfully created
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>

      {createStationErrorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: createStationErrorVertical,
            horizontal: createStationErrorHorizontal,
          }}
          autoHideDuration={5000}
          open={isCreateStationErrorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {createStationErrorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default CreateTask;
