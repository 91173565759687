import React, {FC} from 'react';
import {pageSizes, statuses} from 'core/constants';
import {SelectPopupIcon} from 'assets/icons';
import {
  Autocomplete,
  createTheme,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import {searchAndSelect, selectRowsPaperProps} from 'styles/MUIStyles/common';
import styles from '../Dealers.module.scss';
import search from '../../../assets/icons/search.svg';
import {IMetadata} from '../../../store/models/common';

interface Props {
  search: string;
  setSearch: (search: string) => void;
  status: {label: string; value: boolean; queryName: string};
  setStatus: (
    status: {label: string; value: boolean; queryName: string} | null,
  ) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  metaData: IMetadata | null;
}

const DealersTableTopToolbar: FC<Props> = (props) => {
  const theme = createTheme({
    components: {
      MuiSelect: {
        styleOverrides: {
          root: {
            color: '#8A8894',
            fontFamily: 'Poppins, sans-serif',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '24px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
              borderRadius: '12px',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #BDBCDB',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #3152C7',
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.tableAutocompleteFilters}>
        <div className={styles.dealersTopToolbarSearch}>
          <input
            name="clientsSearch"
            type="text"
            className="input"
            placeholder="Search"
            autoComplete="off"
            value={props.search}
            onChange={(e) => props.setSearch(e.target.value)}
          />
          <img className={styles.searchIcon} src={search} alt="search icon" />
        </div>
        <Autocomplete
          disablePortal
          options={statuses}
          value={props.status}
          onChange={(event, value) => {
            if (value) {
              props.setStatus(value);
            } else {
              props.setStatus(null);
            }
          }}
          PaperComponent={(props) => (
            <Paper
              sx={{
                borderRadius: '12px',
                boxShadow: '0px 4px 20px 0px #4444441F',
                marginTop: '8px',
                cursor: 'pointer',
              }}
              {...props}
            />
          )}
          sx={{width: '20%'}}
          ListboxProps={{style: {maxHeight: '200px', padding: 0}}}
          isOptionEqualToValue={(option, value) =>
            value.label === '' ? true : option.label === value.label
          }
          popupIcon={<SelectPopupIcon />}
          renderOption={(props: any, option) => {
            return (
              <span {...props} className={styles.dropDownItem}>
                {option.label}
              </span>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Select status"
              sx={searchAndSelect}
            />
          )}
        />
        <div className={styles.topToolbarSelectContainer}>
          {props.metaData && (
            <>
              <span>Show rows:</span>
              <FormControl size="small">
                <Select
                  name="dealersPageCount"
                  value={+props.pageSize}
                  onChange={(e) => {
                    props.setPageSize(+e.target.value);
                  }}
                  IconComponent={(props) => <SelectPopupIcon {...props} />}
                  MenuProps={{
                    MenuListProps: {disablePadding: true},
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    PaperProps: selectRowsPaperProps,
                  }}
                >
                  {pageSizes.map((page) => {
                    return (
                      <MenuItem key={page} value={page}>
                        {page}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <span>{`${props.metaData?.firstItemOnPage}-${props.metaData?.lastItemOnPage} of ${props.metaData?.totalItemCount}`}</span>
            </>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default DealersTableTopToolbar;
