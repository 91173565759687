import React, {FC, memo} from 'react';
import {IAllBackupsProps} from 'store/models/backups';
import AllBackupsTopToolbar from './AllBackupsTopToolbar/AllBackupsTopToolbar';
import AllBackupsTable from './AllBackupsTable/AllBackupsTable';
import {useLocalStorage} from 'hooks';

const AllBackups: FC<IAllBackupsProps> = memo((props) => {
  const [
    isAllBackupsShowFilterButtonOpen,
    setIsAllBackupsShowFilterButtonOpen,
  ] = useLocalStorage('isAllBackupsShowFilterButtonOpen', true);

  const clearAllBackupsFilters = () => {
    props.setAllBackupsStartDate(null);
    props.setAllBackupsFinishDate(null);
    props.setSelectedStationsForAllBackups([]);
  };

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <AllBackupsTopToolbar
        search={props.search}
        setSearch={props.setSearch}
        allBackupsStartDate={props.allBackupsStartDate}
        setAllBackupsStartDate={props.setAllBackupsStartDate}
        allBackupsFinishDate={props.allBackupsFinishDate}
        setAllBackupsFinishDate={props.setAllBackupsFinishDate}
        allBackupsPageSize={props.allBackupsPageSize}
        setAllBackupsPageSize={props.setAllBackupsPageSize}
        stationsForAllBackups={props.stationsForAllBackups}
        selectedStationsForAllBackups={props.selectedStationsForAllBackups}
        setSelectedStationsForAllBackups={
          props.setSelectedStationsForAllBackups
        }
        metaData={props.metaData}
        backupsFileTypes={props.backupsFileTypes}
        allBackupFileType={props.allBackupFileType}
        setAllBackupFileType={props.setAllBackupFileType}
        isAllBackupsShowFilterButtonOpen={isAllBackupsShowFilterButtonOpen}
        setIsAllBackupsShowFilterButtonOpen={
          setIsAllBackupsShowFilterButtonOpen
        }
        clearAllBackupsFilters={clearAllBackupsFilters}
        companies={props.companies || []}
        selectedCompanies={props.selectedCompanies}
        setSelectedCompanies={props.setSelectedCompanies}
        branches={props.branches || []}
        selectedBranches={props.selectedBranches}
        setSelectedBranches={props.setSelectedBranches}
      />
      <div
        style={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <AllBackupsTable
          modifiedAllBackups={props.modifiedAllBackups}
          setModifiedAllBackups={props.setModifiedAllBackups}
          sortConfig={props.sortConfig}
          handleColumnClick={props.handleColumnClick}
          allBackupsDebouncedSearch={props.allBackupsDebouncedSearch}
          metaData={props.metaData}
          downloadAllBackupsFileLoading={props.downloadAllBackupsFileLoading}
          setDownloadAllBackupsLoading={props.setDownloadAllBackupsLoading}
        />
      </div>
    </div>
  );
});

export default AllBackups;
