import React from 'react';
import {TabPanelProps} from 'store/models/common';
import {Box} from '@mui/material';

//так, этот компонент нужно юзать только тогда, когда
// у нас табы, как на страницах бэкапы или логи)
const CustomTabPanel = (props: TabPanelProps) => {
  const {children, value, index, ...other} = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{height: '100%'}}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '12px 0 0 0', height: '100%'}}>
          <div style={{height: '100%'}}>{children}</div>
        </Box>
      )}
    </div>
  );
};

export default CustomTabPanel;
