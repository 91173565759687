// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DataCollector_dcTop__aVIJd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.DataCollector_dcTop__aVIJd .DataCollector_dcTitle__oukO\\+ {
  font-weight: 600;
  font-size: 24px;
  margin: 0;
}

.DataCollector_dcMainContent__0BhIY {
  height: 80%;
  overflow: auto;
  padding: 15px;
  background: #fff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
.DataCollector_dcMainContent__0BhIY::-webkit-scrollbar {
  width: 8px;
}
.DataCollector_dcMainContent__0BhIY::-webkit-scrollbar-track {
  background-color: transparent;
}
.DataCollector_dcMainContent__0BhIY::-webkit-scrollbar-thumb {
  background-color: #e0e0f1;
  border-radius: 100px;
}

.DataCollector_tableFiltersTop__SAyBZ {
  display: flex;
  background: #fff;
  margin-bottom: 16px;
}

.DataCollector_topToolbarSelectContainer__fpd\\+O {
  display: flex;
  align-items: center;
  margin-left: auto;
  column-gap: 8px;
}

.DataCollector_dropDownItem__DQch3 {
  font-family: "Poppins", sans-serif;
  display: block;
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  color: #212121;
  line-height: 24px;
  font-weight: 400;
}
.DataCollector_dropDownItem__DQch3:hover {
  background: #f5f8ff;
}

.DataCollector_pagination__ilwSY {
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/views/DataCollector/DataCollector.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,SAAA;AACR;;AAGA;EACI,WAAA;EACA,cAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;AAAJ;AAEI;EACI,UAAA;AAAR;AAGI;EACI,6BAAA;AADR;AAII;EACI,yBAAA;EACA,oBAAA;AAFR;;AAMA;EACI,aAAA;EACA,gBAAA;EACA,mBAAA;AAHJ;;AAMA;EACI,aAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;AAHJ;;AAMA;EACI,kCAAA;EACA,cAAA;EACA,4BAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAHJ;AAKI;EACI,mBAAA;AAHR;;AAOA;EACI,YAAA;AAJJ","sourcesContent":[".dcTop {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-bottom: 24px;\n\n    & .dcTitle {\n        font-weight: 600;\n        font-size: 24px;\n        margin: 0;\n    }\n}\n\n.dcMainContent {\n    height: 80%;\n    overflow: auto;\n    padding: 15px;\n    background: #fff;\n    border-radius: 16px;\n    display: flex;\n    flex-direction: column;\n\n    &::-webkit-scrollbar {\n        width: 8px;\n    }\n\n    &::-webkit-scrollbar-track {\n        background-color: transparent;\n    }\n\n    &::-webkit-scrollbar-thumb {\n        background-color: #e0e0f1;\n        border-radius: 100px;\n    }\n}\n\n.tableFiltersTop {\n    display: flex;\n    background: #fff;\n    margin-bottom: 16px;\n}\n\n.topToolbarSelectContainer {\n    display: flex;\n    align-items: center;\n    margin-left: auto;\n    column-gap: 8px;\n}\n\n.dropDownItem {\n    font-family: 'Poppins', sans-serif;\n    display: block;\n    padding: 12px 16px 12px 16px;\n    font-size: 14px;\n    color: #212121;\n    line-height: 24px;\n    font-weight: 400;\n\n    &:hover {\n        background: #f5f8ff;\n    }\n}\n\n.pagination {\n    margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dcTop": `DataCollector_dcTop__aVIJd`,
	"dcTitle": `DataCollector_dcTitle__oukO+`,
	"dcMainContent": `DataCollector_dcMainContent__0BhIY`,
	"tableFiltersTop": `DataCollector_tableFiltersTop__SAyBZ`,
	"topToolbarSelectContainer": `DataCollector_topToolbarSelectContainer__fpd+O`,
	"dropDownItem": `DataCollector_dropDownItem__DQch3`,
	"pagination": `DataCollector_pagination__ilwSY`
};
export default ___CSS_LOADER_EXPORT___;
