// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtpInput_otp_box__LMPZD {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-block: 24px;
}
.OtpInput_otp_box_input__Ux550 {
  width: 50px;
  height: 50px;
  text-align: center;
  border: 3px solid #bdbcdb;
  border-radius: 6px;
  font-size: 24px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/OtpInput/OtpInput.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;AACJ;AACI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AACR","sourcesContent":[".otp_box {\n    display: flex;\n    align-items: center;\n    gap: 10px;\n    margin-block: 24px;\n\n    &_input {\n        width: 50px;\n        height: 50px;\n        text-align: center;\n        border: 3px solid #bdbcdb;\n        border-radius: 6px;\n        font-size: 24px;\n        font-weight: 600;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otp_box": `OtpInput_otp_box__LMPZD`,
	"otp_box_input": `OtpInput_otp_box_input__Ux550`
};
export default ___CSS_LOADER_EXPORT___;
