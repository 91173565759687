import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {LOGIN_PAGE_PATH} from 'router/constants';
import CustomNavigate from 'components/CustomNavigate/CustomNavigate';
import Sidebar from './Sidebar/Sidebar';
import styles from './Layout.module.scss';
import './Sidebar/Sidebar.scss';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from 'components/ErrorFallback/ErrorFallback';
import IdleTimer from 'components/IdleTimer/IdleTimer';

const Layout = () => {
  const location = useLocation();
  if (location.pathname === '/') {
    return <Navigate to={LOGIN_PAGE_PATH} />;
  }

  return (
    <div>
      <CustomNavigate />
      <div className={styles.appWrapper}>
        <div className={styles.contentWrapper}>
          <Sidebar />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            key={location.pathname}
          >
            <main className="main-content">
              <Outlet />
            </main>
          </ErrorBoundary>
        </div>
      </div>
      <IdleTimer />
    </div>
  );
};

export default Layout;
