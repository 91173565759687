import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLazyGetBranchByIdQuery} from 'store/slices/branches';
import {Box, Button, CircularProgress, Tab, Tabs} from '@mui/material';
import {EditIcon, GoBackArrowButtonIcon} from 'assets/icons';
import InformationTab from './InformationTab/InformationTab';
import ActivityLogTab from './ActivityLogTab/ActivityLogTab';
import {editDealerButton, goBackButton} from 'styles/MUIStyles/buttons';
import {tab} from 'styles/MUIStyles/common';
import styles from './BranchesInformation.module.scss';
import {useAppSelector} from 'store/store';
import {checkAllowedRoles} from 'core/utils';
import {UserRole} from 'enums';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BranchesInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [getBranchById, {data: branchInfo, isFetching}] =
    useLazyGetBranchByIdQuery();

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const userRole = useAppSelector((state) => state.auth.role);
  const isAllowedRoleFor = checkAllowedRoles(
    [UserRole.Admin, UserRole.Dealer],
    userRole,
  );

  useEffect(() => {
    if (params?.branchId) {
      getBranchById(params.branchId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.branchId]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.branchInformationTop}>
        <h1 className={styles.branchInformationTitle}>Branch</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            onClick={() => {
              if (params?.branchId) {
                navigate('/branches/edit/' + params.branchId);
              }
            }}
            sx={editDealerButton}
          >
            Edit Info
          </Button>
        </div>
      </div>

      <div className={styles.branchInformationMainContent}>
        {isFetching ? (
          <Box sx={{display: 'flex', height: '100%'}}>
            <CircularProgress
              size={45}
              sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
            />
          </Box>
        ) : (
          <Box sx={{height: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
              }}
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Information" {...a11yProps(0)} />
              {isAllowedRoleFor && (
                <Tab sx={tab} label="Activity Log" {...a11yProps(1)} />
              )}
            </Tabs>
            <CustomTabPanel value={activeTab} index={0}>
              <InformationTab branchInfo={branchInfo} />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <ActivityLogTab branchInfo={branchInfo} />
            </CustomTabPanel>
          </Box>
        )}
      </div>
    </div>
  );
};

export default BranchesInformation;
