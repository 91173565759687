import React, {FC} from 'react';
import {IMetadata} from 'store/models/common';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  createTheme,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  ThemeProvider,
} from '@mui/material';
import {
  calendarStyles,
  chipStyles,
  muiSelectElementsCount,
  searchAndSelect,
  selectRowsPaperProps,
  stationsChipClear,
  stationsChipClearIcon,
} from '../../../../styles/MUIStyles/common';
import styles from '../../Logs.module.scss';
import search from '../../../../assets/icons/search.svg';
import {
  CalendarIcon,
  CheckedCheckboxIcon,
  CloseIcon,
  FilterIcon,
  SelectPopupIcon,
  UncheckedCheckboxIcon,
} from '../../../../assets/icons';
import {clearFiltersButton} from '../../../../styles/MUIStyles/buttons';
import {pageSizes} from '../../../../core/constants';
import {DateTimePicker} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import {IGetCompaniesListItem} from 'store/models/companies';
import {IGetBranchesListItem} from 'store/models/branches';
import {StationsListItem} from 'store/models/stations';

interface Props {
  posChassisNumber: string;
  setPosChassisNumber: (search: string) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  fiscalDeviceId: string;
  setFiscalDeviceId: (search: string) => void;
  alertsMessage: string;
  setAlertsMessage: (search: string) => void;
  alertsStartTime: string;
  setAlertsStartTime: (date: string | null) => void;
  alertsEndTime: string;
  setAlertsEndTime: (date: string | null) => void;
  isShowAlertsFilterButtonOpen: boolean;
  setIsShowAlertsFilterButtonOpen: (
    prevState: (isOpen: boolean) => boolean,
  ) => void;
  metaData: IMetadata | null;
  clearAlertsFilters: () => void;
  companies: IGetCompaniesListItem[];
  companiesForAlerts: IGetCompaniesListItem[];
  setCompaniesForAlerts: (companies: IGetCompaniesListItem[]) => void;
  branches: IGetBranchesListItem[];
  branchesForAlerts: IGetBranchesListItem[];
  setBranchesForAlerts: (branches: IGetBranchesListItem[]) => void;
  stations: StationsListItem[];
  stationsForAlerts: StationsListItem[];
  setStationsForAlerts: (stations: StationsListItem[]) => void;
}

const AlertsTopToolbar: FC<Props> = (props) => {
  const theme = createTheme({...muiSelectElementsCount});
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            justifyContent: 'space-between',
            marginBottom: '14px',
          }}
        >
          <Box
            sx={{
              width: '75%',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <div className={styles.logsTopToolbarSearch}>
              <input
                name="alertsMessageSearch"
                type="text"
                className="input"
                placeholder="Search"
                autoComplete="off"
                value={props.alertsMessage}
                onChange={(e) => props.setAlertsMessage(e.target.value)}
              />
              <img
                className={styles.searchIcon}
                src={search}
                alt="search icon"
              />
            </div>
            <Button
              variant="text"
              startIcon={
                <FilterIcon
                  style={{
                    width: '16px',
                    height: '16px',
                    filter:
                      'invert(25%) sepia(81%) saturate(1632%) hue-rotate(213deg) brightness(95%) contrast(95%)',
                  }}
                />
              }
              sx={clearFiltersButton}
              onClick={() => {
                props.setIsShowAlertsFilterButtonOpen(
                  (prevState) => !prevState,
                );
              }}
            >
              {props.isShowAlertsFilterButtonOpen
                ? 'Hide filters'
                : 'Show filters'}
            </Button>
          </Box>

          <div className={styles.topToolbarSelectContainer}>
            {props.metaData && (
              <>
                <span>Show rows:</span>
                <FormControl size="small">
                  <Select
                    name="logsPageCount"
                    value={+props.pageSize}
                    onChange={(e) => {
                      props.setPageSize(+e.target.value);
                    }}
                    IconComponent={(props) => <SelectPopupIcon {...props} />}
                    MenuProps={{
                      MenuListProps: {disablePadding: true},
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      PaperProps: selectRowsPaperProps,
                    }}
                  >
                    {pageSizes.map((page) => {
                      return (
                        <MenuItem key={page} value={page}>
                          {page}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <span>{`${props.metaData?.firstItemOnPage}-${props.metaData?.lastItemOnPage} of ${props.metaData?.totalItemCount}`}</span>
              </>
            )}
          </div>
        </Box>

        {props.isShowAlertsFilterButtonOpen && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                gap: '14px',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <div className={styles.alertsDateTimePicker}>
                  <span>Start time(dd.mm.yy hh:mm)</span>
                  <DateTimePicker
                    inputFormat="DD.MM.YY HH:mm"
                    value={props.alertsStartTime}
                    ampm={false}
                    onChange={(newValue) => {
                      if (dayjs(newValue).isValid()) {
                        props.setAlertsStartTime(
                          dayjs(newValue).utc().format(),
                        );
                      }

                      if (newValue === null) {
                        props.setAlertsStartTime(null);
                      }
                    }}
                    components={{
                      OpenPickerIcon: CalendarIcon,
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                        style: {justifyContent: 'flex-end'},
                      },
                    }}
                    minutesStep={1}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{
                          ...calendarStyles,
                          width: '100%',
                          padding: '2px 0 0 0',
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Select start date',
                          autoComplete: 'off',
                        }}
                      />
                    )}
                  />
                </div>

                <div className={styles.alertsDateTimePicker}>
                  <span>End time(dd.mm.yy hh:mm)</span>
                  <DateTimePicker
                    inputFormat="DD.MM.YY HH:mm"
                    value={props.alertsEndTime}
                    ampm={false}
                    onChange={(newValue) => {
                      if (dayjs(newValue).isValid()) {
                        props.setAlertsEndTime(dayjs(newValue).utc().format());
                      }

                      if (newValue === null) {
                        props.setAlertsEndTime(null);
                      }
                    }}
                    components={{
                      OpenPickerIcon: CalendarIcon,
                    }}
                    componentsProps={{
                      actionBar: {
                        actions: ['clear'],
                        style: {justifyContent: 'flex-end'},
                      },
                    }}
                    minutesStep={1}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{...calendarStyles, width: '100%'}}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'Select end date',
                          autoComplete: 'off',
                        }}
                      />
                    )}
                  />
                </div>

                <div className={styles.alertsTopToolbarSearch}>
                  <input
                    name="possChasisSearch"
                    type="text"
                    className="input"
                    placeholder="PosChassis number"
                    autoComplete="off"
                    value={props.posChassisNumber}
                    onChange={(e) => props.setPosChassisNumber(e.target.value)}
                  />
                  <img
                    className={styles.searchIcon}
                    src={search}
                    alt="search icon"
                  />
                </div>

                <div className={styles.alertsTopToolbarSearch}>
                  <input
                    name="fiscalDeviceIdSearch"
                    type="text"
                    className="input"
                    placeholder="FiscalDevice id"
                    autoComplete="off"
                    value={props.fiscalDeviceId}
                    onChange={(e) => props.setFiscalDeviceId(e.target.value)}
                  />
                  <img
                    className={styles.searchIcon}
                    src={search}
                    alt="search icon"
                  />
                </div>
              </Box>
              <Button
                variant="text"
                startIcon={<CloseIcon style={stationsChipClearIcon} />}
                sx={{...stationsChipClear, marginLeft: 'auto'}}
                onClick={props.clearAlertsFilters}
              >
                Clear
              </Button>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                gap: '8px',
              }}
            >
              <Autocomplete
                multiple
                limitTags={1}
                options={props.companies || []}
                loading={false}
                size="small"
                popupIcon={<SelectPopupIcon />}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      borderRadius: '12px',
                      boxShadow: '0px 4px 20px 0px #4444441F',
                      marginTop: '8px',
                      cursor: 'pointer',
                    }}
                    {...props}
                  />
                )}
                sx={{width: '33.3%'}}
                value={props.companiesForAlerts}
                ListboxProps={{
                  style: {maxHeight: '300px'},
                  className: 'autoCompleteListBox',
                }}
                isOptionEqualToValue={(option, value) =>
                  value.name === '' ? true : option.id === value.id
                }
                getOptionLabel={(option) => (option?.name ? option.name : '')}
                onChange={(_, value) => {
                  props.setCompaniesForAlerts([]);

                  if (value) {
                    props.setCompaniesForAlerts(value);
                  }
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({index})}
                      size="small"
                      label={option.name}
                      sx={chipStyles}
                      deleteIcon={
                        <CloseIcon style={{width: '24px', height: '24px'}} />
                      }
                    />
                  ))
                }
                renderOption={(props: any, option, {selected}) => (
                  <span {...props} className={styles.dropDownItem}>
                    <Checkbox
                      icon={<UncheckedCheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </span>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select company"
                    sx={searchAndSelect}
                  />
                )}
              />
              <Autocomplete
                multiple
                limitTags={1}
                options={props.branches || []}
                loading={false}
                size="small"
                popupIcon={<SelectPopupIcon />}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      borderRadius: '12px',
                      boxShadow: '0px 4px 20px 0px #4444441F',
                      marginTop: '8px',
                      cursor: 'pointer',
                    }}
                    {...props}
                  />
                )}
                sx={{width: '33.3%'}}
                value={props.branchesForAlerts}
                ListboxProps={{
                  style: {maxHeight: '300px'},
                  className: 'autoCompleteListBox',
                }}
                isOptionEqualToValue={(option, value) =>
                  value.name === '' ? true : option.id === value.id
                }
                getOptionLabel={(option) => (option?.name ? option.name : '')}
                onChange={(_, value) => {
                  if (value) {
                    props.setBranchesForAlerts(value);
                  }
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({index})}
                      size="small"
                      label={option.name}
                      sx={chipStyles}
                      deleteIcon={
                        <CloseIcon style={{width: '24px', height: '24px'}} />
                      }
                    />
                  ))
                }
                renderOption={(props: any, option, {selected}) => (
                  <span {...props} className={styles.dropDownItem}>
                    <Checkbox
                      icon={<UncheckedCheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name}
                  </span>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select branch"
                    sx={searchAndSelect}
                  />
                )}
              />
              <Autocomplete
                multiple
                limitTags={1}
                options={props.stations || []}
                loading={false}
                size="small"
                popupIcon={<SelectPopupIcon />}
                PaperComponent={(props) => (
                  <Paper
                    sx={{
                      borderRadius: '12px',
                      boxShadow: '0px 4px 20px 0px #4444441F',
                      marginTop: '12px',
                      cursor: 'pointer',
                    }}
                    {...props}
                  />
                )}
                sx={{
                  width: '33.3%',
                }}
                disableCloseOnSelect
                ListboxProps={{
                  style: {maxHeight: '200px'},
                  className: 'autoCompleteListBox',
                }}
                isOptionEqualToValue={(option, value) =>
                  value.name === '' ? true : option.id === value.id
                }
                getOptionLabel={(option) =>
                  `${option.name}/${option.posChassicNumber}`
                }
                value={props.stationsForAlerts}
                onChange={(_, value) => {
                  if (value) {
                    props.setStationsForAlerts(value);
                  }
                }}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({index})}
                      size="small"
                      label={`${option.name}/${option.posChassicNumber}`}
                      sx={chipStyles}
                      deleteIcon={
                        <CloseIcon style={{width: '24px', height: '24px'}} />
                      }
                    />
                  ))
                }
                renderOption={(props: any, option, {selected}) => (
                  <span {...props} className={styles.dropDownItem}>
                    <Checkbox
                      icon={<UncheckedCheckboxIcon />}
                      checkedIcon={<CheckedCheckboxIcon />}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.name} / {option.posChassicNumber}
                  </span>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    placeholder="Select station/posChassisNumber"
                    sx={searchAndSelect}
                  />
                )}
              />
            </Box>
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default AlertsTopToolbar;
