import axios from 'axios';
import {UserRole} from '../enums';

export const calculateItemNumbers = (
  firstItemOnPage: number,
  lastItemOnPage: number,
) => {
  const items: number[] = [];

  for (let i = firstItemOnPage; i <= lastItemOnPage; i++) {
    items.push(i);
  }

  return items;
};

export const downloadFile = async (
  url: string,
  setDownloadAllBackupsLoading?: (isLoading: boolean) => void,
) => {
  if (setDownloadAllBackupsLoading) {
    setDownloadAllBackupsLoading(true);
  }
  try {
    const response = await axios.get(url, {responseType: 'blob'});
    const urlParts = url.split('/');
    const fileName = urlParts[urlParts.length - 1];

    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error(`Ошибка при скачивании файла из ${url}: ${error}`);
  } finally {
    if (setDownloadAllBackupsLoading) {
      setDownloadAllBackupsLoading(false);
    }
  }
};

export const downloadMultipleFiles = async (
  backupLinksArray: string[],
  setDownloadFileLoading: (loading: boolean) => void,
) => {
  setDownloadFileLoading(true);
  const promises = backupLinksArray.map((backupLink) =>
    downloadFile(backupLink),
  );
  await Promise.allSettled(promises);
  setDownloadFileLoading(false);
};

export const downloadAllBackupFiles = async (
  backups: {
    fileLink: string;
  }[],
  setDownloadFileLoading: (loading: boolean) => void,
) => {
  setDownloadFileLoading(true);
  const promises = backups.map((backup) => downloadFile(backup.fileLink));
  await Promise.allSettled(promises);
  setDownloadFileLoading(false);
};

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const checkAllowedRoles = (
  allowedRoles: UserRole[],
  userRole: string | null,
) => {
  return allowedRoles.some((allowedRole) => allowedRole === userRole);
};
