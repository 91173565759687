// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyOwnersTable_companyOwnerStatus__fmJls {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  border-radius: 12px;
  padding: 4px 8px 4px 8px;
}

.CompanyOwnersTable_companyOwnerStatus__fmJls.CompanyOwnersTable_active__UclsS {
  color: #31aa27;
  background-color: #ebf6eb;
}

.CompanyOwnersTable_companyOwnerStatus__fmJls.CompanyOwnersTable_inactive__7wpzW {
  color: #7774B6;
  background-color: #DFE4FD;
}

.CompanyOwnersTable_deleted__\\+7aMX {
  color: #f93232;
  background-color: #fff0f0;
}

.CompanyOwnersTable_dealersResetPasswordMainTitle__46aMJ {
  font-weight: 600;
  line-height: 40px;
  font-size: 24px;
  color: #212121;
  margin: 0;
}

.CompanyOwnersTable_closeIcon__GBnip {
  cursor: pointer;
}

.CompanyOwnersTable_removeTextContainer__cLhSA {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.CompanyOwnersTable_removeTextContainer__cLhSA > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
}

.CompanyOwnersTable_buttonsContainer__eYM9V {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/CompanyOwners/CompanyOwnersTable/CompanyOwnersTable.module.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;EACA,wBAAA;AACJ;;AAEA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,yBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,mBAAA;AACR;;AAGA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAAJ","sourcesContent":[".companyOwnerStatus {\n    display: inline-block;\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 20px;\n    border-radius: 12px;\n    padding: 4px 8px 4px 8px;\n}\n\n.companyOwnerStatus.active {\n    color: #31aa27;\n    background-color: #ebf6eb;\n}\n\n.companyOwnerStatus.inactive {\n    color: #7774B6;\n    background-color: #DFE4FD;\n}\n\n.deleted {\n    color: #f93232;\n    background-color: #fff0f0;\n}\n\n.dealersResetPasswordMainTitle {\n    font-weight: 600;\n    line-height: 40px;\n    font-size: 24px;\n    color: #212121;\n    margin: 0;\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n\n.removeTextContainer {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 24px;\n\n    & > span {\n        font-weight: 400;\n        font-size: 18px;\n        line-height: 25.2px;\n    }\n}\n\n.buttonsContainer {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyOwnerStatus": `CompanyOwnersTable_companyOwnerStatus__fmJls`,
	"active": `CompanyOwnersTable_active__UclsS`,
	"inactive": `CompanyOwnersTable_inactive__7wpzW`,
	"deleted": `CompanyOwnersTable_deleted__+7aMX`,
	"dealersResetPasswordMainTitle": `CompanyOwnersTable_dealersResetPasswordMainTitle__46aMJ`,
	"closeIcon": `CompanyOwnersTable_closeIcon__GBnip`,
	"removeTextContainer": `CompanyOwnersTable_removeTextContainer__cLhSA`,
	"buttonsContainer": `CompanyOwnersTable_buttonsContainer__eYM9V`
};
export default ___CSS_LOADER_EXPORT___;
