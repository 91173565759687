import React, {FC, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Controller, useFormContext} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {useUpdateEffect} from 'hooks';
import styles from '../StationsEdit.module.scss';
import {ISnackbarState} from 'store/models/common';
import {
  useDeleteBranchFromStationMutation,
  useDeleteCompanyFromStationMutation,
} from 'store/slices/stations';
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Stack,
  styled,
  Switch,
} from '@mui/material';
import WarningModal from 'components/WarningModal/WarningModal';
import {CloseIcon, PlusIcon, SuccessNotification} from 'assets/icons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {addBranchInEdit} from 'styles/MUIStyles/buttons';
import {IGetStationById} from 'store/models/stations';
import {androidSwitch, switchLabel} from 'styles/MUIStyles/common';
import StationsAddCompany from '../../StationsAddCompany/StationsAddCompany';
import StationsAddBranch from '../../StationsAddBranch/StationsAddBranch';
import {useAppSelector} from 'store/store';
import {checkAllowedRoles} from 'core/utils';
import {UserRole} from 'enums';
import Guard from 'components/Guard/Guard';

const AndroidSwitch = styled(Switch)(() => androidSwitch);

interface Props {
  stationData: IGetStationById | undefined;
}

const MainInfoTab: FC<Props> = (props) => {
  const params = useParams();
  const userRole = useAppSelector((state) => state.auth.role);
  const isAllowedRoleFor = checkAllowedRoles([UserRole.Admin], userRole);
  const isAllowedRoleForOwner = checkAllowedRoles([UserRole.Owner], userRole);

  const {
    register,
    control,
    formState: {errors},
  } = useFormContext();

  const [companyToDelete, _] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [branchToDelete, setBranchToDelete] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [isDeleteCompanyModalOpen, setIsDeleteCompanyModalOpen] =
    useState(false);
  const [isDeleteBranchModalOpen, setIsDeleteBranchModalOpen] = useState(false);
  const [isAddCompanyToStationModalOpen, setIsAddCompanyToStationModalOpen] =
    useState(false);
  const [isAddBranchToStationModalOpen, setIsAddBranchToCompanyModalOpen] =
    useState(false);
  const [deleteBranchFromStationSnackbar, setDeleteBranchFromStationSnackbar] =
    React.useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [
    deleteCompanyFromStationSnackbar,
    setDeleteCompanyFromStationSnackbar,
  ] = React.useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {
    vertical: deleteBranchVertical,
    horizontal: deleteBranchHorizontal,
    open: isDeleteBranchOpen,
  } = deleteBranchFromStationSnackbar;
  const {
    vertical: deleteCompanyVertical,
    horizontal: deleteCompanyHorizontal,
    open: isDeleteCompanyOpen,
  } = deleteCompanyFromStationSnackbar;

  const [
    deleteBranch,
    {isLoading: isDeletingBranchLoading, isSuccess: isDeletingBranchSuccess},
  ] = useDeleteBranchFromStationMutation();

  const [
    deleteCompany,
    {isLoading: isDeletingCompanyLoading, isSuccess: isDeletingCompanySuccess},
  ] = useDeleteCompanyFromStationMutation();

  const handleSnackbarClose = () => {
    setDeleteBranchFromStationSnackbar({
      ...deleteBranchFromStationSnackbar,
      open: false,
    });
    setDeleteCompanyFromStationSnackbar({
      ...deleteCompanyFromStationSnackbar,
      open: false,
    });
  };

  useUpdateEffect(() => {
    if (isDeletingBranchSuccess) {
      setDeleteBranchFromStationSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteBranchModalOpen(false);
    }

    if (isDeletingCompanySuccess) {
      setDeleteCompanyFromStationSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteCompanyModalOpen(false);
    }
  }, [isDeletingBranchSuccess, isDeletingCompanySuccess]);

  return (
    <div>
      <div className={styles.nameInputContainer}>
        <label htmlFor="name">Name station</label>
        <input
          {...register('name', {required: 'This field is required'})}
          type="text"
          id="name"
          autoComplete="off"
          className={`${errors?.name ? 'input inputError' : 'input'}`}
          placeholder="Enter name station"
        />
        <ErrorMessage
          name="name"
          errors={errors}
          render={({message}) => (
            <span className={styles.inputErrorMessage}>{message}</span>
          )}
        />
      </div>

      <div className={styles.fiscalDeviceIdInputContainer}>
        <label htmlFor="fiscalDeviceId">Fiscal device id</label>
        <input
          {...register('fiscalDeviceId', {required: 'This field is required'})}
          type="text"
          id="fiscalDeviceId"
          autoComplete="off"
          className={`${errors?.name ? 'input inputError' : 'input'}`}
          placeholder="Enter fiscal device id"
          disabled={isAllowedRoleFor ? false : true}
        />
        <ErrorMessage
          name="fiscalDeviceId"
          errors={errors}
          render={({message}) => (
            <span className={styles.inputErrorMessage}>{message}</span>
          )}
        />
      </div>

      <div className={styles.passChassicNumberInputContainer}>
        <label htmlFor="posChassicNumber">PosChassis number</label>
        <input
          {...register('posChassicNumber', {
            required: 'This field is required',
          })}
          type="text"
          id="posChassicNumber"
          autoComplete="off"
          className={`${errors?.name ? 'input inputError' : 'input'}`}
          placeholder="Enter posChassis number"
          disabled={isAllowedRoleFor ? false : true}
        />
        <ErrorMessage
          name="posChassicNumber"
          errors={errors}
          render={({message}) => (
            <span className={styles.inputErrorMessage}>{message}</span>
          )}
        />
      </div>

      {/* {props?.stationData?.companies?.length ? (
        <>
          <div className={styles.stationEditCompaniesList}>
            <h3>Companies</h3>
            {props?.stationData?.companies.map((company) => (
              <div key={company.id} className={styles.companiesListItem}>
                <span>{company.name}</span>
                {company.name !== 'All' ? (
                  <Button
                    variant="text"
                    onClick={() => {
                      setCompanyToDelete(company);
                      setIsDeleteCompanyModalOpen(true);
                    }}
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      textTransform: 'capitalize',
                      color: '#3152C7',
                      lineHeight: '16px',
                      fontSize: '14px',
                    }}
                  >
                    Remove
                  </Button>
                ) : null}
              </div>
            ))}
          </div>
        </>
      ) : null}

      <div>
        <Button
          variant="text"
          startIcon={<PlusIcon />}
          sx={addBranchInEdit}
          onClick={() => setIsAddCompanyToStationModalOpen(true)}
        >
          Add company
        </Button>
      </div> */}

      <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
        <>
          {props?.stationData?.branches.length ? (
            <>
              <div className={styles.stationEditBranchesList}>
                <h3>Branches</h3>
                {props?.stationData?.branches.map((branch) => (
                  <div key={branch.id} className={styles.branchesListItem}>
                    <span>{branch.name}</span>
                    <Button
                      variant="text"
                      onClick={() => {
                        setBranchToDelete(branch);
                        setIsDeleteBranchModalOpen(true);
                      }}
                      disabled={isAllowedRoleForOwner}
                      sx={{
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'capitalize',
                        color: '#3152C7',
                        lineHeight: '16px',
                        fontSize: '14px',
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          <div>
            <Button
              variant="text"
              startIcon={<PlusIcon />}
              sx={addBranchInEdit}
              onClick={() => setIsAddBranchToCompanyModalOpen(true)}
              disabled={isAllowedRoleForOwner}
            >
              Add branch
            </Button>
          </div>
        </>
      </Guard>

      <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
        <div className={styles.stationMainInfoEditControls}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Controller
              name={'isActive'}
              control={control}
              render={({field}) => (
                <FormGroup>
                  <FormControlLabel
                    sx={switchLabel}
                    disabled={isAllowedRoleForOwner}
                    control={<AndroidSwitch {...field} checked={field.value} />}
                    label={field.value ? 'active' : 'inactive'}
                  />
                </FormGroup>
              )}
            />
          </Stack>
        </div>
      </Guard>

      {branchToDelete && params?.stationId ? (
        <>
          <WarningModal
            isOpen={isDeleteBranchModalOpen}
            onClose={() => setIsDeleteBranchModalOpen(false)}
            entityText={''}
            title={'Remove branch ?'}
            textBlocks={[
              `Are you sure you want to remove branch ${branchToDelete.name} ?`,
            ]}
            confirmButtonText={'Yes, remove'}
            onConfirmButtonClick={() => {
              if (params?.stationId && branchToDelete?.name) {
                deleteBranch({
                  stationId: params.stationId,
                  branchId: branchToDelete.id,
                });
              }
            }}
            loading={isDeletingBranchLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: deleteBranchVertical,
              horizontal: deleteBranchHorizontal,
            }}
            autoHideDuration={2500}
            open={isDeleteBranchOpen}
            onClose={handleSnackbarClose}
            key={'deleteBranch'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Branch {`"${branchToDelete.name}"`} was removed
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {companyToDelete && params?.stationId ? (
        <>
          <WarningModal
            isOpen={isDeleteCompanyModalOpen}
            onClose={() => setIsDeleteCompanyModalOpen(false)}
            entityText={''}
            title={'Remove company ?'}
            textBlocks={[
              `Are you sure you want to remove company ${companyToDelete.name} ?`,
            ]}
            confirmButtonText={'Yes, remove'}
            onConfirmButtonClick={() => {
              if (params?.stationId && companyToDelete?.name) {
                deleteCompany({
                  stationId: params.stationId,
                  companyId: companyToDelete.id,
                });
              }
            }}
            loading={isDeletingCompanyLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: deleteCompanyVertical,
              horizontal: deleteCompanyHorizontal,
            }}
            autoHideDuration={2500}
            open={isDeleteCompanyOpen}
            onClose={handleSnackbarClose}
            key={'deleteCompany'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Company {`"${companyToDelete.name}"`} was removed
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {props?.stationData && (
        <StationsAddCompany
          isOpen={isAddCompanyToStationModalOpen}
          isStopPropogation={true}
          onClose={() => setIsAddCompanyToStationModalOpen(false)}
          clickedStation={{
            id: props?.stationData?.id,
            name: props?.stationData?.name,
            posChassicNumber: props?.stationData?.posChassicNumber,
            fiscalDeviceId: props?.stationData?.fiscalDeviceId,
            isActive: props?.stationData?.isActive,
            isDeleted: props?.stationData?.isDeleted,
            stationTypeName: props?.stationData?.stationTypeName,
          }}
        />
      )}
      {props?.stationData && (
        <StationsAddBranch
          isOpen={isAddBranchToStationModalOpen}
          isStopPropogation={true}
          onClose={() => setIsAddBranchToCompanyModalOpen(false)}
          clickedStation={{
            id: props?.stationData?.id,
            name: props?.stationData?.name,
            posChassicNumber: props?.stationData?.posChassicNumber,
            fiscalDeviceId: props?.stationData?.fiscalDeviceId,
            isActive: props?.stationData?.isActive,
            isDeleted: props?.stationData?.isDeleted,
            stationTypeName: props?.stationData?.stationTypeName,
          }}
        />
      )}
    </div>
  );
};

export default MainInfoTab;
