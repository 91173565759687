import {Button} from '@mui/material';
import {CreateIcon} from 'assets/icons';
import {FC, MutableRefObject, useRef} from 'react';
import {useUploadDCVersionMutation} from 'store/slices/dataCollector';
import {createButton} from 'styles/MUIStyles/buttons';

interface UploadDCVersionButtonProps {}

const UploadDCVersionButton: FC<UploadDCVersionButtonProps> = () => {
  const [uploadFile] = useUploadDCVersionMutation();

  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleUploadDCVersion = async (e: any) => {
    const fd = new FormData();

    const archiveName = e.target.files[0].name;

    fd.append('dataCollector', e.target.files[0], archiveName);

    await uploadFile(fd);
  };

  const openFileUploadModal = () => {
    inputRef.current.click();
  };

  return (
    <>
      <input
        ref={inputRef}
        hidden
        type="file"
        accept=".zip"
        onChange={handleUploadDCVersion}
      />
      <Button
        variant="contained"
        startIcon={<CreateIcon />}
        onClick={openFileUploadModal}
        sx={createButton}
      >
        Add new versions
      </Button>
    </>
  );
};

export default UploadDCVersionButton;
