import React, {FC, useEffect, useId, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {useUpdateEffect} from 'hooks';
import {ISnackbarState} from 'store/models/common';
import {
  useEditStationMutation,
  useLazyGetDefaultManifestQuery,
  useLazyGetStationByIdQuery,
} from 'store/slices/stations';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Tab,
  Tabs,
} from '@mui/material';
import MainInfoTab from './MainInfoTab/MainInfoTab';
import ManifestTab from './ManifestTab/ManifestTab';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {
  CloseIcon,
  ErrorNotification,
  GoBackArrowButtonIcon,
  SaveIcon,
  SuccessNotification,
} from 'assets/icons';
import {createButton, goBackButton} from 'styles/MUIStyles/buttons';
import {tab} from 'styles/MUIStyles/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './StationsEdit.module.scss';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';
import Guard from 'components/Guard/Guard';
import {UserRole} from 'enums';
import {useAppSelector} from 'store/store';
import {checkAllowedRoles} from 'core/utils';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IMainInfoForm {
  name: string;
  posChassicNumber: string;
  fiscalDeviceId: string;
  isActive: boolean;
}

const StationsEdit: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.role);

  const isAllowedRoleFor = checkAllowedRoles(
    [UserRole.Admin, UserRole.Dealer],
    userRole,
  );

  const methods = useForm<IMainInfoForm>({
    defaultValues: {
      name: '',
      posChassicNumber: '',
      fiscalDeviceId: '',
      isActive: false,
    },
  });

  const [
    getStationById,
    {data: stationData, isLoading: isGettingStationDataLoading},
  ] = useLazyGetStationByIdQuery();

  const [
    getDefaultManifest,
    {
      data: defaultManifest,
      isLoading: isGettingDefaultManifestLoading,
      isSuccess: isGettingDefaultManifestSuccess,
    },
  ] = useLazyGetDefaultManifestQuery();

  const [
    editStation,
    {
      isLoading: isEditingStationLoading,
      isSuccess: isEditingStationSuccess,
      error: editStationError,
    },
  ] = useEditStationMutation();

  const stationEditFormId = useId();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [editStationSnackbarError, setEditStationSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [editStationSnackbarSuccess, setEditStationSnackbarSuccess] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: editVerticalError,
    horizontal: editHorizontalError,
    open: isEditOpenError,
  } = editStationSnackbarError;
  const {
    vertical: editStationSuccessVertical,
    horizontal: editStationSuccessHorizontal,
    open: isEditStationSuccessOpen,
  } = editStationSnackbarSuccess;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleSnackbarClose = () => {
    setEditStationSnackbarSuccess({...editStationSnackbarSuccess, open: false});
    setEditStationSnackbarError({...editStationSnackbarError, open: false});
    setErrorMessage(null);
  };

  const onSubmit = (formData: IMainInfoForm) => {
    if (stationData) {
      editStation({
        ...stationData,
        manifest: {
          commands: defaultManifest
            ? defaultManifest.commands
            : stationData.manifest.commands,
          id: stationData.manifest.id,
        },
        id: stationData.id,
        name: formData.name,
        posChassicNumber: formData.posChassicNumber,
        fiscalDeviceId: formData.fiscalDeviceId,
        isActive: formData.isActive,
      });
    }
  };

  useEffect(() => {
    if (params?.stationId) {
      getStationById(params.stationId);
    }
  }, [params, getStationById]);

  useEffect(() => {
    if (stationData) {
      methods.reset({
        name: stationData.name,
        fiscalDeviceId: stationData.fiscalDeviceId,
        posChassicNumber: stationData.posChassicNumber,
        isActive: stationData.isActive,
      });
    }
  }, [stationData, methods]);

  useUpdateEffect(() => {
    if (isEditingStationSuccess) {
      setEditStationSnackbarSuccess({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [isEditingStationSuccess]);

  useUpdateEffect(() => {
    if (
      editStationError &&
      'data' in editStationError &&
      'status' in editStationError
    ) {
      if (editStationError.status === 400 || editStationError.status === 404) {
        const errors = editStationError.data?.errors;

        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            //@ts-ignore
            setErrorMessage(errors[key]);
            setEditStationSnackbarError({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  }, [editStationError]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.stationEditInformationTop}>
        <h1 className={styles.stationEditInformationTitle}>Station</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <ButtonWithProgress
            type="submit"
            startIcon={
              isEditingStationLoading ? (
                <LoadingIconSpacer width={'16px'} height={'16px'} />
              ) : (
                <SaveIcon />
              )
            }
            loading={isEditingStationLoading}
            disabled={isEditingStationLoading}
            form={stationEditFormId}
            sx={createButton}
          >
            Save
          </ButtonWithProgress>
        </div>
      </div>
      <div className={styles.stationInformationMainContent}>
        {isGettingStationDataLoading ? (
          <Box sx={{display: 'flex', height: '100%'}}>
            <CircularProgress
              size={45}
              sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
            />
          </Box>
        ) : (
          <Box sx={{height: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
              }}
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Main info" {...a11yProps(0)} />
              {isAllowedRoleFor && (
                <Tab sx={tab} label="Manifest" {...a11yProps(1)} />
              )}
            </Tabs>
            <CustomTabPanel value={activeTab} index={0}>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  id={stationEditFormId}
                >
                  <MainInfoTab stationData={stationData} />
                </form>
              </FormProvider>
            </CustomTabPanel>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <CustomTabPanel value={activeTab} index={1}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  id={stationEditFormId}
                >
                  <ManifestTab
                    stationData={stationData}
                    getDataFromMainInfo={methods.getValues}
                    getDefaultManifest={getDefaultManifest}
                    defaultManifest={defaultManifest}
                    isGettingDefaultManifestSuccess={
                      isGettingDefaultManifestSuccess
                    }
                    isGettingDefaultManifestLoading={
                      isGettingDefaultManifestLoading
                    }
                  />
                </form>
              </CustomTabPanel>
            </Guard>
          </Box>
        )}
      </div>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: editVerticalError,
            horizontal: editHorizontalError,
          }}
          autoHideDuration={3500}
          open={isEditOpenError}
          onClose={handleSnackbarClose}
          key={'editStationError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}

      <Snackbar
        anchorOrigin={{
          vertical: editStationSuccessVertical,
          horizontal: editStationSuccessHorizontal,
        }}
        autoHideDuration={2500}
        open={isEditStationSuccessOpen}
        onClose={handleSnackbarClose}
        key={'editStationSuccess'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            The data was successfully saved
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default StationsEdit;
