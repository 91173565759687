// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/icons/Background_Complete.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._404_pageNotFound__gldnp {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 32px);
  width: 100%;
}

._404_pageNotFoundContainer__Jm6du {
  width: 60%;
  height: 60%;
  text-align: center;
  align-items: center;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center -10% no-repeat;
  background-size: 70%;
  padding: 20px;
  border-radius: 10px;
}

._404_pageNotFoundHeading__muNNI {
  margin-top: 65px;
  font-size: 180px;
  font-weight: 600;
  line-height: 160px;
  letter-spacing: 0em;
  color: #3152c7;
}

._404_pageNotFoundTextPrimary__XxM63 {
  font-size: 52px;
  font-weight: 500;
  line-height: 78px;
  letter-spacing: 0px;
  color: #000000;
}

._404_pageNotFoundTextSecondary__WHMPM {
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  color: #8a8894;
  margin-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./src/views/404/404.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,0BAAA;EACA,WAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,yEAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AAEF","sourcesContent":[".pageNotFound {\n  background: #fff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: calc(100vh - 32px);\n  width: 100%;\n}\n\n.pageNotFoundContainer {\n  width: 60%;\n  height: 60%;\n  text-align: center;\n  align-items: center;\n  background: url('../../assets/icons/Background_Complete.svg') center -10% no-repeat;\n  background-size: 70%;\n  padding: 20px;\n  border-radius: 10px;\n}\n\n.pageNotFoundHeading {\n  margin-top: 65px;\n  font-size: 180px;\n  font-weight: 600;\n  line-height: 160px;\n  letter-spacing: 0em;\n  color: #3152c7;\n}\n\n.pageNotFoundTextPrimary {\n  font-size: 52px;\n  font-weight: 500;\n  line-height: 78px;\n  letter-spacing: 0px;\n  color: #000000;\n}\n.pageNotFoundTextSecondary {\n  font-size: 32px;\n  font-weight: 400;\n  line-height: 48px;\n  letter-spacing: 0px;\n  text-align: center;\n  color: #8a8894;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageNotFound": `_404_pageNotFound__gldnp`,
	"pageNotFoundContainer": `_404_pageNotFoundContainer__Jm6du`,
	"pageNotFoundHeading": `_404_pageNotFoundHeading__muNNI`,
	"pageNotFoundTextPrimary": `_404_pageNotFoundTextPrimary__XxM63`,
	"pageNotFoundTextSecondary": `_404_pageNotFoundTextSecondary__WHMPM`
};
export default ___CSS_LOADER_EXPORT___;
