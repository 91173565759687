import React, {ChangeEvent, FC, useState} from 'react';
import {
  useDebounce,
  useLocalStorage,
  useSortColumn,
  useUpdateEffect,
} from 'hooks';
import {useGetCompaniesQuery} from 'store/slices/companies';
import CompaniesTable from 'views/Companies/CompaniesTable/CompaniesTable';
import CompaniesTopToolbar from './CompaniesTopToolbar/CompaniesTopToolbar';
import {Button, Pagination, PaginationItem} from '@mui/material';
import {
  CreateIcon,
  PaginationArrowLeft,
  PaginationArrowRight,
} from 'assets/icons';
import CompaniesCreate from './CompaniesCreate/CompaniesCreate';
import {createButton} from 'styles/MUIStyles/buttons';
import {pagination} from 'styles/MUIStyles/common';
import styles from './Companies.module.scss';

const Companies: FC = () => {
  const [isCreateCompanyModalOpen, setIsCreateCompanyModalOpen] =
    useState(false);
  const [pageNumber, setPageNumber] = useLocalStorage('companiesPageNumber', 1);
  const [pageSize, setPageSize] = useLocalStorage('companiesPageSize', 10);
  const [search, setSearch] = useLocalStorage('companiesSearch', '');
  const [sortConfig, handleColumnClick] = useSortColumn('companiesSortConfig');
  const debouncedSearch = useDebounce(search, 800);

  const {data: companiesData} = useGetCompaniesQuery({
    pageSize,
    pageNumber,
    search: debouncedSearch,
    sortBy: sortConfig,
  });

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  useUpdateEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1);
    }
  }, [debouncedSearch, pageSize]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.companiesTop}>
        <h1 className={styles.companiesTitle}>Companies</h1>
        <Button
          variant="contained"
          startIcon={<CreateIcon />}
          onClick={() => setIsCreateCompanyModalOpen(true)}
          sx={createButton}
        >
          Create
        </Button>
      </div>
      <div className={styles.companiesMainContent}>
        <div className={styles.tableFiltersTop}>
          <CompaniesTopToolbar
            search={search}
            setSearch={setSearch}
            pageSize={pageSize}
            setPageSize={setPageSize}
            metaData={companiesData?.metadata || null}
          />
        </div>
        <div style={{height: '87%', flexGrow: 1}}>
          <CompaniesTable
            companies={companiesData?.data || []}
            metaData={companiesData?.metadata || null}
            search={debouncedSearch}
            sortConfig={sortConfig}
            handleColumnClick={handleColumnClick}
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={companiesData?.metadata?.pageCount || 1}
          page={pageNumber}
          onChange={handleOnPageChange}
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>

      <CompaniesCreate
        isCreateCompanyModalOpen={isCreateCompanyModalOpen}
        setIsCreateCompanyModalOpen={setIsCreateCompanyModalOpen}
      />
    </div>
  );
};

export default Companies;
