// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StationsAddBranch_modalTop__zZi16 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.StationsAddBranch_modalTop__zZi16 svg {
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.StationsAddBranch_stationsAddBranchTitle__4SSA- {
  font-weight: 700;
  line-height: 24px;
  font-size: 18px;
  color: #000;
  margin: 0;
}

.StationsAddBranch_dropDownItem__V8CUL {
  font-family: "Poppins", sans-serif;
  display: block;
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  color: #212121;
  line-height: 24px;
  font-weight: 400;
}
.StationsAddBranch_dropDownItem__V8CUL:hover {
  background: #f5f8ff;
}

.StationsAddBranch_buttonsContainer__r77lW {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.StationsAddBranch_inputErrorMessage__cTaiT {
  display: inline-block;
  font-size: 12px;
  color: #f93232;
  line-height: 16px;
  margin-left: 12px;
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/views/Stations/StationsAddBranch/StationsAddBranch.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAGA;EACE,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,SAAA;AAAF;;AAGA;EACE,kCAAA;EACA,cAAA;EACA,4BAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AADF;;AAIA;EACE,qBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AADF","sourcesContent":[".modalTop {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 15px;\n\n  & svg {\n    cursor: pointer;\n    width: 36px;\n    height: 36px;\n  }\n}\n\n.stationsAddBranchTitle {\n  font-weight: 700;\n  line-height: 24px;\n  font-size: 18px;\n  color: #000;\n  margin: 0;\n}\n\n.dropDownItem {\n  font-family: 'Poppins', sans-serif;\n  display: block;\n  padding: 12px 16px 12px 16px;\n  font-size: 14px;\n  color: #212121;\n  line-height: 24px;\n  font-weight: 400;\n\n  &:hover {\n    background: #f5f8ff;\n  }\n}\n\n.buttonsContainer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: 16px;\n}\n\n.inputErrorMessage {\n  display: inline-block;\n  font-size: 12px;\n  color: #f93232;\n  line-height: 16px;\n  margin-left: 12px;\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalTop": `StationsAddBranch_modalTop__zZi16`,
	"stationsAddBranchTitle": `StationsAddBranch_stationsAddBranchTitle__4SSA-`,
	"dropDownItem": `StationsAddBranch_dropDownItem__V8CUL`,
	"buttonsContainer": `StationsAddBranch_buttonsContainer__r77lW`,
	"inputErrorMessage": `StationsAddBranch_inputErrorMessage__cTaiT`
};
export default ___CSS_LOADER_EXPORT___;
