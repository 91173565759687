import {api} from './api';
import {
  ICreateBranchArgs,
  IEditBranchArgs,
  IGetBranchById,
  IGetBranchesArgs,
  IGetBranchesListItem,
  IGetBranchesResponse,
} from '../models/branches';
import {IGetCompaniesListItem} from '../models/companies';
import {StationsListItem} from 'store/models/stations';

export const branches = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getBranchesList: builder.query<
        IGetBranchesListItem[],
        IGetCompaniesListItem[]
      >({
        query: (companies) => {
          const params = new URLSearchParams();

          if (companies.length) {
            companies.forEach((company) => {
              params.append('companyIds', company.id);
            });
          }

          return {
            url: `branches/dictionary?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Branches', 'Dictionarys'],
      }),
      getBranches: builder.query<IGetBranchesResponse, IGetBranchesArgs>({
        query: (branchesParams) => {
          const params = new URLSearchParams();

          if (branchesParams.search) {
            params.append('term', branchesParams.search);
          }

          if (branchesParams.pageNumber) {
            params.append('pageNumber', String(branchesParams.pageNumber));
          }

          if (branchesParams.pageSize) {
            params.append('pageSize', String(branchesParams.pageSize));
          }

          if (
            branchesParams.sortBy?.column &&
            branchesParams.sortBy.direction
          ) {
            const column = branchesParams.sortBy?.column;
            const direction = branchesParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `branches?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Branches'],
      }),
      getCompaniesToCreateBranch: builder.query<
        {id: string; name: string}[],
        void
      >({
        query: () => ({
          url: 'companies/dictionary',
          method: 'get',
        }),
      }),
      createBranch: builder.mutation<string, ICreateBranchArgs>({
        query: (branchData) => ({
          url: 'branches',
          method: 'post',
          data: branchData,
        }),
        invalidatesTags: ['Branches'],
      }),
      getBranchById: builder.query<IGetBranchById, string>({
        query: (branchId) => ({
          url: `branches/${branchId}`,
          method: 'get',
        }),
      }),
      editBranch: builder.mutation<string, IEditBranchArgs>({
        query: (branchData) => ({
          url: 'branches',
          method: 'put',
          data: branchData,
        }),
        invalidatesTags: ['Branches'],
      }),
      deleteBranch: builder.mutation<string, string>({
        query: (branchId) => ({
          url: 'branches',
          method: 'delete',
          data: {id: branchId},
        }),
        invalidatesTags: ['Branches'],
      }),
      getUnassignedStationsByBranch: builder.query<StationsListItem[], string>({
        query: (branchId) => {
          return {
            url: `branches/unassigned-stations?branchId=${branchId}`,
            method: 'get',
          };
        },
      }),
    };
  },
});

export const {
  useGetBranchesListQuery,
  useLazyGetBranchesListQuery,
  useLazyGetUnassignedStationsByBranchQuery,
  useGetBranchesQuery,
  useLazyGetCompaniesToCreateBranchQuery,
  useCreateBranchMutation,
  useLazyGetBranchByIdQuery,
  useEditBranchMutation,
  useDeleteBranchMutation,
} = branches;
