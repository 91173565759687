import React, {FC, useEffect} from 'react';
import {Controller, useFieldArray, useForm} from 'react-hook-form';
import {backupPeriodTypes} from 'core/constants';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControlLabel,
  FormGroup,
  Modal,
  Paper,
  Stack,
  styled,
  Switch,
  TextField,
} from '@mui/material';
import {CloseIcon, SelectPopupIcon} from 'assets/icons';
import {
  androidSwitchDisabled,
  createTaskBackupSwitchLabel,
  searchAndSelect,
} from 'styles/MUIStyles/common';
import {modal} from 'styles/MUIStyles/modal';
import {
  cancelButton,
  createTaskBackupButtonsDisabled,
} from 'styles/MUIStyles/buttons';
import styles from '../../StationInformation.module.scss';

const AndroidSwitch = styled(Switch)(() => androidSwitchDisabled);

interface IManifestEditTask {
  name: string;
  id: string;
  isMain: boolean;
  include: {
    directory: string;
  }[];
  exclude:
    | {
        directory: string;
      }[]
    | null;
  isIncrementalBackupSettingsChecked: boolean;
  isExcludeDirectoryChecked: boolean;
  isPreBackupScriptChecked: boolean;
  isPostBackupScriptChecked: boolean;
  preBackupScripts:
    | {
        script: string;
      }[]
    | null;
  postBackupScripts:
    | {
        script: string;
      }[]
    | null;
  keepFullCopy: number;
  fullBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
  incrementalBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
}

interface Props {
  isManifestTaskViewModalOpen: boolean;
  setIsManifestTaskViewModalOpen: (isOpen: boolean) => void;
  clickedManifestTask: IManifestEditTask | null;
}

const ManifestTaskViewModal: FC<Props> = (props) => {
  const {register, watch, control, reset} = useForm<IManifestEditTask>({
    defaultValues: {
      name: '',
      id: '',
      include: [{directory: ''}],
      exclude: [{directory: ''}],
      isIncrementalBackupSettingsChecked: false,
      isExcludeDirectoryChecked: false,
      isPreBackupScriptChecked: false,
      isPostBackupScriptChecked: false,
      preBackupScripts: [{script: ''}],
      postBackupScripts: [{script: ''}],
      keepFullCopy: 1,
      fullBackupSettings: {
        periodType: null,
        periodCount: 1,
      },
      incrementalBackupSettings: {
        periodType: null,
        periodCount: 1,
      },
    },
  });

  const {fields: includeDirectories} = useFieldArray<IManifestEditTask>({
    control,
    name: 'include',
  });

  const {fields: excludeDirectories} = useFieldArray<IManifestEditTask>({
    control,
    name: 'exclude',
  });

  const {fields: preScripts} = useFieldArray<IManifestEditTask>({
    control,
    name: 'preBackupScripts',
  });

  const {fields: postScripts} = useFieldArray<IManifestEditTask>({
    control,
    name: 'postBackupScripts',
  });

  const fullBackupSettingsPeriodCount = watch('fullBackupSettings.periodCount');
  const incrementalBackupSettingsPeriodCount = watch(
    'incrementalBackupSettings.periodCount',
  );
  const keepFullBackupCopyCount = watch('keepFullCopy');
  const isIncrementalBackupSettingsChecked = watch(
    'isIncrementalBackupSettingsChecked',
  );
  const isExcludeDirectoryChecked = watch('isExcludeDirectoryChecked');
  const isPreBackupScriptChecked = watch('isPreBackupScriptChecked');
  const isPostBackupScriptChecked = watch('isPostBackupScriptChecked');

  useEffect(() => {
    if (props.clickedManifestTask) {
      reset({
        name: props.clickedManifestTask.name,
        id: props.clickedManifestTask.id,
        include: props.clickedManifestTask.include,
        isMain: props.clickedManifestTask.isMain,
        exclude: props.clickedManifestTask?.exclude?.length
          ? props.clickedManifestTask.exclude
          : [{directory: ''}],
        preBackupScripts: props.clickedManifestTask?.preBackupScripts?.length
          ? props.clickedManifestTask.preBackupScripts
          : [{script: ''}],
        postBackupScripts: props.clickedManifestTask?.postBackupScripts?.length
          ? props.clickedManifestTask.postBackupScripts
          : [{script: ''}],
        isIncrementalBackupSettingsChecked:
          props.clickedManifestTask.isIncrementalBackupSettingsChecked,
        isExcludeDirectoryChecked:
          props.clickedManifestTask.isExcludeDirectoryChecked,
        isPreBackupScriptChecked:
          props.clickedManifestTask.isPreBackupScriptChecked,
        isPostBackupScriptChecked:
          props.clickedManifestTask.isPostBackupScriptChecked,
        keepFullCopy: props.clickedManifestTask.keepFullCopy,
        fullBackupSettings: props.clickedManifestTask.fullBackupSettings,
        incrementalBackupSettings:
          props.clickedManifestTask.incrementalBackupSettings,
      });
    }
  }, [props.clickedManifestTask, reset]);

  return (
    <div>
      <Modal
        open={props.isManifestTaskViewModalOpen}
        onClose={() => props.setIsManifestTaskViewModalOpen(false)}
      >
        <Box sx={modal}>
          <form>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '8.5px',
              }}
            >
              <h2 className={styles.createManifestTask}>Task</h2>
              <div
                className={styles.closeIcon}
                onClick={() => props.setIsManifestTaskViewModalOpen(false)}
              >
                <CloseIcon />
              </div>
            </Box>
            <div
              className={
                styles.nameInputContainer + ' ' + styles.createTaskName
              }
            >
              <label htmlFor="name">Name</label>
              <input
                {...register('name')}
                type="text"
                id="name"
                disabled
                autoComplete="off"
                className={'input'}
                placeholder="Enter name"
              />
            </div>
            <h3 className={styles.createManifestCommands}>Commands:</h3>
            {includeDirectories.map((includeDirectory, index) => (
              <div
                className={
                  styles.includeInputContainer + ' ' + styles.includeDirectory
                }
                key={includeDirectory.id}
              >
                <label htmlFor="include">Include</label>
                <div className={styles.createAndDeleteDirectoryContainer}>
                  <input
                    {...register(`include.${index}.directory`)}
                    type="text"
                    id="include"
                    disabled
                    autoComplete="off"
                    className={'input'}
                    placeholder="Write directories"
                  />
                </div>
              </div>
            ))}

            {!props.clickedManifestTask?.isMain ? (
              <div className={styles.createTaskBackupFull}>
                <span>Backup-full</span>
                <div className={styles.createTaskFullBackupControls}>
                  <span>Every</span>
                  <div className={styles.fullBackupButtonsContainer}>
                    <Button disabled sx={createTaskBackupButtonsDisabled}>
                      -
                    </Button>
                    <div
                      className={styles.fullBackupSettingsPeriodCountDisabled}
                    >
                      {fullBackupSettingsPeriodCount}
                    </div>
                    <Button disabled sx={createTaskBackupButtonsDisabled}>
                      +
                    </Button>
                  </div>
                  <Controller
                    name={'fullBackupSettings.periodType'}
                    control={control}
                    render={({field}) => (
                      <Autocomplete
                        {...field}
                        disabled
                        options={backupPeriodTypes}
                        value={field.value}
                        onChange={(_, data) => field.onChange(data)}
                        PaperComponent={(props) => (
                          <Paper
                            sx={{
                              borderRadius: '12px',
                              boxShadow: '0px 4px 20px 0px #4444441F',
                              marginTop: '8px',
                              cursor: 'pointer',
                            }}
                            {...props}
                          />
                        )}
                        sx={{width: '20%', flexGrow: 2}}
                        ListboxProps={{
                          style: {maxHeight: '200px'},
                          className: 'autoCompleteListBox',
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === '' ? true : option === value
                        }
                        popupIcon={<SelectPopupIcon />}
                        renderOption={(props: any, option) => {
                          return (
                            <span {...props} className={styles.dropDownItem}>
                              {option}
                            </span>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled
                            size="small"
                            placeholder="Select time period"
                            sx={searchAndSelect}
                            style={{
                              backgroundColor: '#ecf2f7',
                              borderRadius: '12px',
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            ) : null}

            {!props.clickedManifestTask?.isMain ? (
              <div className={styles.createTaskBackupKeepFullCopy}>
                <div className={styles.createTaskBackupKeepFullCopyControls}>
                  <span>Keep</span>
                  <div className={styles.fullBackupButtonsContainer}>
                    <Button disabled sx={createTaskBackupButtonsDisabled}>
                      -
                    </Button>
                    <div
                      className={styles.fullBackupSettingsPeriodCountDisabled}
                    >
                      {keepFullBackupCopyCount}
                    </div>
                    <Button disabled sx={createTaskBackupButtonsDisabled}>
                      +
                    </Button>
                  </div>
                  <span style={{width: '20%', flexGrow: '2'}}>full copy</span>
                </div>
              </div>
            ) : null}

            {!props.clickedManifestTask?.isMain ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name={'isIncrementalBackupSettingsChecked'}
                  control={control}
                  render={({field}) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={createTaskBackupSwitchLabel}
                        control={
                          <AndroidSwitch
                            {...field}
                            disabled
                            checked={field.value}
                          />
                        }
                        label={'Backup-incremental'}
                      />
                    </FormGroup>
                  )}
                />
              </Stack>
            ) : null}

            {!props.clickedManifestTask?.isMain &&
            isIncrementalBackupSettingsChecked ? (
              <div className={styles.createTaskBackupIncremental}>
                <div className={styles.createTaskIncrementalBackupControls}>
                  <span>Every</span>
                  <div className={styles.incrementalBackupButtonsContainer}>
                    <Button disabled sx={createTaskBackupButtonsDisabled}>
                      -
                    </Button>
                    <div
                      className={styles.fullBackupSettingsPeriodCountDisabled}
                    >
                      {incrementalBackupSettingsPeriodCount}
                    </div>
                    <Button disabled sx={createTaskBackupButtonsDisabled}>
                      +
                    </Button>
                  </div>
                  <Controller
                    name={'incrementalBackupSettings.periodType'}
                    control={control}
                    render={({field}) => (
                      <Autocomplete
                        {...field}
                        disabled
                        options={backupPeriodTypes}
                        value={field.value}
                        onChange={(_, data) => field.onChange(data)}
                        PaperComponent={(props) => (
                          <Paper
                            sx={{
                              borderRadius: '12px',
                              boxShadow: '0px 4px 20px 0px #4444441F',
                              marginTop: '8px',
                              cursor: 'pointer',
                            }}
                            {...props}
                          />
                        )}
                        sx={{width: '20%', flexGrow: 2}}
                        ListboxProps={{
                          style: {maxHeight: '200px'},
                          className: 'autoCompleteListBox',
                        }}
                        isOptionEqualToValue={(option, value) =>
                          value === '' ? true : option === value
                        }
                        popupIcon={<SelectPopupIcon />}
                        renderOption={(props: any, option) => {
                          return (
                            <span {...props} className={styles.dropDownItem}>
                              {option}
                            </span>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            disabled
                            size="small"
                            placeholder="Select time period"
                            sx={searchAndSelect}
                            style={{
                              backgroundColor: '#ecf2f7',
                              borderRadius: '12px',
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
            ) : null}

            {!props.clickedManifestTask?.isMain ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name={'isExcludeDirectoryChecked'}
                  control={control}
                  render={({field}) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={createTaskBackupSwitchLabel}
                        control={
                          <AndroidSwitch
                            {...field}
                            disabled
                            checked={field.value}
                          />
                        }
                        label={'Exclude'}
                      />
                    </FormGroup>
                  )}
                />
              </Stack>
            ) : null}

            {isExcludeDirectoryChecked ? (
              <>
                {excludeDirectories.map((excludeDirectory, index) => (
                  <div
                    className={
                      styles.includeInputContainer +
                      ' ' +
                      styles.includeDirectory
                    }
                    key={excludeDirectory.id}
                  >
                    <label>Exclude</label>
                    <div className={styles.createAndDeleteDirectoryContainer}>
                      <input
                        {...register(`exclude.${index}.directory`)}
                        type="text"
                        autoComplete="off"
                        disabled
                        className={'input'}
                        placeholder="Write directories"
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : null}

            {!props.clickedManifestTask?.isMain ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name={'isPreBackupScriptChecked'}
                  control={control}
                  render={({field}) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={createTaskBackupSwitchLabel}
                        control={
                          <AndroidSwitch
                            {...field}
                            disabled
                            checked={field.value}
                          />
                        }
                        label={'Pre-backup scripts'}
                      />
                    </FormGroup>
                  )}
                />
              </Stack>
            ) : null}
            {!props.clickedManifestTask?.isMain && isPreBackupScriptChecked ? (
              <>
                {preScripts.map((preScript, index) => (
                  <div
                    className={styles.preScriptInputContainer}
                    key={preScript.id}
                  >
                    <div className={styles.createAndDeleteScriptContainer}>
                      <textarea
                        {...register(`preBackupScripts.${index}.script`)}
                        cols={30}
                        rows={2}
                        className={`input`}
                        disabled
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : null}
            {!props.clickedManifestTask?.isMain ? (
              <Stack direction="row" spacing={1} alignItems="center">
                <Controller
                  name={'isPostBackupScriptChecked'}
                  control={control}
                  render={({field}) => (
                    <FormGroup>
                      <FormControlLabel
                        sx={createTaskBackupSwitchLabel}
                        control={
                          <AndroidSwitch
                            {...field}
                            disabled
                            checked={field.value}
                          />
                        }
                        label={'Post-backup scripts'}
                      />
                    </FormGroup>
                  )}
                />
              </Stack>
            ) : null}
            {!props.clickedManifestTask?.isMain && isPostBackupScriptChecked ? (
              <>
                {postScripts.map((postScript, index) => (
                  <div
                    className={styles.postScriptInputContainer}
                    key={postScript.id}
                  >
                    <div className={styles.createAndDeleteScriptContainer}>
                      <textarea
                        {...register(`postBackupScripts.${index}.script`)}
                        cols={30}
                        rows={2}
                        className={`input`}
                        disabled
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : null}

            <Divider
              sx={{backgroundColor: '#E4E4EF', margin: '28px 0 14px 0'}}
            />
            <div className={styles.createTaskButtonsContainer}>
              <Button
                sx={cancelButton}
                onClick={() => props.setIsManifestTaskViewModalOpen(false)}
              >
                Close
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ManifestTaskViewModal;
