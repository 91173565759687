import React, {FC} from 'react';
import {IGetClientById} from 'store/models/clients';
import styles from '../ClientsInformation.module.scss';
import {Link} from 'react-router-dom';
import {LinkIcon} from 'assets/icons';
import Guard from 'components/Guard/Guard';
import {UserRole} from 'enums';

interface Props {
  clientInfo: IGetClientById | undefined;
}

const InformationTab: FC<Props> = (props) => {
  return (
    <div>
      <div className={styles.clientUserInfo}>
        {props?.clientInfo?.audit?.deleting ? (
          <span className={`${styles.clientStatus} ${styles.deleted}`}>
            Removed
          </span>
        ) : (
          <span
            className={
              props?.clientInfo?.isActive
                ? `${styles.clientStatus} ${styles.active}`
                : `${styles.clientStatus} ${styles.inactive}`
            }
          >
            {props?.clientInfo?.isActive ? 'Active' : 'Inactive'}
          </span>
        )}
        <div>
          <span>First Name</span>
          <span>{props?.clientInfo?.firstName}</span>
        </div>

        <div>
          <span>Last Name</span>
          <span>{props?.clientInfo?.lastName}</span>
        </div>

        <div>
          <span>Email</span>
          <span>{props?.clientInfo?.email}</span>
        </div>

        <div>
          <span>Number</span>
          <span>{props?.clientInfo?.phoneNumber}</span>
        </div>

        {props?.clientInfo?.additionalInformation ? (
          <div>
            <span>Additional information - Optional</span>
            <span>{props.clientInfo.additionalInformation}</span>
          </div>
        ) : null}
      </div>

      <div className={styles.clientBranches}>
        {props?.clientInfo?.branches.length ? <h3>Branches</h3> : null}
        {props?.clientInfo?.branches.map((branch) => (
          <span key={branch.id}>
            {branch.name}{' '}
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <Link to={`/branches/information/${branch.id}`}>
                <LinkIcon style={{height: '12px', width: '12px'}} />
              </Link>
            </Guard>
          </span>
        ))}
      </div>
    </div>
  );
};

export default InformationTab;
