import {
  ICHeckResetTokenArgs,
  IChangePWDArgs,
  IForgotPWDArgs,
  IResetPWDArgs,
} from 'store/models/profile';
import {api} from './api';
import {
  IEditProfileInfo,
  IGetAuthorizedUserInfo,
  ILoginSuccessRes,
} from 'store/models/auth';
import {setAuthData, setProfileData} from './authSlice';

export const profile = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      login: builder.mutation<void, {email: string; password: string}>({
        query: (data) => {
          return {
            url: `auth/login`,
            method: 'post',
            data: data,
          };
        },
        invalidatesTags: ['Profile'],
      }),
      getProfileData: builder.query<IGetAuthorizedUserInfo, void>({
        query: () => {
          return {
            url: 'user-accounts/about-me',
            method: 'get',
          };
        },
        async onQueryStarted(arg, {dispatch, queryFulfilled}) {
          try {
            const res = await queryFulfilled;

            dispatch(setProfileData(res.data));
          } catch (error) {}
        },
        providesTags: ['Profile'],
      }),
      editProfile: builder.mutation<void, IEditProfileInfo>({
        query: (data) => {
          return {
            url: 'user-accounts/edit-me',
            method: 'put',
            data: data,
          };
        },
        invalidatesTags: ['Profile'],
      }),
      checkResetPwdToken: builder.mutation<
        {isResetTokenExpired: boolean},
        ICHeckResetTokenArgs
      >({
        query: (data) => {
          return {
            url: `user-accounts/is-reset-token-expired`,
            method: 'post',
            data: data,
          };
        },
      }),
      forgotPassword: builder.mutation<void, IForgotPWDArgs>({
        query: (data) => {
          return {
            url: 'user-accounts/forgot-password',
            method: 'post',
            data: data,
          };
        },
      }),
      resetProfilePassword: builder.mutation<void, IResetPWDArgs>({
        query: (data) => {
          return {
            url: 'user-accounts/reset-password',
            method: 'post',
            data: data,
          };
        },
      }),
      changeProfilePassword: builder.mutation<void, IChangePWDArgs>({
        query: (data) => {
          return {
            url: 'user-accounts/change-password',
            method: 'post',
            data: data,
          };
        },
      }),
      sendOTPCode: builder.mutation<
        ILoginSuccessRes,
        {email: string; code: number}
      >({
        query: (data) => {
          return {
            url: 'auth/login-by-code',
            method: 'post',
            data: data,
          };
        },
        async onQueryStarted(arg, {dispatch, queryFulfilled}) {
          try {
            const res = await queryFulfilled;

            dispatch(setAuthData(res.data));
          } catch (error) {}
        },
      }),
    };
  },
});

export const {
  useLoginMutation,
  useGetProfileDataQuery,
  useLazyGetProfileDataQuery,
  useEditProfileMutation,
  useSendOTPCodeMutation,
  useCheckResetPwdTokenMutation,
  useForgotPasswordMutation,
  useResetProfilePasswordMutation,
  useChangeProfilePasswordMutation,
} = profile;
