import React, {FC, useId, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useUpdateEffect} from 'hooks';
import {
  useCreateStationMutation,
  useLazyGetDefaultManifestQuery,
} from 'store/slices/stations';
import {ISnackbarState} from 'store/models/common';
import {Alert, Box, Button, Snackbar, Tab, Tabs} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import MainInfo from './MainInfo/MainInfo';
import Manifest from './Manifest/Manifest';
import {
  CloseIcon,
  CreateIcon,
  ErrorNotification,
  GoBackArrowButtonIcon,
  SuccessNotification,
} from 'assets/icons';
import {createButton, goBackButton} from 'styles/MUIStyles/buttons';
import {tab} from 'styles/MUIStyles/common';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './StationsCreate.module.scss';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';
import {IStationType} from 'store/models/stations';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface IMainInfoForm {
  name: string;
  posChassicNumber: string;
  fiscalDeviceId: string;
  stationType: IStationType;
}

const StationsCreate: FC = () => {
  const navigate = useNavigate();

  const methods = useForm<IMainInfoForm>({
    defaultValues: {
      name: '',
      posChassicNumber: '',
      fiscalDeviceId: '',
      stationType: {
        name: '',
        id: 0,
      },
    },
  });

  methods.watch((data: any) => {
    if (data?.stationType?.id) fetchDefaultManifest(data.stationType.id);
  });
  const [fetchDefaultManifest, {data: defaultManifest}] =
    useLazyGetDefaultManifestQuery();
  const [
    createStation,
    {
      isSuccess: isCreatingStationSuccess,
      isLoading: isCreatingStationLoading,
      error: createStationError,
    },
  ] = useCreateStationMutation();

  const mainInfoFormId = useId();
  const [createStationErrorMessage, setCreateStationErrorMessage] = useState<
    string | null
  >(null);
  const [activeTab, setActiveTab] = useState(0);
  const [createStationSnackbarSuccess, setCreateStationSnackbarSuccess] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [createStationSnackbarError, setCreateStationSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const {
    vertical: createStationSuccessVertical,
    horizontal: createStationSuccessHorizontal,
    open: isCreateStationSuccessOpen,
  } = createStationSnackbarSuccess;
  const {
    vertical: createStationErrorVertical,
    horizontal: createStationErrorHorizontal,
    open: isCreateStationErrorOpen,
  } = createStationSnackbarError;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const onSubmit = (data: IMainInfoForm) => {
    if (defaultManifest) {
      createStation({
        fiscalDeviceId: data.fiscalDeviceId,
        name: data.name,
        posChassicNumber: data.posChassicNumber,
        stationTypeId: data.stationType.id,
        manifest: {
          commands: defaultManifest.commands,
        },
      });
    }
  };

  const handleSnackbarClose = () => {
    setCreateStationSnackbarSuccess({
      ...createStationSnackbarSuccess,
      open: false,
    });
    setCreateStationSnackbarError({
      ...createStationSnackbarError,
      open: false,
    });
    setCreateStationErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (isCreatingStationSuccess) {
      setCreateStationSnackbarSuccess({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [isCreatingStationSuccess]);

  useUpdateEffect(() => {
    if (
      createStationError &&
      'data' in createStationError &&
      'status' in createStationError
    ) {
      if (
        createStationError.status === 400 ||
        createStationError.status === 404
      ) {
        const errors = createStationError.data?.errors;

        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            //@ts-ignore
            setCreateStationErrorMessage(errors[key]);
            setCreateStationSnackbarError({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  }, [createStationError]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.stationCreateTop}>
        <h1 className={styles.stationCreateTitle}>Station</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <ButtonWithProgress
            startIcon={
              isCreatingStationLoading ? (
                <LoadingIconSpacer width={'16px'} height={'16px'} />
              ) : (
                <CreateIcon />
              )
            }
            form={mainInfoFormId}
            type="submit"
            loading={isCreatingStationLoading}
            disabled={isCreatingStationLoading}
            sx={createButton}
          >
            Create
          </ButtonWithProgress>
        </div>
      </div>
      <div className={styles.stationCreateMainContent}>
        <Box sx={{height: '100%'}}>
          <Tabs
            TabIndicatorProps={{
              style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
            }}
            value={activeTab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab sx={tab} label="Main info" {...a11yProps(0)} />
            <Tab sx={tab} label="Manifest" {...a11yProps(1)} />
          </Tabs>
          <CustomTabPanel value={activeTab} index={0}>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                id={mainInfoFormId}
              >
                <MainInfo />
              </form>
            </FormProvider>
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <Manifest
              defaultManifest={defaultManifest}
              getDataFromMainInfo={methods.getValues}
            />
          </CustomTabPanel>
        </Box>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: createStationSuccessVertical,
          horizontal: createStationSuccessHorizontal,
        }}
        autoHideDuration={2500}
        open={isCreateStationSuccessOpen}
        onClose={handleSnackbarClose}
        key={'createStationSuccess'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            Station was successfully created
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>

      {createStationErrorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: createStationErrorVertical,
            horizontal: createStationErrorHorizontal,
          }}
          autoHideDuration={3500}
          open={isCreateStationErrorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {createStationErrorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default StationsCreate;
