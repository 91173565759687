import React, {FC} from 'react';

interface Props {
  width: string;
  height: string;
}

const LoadingIconSpacer: FC<Props> = (props) => {
  return <div style={{width: props.width, height: props.height}} />;
};

export default LoadingIconSpacer;
