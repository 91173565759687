import type {FC} from 'react';
import styles from './ErrorFallback.module.scss';
import {createButton} from 'styles/MUIStyles/buttons';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'store/store';
import {mainPagesByRole} from 'core/constants';
import {UserRole} from 'enums';
import {FallbackProps} from 'react-error-boundary';
import {useEffect} from 'react';

const ErrorFallback: FC<FallbackProps> = ({error, resetErrorBoundary}) => {
  const userRole = useAppSelector((state) => state.auth.role);

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      resetErrorBoundary();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorPageContainer}>
        <p className={styles.errorPageHeading}>Something went wrong!</p>
        <p className={styles.errorPageText}>Please try to refresh page</p>
        <Button
          onClick={() => {
            resetErrorBoundary();
            navigate(mainPagesByRole[userRole as UserRole]);
          }}
          sx={createButton}
        >
          &lt; Go back to main page
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallback;
