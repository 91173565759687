import {RelativeRoutingType, To} from 'react-router-dom';

interface NavigateFunction {
  (
    to: To,
    options?: {
      replace?: boolean;
      state?: any;
      relative?: RelativeRoutingType;
    },
  ): void;
  (delta: number): void;
}

interface IHistory {
  navigate: null | NavigateFunction;
  push: (page: string) => void;
}

const History: IHistory = {
  navigate: null,
  push: (page: string) => History.navigate && History.navigate(page),
};

export default History;
