import React, {FC, useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {IGetStationsList as IGetStation} from 'store/models/stations';
import {
  useAddBranchToStationMutation,
  useLazyGetUnassignedBranchesToAddToStationQuery,
} from 'store/slices/stations';
import {ISnackbarState} from 'store/models/common';
import {useUpdateEffect} from 'hooks';
import {ErrorMessage} from '@hookform/error-message';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Snackbar,
  TextField,
} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {CloseIcon, SelectPopupIcon, SuccessNotification} from 'assets/icons';
import {searchAndSelect, searchAndSelectError} from 'styles/MUIStyles/common';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import {modal} from 'styles/MUIStyles/modal';
import styles from './StationsAddBranch.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  clickedStation: IGetStation | null;
  isStopPropogation?: boolean;
}

interface IFormData {
  branch: {
    id: string;
    name: string;
  };
}

const StationsAddBranch: FC<Props> = (props) => {
  const {
    handleSubmit,
    reset,
    clearErrors,
    setError,
    control,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      branch: {
        id: '',
        name: '',
      },
    },
  });

  const [
    getUnassignedBranches,
    {data: unAssignedBranches, isFetching: isFetchingUnassignedBranchesLoading},
  ] = useLazyGetUnassignedBranchesToAddToStationQuery();

  const [
    addBranchToStation,
    {
      isLoading: isAddingBranchToStationLoading,
      isSuccess: isAddingBranchToStationSuccess,
      error: addBranchToStationError,
    },
  ] = useAddBranchToStationMutation();

  const [branchName, setBranchName] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {vertical, horizontal, open} = snackbar;

  const onSubmit = async (formData: IFormData) => {
    if (props.clickedStation) {
      const response = await addBranchToStation({
        stationId: props.clickedStation.id,
        branchId: formData.branch.id,
      });

      if (response && !('error' in response)) {
        setBranchName(formData.branch.name);
      }
    }
  };

  const clearDataAndErrors = () => {
    reset();
    clearErrors();
    props.onClose();
  };

  const handleSnackbarClose = () => {
    setBranchName(null);
    setSnackbar({...snackbar, open: false});
  };

  useUpdateEffect(() => {
    if (props.isOpen && props.clickedStation) {
      getUnassignedBranches(props.clickedStation.id);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (
      addBranchToStationError &&
      'data' in addBranchToStationError &&
      'status' in addBranchToStationError
    ) {
      if (
        addBranchToStationError.status === 400 ||
        addBranchToStationError.status === 404
      ) {
        const errors = addBranchToStationError.data?.errors;

        if (errors?.branchId?.[0]) {
          setError('branch', {type: 'custom', message: errors.branchId[0]});
        }

        if (errors?.stationId?.[0]) {
          setError('branch', {type: 'custom', message: errors.stationId[0]});
        }
      }
    }
  }, [addBranchToStationError, setError]);

  useUpdateEffect(() => {
    if (isAddingBranchToStationSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      reset();
      props.onClose();
    }
  }, [isAddingBranchToStationSuccess]);

  return (
    <>
      <Modal open={props.isOpen} onClose={clearDataAndErrors}>
        <Box sx={{...modal, width: '35%'}}>
          <form
            onSubmit={(event) => {
              if (props.isStopPropogation) {
                event.stopPropagation();
                handleSubmit(onSubmit)(event);
              } else {
                handleSubmit(onSubmit)(event);
              }
            }}
          >
            <div className={styles.modalTop}>
              <h2 className={styles.stationsAddBranchTitle}>Add branch</h2>
              <CloseIcon onClick={clearDataAndErrors} />
            </div>
            <Controller
              name={'branch'}
              control={control}
              rules={{
                validate: (value) => {
                  if (!value?.name && !value?.id) {
                    return 'This field cannot be empty';
                  }
                },
              }}
              render={({field}) => (
                <Autocomplete
                  {...field}
                  options={unAssignedBranches || []}
                  loading={isFetchingUnassignedBranchesLoading}
                  popupIcon={<SelectPopupIcon />}
                  PaperComponent={(props) => (
                    <Paper
                      sx={{
                        borderRadius: '12px',
                        boxShadow: '0px 4px 20px 0px #4444441F',
                        marginTop: '8px',
                        cursor: 'pointer',
                      }}
                      {...props}
                    />
                  )}
                  sx={{width: '100%'}}
                  ListboxProps={{
                    style: {maxHeight: '200px'},
                    className: 'autoCompleteListBox',
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value.name === '' ? true : option.id === value.id
                  }
                  getOptionLabel={(option) => (option?.name ? option.name : '')}
                  onChange={(_, value) => {
                    if (value) {
                      field.onChange(value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  renderOption={(props: any, option) => (
                    <span {...props} className={styles.dropDownItem}>
                      {option?.name}
                    </span>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select branch name"
                      sx={
                        errors?.branch
                          ? searchAndSelectError
                          : {...searchAndSelect, marginBottom: '16px'}
                      }
                    />
                  )}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={'branch'}
              render={({message}) => (
                <span className={styles.inputErrorMessage}>{message}</span>
              )}
            />
            <Divider
              sx={{backgroundColor: '#E4E4EF', margin: '18px 0 14px 0'}}
            />
            <div className={styles.buttonsContainer}>
              <Button sx={cancelButton} onClick={clearDataAndErrors}>
                Cancel
              </Button>
              <ButtonWithProgress
                type="submit"
                sx={{...createButtonModal}}
                loading={isAddingBranchToStationLoading}
                disabled={isAddingBranchToStationLoading}
              >
                Add branch
              </ButtonWithProgress>
            </div>
          </form>
        </Box>
      </Modal>
      {branchName && props.clickedStation ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Branch {`"${branchName}"`} successfully added to station{' '}
              {`"${props.clickedStation.name}"`}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default StationsAddBranch;
