import React, {FC, useState} from 'react';
import {useDeleteLogsMutation} from 'store/slices/logs';
import {Alert, Box, Button, Snackbar} from '@mui/material';
import {CloseIcon, DeleteIcon, SuccessNotification} from 'assets/icons';
import {createButton as deleteButton} from 'styles/MUIStyles/buttons';
import WarningModal from 'components/WarningModal/WarningModal';
import {ISnackbarState} from '../../../store/models/common';
import {useUpdateEffect} from '../../../hooks';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from '../../../styles/MUIStyles/snackbars';

interface Props {
  systemLogsStartTime: string;
  systemLogsEndTime: string;
}

const LogsDeleteButton: FC<Props> = (props) => {
  const [
    deleteLogs,
    {isLoading: isDeletingLogsLoading, isSuccess: isDeletingSystemLogsSuccess},
  ] = useDeleteLogsMutation();

  const [isDeleteLogsModalOpen, setIsDeleteLogsModalOpen] = useState(false);

  const [snackbar, setSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {vertical, horizontal, open} = snackbar;

  const handleSnackbarClose = () => {
    setSnackbar({...snackbar, open: false});
  };

  useUpdateEffect(() => {
    if (isDeletingSystemLogsSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      setIsDeleteLogsModalOpen(false);
    }
  }, [isDeletingSystemLogsSuccess]);

  return (
    <>
      <Button
        variant="contained"
        startIcon={
          <DeleteIcon
            style={{
              filter:
                'invert(100%) sepia(4%) saturate(15%) hue-rotate(33deg) brightness(106%) contrast(103%)',
            }}
          />
        }
        onClick={() => {
          setIsDeleteLogsModalOpen(true);
        }}
        sx={deleteButton}
      >
        Remove
      </Button>
      <WarningModal
        isOpen={isDeleteLogsModalOpen}
        onClose={() => setIsDeleteLogsModalOpen(false)}
        entityText={''}
        title={'Remove system logs ?'}
        textBlocks={[
          `Are you sure you want to remove the system logs for the ${
            props.systemLogsStartTime && props.systemLogsEndTime
              ? 'selected period ?'
              : 'entire period ?'
          }`,
        ]}
        confirmButtonText={'Yes, remove'}
        onConfirmButtonClick={() => {
          deleteLogs({
            startTime: props.systemLogsStartTime,
            endTime: props.systemLogsEndTime,
          });
        }}
        loading={isDeletingLogsLoading}
      />

      <Snackbar
        anchorOrigin={{vertical, horizontal}}
        autoHideDuration={2500}
        open={open}
        onClose={handleSnackbarClose}
        key={vertical + horizontal}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            System logs were removed
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>
    </>
  );
};

export default LogsDeleteButton;
