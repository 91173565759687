import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useLazyGetManifestByIdQuery} from 'store/slices/manifests';
import ManifestsTable from './ManifestsTable/ManifestsTable';
import {Button} from '@mui/material';
import {CreateIcon, GoBackArrowButtonIcon} from 'assets/icons';
import {
  createTaskButtonOutlinedWithoutLoading,
  goBackButton,
} from 'styles/MUIStyles/buttons';
import styles from './ManifestInformation.module.scss';
import {UserRole} from '../../../enums';
import Guard from '../../../components/Guard/Guard';

const ManifestsInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [isManifestCreateTaskModalOpen, setIsManifestCreateTaskModalOpen] =
    useState(false);

  const [getManifestById, {data: manifestData}] = useLazyGetManifestByIdQuery();

  useEffect(() => {
    if (params?.manifestId) {
      getManifestById(params.manifestId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.manifestId]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.manifestInformationTop}>
        <h1 className={styles.manifestInformationTitle}>Manifest</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Guard allowedRoles={[UserRole.Admin]}>
            <Button
              sx={createTaskButtonOutlinedWithoutLoading}
              startIcon={<CreateIcon />}
              onClick={() => setIsManifestCreateTaskModalOpen(true)}
            >
              Create task
            </Button>
          </Guard>
        </div>
      </div>
      <div className={styles.manifestInformationMainContent}>
        <div style={{height: '87%'}}>
          <ManifestsTable
            manifestData={manifestData || null}
            isManifestCreateTaskModalOpen={isManifestCreateTaskModalOpen}
            setIsManifestCreateTaskModalOpen={setIsManifestCreateTaskModalOpen}
          />
        </div>
      </div>
    </div>
  );
};

export default ManifestsInformation;
