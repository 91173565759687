import {FC, useEffect, useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {useUpdateEffect} from 'hooks';
import {ErrorMessage} from '@hookform/error-message';
import {useAddBranchToClientMutation} from 'store/slices/clients';
import {useLazyGetBranchesListQuery} from 'store/slices/branches';
import {IGetClientsList as IClient} from 'store/models/clients';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  Paper,
  Snackbar,
  SnackbarOrigin,
  TextField,
} from '@mui/material';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {searchAndSelect, searchAndSelectError} from 'styles/MUIStyles/common';
import {CloseIcon, SelectPopupIcon, SuccessNotification} from 'assets/icons';
import {cancelButton, createButtonModal} from 'styles/MUIStyles/buttons';
import {modal} from 'styles/MUIStyles/modal';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './ClientsAddBranch.module.scss';

interface IFormData {
  id: string;
  name: string;
  address: string;
  companyId: string;
  companyName: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  clickedClient: IClient | null;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const ClientsAddBranch: FC<Props> = (props) => {
  const {
    handleSubmit,
    clearErrors,
    reset,
    control,
    setError,
    formState: {errors},
  } = useForm<{
    branch: IFormData;
  }>({
    defaultValues: {
      branch: {
        id: '',
        name: '',
        address: '',
        companyId: '',
        companyName: '',
      },
    },
  });

  const [branchName, setBranchName] = useState<string | null>(null);
  const [snackbar, setSnackbar] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = snackbar;

  const [getBranches, {data: branches, isFetching}] =
    useLazyGetBranchesListQuery();

  const [
    addBranchToClient,
    {
      isSuccess,
      isLoading: isAddingBranchToClientLoading,
      error: addBranchToClientError,
    },
  ] = useAddBranchToClientMutation();

  const onSubmit: SubmitHandler<{branch: IFormData}> = async (formData) => {
    if (props.clickedClient) {
      const response = await addBranchToClient({
        clientId: props.clickedClient.id,
        branchId: formData.branch.id,
      });

      if (response && !('error' in response)) {
        setBranchName(formData.branch.name);
      }
    }
  };

  const clearDataAndErrors = () => {
    reset();
    clearErrors();
    props.onClose();
  };

  const handleSnackbarClose = () => {
    setBranchName(null);
    setSnackbar({...snackbar, open: false});
  };

  useUpdateEffect(() => {
    if (props.isOpen) {
      getBranches([]);
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (
      addBranchToClientError &&
      'data' in addBranchToClientError &&
      'status' in addBranchToClientError
    ) {
      if (addBranchToClientError.status === 400) {
        const errors = addBranchToClientError.data?.errors;

        if (errors?.branchId?.[0]) {
          setError('branch', {type: 'custom', message: errors?.branchId[0]});
        }
      }
    }
  }, [addBranchToClientError, setError]);

  useEffect(() => {
    if (isSuccess) {
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      reset();
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={clearDataAndErrors}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{...modal, width: '35%'}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.modalTop}>
              <h2 className={styles.clientAddCompanyTitle}>Add branch</h2>
              <CloseIcon onClick={clearDataAndErrors} />
            </div>
            <Controller
              name={'branch'}
              control={control}
              rules={{
                validate: (value) => {
                  if (!value?.name && !value?.id) {
                    return 'This field cannot be empty';
                  }
                },
              }}
              render={({field}) => (
                <Autocomplete
                  {...field}
                  options={branches || []}
                  loading={isFetching}
                  popupIcon={<SelectPopupIcon />}
                  PaperComponent={(props) => (
                    <Paper
                      sx={{
                        borderRadius: '12px',
                        boxShadow: '0px 4px 20px 0px #4444441F',
                        marginTop: '8px',
                        cursor: 'pointer',
                      }}
                      {...props}
                    />
                  )}
                  sx={{width: '100%'}}
                  ListboxProps={{
                    style: {maxHeight: '200px'},
                    className: 'autoCompleteListBox',
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value.name === '' ? true : option.id === value.id
                  }
                  getOptionLabel={(option) => (option?.name ? option.name : '')}
                  onChange={(_, value) => {
                    if (value) {
                      field.onChange(value);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  renderOption={(props: any, option) => (
                    <span {...props} className={styles.dropDownItem}>
                      {option?.name}
                    </span>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder="Select branch name"
                      sx={
                        errors?.branch
                          ? searchAndSelectError
                          : {...searchAndSelect, marginBottom: '16px'}
                      }
                    />
                  )}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name={'branch'}
              render={({message}) => (
                <span className={styles.inputErrorMessage}>{message}</span>
              )}
            />
            <Divider
              sx={{backgroundColor: '#E4E4EF', margin: '18px 0 14px 0'}}
            />
            <div className={styles.buttonsContainer}>
              <Button sx={cancelButton} onClick={clearDataAndErrors}>
                Cancel
              </Button>
              <ButtonWithProgress
                type="submit"
                sx={{...createButtonModal}}
                loading={isAddingBranchToClientLoading}
                disabled={isAddingBranchToClientLoading}
              >
                Add branch
              </ButtonWithProgress>
            </div>
          </form>
        </Box>
      </Modal>
      {branchName && props.clickedClient ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Branch {`"${branchName}"`} successfully added to user{' '}
              {`"${props.clickedClient.email}"`}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default ClientsAddBranch;
