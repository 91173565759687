import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {
  useDebounce,
  useLocalStorage,
  useSortColumn,
  useUpdateEffect,
} from 'hooks';
import {Button, Pagination, PaginationItem} from '@mui/material';
import DealersTable from './DealersTable/DealersTable';
import {
  CreateIcon,
  PaginationArrowLeft,
  PaginationArrowRight,
} from 'assets/icons';
import DealersTableTopToolbar from './DealersTableTopToolbar/DealersTableTopToolbar';
import {createButton} from 'styles/MUIStyles/buttons';
import {pagination} from 'styles/MUIStyles/common';
import DealersCreate from './DealersCreate/DealersCreate';
import styles from './Dealers.module.scss';
import {useGetDealersQuery} from '../../store/slices/dealers';

const Dealers: FC = () => {
  const [
    isRequestWithDeletedStatusSuccess,
    setIsRequestWithDeletedStatusSuccess,
  ] = useState(false);
  const [search, setSearch] = useLocalStorage<string>('dealersSearch', '');
  const debouncedSearch = useDebounce(search, 800);
  const [status, setStatus] = useLocalStorage('dealersStatus', null);
  const [sortConfig, handleColumnClick] = useSortColumn('dealersSortConfig');
  const [pageNumber, setPageNumber] = useLocalStorage('dealersPageNumber', 1);
  const [pageSize, setPageSize] = useLocalStorage('dealersPageSize', 10);
  const [isCreateDealersModalOpen, setIsCreateDealersModalOpen] =
    useState(false);

  const {
    data: dealersData,
    originalArgs,
    isSuccess,
  } = useGetDealersQuery({
    search: debouncedSearch,
    pageNumber,
    pageSize,
    sortBy: sortConfig,
    status,
  });

  const handleOnPageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  useEffect(() => {
    if (
      originalArgs &&
      originalArgs?.status?.queryName === 'isDeleted' &&
      isSuccess
    ) {
      setIsRequestWithDeletedStatusSuccess(true);
    } else {
      setIsRequestWithDeletedStatusSuccess(false);
    }
  }, [originalArgs, isSuccess]);

  useUpdateEffect(() => {
    if (pageNumber > 1) {
      setPageNumber(1);
    }
  }, [debouncedSearch, status, pageSize]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.dealersTop}>
        <h1 className={styles.dealersTitle}>Dealers</h1>
        <Button
          variant="contained"
          startIcon={<CreateIcon />}
          onClick={() => setIsCreateDealersModalOpen(true)}
          sx={createButton}
        >
          Create
        </Button>
      </div>
      <div className={styles.dealersMainContent}>
        <div className={styles.tableFiltersTop}>
          <DealersTableTopToolbar
            search={search}
            setSearch={setSearch}
            status={status}
            metaData={dealersData?.metadata || null}
            setStatus={setStatus}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        </div>
        <div style={{height: '87%', flexGrow: 1}}>
          <DealersTable
            dealers={dealersData?.data || []}
            metaData={dealersData?.metadata || null}
            sortConfig={sortConfig}
            handleColumnClick={handleColumnClick}
            search={debouncedSearch}
            status={status}
            pageNumber={pageNumber}
            pageSize={pageSize}
            isRequestWithDeletedStatusSuccess={
              isRequestWithDeletedStatusSuccess
            }
          />
        </div>
      </div>
      <div className={styles.pagination}>
        <Pagination
          count={dealersData?.metadata?.pageCount || 1}
          page={pageNumber}
          onChange={handleOnPageChange}
          sx={pagination}
          renderItem={(item) => (
            <PaginationItem
              slots={{
                previous: PaginationArrowLeft,
                next: PaginationArrowRight,
              }}
              {...item}
            />
          )}
        />
      </div>
      <DealersCreate
        isCreateDealersModalOpen={isCreateDealersModalOpen}
        setIsCreateDealersModalOpen={setIsCreateDealersModalOpen}
      />
    </div>
  );
};

export default Dealers;
