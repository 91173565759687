import {api} from './api';
import dayjs from 'dayjs';

export const reports = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getReportByStationId: builder.query({
        query: (reportsParams) => {
          const params = new URLSearchParams();

          if (reportsParams.reportType) {
            params.append('reportType', reportsParams.reportType);
          }

          if (reportsParams?.stationIds?.length) {
            const isAllSelected = reportsParams.stationIds.some(
              (station: any) => station?.id === 'all',
            );
            const isDealerZSkippedReport =
              reportsParams.reportType === 'DEALER_Z_SKIPPED_REPORT';

            if (isAllSelected) {
              params.append('stationIds', 'all');
            }
            if (isDealerZSkippedReport && !isAllSelected) {
              reportsParams.stationIds.forEach((station: any) => {
                params.append('stationIds', `${station.id}`);
              });
            }
            if (!isDealerZSkippedReport && !isAllSelected) {
              params.append('stationIds', reportsParams.stationIds[0]?.id);
            }
          }

          if (reportsParams.startDateTime) {
            params.append(
              'startDateTime',
              dayjs(reportsParams.startDateTime).utc().format(),
            );
          }

          if (reportsParams.endDateTime) {
            params.append(
              'endDateTime',
              dayjs(reportsParams.endDateTime).utc().format(),
            );
          }

          //   dealerBackupReportFilters
          //  lastZReportDate
          if (reportsParams.dealerBackupReportFiltersLastZReportDateFrom) {
            params.append(
              'dealerBackupReportFilters.lastZReportDate.from',
              dayjs(reportsParams.dealerBackupReportFiltersLastZReportDateFrom)
                .utc()
                .format(),
            );
          }
          if (reportsParams.dealerBackupReportFiltersLastZReportDateTo) {
            params.append(
              'dealerBackupReportFilters.lastZReportDate.to',
              dayjs(reportsParams.dealerBackupReportFiltersLastZReportDateTo)
                .utc()
                .format(),
            );
          }
          //  lastReceivedMainBackupDate
          if (
            reportsParams.dealerBackupReportFiltersLastReceivedMainBackupDateFrom
          ) {
            params.append(
              'dealerBackupReportFilters.lastReceivedMainBackupDate.from',
              dayjs(
                reportsParams.dealerBackupReportFiltersLastReceivedMainBackupDateFrom,
              )
                .utc()
                .format(),
            );
          }
          if (
            reportsParams.dealerBackupReportFiltersLastReceivedMainBackupDateTo
          ) {
            params.append(
              'dealerBackupReportFilters.lastReceivedMainBackupDate.to',
              dayjs(
                reportsParams.dealerBackupReportFiltersLastReceivedMainBackupDateTo,
              )
                .utc()
                .format(),
            );
          }

          //  lastReceivedNonMainBackupDate
          if (
            reportsParams.dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom
          ) {
            params.append(
              'dealerBackupReportFilters.lastReceivedNonMainBackupDate.from',
              dayjs(
                reportsParams.dealerBackupReportFiltersLastReceivedNonMainBackupDateFrom,
              )
                .utc()
                .format(),
            );
          }
          if (
            reportsParams.dealerBackupReportFiltersLastReceivedNonMainBackupDateTo
          ) {
            params.append(
              'dealerBackupReportFilters.lastReceivedNonMainBackupDate.to',
              dayjs(
                reportsParams.dealerBackupReportFiltersLastReceivedNonMainBackupDateTo,
              )
                .utc()
                .format(),
            );
          }
          //   lastDCRequestDate
          if (reportsParams.dealerBackupReportFiltersLastDCRequestDateFrom) {
            params.append(
              'dealerBackupReportFilters.lastDCRequestDate.from',
              dayjs(
                reportsParams.dealerBackupReportFiltersLastDCRequestDateFrom,
              )
                .utc()
                .format(),
            );
          }
          if (reportsParams.dealerBackupReportFiltersLastDCRequestDateTo) {
            params.append(
              'dealerBackupReportFilters.lastDCRequestDate.to',
              dayjs(reportsParams.dealerBackupReportFiltersLastDCRequestDateTo)
                .utc()
                .format(),
            );
          }

          if (reportsParams.isActive) {
            params.append('isActive', String(reportsParams.isActive));
          }

          return {
            url: `reports?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Reports'],
      }),
    };
  },
});

export const {useLazyGetReportByStationIdQuery} = reports;
