import React, {FC, useEffect, useState} from 'react';
import {IGetStationById} from 'store/models/stations';
import styles from '../StationInformation.module.scss';
import dayjs from 'dayjs';
import Guard from '../../../../components/Guard/Guard';
import {UserRole} from '../../../../enums';
import ButtonWithProgress from '../../../../components/ButtonWithProgress/ButtonWithProgress';
import {createButton} from '../../../../styles/MUIStyles/buttons';
import {useResetHandshakeMutation} from '../../../../store/slices/stations';
import {ISnackbarState} from '../../../../store/models/common';
import {useUpdateEffect} from '../../../../hooks';
import {Alert, Box, Snackbar} from '@mui/material';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from '../../../../styles/MUIStyles/snackbars';
import {
  CloseIcon,
  ErrorNotification,
  LinkIcon,
  PingIcon,
  RefreshIcon,
  SuccessNotification,
} from '../../../../assets/icons';
import {Link, useParams} from 'react-router-dom';
import {useLazyPingDataCollectorQuery} from '../../../../store/slices/dataCollector';
import LoadingIconSpacer from '../../../../components/LoadingIconSpacer/LoadingIconSpacer';

interface Props {
  stationData: IGetStationById | undefined;
  getStationById: (stationId: string) => any;
}

const MainInfoTab: FC<Props> = (props) => {
  const params = useParams();

  const [
    resetHandshake,
    {
      isLoading: isResettingHandshakeLoading,
      isSuccess: isResettingHandshakeSuccess,
      error: resetHandshakeError,
    },
  ] = useResetHandshakeMutation();

  const [
    pingDataCollector,
    {
      currentData: pingDataCollectorData,
      isSuccess: isPingingDataCollectorSuccess,
      isFetching: isPingingDataCollectorLoading,
      requestId,
      error: pingDataCollectorError,
    },
  ] = useLazyPingDataCollectorQuery();

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [snackbarError, setSnackbarError] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [resetHandshakeSnackbar, setResetHandshakeSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [pingDataCollectorSnackbar, setPingDataCollectorSnackbar] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {
    vertical: errorSnackbarVertical,
    horizontal: errorSnackbarHorizontal,
    open: errorSnackbarOpen,
  } = snackbarError;
  const {
    vertical: recoverResetHandshakeVertical,
    horizontal: recoverResetHandshakeHorizontal,
    open: recoverResetHandshakeOpen,
  } = resetHandshakeSnackbar;
  const {
    vertical: pingDataCollectorSnackbarVertical,
    horizontal: pingDataCollectorSnackbarHorizontal,
    open: pingDataCollectorSnackbarOpen,
  } = pingDataCollectorSnackbar;

  const handleSnackbarClose = () => {
    setResetHandshakeSnackbar({...resetHandshakeSnackbar, open: false});
    setPingDataCollectorSnackbar({...pingDataCollectorSnackbar, open: false});
    setSnackbarError({...snackbarError, open: false});
  };

  useUpdateEffect(() => {
    const refetchStationById = async () => {
      if (isResettingHandshakeSuccess && params?.stationId) {
        const response = await props.getStationById(params.stationId);

        if (response?.isSuccess) {
          setResetHandshakeSnackbar({
            vertical: 'top',
            horizontal: 'center',
            open: true,
          });
        }
      }
    };

    refetchStationById();
  }, [isResettingHandshakeSuccess, isPingingDataCollectorSuccess]);

  useEffect(() => {
    if (isPingingDataCollectorSuccess && pingDataCollectorData?.isAlive) {
      setPingDataCollectorSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }

    if (isPingingDataCollectorSuccess && !pingDataCollectorData?.isAlive) {
      setErrorMessage('DataCollector is not active');
      setSnackbarError({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }
  }, [isPingingDataCollectorSuccess, pingDataCollectorData, requestId]);

  useUpdateEffect(() => {
    if (resetHandshakeError) {
      if ('data' in resetHandshakeError && 'status' in resetHandshakeError) {
        if (
          resetHandshakeError.status === 400 ||
          resetHandshakeError.status === 404
        ) {
          const errors = resetHandshakeError.data?.errors;

          for (let key in errors) {
            if (Array.isArray(errors[key])) {
              //@ts-ignore
              setErrorMessage(errors[key]);
              setSnackbarError({
                vertical: 'top',
                horizontal: 'center',
                open: true,
              });
              break;
            }
          }
        }
      }
    }

    if (pingDataCollectorError) {
      if (
        'data' in pingDataCollectorError &&
        'status' in pingDataCollectorError
      ) {
        if (
          pingDataCollectorError.status === 400 ||
          pingDataCollectorError.status === 404
        ) {
          const errors = (pingDataCollectorError.data as any).errors;

          for (let key in errors) {
            if (Array.isArray(errors[key])) {
              //@ts-ignore
              setErrorMessage(errors[key]);
              setSnackbarError({
                vertical: 'top',
                horizontal: 'center',
                open: true,
              });
              break;
            }
          }
        }
      }
    }
  }, [resetHandshakeError, pingDataCollectorError]);

  return (
    <div>
      <div className={styles.stationMainInfo}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {props?.stationData?.audit?.deleting ? (
            <span className={`${styles.dealerStatus} ${styles.deleted}`}>
              Removed
            </span>
          ) : (
            <span
              className={
                props?.stationData?.isActive
                  ? `${styles.dealerStatus} ${styles.active}`
                  : `${styles.dealerStatus} ${styles.inactive}`
              }
            >
              {props?.stationData?.isActive ? 'Active' : 'Inactive'}
            </span>
          )}
          <Box sx={{display: 'flex', gap: '12px'}}>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <ButtonWithProgress
                loading={isResettingHandshakeLoading}
                disabled={isResettingHandshakeLoading}
                startIcon={
                  isResettingHandshakeLoading ? (
                    <LoadingIconSpacer width={'16px'} height={'16px'} />
                  ) : (
                    <RefreshIcon
                      style={{
                        filter:
                          'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)',
                      }}
                    />
                  )
                }
                sx={{
                  ...createButton,
                  minWidth: '200px',
                  maxWidth: '300px',
                  maxHeight: '40px',
                }}
                onClick={() => {
                  if (params?.stationId) {
                    resetHandshake(params.stationId);
                  }
                }}
              >
                Reset handshake ID
              </ButtonWithProgress>
            </Guard>
            <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
              <ButtonWithProgress
                loading={isPingingDataCollectorLoading}
                disabled={
                  isPingingDataCollectorLoading ||
                  !props.stationData?.handshakeId
                }
                startIcon={
                  isPingingDataCollectorLoading ? (
                    <LoadingIconSpacer width={'24px'} height={'24px'} />
                  ) : (
                    <PingIcon
                      style={{
                        filter:
                          'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)',
                      }}
                    />
                  )
                }
                sx={{
                  ...createButton,
                  background: !props.stationData?.handshakeId
                    ? '#E9EAEB'
                    : '#605DEC',
                  color: !props.stationData?.handshakeId ? '#94979B' : '#fff',
                  border: !props.stationData?.handshakeId
                    ? '1px solid transparent'
                    : '1px solid #605DEC',
                  minWidth: '200px',
                  maxWidth: '350px',
                  maxHeight: '40px',
                }}
                onClick={() => {
                  if (params?.stationId) {
                    pingDataCollector(params.stationId);
                  }
                }}
              >
                Ping DataCollector
              </ButtonWithProgress>
            </Guard>
          </Box>
        </div>
        <div>
          <span>Name</span>
          <span>{props?.stationData?.name}</span>
        </div>

        <div>
          <span>Fiscal device id</span>
          <span>{props?.stationData?.fiscalDeviceId}</span>
        </div>

        <div>
          <span>PosChassis number</span>
          <span>{props?.stationData?.posChassicNumber}</span>
        </div>

        <div>
          <span>Station Type</span>
          <span>{props?.stationData?.stationTypeName}</span>
        </div>

        <div>
          <h3 className={styles.additionalInformation}>
            <span>Additional Information</span>
          </h3>
        </div>

        <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
          <div>
            <span>Handshake ID</span>
            <span>{props?.stationData?.handshakeId}</span>
          </div>
        </Guard>

        <div>
          <span>Last received time</span>
          <span>
            {props?.stationData?.lastReceivedDateTime
              ? dayjs(props?.stationData?.lastReceivedDateTime).format(
                  'DD.MM.YYYY HH:mm:ss',
                )
              : null}
          </span>
        </div>

        <div>
          <span>Last received filename</span>
          <span>{props?.stationData?.lastReceivedFileName}</span>
        </div>

        <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
          <div>
            <span>Last DataCollector alive time</span>
            <span>
              {props?.stationData?.lastDataCollectorAliveTimeUtc
                ? dayjs
                    .utc(props?.stationData?.lastDataCollectorAliveTimeUtc)
                    .tz(dayjs.tz.guess())
                    .format('DD.MM.YYYY HH:mm')
                : null}
            </span>
          </div>
        </Guard>

        <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
          <div>
            <span>Last DataCollector request time</span>
            <span>
              {props?.stationData?.lastDataCollectorRequestTimeUtc
                ? dayjs
                    .utc(props?.stationData?.lastDataCollectorRequestTimeUtc)
                    .tz(dayjs.tz.guess())
                    .format('DD.MM.YYYY HH:mm')
                : null}
            </span>
          </div>
        </Guard>

        <div>
          <span>All backup size</span>
          <span>{props?.stationData?.allBackupSize}</span>
        </div>

        <div className={styles.companyStations}>
          {props?.stationData?.companies.length ? <h3>Companies</h3> : null}
          {props?.stationData?.companies.map((company) => (
            <span key={company.id}>
              {company.name}{' '}
              <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
                <Link to={`/companies/information/${company.id}`}>
                  <LinkIcon style={{height: '12px', width: '12px'}} />
                </Link>
              </Guard>
            </span>
          ))}
        </div>

        <div className={styles.branchesStations}>
          {props?.stationData?.branches.length ? <h3>Branches</h3> : null}
          {props?.stationData?.branches.map((branch) => (
            <span key={branch.id}>
              {branch.name}{' '}
              <Guard allowedRoles={[UserRole.Admin, UserRole.Dealer]}>
                <Link to={`/branches/information/${branch.id}`}>
                  <LinkIcon style={{height: '12px', width: '12px'}} />
                </Link>
              </Guard>
            </span>
          ))}
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: recoverResetHandshakeVertical,
          horizontal: recoverResetHandshakeHorizontal,
        }}
        autoHideDuration={2500}
        open={recoverResetHandshakeOpen}
        onClose={handleSnackbarClose}
        key={`resetHandshake`}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            HandshakeId was reset successfully
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: pingDataCollectorSnackbarVertical,
          horizontal: pingDataCollectorSnackbarHorizontal,
        }}
        autoHideDuration={1500}
        open={pingDataCollectorSnackbarOpen}
        onClose={handleSnackbarClose}
        key={`pingDataCollector`}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            DataCollector is alive
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorSnackbarVertical,
            horizontal: errorSnackbarHorizontal,
          }}
          autoHideDuration={3500}
          open={errorSnackbarOpen}
          onClose={handleSnackbarClose}
          key={'errorSnackbar'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default MainInfoTab;
