import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Snackbar,
  SnackbarOrigin,
  Stack,
  styled,
  Switch,
} from '@mui/material';
import {
  addBranchInEdit,
  cancelButton,
  warningModalButton as createButton,
} from 'styles/MUIStyles/buttons';
import {CloseIcon, PlusIcon, SaveIcon, SuccessNotification} from 'assets/icons';
import {ErrorMessage} from '@hookform/error-message';
import {androidSwitch, switchLabel} from 'styles/MUIStyles/common';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './CompanyOwnersEdit.module.scss';
import WarningModal from 'components/WarningModal/WarningModal';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import LoadingIconSpacer from '../../../components/LoadingIconSpacer/LoadingIconSpacer';
import {
  useCompanyOwnerResetPasswordByAdminMutation,
  useCompanyOwnerResetPasswordByDealerMutation,
  useDeleteCompanyFromCompanyOwnerMutation,
  useEditCompanyOwnerMutation,
  useLazyGetCompanyOwnerByIdQuery,
} from 'store/slices/companyOwners';
import {useUpdateEffect} from '../../../hooks';
import CompanyOwnersAddCompany from '../CompanyOwnersAddCompany/CompanyOwnersAddCompany';
import {useAppSelector} from 'store/store';
import {UserRole} from 'enums';

interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  additionalInformation: string;
  isActive: boolean;
}

const AndroidSwitch = styled(Switch)(() => androidSwitch);

interface State extends SnackbarOrigin {
  open: boolean;
}

const CompanyOwnersEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userRole = useAppSelector((state) => state.auth.role);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    setError,
    formState: {errors},
  } = useForm<IFormData>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      additionalInformation: '',
      isActive: false,
    },
  });

  const [getCompanyOwnerById, {data: companyOwnerData, isFetching}] =
    useLazyGetCompanyOwnerByIdQuery();

  const [
    editCompanyOwner,
    {
      isLoading: isCompanyOwnerEditingLoading,
      isSuccess: isCompanyOwnerEditingSuccess,
      error: editCompanyOwnerError,
    },
  ] = useEditCompanyOwnerMutation();

  const [
    companyOwnerResetPasswordByAdmin,
    {
      isLoading: isResettingCompanyOwnerPasswordByAdminLoading,
      isSuccess: isResettingCompanyOwnerPasswordByAdminSuccess,
    },
  ] = useCompanyOwnerResetPasswordByAdminMutation();

  const [
    companyOwnerResetPasswordByDealer,
    {
      isLoading: isResettingCompanyOwnerPasswordByDealerLoading,
      isSuccess: isResettingCompanyOwnerPasswordByDealerSuccess,
    },
  ] = useCompanyOwnerResetPasswordByDealerMutation();

  const [
    deleteCompanyFromCompanyOwner,
    {
      isLoading: isCompanyDeletingFromCompanyOwnerLoading,
      isSuccess: isCompanyDeletingFromCompanyOwnerSuccess,
    },
  ] = useDeleteCompanyFromCompanyOwnerMutation();

  const [deleteCompany, setDeleteCompany] = useState<{
    name: string;
    id: string;
  } | null>(null);
  const [isAddCompanyToOwnerModalOpen, setIsAddCompanyToOwnerModalOpen] =
    useState(false);
  const [isDeleteCompanyModalOpen, setIsDeleteCompanyModalOpen] =
    useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [resetCompanyOwnerSnackbar, setResetCompanyOwnerSnackbar] =
    React.useState<State>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const [
    deleteCompanyFromCompanyOwnerSnackbar,
    setDeleteCompanyFromCompanyOwnerSnackbar,
  ] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [successEditSnackbar, setSuccessEditSnackbar] = React.useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {
    vertical: resetVertical,
    horizontal: resetHorizontal,
    open: resetOpen,
  } = resetCompanyOwnerSnackbar;
  const {
    vertical: deleteVertical,
    horizontal: deleteHorizontal,
    open: deleteOpen,
  } = deleteCompanyFromCompanyOwnerSnackbar;
  const {
    vertical: editVertical,
    horizontal: editHorizontal,
    open: editOpen,
  } = successEditSnackbar;

  const onSubmit = async (companyOwnersData: IFormData) => {
    if (params?.companyOwnerId) {
      editCompanyOwner({
        ...companyOwnersData,
        id: params.companyOwnerId,
        firstName: companyOwnersData.firstName.trim(),
        lastName: companyOwnersData.lastName.trim(),
        email: companyOwnersData.email.trim(),
        phoneNumber: companyOwnersData.phoneNumber.trim(),
        additionalInformation: companyOwnersData.additionalInformation.trim(),
      });
    }
  };

  const handleSnackbarClose = () => {
    setSuccessEditSnackbar({...successEditSnackbar, open: false});
    setResetCompanyOwnerSnackbar({...resetCompanyOwnerSnackbar, open: false});
    setDeleteCompanyFromCompanyOwnerSnackbar({
      ...deleteCompanyFromCompanyOwnerSnackbar,
      open: false,
    });
  };

  useEffect(() => {
    if (params?.companyOwnerId) {
      getCompanyOwnerById(params.companyOwnerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.companyOwnerId]);

  useUpdateEffect(() => {
    if (isCompanyOwnerEditingSuccess) {
      setSuccessEditSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
    }

    if (
      isResettingCompanyOwnerPasswordByAdminSuccess ||
      isResettingCompanyOwnerPasswordByDealerSuccess
    ) {
      setResetCompanyOwnerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsResetPasswordModalOpen(false);
    }

    if (isCompanyDeletingFromCompanyOwnerSuccess && params.companyOwnerId) {
      setDeleteCompanyFromCompanyOwnerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsDeleteCompanyModalOpen(false);
      getCompanyOwnerById(params.companyOwnerId);
    }
  }, [
    isCompanyOwnerEditingSuccess,
    isCompanyDeletingFromCompanyOwnerSuccess,
    isResettingCompanyOwnerPasswordByAdminSuccess,
    isResettingCompanyOwnerPasswordByDealerSuccess,
  ]);

  useEffect(() => {
    if (companyOwnerData) {
      setValue('firstName', companyOwnerData.firstName);
      setValue('lastName', companyOwnerData.lastName);
      setValue('email', companyOwnerData.email);
      setValue('phoneNumber', companyOwnerData.phoneNumber);
      setValue(
        'additionalInformation',
        companyOwnerData?.additionalInformation || '',
      );
      setValue('isActive', companyOwnerData.isActive);
    }
  }, [companyOwnerData, setValue]);

  useUpdateEffect(() => {
    if (editCompanyOwnerError) {
      if ('data' in editCompanyOwnerError) {
        const errors = editCompanyOwnerError.data?.errors;
        if (errors?.email?.[0] || errors?.Email?.[0]) {
          setError('email', {
            type: 'custom',
            message: errors?.email?.[0] || errors?.Email?.[0],
          });
        }

        if (errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0]) {
          setError('phoneNumber', {
            type: 'custom',
            message: errors?.phoneNumber?.[0] || errors?.PhoneNumber?.[0],
          });
        }
      }
    }
  }, [editCompanyOwnerError, setError]);

  return (
    <div style={{height: '100%'}}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.companyOwnersEditForm}
      >
        {isFetching ? (
          <CircularProgress
            size={45}
            sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
          />
        ) : (
          <>
            <div className={styles.companyOwnersEditTop}>
              <h1 className={styles.companyOwnersEditTitle}>
                Edit company owner
              </h1>
              <div className={styles.companyOwnersButtons}>
                <Button onClick={() => navigate(-1)} sx={cancelButton}>
                  Cancel
                </Button>
                <ButtonWithProgress
                  startIcon={
                    isCompanyOwnerEditingLoading ? (
                      <LoadingIconSpacer width={'16px'} height={'16px'} />
                    ) : (
                      <SaveIcon />
                    )
                  }
                  disabled={isCompanyOwnerEditingLoading}
                  loading={isCompanyOwnerEditingLoading}
                  type="submit"
                  sx={createButton}
                >
                  Save
                </ButtonWithProgress>
              </div>
            </div>
            <div className={styles.companyOwnersEditMainContent}>
              <div className={styles.firstNameInputContainer}>
                <label htmlFor="firstName">First Name</label>
                <input
                  {...register('firstName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="firstName"
                  autoComplete="off"
                  className={`${
                    errors?.firstName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter First Name"
                />
                <ErrorMessage
                  name="firstName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.lastNameInputContainer}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  {...register('lastName', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="lastName"
                  autoComplete="off"
                  className={`${
                    errors?.lastName ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter Last Name"
                />
                <ErrorMessage
                  name="lastName"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.emailInputContainer}>
                <label htmlFor="email">Email</label>
                <input
                  {...register('email', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      message: 'Invalid email address',
                    },
                  })}
                  type="text"
                  id="email"
                  autoComplete="off"
                  className={`${errors?.email ? 'input inputError' : 'input'}`}
                  placeholder="Enter Email"
                />
                <ErrorMessage
                  name="email"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.phoneNumberInputContainer}>
                <label htmlFor="phoneNumber">Phone number</label>
                <input
                  {...register('phoneNumber', {
                    required: 'This field is required',
                  })}
                  type="text"
                  id="phoneNumber"
                  autoComplete="off"
                  className={`${
                    errors?.phoneNumber ? 'input inputError' : 'input'
                  }`}
                  placeholder="Enter phone number"
                />
                <ErrorMessage
                  name="phoneNumber"
                  errors={errors}
                  render={({message}) => (
                    <span className={styles.inputErrorMessage}>{message}</span>
                  )}
                />
              </div>

              <div className={styles.additionalInfoInputContainer}>
                <label htmlFor="additionalInformation">
                  Additional information - Optional
                </label>
                <input
                  {...register('additionalInformation')}
                  type="text"
                  id="additionalInformation"
                  autoComplete="off"
                  className={`input`}
                  placeholder="Enter additional information"
                />
              </div>

              {companyOwnerData?.companies.length ? (
                <div className={styles.companyOwnerCompaniesList}>
                  <h3>Companies</h3>
                  {companyOwnerData.companies.map((company) => (
                    <div key={company.id} className={styles.companiesListItem}>
                      <span>{company.name}</span>
                      <Button
                        variant="text"
                        onClick={() => {
                          setDeleteCompany(company);
                          setIsDeleteCompanyModalOpen(true);
                        }}
                        sx={{
                          fontFamily: 'Poppins, sans-serif',
                          textTransform: 'capitalize',
                          color: '#3152C7',
                          lineHeight: '16px',
                          fontSize: '14px',
                        }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              ) : null}

              <Button
                variant="text"
                startIcon={<PlusIcon />}
                sx={addBranchInEdit}
                onClick={() => setIsAddCompanyToOwnerModalOpen(true)}
              >
                Add company
              </Button>

              <div className={styles.dealerEditControls}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Controller
                    name={'isActive'}
                    control={control}
                    render={({field}) => (
                      <FormGroup>
                        <FormControlLabel
                          sx={switchLabel}
                          control={
                            <AndroidSwitch {...field} checked={field.value} />
                          }
                          label={field.value ? 'active' : 'inactive'}
                        />
                      </FormGroup>
                    )}
                  />
                </Stack>
                <Button
                  onClick={() => setIsResetPasswordModalOpen(true)}
                  sx={cancelButton}
                >
                  Reset Password
                </Button>
              </div>
            </div>
          </>
        )}
      </form>

      {deleteCompany ? (
        <>
          <WarningModal
            isOpen={isDeleteCompanyModalOpen}
            onClose={() => setIsDeleteCompanyModalOpen(false)}
            entityText=""
            title="Remove Company ?"
            textBlocks={[
              `Are you sure you want to remove company "${deleteCompany.name}" ?`,
            ]}
            confirmButtonText="Yes, remove"
            onConfirmButtonClick={() => {
              if (params?.companyOwnerId && deleteCompany) {
                deleteCompanyFromCompanyOwner({
                  ownerId: params.companyOwnerId,
                  companyId: deleteCompany.id,
                });
              }
            }}
            loading={isCompanyDeletingFromCompanyOwnerLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: deleteVertical,
              horizontal: deleteHorizontal,
            }}
            autoHideDuration={4000}
            open={deleteOpen}
            onClose={handleSnackbarClose}
            key={'deleteCompanyFromCompanyOwner'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Company {`"${deleteCompany.name}"`} was deleted
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {companyOwnerData?.email && (
        <>
          <WarningModal
            isOpen={isResetPasswordModalOpen}
            onClose={() => setIsResetPasswordModalOpen(false)}
            entityText={companyOwnerData.email}
            title="Reset Password ?"
            textBlocks={[
              `Are you sure you want to reset company owner's password ?`,
              'Password reset link will be sent to email',
            ]}
            confirmButtonText="Yes, reset"
            onConfirmButtonClick={() => {
              if (params?.companyOwnerId) {
                if (userRole === UserRole.Admin) {
                  companyOwnerResetPasswordByAdmin(params.companyOwnerId);
                } else if (userRole === UserRole.Dealer) {
                  companyOwnerResetPasswordByDealer(params.companyOwnerId);
                }
              }
            }}
            loading={
              isResettingCompanyOwnerPasswordByAdminLoading ||
              isResettingCompanyOwnerPasswordByDealerLoading
            }
          />
          <Snackbar
            anchorOrigin={{
              vertical: resetVertical,
              horizontal: resetHorizontal,
            }}
            autoHideDuration={4000}
            open={resetOpen}
            onClose={handleSnackbarClose}
            key={'resetCompanyOwnerPassword'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Password sent to email {companyOwnerData?.email}
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      )}
      <Snackbar
        anchorOrigin={{vertical: editVertical, horizontal: editHorizontal}}
        autoHideDuration={2500}
        open={editOpen}
        onClose={handleSnackbarClose}
        key={'editSnackbar'}
      >
        <Alert icon={false} sx={snackbarSuccessAlert}>
          <Box sx={snackbarAlertContent}>
            <SuccessNotification />
            The data was successfully saved
            <span onClick={handleSnackbarClose}>
              <CloseIcon />
            </span>
          </Box>
        </Alert>
      </Snackbar>

      {companyOwnerData && (
        <CompanyOwnersAddCompany
          isOpen={isAddCompanyToOwnerModalOpen}
          onClose={() => setIsAddCompanyToOwnerModalOpen(false)}
          clickedCompanyOwner={{
            id: companyOwnerData.id,
            firstName: companyOwnerData.firstName,
            lastName: companyOwnerData.lastName,
            email: companyOwnerData.email,
            phoneNumber: companyOwnerData.phoneNumber,
            additionalInformation: companyOwnerData.additionalInformation,
            isActive: companyOwnerData.isActive,
            isLockedOut: companyOwnerData.isLockedOut,
          }}
        />
      )}
    </div>
  );
};

export default CompanyOwnersEdit;
