import {Alert, Box, Snackbar} from '@mui/material';
import {CloseIcon, ErrorNotification, SuccessNotification} from 'assets/icons';
import {FC, useState} from 'react';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';

import {ISnackbarState} from 'store/models/common';
import WarningModal from 'components/WarningModal/WarningModal';
import {useMarkAsDeletedMutation} from 'store/slices/backups';
import {useUpdateEffect} from 'hooks';

interface Props {
  isOpen: boolean;
  onClose: (state: boolean) => void;
  selectedBackupFile: any;
  closeModalEditing: () => void;
}

const MarkAsDeleteModal: FC<Props> = ({
  isOpen,
  onClose,
  selectedBackupFile,
  closeModalEditing,
}) => {
  const [snackbar, setSnackbar] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const {vertical, horizontal, open} = snackbar;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [snackbarError, setSnackbarError] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const {
    vertical: errorVertical,
    horizontal: errorHorizontal,
    open: isErrorOpen,
  } = snackbarError;

  const isObject = (value: any) => {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  };

  const [markAsDelete, result] = useMarkAsDeletedMutation();

  const submitHandler = async () => {
    if (!selectedBackupFile) {
      setErrorMessage('File is required');
      setSnackbarError({vertical: 'top', horizontal: 'center', open: true});

      return;
    }
    try {
      if (isObject(selectedBackupFile)) {
        await markAsDelete({
          fileId: selectedBackupFile?.id,
          isDelete: selectedBackupFile?.deletedDateTime ? false : true,
        }).unwrap();
      } else {
        const res = selectedBackupFile.map((file: any) => ({
          fileId: file.id,
          isDelete: file.deletedDateTime ? false : true,
        }));
        await markAsDelete(res).unwrap();
        closeModalEditing();
      }
      setSnackbar({vertical: 'top', horizontal: 'center', open: true});
      handleClose();
    } catch (error: any) {
      const errorKey = Object.keys(error?.data?.errors)[0];
      setErrorMessage((error as any)?.data.errors[errorKey][0]);
      setSnackbarError({vertical: 'top', horizontal: 'center', open: true});
    }
  };

  const handleClose = () => {
    onClose(false);
  };

  const handleSnackbarClose = () => {
    setSnackbar({...snackbar, open: false});
    setSnackbarError({
      ...snackbarError,
      open: false,
    });
    setErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (isOpen) {
    }
  }, [isOpen]);

  const selelected = isObject(selectedBackupFile);
  return (
    <div>
      <WarningModal
        isOpen={isOpen}
        onClose={handleClose}
        entityText={''}
        title={
          selelected
            ? selectedBackupFile?.deletedDateTime
              ? 'Restore?'
              : 'Mark as deleted ?'
            : selectedBackupFile?.[0]?.deletedDateTime
              ? 'Restore?'
              : 'Mark as deleted ?'
        }
        textBlocks={[
          selelected
            ? `Are you sure you want to ${
                selectedBackupFile?.deletedDateTime
                  ? 'restore'
                  : 'mark as deleted'
              } backup file ${selectedBackupFile?.fileName} ?`
            : `Are you sure you want to ${
                selectedBackupFile?.[0]?.deletedDateTime
                  ? 'restore'
                  : 'mark as deleted'
              } backup files ?`,
        ]}
        confirmButtonText={
          selectedBackupFile?.deletedDateTime ? 'Yes, restore' : 'Yes, remove'
        }
        onConfirmButtonClick={submitHandler}
        loading={result.isLoading}
      />
      {result.isSuccess && result.data ? (
        <Snackbar
          anchorOrigin={{vertical, horizontal}}
          autoHideDuration={2500}
          open={open}
          onClose={handleSnackbarClose}
          key={vertical + horizontal}
        >
          <Alert icon={false} sx={snackbarSuccessAlert}>
            <Box sx={snackbarAlertContent}>
              <SuccessNotification />
              Backup file {selectedBackupFile?.fileName} successfully{' '}
              {selectedBackupFile?.deletedDateTime
                ? 'restored'
                : 'marked as deleted'}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: errorVertical,
            horizontal: errorHorizontal,
          }}
          autoHideDuration={3500}
          open={isErrorOpen}
          onClose={handleSnackbarClose}
          key={'createManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {errorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </div>
  );
};

export default MarkAsDeleteModal;
