import {api} from './api';
import {
  IGetLogsResponse,
  IGetLogsArgs,
  IDeleteLogsArgs,
  IGetUpdaterAlertsResponse,
  IGetUpdaterAlertsArgs,
} from '../models/logs';
import dayjs from 'dayjs';

export const logs = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getSystemLogs: builder.query<IGetLogsResponse, IGetLogsArgs>({
        query: (logsParams) => {
          const params = new URLSearchParams();

          if (logsParams.search) {
            params.append('message', logsParams.search);
          }

          if (logsParams.status) {
            params.append(
              logsParams.status.queryName,
              String(logsParams.status.value),
            );
          }

          if (logsParams.pageNumber) {
            params.append('pageNumber', String(logsParams.pageNumber));
          }

          if (logsParams.pageSize) {
            params.append('pageSize', String(logsParams.pageSize));
          }

          if (logsParams.startTime) {
            params.append(
              'startTime',
              dayjs(logsParams.startTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (logsParams.endTime) {
            params.append(
              'endTime',
              dayjs(logsParams.endTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (logsParams.sortBy?.column && logsParams.sortBy.direction) {
            const column = logsParams.sortBy?.column;
            const direction = logsParams.sortBy.direction;
            if (column && direction === 'asc') {
              params.append('orderByCreatedDateTimeAsc', 'true');
            }

            if (column && direction === 'desc') {
              params.append('orderByCreatedDateTimeAsc', 'false');
            }
          }

          return {
            url: `logs?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['SystemLogs'],
      }),
      getUpdaterAlerts: builder.query<
        IGetUpdaterAlertsResponse,
        IGetUpdaterAlertsArgs
      >({
        query: (alertsParams) => {
          const params = new URLSearchParams();

          if (alertsParams.search) {
            params.append('term', alertsParams.search);
          }

          if (alertsParams.posChassisNumberSearch) {
            params.append(
              'posChassisNumber',
              alertsParams.posChassisNumberSearch,
            );
          }

          if (alertsParams.fiscalDeviceIdSearch) {
            params.append('fiscalDeviceId', alertsParams.fiscalDeviceIdSearch);
          }

          if (alertsParams.companies?.length) {
            alertsParams.companies.forEach((c) => {
              params.append('companyIds', c.id);
            });
          }
          if (alertsParams.branches?.length) {
            alertsParams.branches.forEach((b) => {
              params.append('branchIds', b.id);
            });
          }
          if (alertsParams.stations?.length) {
            alertsParams.stations.forEach((s) => {
              params.append('stationIds', s.id);
            });
          }

          if (alertsParams.pageNumber) {
            params.append('pageNumber', String(alertsParams.pageNumber));
          }

          if (alertsParams.pageSize) {
            params.append('pageSize', String(alertsParams.pageSize));
          }

          if (alertsParams.startTime) {
            params.append(
              'startDateTime',
              dayjs(alertsParams.startTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (alertsParams.endTime) {
            params.append(
              'endDateTime',
              dayjs(alertsParams.endTime)
                .subtract(6, 'hour')
                .format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
            );
          }

          if (alertsParams.sortBy?.column && alertsParams.sortBy.direction) {
            const column = alertsParams.sortBy?.column;
            const direction = alertsParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }
          return {
            url: `updater/updater-alerts?${params.toString()}`,
            method: 'get',
          };
        },
      }),
      deleteLogs: builder.mutation<string, IDeleteLogsArgs>({
        query: (logsParams) => {
          const params = new URLSearchParams();

          if (logsParams.startTime) {
            params.append('startTime', String(logsParams.startTime + 'Z'));
          }

          if (logsParams.endTime) {
            params.append('endTime', String(logsParams.endTime + 'Z'));
          }

          return {
            url: `logs?${params.toString()}`,
            method: 'delete',
          };
        },
        invalidatesTags: ['SystemLogs'],
      }),
    };
  },
});

export const {
  useGetSystemLogsQuery,
  useDeleteLogsMutation,
  useGetUpdaterAlertsQuery,
} = logs;
