import {FC} from 'react';
import ButtonWithProgress from 'components/ButtonWithProgress/ButtonWithProgress';
import {DownloadIcon} from 'assets/icons';
import {checkAllowedRoles, downloadAllBackupFiles} from 'core/utils';
import {UserRole} from 'enums';
import {
  createButton as downloadButton,
  disabledCreateButtonModal as disabledDownloadButton,
} from 'styles/MUIStyles/buttons';
import {
  IGetAllBackupsListModified,
  IGetMainBackupsListModified,
} from 'store/models/backups';
import {useAppSelector} from '../../../store/store';

interface Props {
  modifiedMainBackups: IGetMainBackupsListModified[];
  modifiedAllBackups: IGetAllBackupsListModified[];
  activeTab: number;
  downloadHandler: (backupIds: number[]) => Promise<void>;
  loading: boolean;
  downloadFileLoading: boolean;
  setDownloadFileLoading: (isLoading: boolean) => void;
}

const DownloadBackupsButton: FC<Props> = (props) => {
  const userRole = useAppSelector((state) => state.auth.role);

  const checkIsDownloadButtonDisabled = () => {
    const backupsToCheck =
      props.activeTab === 0
        ? props.modifiedMainBackups
        : props.modifiedAllBackups;
    return !backupsToCheck.some((backup) => backup.isChecked);
  };

  return (
    <>
      {props.activeTab === 0 ? (
        <ButtonWithProgress
          loading={props.loading}
          disabled={checkIsDownloadButtonDisabled()}
          startIcon={
            <DownloadIcon
              style={{
                width: '16px',
                height: '16px',
                filter:
                  'invert(99%) sepia(98%) saturate(5%) hue-rotate(251deg) brightness(106%) contrast(100%)',
              }}
            />
          }
          onClick={() => {
            if (props.modifiedMainBackups) {
              props.downloadHandler(
                props.modifiedMainBackups
                  .filter((backup) => backup.isChecked)
                  .map((backup) => backup.id),
              );
            }
          }}
          sx={
            (props.modifiedMainBackups.filter((backup) => backup.isChecked)
              .length &&
              downloadButton) ||
            disabledDownloadButton
          }
        >
          Download
        </ButtonWithProgress>
      ) : null}
      {props.activeTab === 1 &&
      checkAllowedRoles([UserRole.Admin, UserRole.Dealer], userRole) ? (
        <ButtonWithProgress
          loading={false}
          disabled={checkIsDownloadButtonDisabled()}
          startIcon={
            <DownloadIcon
              style={{
                filter:
                  'invert(99%) sepia(98%) saturate(5%) hue-rotate(251deg) brightness(106%) contrast(100%)',
              }}
            />
          }
          onClick={() => {
            if (props.modifiedAllBackups) {
              downloadAllBackupFiles(
                props.modifiedAllBackups.filter((backup) => backup.isChecked),
                props.setDownloadFileLoading,
              );
            }
          }}
          sx={
            (props.modifiedAllBackups.filter((backup) => backup.isChecked)
              .length &&
              downloadButton) ||
            disabledDownloadButton
          }
        >
          Download
        </ButtonWithProgress>
      ) : null}
    </>
  );
};

export default DownloadBackupsButton;
