import React, {FC, useState} from 'react';
import {UseFormGetValues} from 'react-hook-form';
import {IGetDefaultManifest, IStationType} from 'store/models/stations';
import {
  Box,
  Button,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CreateTask from '../CreateTask/CreateTask';
import {CreateIconBlue} from 'assets/icons';
import {createButtonOutlinedWithoutLoading} from 'styles/MUIStyles/buttons';
import {
  styledTableBody,
  styledTableHead,
  styledTableRow,
  tableContainer,
  tableHeadCell,
} from 'styles/MUIStyles/table';
import styles from '../StationsCreate.module.scss';
import Guard from '../../../../components/Guard/Guard';
import {UserRole} from '../../../../enums';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: styledTableHead,
  borderBottom: '1px solid #E4E4EF',
  [`&.${tableCellClasses.body}`]: {
    ...styledTableBody,
    padding: '18px 16px 18px 16px',
  },
}));
const StyledTableRow = styled(TableRow)(() => styledTableRow);

interface Props {
  defaultManifest: IGetDefaultManifest | undefined;
  getDataFromMainInfo: UseFormGetValues<{
    name: string;
    posChassicNumber: string;
    fiscalDeviceId: string;
    stationType: IStationType;
  }>;
}

const Manifest: FC<Props> = (props) => {
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);

  return (
    <div>
      <div className={styles.stationManifestTopToolbarContainer}>
        <Guard allowedRoles={[UserRole.Admin]}>
          <Button
            sx={createButtonOutlinedWithoutLoading}
            onClick={() => setIsCreateTaskModalOpen(true)}
            startIcon={<CreateIconBlue />}
          >
            Create task
          </Button>
        </Guard>
      </div>

      <TableContainer component={Paper} sx={tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell sx={{width: '5'}}>№</StyledTableCell>
              <StyledTableCell sx={{width: '95%'}}>
                <Box sx={tableHeadCell}>
                  <span>Name task</span>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.defaultManifest?.commands.map((command, index) => (
              <StyledTableRow
                key={command.id}
                sx={{
                  border: 0,
                  '&:hover': {
                    background: '#F5F8FF',
                  },
                }}
              >
                <StyledTableCell>{index + 1}</StyledTableCell>
                <StyledTableCell>
                  <Box sx={{display: 'flex', flexDirection: 'column'}}>
                    {command.isMain ? (
                      <span className={styles.mainTask}>Main task</span>
                    ) : null}
                    {command.name}
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CreateTask
        isCreateTaskModalOpen={isCreateTaskModalOpen}
        setIsCreateTaskModalOpen={setIsCreateTaskModalOpen}
        getDataFromMainInfo={props.getDataFromMainInfo}
        defaultManifest={props.defaultManifest}
      />
    </div>
  );
};

export default Manifest;
