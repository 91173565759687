import {useNavigate} from 'react-router-dom';
import {Avatar, Box, Button, Stack} from '@mui/material';
import {
  BranchIcon,
  ChangePasswordIcon,
  CompaniesIcon,
  EditIcon,
  EnvelopeIcon,
  GoBackArrowButtonIcon,
  PhoneIcon,
} from 'assets/icons';
import {editDealerButton, goBackButton} from 'styles/MUIStyles/buttons';
import styles from './Profile.module.scss';
import {useAppSelector} from 'store/store';

const Profile = () => {
  const navigate = useNavigate();

  const userData = useAppSelector((state) => state.auth);

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
      }}
    >
      <div className={styles.profileTop}>
        <h1 className={styles.profileTitle}>My account</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            variant="contained"
            startIcon={<EditIcon />}
            sx={editDealerButton}
            onClick={() => navigate('/profile/edit')}
          >
            Edit
          </Button>
        </div>
      </div>
      <div className={styles.informationMainContent}>
        <div className={styles.profileInfo}>
          <Stack spacing={3}>
            <Box display="flex" justifyContent="space-between">
              <Box className={styles.profileCell}>
                <Avatar
                  sx={{
                    bgcolor: '#D4E3FF',
                    color: '#3152C7',
                    width: '50px',
                    height: '50px',
                  }}
                >
                  {userData.firstName[0]}
                  {userData.lastName[0]}
                </Avatar>
                <Box>
                  <p className={styles.cellFullName}>
                    {userData?.firstName} {userData?.lastName}
                  </p>
                  <p className={styles.cellRole}>{userData.role}</p>
                </Box>
              </Box>

              <Button
                variant="text"
                startIcon={
                  <ChangePasswordIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      filter:
                        'invert(98%) sepia(98%) saturate(0%) hue-rotate(22deg) brightness(104%) contrast(102%)',
                    }}
                  />
                }
                sx={editDealerButton}
                onClick={() => navigate('/profile/change-password')}
              >
                Change Password
              </Button>
            </Box>
            <Box>
              <span
                className={`${styles.status} ${
                  userData.isActive ? styles.active : styles.inactive
                }`}
              >
                {userData.isActive ? 'Active' : 'Inactive'}
              </span>
            </Box>
            <Box className={styles.profileCell}>
              <Avatar variant="rounded" className={styles.cellIcon}>
                <PhoneIcon
                  style={{
                    filter:
                      'invert(22%) sepia(90%) saturate(1942%) hue-rotate(219deg) brightness(96%) contrast(88%)',
                  }}
                />
              </Avatar>
              <Box>
                <p className={styles.cellLabel}>Phone number</p>
                <p className={styles.cellInfo}>{userData.phoneNumber}</p>
              </Box>
            </Box>
            <Box className={styles.profileCell}>
              <Avatar variant="rounded" className={styles.cellIcon}>
                <EnvelopeIcon
                  style={{
                    filter:
                      'invert(22%) sepia(90%) saturate(1942%) hue-rotate(219deg) brightness(96%) contrast(88%)',
                  }}
                />
              </Avatar>
              <Box>
                <p className={styles.cellLabel}>Email</p>
                <p className={styles.cellInfo}>{userData.email}</p>
              </Box>
            </Box>
          </Stack>
        </div>
      </div>
      {userData.companies || userData.branches ? (
        <div className={styles.profileLinkedInfo}>
          <Box
            sx={{
              display: 'flex',
              gap: '8px',
              marginBottom: '24px',
            }}
          >
            {userData.companies && <CompaniesIcon />}
            {userData.branches && <BranchIcon />}
            {userData.companies && (
              <h3 className={styles.linkedInfoTitle}>My companies</h3>
            )}
            {userData.branches && (
              <h3 className={styles.linkedInfoTitle}>My branches</h3>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            {userData.companies &&
              userData.companies.map((company: any) => (
                <div className={styles.linkedInfoBox}>
                  <p key={company.id} className={styles.linkedInfoItem}>
                    Name: {company.name}
                  </p>
                  <Box>
                    Assigned owners:{' '}
                    {company?.owners.map((owner: any, index: number) => (
                      <span>
                        {owner?.firstName + ' ' + owner?.lastName}
                        {index < company?.owners.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </Box>
                </div>
              ))}
            {userData.branches &&
              userData.branches.map((branch: any) => (
                <div className={styles.linkedInfoBox}>
                  <p key={branch.id} className={styles.linkedInfoItem}>
                    Name: {branch.name}
                  </p>
                  <Box>
                    Assigned users:{' '}
                    {branch?.users.map((user: any, index: number) => (
                      <span>
                        {user?.firstName + ' ' + user?.lastName}
                        {index < branch?.users.length - 1 ? ', ' : ''}
                      </span>
                    ))}
                  </Box>
                </div>
              ))}
          </Box>
        </div>
      ) : null}
    </div>
  );
};

export default Profile;
