// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyOwnersCreate_companyOwnersCreateTitle__BKvsq {
  font-weight: 600;
  line-height: 40px;
  font-size: 24px;
  color: #212121;
  margin: 0;
}

.CompanyOwnersCreate_closeIcon__YnuUX {
  cursor: pointer;
}

.CompanyOwnersCreate_firstNameInputContainer__7OPtx,
.CompanyOwnersCreate_lastNameInputContainer__5JAA\\+,
.CompanyOwnersCreate_emailInputContainer__SDfyK,
.CompanyOwnersCreate_phoneNumberInputContainer__rLnYS,
.CompanyOwnersCreate_additionalInfoInputContainer__475Ia {
  margin-bottom: 6px;
}
.CompanyOwnersCreate_firstNameInputContainer__7OPtx label,
.CompanyOwnersCreate_lastNameInputContainer__5JAA\\+ label,
.CompanyOwnersCreate_emailInputContainer__SDfyK label,
.CompanyOwnersCreate_phoneNumberInputContainer__rLnYS label,
.CompanyOwnersCreate_additionalInfoInputContainer__475Ia label {
  display: inline-block;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #8a8894;
  margin-left: 12px;
  margin-bottom: 6px;
}
.CompanyOwnersCreate_firstNameInputContainer__7OPtx .CompanyOwnersCreate_inputErrorMessage__fkmJe,
.CompanyOwnersCreate_lastNameInputContainer__5JAA\\+ .CompanyOwnersCreate_inputErrorMessage__fkmJe,
.CompanyOwnersCreate_emailInputContainer__SDfyK .CompanyOwnersCreate_inputErrorMessage__fkmJe,
.CompanyOwnersCreate_phoneNumberInputContainer__rLnYS .CompanyOwnersCreate_inputErrorMessage__fkmJe,
.CompanyOwnersCreate_additionalInfoInputContainer__475Ia .CompanyOwnersCreate_inputErrorMessage__fkmJe {
  display: inline-block;
  font-size: 12px;
  color: #f93232;
  line-height: 16px;
  margin-left: 12px;
  margin-top: 4px;
}

.CompanyOwnersCreate_buttonsContainer__8rcwQ {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.CompanyOwnersCreate_dropDownItem__tyknh {
  font-family: "Poppins", sans-serif;
  display: block;
  padding: 12px 16px 12px 16px;
  font-size: 14px;
  color: #212121;
  line-height: 24px;
  font-weight: 400;
}
.CompanyOwnersCreate_dropDownItem__tyknh:hover {
  background: #f5f8ff;
}`, "",{"version":3,"sources":["webpack://./src/views/CompanyOwners/CompanyOwnersCreate/CompanyOwnersCreate.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;EACA,SAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;;;;;EAKI,kBAAA;AACJ;AACI;;;;;EACI,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;AAKR;AAFI;;;;;EACI,qBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,iBAAA;EACA,eAAA;AAQR;;AAJA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,SAAA;AAOJ;;AAJA;EACI,kCAAA;EACA,cAAA;EACA,4BAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;AAOJ;AALI;EACI,mBAAA;AAOR","sourcesContent":[".companyOwnersCreateTitle {\n    font-weight: 600;\n    line-height: 40px;\n    font-size: 24px;\n    color: #212121;\n    margin: 0;\n}\n\n.closeIcon {\n    cursor: pointer;\n}\n\n.firstNameInputContainer,\n.lastNameInputContainer,\n.emailInputContainer,\n.phoneNumberInputContainer,\n.additionalInfoInputContainer {\n    margin-bottom: 6px;\n\n    & label {\n        display: inline-block;\n        font-size: 12px;\n        line-height: 18px;\n        font-weight: 400;\n        color: #8a8894;\n        margin-left: 12px;\n        margin-bottom: 6px;\n    }\n\n    & .inputErrorMessage {\n        display: inline-block;\n        font-size: 12px;\n        color: #f93232;\n        line-height: 16px;\n        margin-left: 12px;\n        margin-top: 4px;\n    }\n}\n\n.buttonsContainer {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    gap: 16px;\n}\n\n.dropDownItem {\n    font-family: 'Poppins', sans-serif;\n    display: block;\n    padding: 12px 16px 12px 16px;\n    font-size: 14px;\n    color: #212121;\n    line-height: 24px;\n    font-weight: 400;\n\n    &:hover {\n        background: #f5f8ff;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"companyOwnersCreateTitle": `CompanyOwnersCreate_companyOwnersCreateTitle__BKvsq`,
	"closeIcon": `CompanyOwnersCreate_closeIcon__YnuUX`,
	"firstNameInputContainer": `CompanyOwnersCreate_firstNameInputContainer__7OPtx`,
	"lastNameInputContainer": `CompanyOwnersCreate_lastNameInputContainer__5JAA+`,
	"emailInputContainer": `CompanyOwnersCreate_emailInputContainer__SDfyK`,
	"phoneNumberInputContainer": `CompanyOwnersCreate_phoneNumberInputContainer__rLnYS`,
	"additionalInfoInputContainer": `CompanyOwnersCreate_additionalInfoInputContainer__475Ia`,
	"inputErrorMessage": `CompanyOwnersCreate_inputErrorMessage__fkmJe`,
	"buttonsContainer": `CompanyOwnersCreate_buttonsContainer__8rcwQ`,
	"dropDownItem": `CompanyOwnersCreate_dropDownItem__tyknh`
};
export default ___CSS_LOADER_EXPORT___;
