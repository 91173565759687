import {createSlice} from '@reduxjs/toolkit';

interface IAuthSlice {
  isAuth: boolean;
  isProfileEdited: boolean;
  phoneNumber: string | null;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  role: string | null;
  id: string;
  companies:
    | {
        id: string;
        name: string;
        owners: {
          id: string;
          email: string;
          lastName: string;
          firstName: string;
        }[];
      }[]
    | null;
  branches:
    | {
        id: string;
        name: string;
        users: {
          id: string;
          email: string;
          lastName: string;
          firstName: string;
        }[];
      }[]
    | null;
}

const initialState: IAuthSlice = {
  isAuth: false,
  isProfileEdited: false,
  phoneNumber: null,
  firstName: '',
  lastName: '',
  isActive: false,
  email: '',
  role: null,
  id: '',
  companies: null,
  branches: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setAuthData: (state, action) => {
      state.isAuth = true;
      state.role = action.payload.role;
      localStorage.setItem('accessToken', action.payload.accessToken);
      localStorage.setItem('refreshToken', action.payload.refreshToken);
    },
    setProfileData: (state, action) => {
      state.isAuth = true;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.role = action.payload.role;
      state.isActive = action.payload.isActive;
      state.id = action.payload.id;
      state.phoneNumber = action.payload.phoneNumber;
      state.email = action.payload.email;
      state.companies = action.payload.companies || null;
      state.branches = action.payload.branches || null;
    },
  },
});

export const {setIsAuth, setEmail, setAuthData, setProfileData} =
  authSlice.actions;

export default authSlice.reducer;
