export const MAIN_PAGE_PATH = '/';
export const LOGIN_PAGE_PATH = 'user/login';
export const REPORTS_PATH = 'reports';
export const FORGOT_PASSWORD_PAGE_PATH = 'user/forgot-password';
export const OTP_CODE_PATH = 'user/otp-code';

export const RESET_PASSWORD_PAGE_PATH = 'reset-password';
export const DEALERS_PAGE_PATH = 'dealers';
export const DEALERS_EDIT_PATH = 'dealers/edit/:dealerId';

export const DEALERS_DETAIL_INFORMATION_PATH = 'dealers/information/:dealerId';
export const COMPANY_OWNERS_PATH = 'companyOwners';
export const COMPANY_OWNERS_EDIT_PATH = 'companyOwners/edit/:companyOwnerId';

export const COMPANY_OWNERS_INFORMATION_PATH =
  'companyOwners/information/:companyOwnerId';
export const CLIENTS_PATH = 'users';
export const CLIENTS_DETAIL_INFORMATION_PATH = 'users/information/:userId';

export const CLIENTS_EDIT_PATH = 'users/edit/:userId';
export const COMPANIES_PATH = 'companies';
export const COMPANIES_DETAIL_INFORMATION_PATH =
  'companies/information/:companyId';

export const COMPANIES_EDIT_PATH = 'companies/edit/:companyId';
export const BRANCHES_PATH = 'branches';
export const BRANCHES_DETAIL_INFORMATION_PATH =
  'branches/information/:branchId';

export const BRANCHES_EDIT_PATH = 'branches/edit/:branchId';
export const STATIONS_PATH = 'stations';

export const STATIONS_CREATE_PATH = 'stations/create';
export const STATIONS_DETAIL_INFORMATION_PATH =
  'stations/information/:stationId';
export const STATIONS_EDIT_PATH = 'stations/edit/:stationId';

export const BACKUPS_PATH = 'backups';

export const MANIFEST_PATH = 'manifests';
export const MANIFEST_DETAIL_INFORMATION_PATH =
  'manifests/information/:manifestId';

export const NOT_FOUND_PAGE_PATH = '*';

export const PROFILE_PATH = 'profile';
export const PROFILE_EDIT_PATH = 'profile/edit';
export const PROFILE_CHANGE_PASSWORD_PATH = 'profile/change-password';

export const LOGS_PATH = 'logs';

export const DATA_COLLECTOR_PATH = 'data-collector';
