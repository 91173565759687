export const loginButton = {
  fontFamily: 'Poppins, sans-serif',
  width: '100%',
  backgroundColor: '#605DEC',
  color: '#FFFFFF',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: '400',
  textTransform: 'none',
  borderRadius: '12px',
  padding: '14px 32px 14px 32px',
  marginBottom: '24px',
  '&:hover': {
    backgroundColor: '#3D3ACE',
  },
};

export const forgotPasswordBackButton = {
  fontFamily: 'Poppins, sans-serif',
  textTransform: 'capitalize',
  color: '#3152C7',
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '16px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const createButton = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  border: '1px solid #605DEC',
  background: '#605DEC',
  color: '#FFFFFF',
  boxShadow: 'none',
  textTransform: 'capitalize',
  '&:hover': {
    background: '#3D3ACE',
    boxShadow: 'none',
  },
};

export const createButtonModal = {
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#605DEC',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  fontWeight: 400,
  lineHeight: '16px',
  fontSize: '14px',
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#3D3ACE',
  },
  '&:disabled': {
    backgroundColor: '#3D3ACE',
    color: '94979B',
  },
};
export const disabledCreateButtonModal = {
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#E9EAEB',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  fontWeight: 400,
  lineHeight: '16px',
  fontSize: '14px',
  color: '#94979B',
  textTransform: 'capitalize',
};

export const deleteButtonModal = {
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#605DEC',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  fontWeight: 400,
  lineHeight: '16px',
  fontSize: '14px',
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#3D3ACE',
  },
};

export const cancelButton = {
  fontFamily: 'Poppins, sans-serif',
  border: '1px solid #3152C7',
  padding: '11px 24px 11px 24px',
  borderRadius: '12px',
  color: '#3152C7',
  lineHeight: '16px',
  fontWeight: '400',
  textTransform: 'capitalize',
  fontSize: '14px',
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: '#F5F8FF',
  },
};

export const editDealerButton = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  border: '1px solid #605DEC',
  background: '#605DEC',
  color: '#FFFFFF',
  boxShadow: 'none',
  textTransform: 'capitalize',
  '& svg': {
    width: '16px',
    height: '16px',
    filter:
      'invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)',
  },
  '&:hover': {
    background: '#3D3ACE',
    boxShadow: 'none',
  },
};

export const resetPasswordButtonModal = {
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#605DEC',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  fontWeight: 400,
  lineHeight: '16px',
  fontSize: '14px',
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#3D3ACE',
  },
};

export const recoverButtonModal = {
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#605DEC',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  fontWeight: 400,
  lineHeight: '16px',
  fontSize: '14px',
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#3D3ACE',
  },
};

export const warningModalButton = {
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: '#605DEC',
  borderRadius: '12px',
  padding: '12px 24px 12px 24px',
  fontWeight: 400,
  lineHeight: '16px',
  fontSize: '14px',
  color: '#fff',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#3D3ACE',
  },
};

export const goBackButton = {
  fontFamily: 'Poppins, sans-serif',
  color: '#3152C7',
  borderRadius: '12px',
  border: '1px solid #3152C7',
  padding: '12px 24px 12px 24px',
  background: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '400',
  gap: '6px',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: '#F5F8FF',
  },
};

export const createTaskBackupButtons = {
  fontFamily: 'Poppins, sans-serif',
  color: '#3152C7',
  lineHeight: '20px',
  fontSize: '20px',
  fontWeight: '500',
  background: '#FFF',
  borderRadius: '12px',
  minWidth: '40px',
  minHeight: '40px',
  border: '1px solid #DDE1E6',
  '&:hover': {
    background: '#FFF',
  },
};

export const createTaskBackupButtonsDisabled = {
  ...createTaskBackupButtons,
  background: '#E9EAEB',
};

export const addDirectoryButton = {
  backgroundColor: '#605DEC',
  color: '#FFFFFF',
  borderRadius: '12px',
  minWidth: '40px',
  minHeight: '40px',
  '&:hover': {
    backgroundColor: '#605DEC',
  },
};

export const createButtonOutlinedWithoutLoading = {
  fontFamily: 'Poppins, sans-serif',
  color: '#3152C7',
  borderRadius: '12px',
  border: '1px solid #3152C7',
  padding: '12px 24px 12px 24px',
  background: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '400',
  gap: '6px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#F5F8FF',
  },
};

export const createTaskButtonOutlinedWithoutLoading = {
  fontFamily: 'Poppins, sans-serif',
  color: '#FFF',
  borderRadius: '12px',
  border: '1px solid #3152C7',
  padding: '12px 24px 12px 24px',
  background: '#605DEC',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '400',
  gap: '6px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#3D3ACE',
  },
};

export const clearFiltersButton = {
  fontFamily: 'Poppins, sans-serif',
  borderRadius: '12px',
  padding: '10px 0 10px 0',
  color: '#3152C7',
  border: '1px solid #3152C7',
  lineHeight: '16px',
  fontSize: '14px',
  fontWeight: '400',
  textTransform: 'none',
  flexGrow: '1',
};

export const addBranchInEdit = {
  fontFamily: 'Poppins, sans-serif',
  fontWeight: '400',
  color: '#3152C7',
  lineHeight: '16px',
  fontSize: '14px',
  textTransform: 'none',
  marginBottom: '16px',
  '& svg': {
    filter:
      'invert(27%) sepia(86%) saturate(1209%) hue-rotate(208deg) brightness(94%) contrast(102%)',
  },
};
