import React, {FC, useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  SnackbarOrigin,
  Tab,
  Tabs,
} from '@mui/material';
import InformationTab from './InformationTab/InformationTab';
import ActivityLogTab from './ActivityLogTab/ActivityLogTab';
import WarningModal from 'components/WarningModal/WarningModal';
import {
  CloseIcon,
  EditIcon,
  GoBackArrowButtonIcon,
  RecoverIcon,
  SuccessNotification,
} from 'assets/icons';
import {tab} from 'styles/MUIStyles/common';
import {editDealerButton, goBackButton} from 'styles/MUIStyles/buttons';
import {
  snackbarAlertContent,
  snackbarSuccessAlert,
} from 'styles/MUIStyles/snackbars';
import styles from './CompanyOwnersInformation.module.scss';
import {
  useLazyGetCompanyOwnerByIdQuery,
  useRecoverCompanyOwnerMutation,
} from '../../../store/slices/companyOwners';
import {useUpdateEffect} from '../../../hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
}

const CompanyOwnersInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [getCompanyOwnerById, {data: companyOwnerData, isFetching}] =
    useLazyGetCompanyOwnerByIdQuery();

  const [
    recoverCompanyOwner,
    {
      isLoading: isRecoveringCompanyOwnerLoading,
      isSuccess: isRecoveringCompanyOwnerSuccess,
    },
  ] = useRecoverCompanyOwnerMutation();

  const [activeTab, setActiveTab] = useState(0);
  const [isRecoverCompanyOwnerModalOpen, setIsRecoverCompanyOwnerModalOpen] =
    useState(false);
  const [recoverCompanyOwnerSnackbar, setRecoverCompanyOwnerSnackbar] =
    useState<State>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });
  const {vertical, horizontal, open} = recoverCompanyOwnerSnackbar;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (params?.companyOwnerId) {
      getCompanyOwnerById(params.companyOwnerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.companyOwnerId]);

  useUpdateEffect(() => {
    if (isRecoveringCompanyOwnerSuccess && params?.companyOwnerId) {
      setRecoverCompanyOwnerSnackbar({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      setIsRecoverCompanyOwnerModalOpen(false);
      getCompanyOwnerById(params.companyOwnerId);
    }
  }, [isRecoveringCompanyOwnerSuccess]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.companyOwnerInformationTop}>
        <h1 className={styles.companyOwnerInformationTitle}>Company owners</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          {companyOwnerData?.audit?.deleting ? (
            <Button
              variant="contained"
              startIcon={<RecoverIcon />}
              onClick={() => setIsRecoverCompanyOwnerModalOpen(true)}
              sx={editDealerButton}
            >
              Recover
            </Button>
          ) : (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                if (params?.companyOwnerId) {
                  navigate('/companyOwners/edit/' + params.companyOwnerId);
                }
              }}
              sx={editDealerButton}
            >
              Edit Info
            </Button>
          )}
        </div>
      </div>

      <div className={styles.companyOwnerInformationMainContent}>
        {isFetching ? (
          <Box sx={{display: 'flex', height: '100%'}}>
            <CircularProgress
              size={45}
              sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
            />
          </Box>
        ) : (
          <Box sx={{height: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
              }}
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Information" {...a11yProps(0)} />
              <Tab sx={tab} label="Activity Log" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={activeTab} index={0}>
              <InformationTab companyOwnerData={companyOwnerData} />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <ActivityLogTab companyOwnerData={companyOwnerData} />
            </CustomTabPanel>
          </Box>
        )}
      </div>

      {companyOwnerData?.email ? (
        <>
          <WarningModal
            isOpen={isRecoverCompanyOwnerModalOpen}
            onClose={() => setIsRecoverCompanyOwnerModalOpen(false)}
            entityText={companyOwnerData.email}
            title="Recover company ?"
            textBlocks={['Are you sure you want to recover company owner']}
            confirmButtonText="Yes, recover"
            onConfirmButtonClick={() => {
              if (params?.companyOwnerId) {
                recoverCompanyOwner(params.companyOwnerId);
              }
            }}
            loading={isRecoveringCompanyOwnerLoading}
          />
          <Snackbar
            anchorOrigin={{vertical, horizontal}}
            autoHideDuration={2500}
            open={open}
            onClose={() =>
              setRecoverCompanyOwnerSnackbar({
                ...recoverCompanyOwnerSnackbar,
                open: false,
              })
            }
            key="companyOwnerInformation"
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Company owner {`"${companyOwnerData.email}"`} was restored
                <span
                  onClick={() => {
                    setRecoverCompanyOwnerSnackbar({
                      ...recoverCompanyOwnerSnackbar,
                      open: false,
                    });
                  }}
                >
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </div>
  );
};

export default CompanyOwnersInformation;
