import React, {FC, useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Tab, Tabs} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useLazyGetCompanyByIdQuery} from 'store/slices/companies';
import ActivityLogTab from './ActivityLog/ActivityLog';
import InformationTab from './InformationTab/InformationTab';
import {EditIcon, GoBackArrowButtonIcon} from 'assets/icons';
import {tab} from 'styles/MUIStyles/common';
import {editDealerButton, goBackButton} from 'styles/MUIStyles/buttons';
import styles from './CompaniesInformation.module.scss';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{padding: '24px 0'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CompaniesInformation: FC = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [getCompanyById, {data: companyInfo, isFetching}] =
    useLazyGetCompanyByIdQuery();

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    if (params?.companyId) {
      getCompanyById(params.companyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.companyId]);

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <div className={styles.companyInformationTop}>
        <h1 className={styles.companyInformationTitle}>Companies</h1>
        <div className={styles.buttonsContainer}>
          <Button
            variant="text"
            startIcon={
              <GoBackArrowButtonIcon style={{width: '20px', height: '20px'}} />
            }
            sx={goBackButton}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>

          {companyInfo?.name !== 'All' ? (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => {
                if (params?.companyId) {
                  navigate('/companies/edit/' + params.companyId);
                }
              }}
              sx={editDealerButton}
            >
              Edit Info
            </Button>
          ) : null}
        </div>
      </div>
      <div className={styles.companyInformationMainContent}>
        {isFetching ? (
          <Box sx={{display: 'flex', height: '100%'}}>
            <CircularProgress
              size={45}
              sx={{margin: 'auto', color: 'rgba(49, 82, 199, 1)'}}
            />
          </Box>
        ) : (
          <Box sx={{height: '100%'}}>
            <Tabs
              TabIndicatorProps={{
                style: {backgroundColor: 'rgba(49, 82, 199, 1)'},
              }}
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab sx={tab} label="Information" {...a11yProps(0)} />
              <Tab sx={tab} label="Activity Log" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={activeTab} index={0}>
              <InformationTab companyInfo={companyInfo} />
            </CustomTabPanel>
            <CustomTabPanel value={activeTab} index={1}>
              <ActivityLogTab companyInfo={companyInfo} />
            </CustomTabPanel>
          </Box>
        )}
      </div>
    </div>
  );
};

export default CompaniesInformation;
