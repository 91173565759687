export const styledTableHead = {
  backgroundColor: '#FFFFFF',
  color: '#212121',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '15px',
  lineHeight: '20px',
  fontWeight: '500 !important',
  padding: '14px 10px 14px 10px',
};

export const styledTableBody = {
  fontFamily: 'Poppins, sans-serif',
  fontSize: '14px',
  color: '#212121',
  lineHeight: '20px',
  fontWeight: '400',
  padding: '8px 10px 8px 10px',
  wordBreak: 'break-all',
};

export const styledTableRow = {
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  '&:hover': {
    background: '#F5F8FF',
  },
};

export const tableContainer = {
  border: '1px solid #E4E4EF',
  borderRadius: '16px',
  boxShadow: 'none',
  height: '100%',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#E0E0F1',
    borderRadius: '100px',
  },
};

export const tableHeadCell = {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  cursor: 'pointer',
};
