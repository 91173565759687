import React, {FC, useState} from 'react';
import {useEditManifestMutation} from '../../../../store/slices/manifests';
import {IGetManifestByIdData} from '../../../../store/models/manifest';
import {ISnackbarState} from '../../../../store/models/common';
import {useUpdateEffect} from '../../../../hooks';
import WarningModal from '../../../../components/WarningModal/WarningModal';
import {Alert, Box, Snackbar} from '@mui/material';
import {
  snackbarAlertContent,
  snackbarErrorAlert,
  snackbarSuccessAlert,
} from '../../../../styles/MUIStyles/snackbars';
import {
  CloseIcon,
  ErrorNotification,
  SuccessNotification,
} from '../../../../assets/icons';

interface IManifestEditTask {
  name: string;
  id: string;
  isMain: boolean;
  include: {
    directory: string;
  }[];
  exclude:
    | {
        directory: string;
      }[]
    | null;
  isIncrementalBackupSettingsChecked: boolean;
  isExcludeDirectoryChecked: boolean;
  fullBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
  incrementalBackupSettings: {
    periodType: string | null;
    periodCount: number;
  } | null;
}

interface Props {
  manifestData: IGetManifestByIdData | null;
  clickedManifestTask: IManifestEditTask | null;
  isDeleteTaskModalOpen: boolean;
  setIsDeleteTaskModalOpen: (isOpen: boolean) => void;
}

const ManifestDeleteTask: FC<Props> = (props) => {
  const [
    editManifest,
    {
      isLoading: isEditingManifestLoading,
      isSuccess: isEditingManifestSuccess,
      error: editManifestError,
    },
  ] = useEditManifestMutation();

  const [deleteManifestTaskErrorMessage, setDeleteManifestTaskErrorMessage] =
    useState<null | string>(null);

  const [
    deleteManifestTaskSnackbarSuccess,
    setDeleteManifestTaskSnackbarSuccess,
  ] = useState<ISnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [deleteManifestTaskSnackbarError, setDeleteManifestTaskSnackbarError] =
    useState<ISnackbarState>({
      open: false,
      vertical: 'top',
      horizontal: 'center',
    });

  const {
    vertical: deleteManifestTaskSuccessVertical,
    horizontal: deleteManifestTaskSuccessHorizontal,
    open: isDeleteManifestTaskSuccessOpen,
  } = deleteManifestTaskSnackbarSuccess;
  const {
    vertical: deleteManifestTaskErrorVertical,
    horizontal: deleteManifestTaskErrorHorizontal,
    open: isDeleteManifestTaskErrorOpen,
  } = deleteManifestTaskSnackbarError;

  const deleteManifestTask = () => {
    if (props.manifestData && props.clickedManifestTask) {
      const deletableManifestTask = props.manifestData.commands.find(
        (task) => task.id === props.clickedManifestTask?.id,
      );

      if (deletableManifestTask) {
        editManifest({
          ...props.manifestData,
          id: props.manifestData.id,
          commands: [
            ...props.manifestData.commands.filter(
              (task) => task.id !== deletableManifestTask.id,
            ),
          ],
        });
      }
    }
  };

  const handleSnackbarClose = () => {
    setDeleteManifestTaskSnackbarSuccess({
      ...deleteManifestTaskSnackbarSuccess,
      open: false,
    });
    setDeleteManifestTaskSnackbarError({
      ...deleteManifestTaskSnackbarError,
      open: false,
    });
    setDeleteManifestTaskErrorMessage(null);
  };

  useUpdateEffect(() => {
    if (isEditingManifestSuccess) {
      setDeleteManifestTaskSnackbarSuccess({
        vertical: 'top',
        horizontal: 'center',
        open: true,
      });
      props.setIsDeleteTaskModalOpen(false);
    }
  }, [isEditingManifestSuccess]);

  useUpdateEffect(() => {
    if (
      editManifestError &&
      'data' in editManifestError &&
      'status' in editManifestError
    ) {
      if (
        editManifestError.status === 400 ||
        editManifestError.status === 404
      ) {
        const errors = editManifestError.data?.errors;
        for (let key in errors) {
          if (Array.isArray(errors[key])) {
            setDeleteManifestTaskErrorMessage(errors[key]);
            setDeleteManifestTaskSnackbarError({
              vertical: 'top',
              horizontal: 'center',
              open: true,
            });
            break;
          }
        }
      }
    }
  }, [editManifestError]);

  return (
    <>
      {props.clickedManifestTask ? (
        <>
          <WarningModal
            isOpen={props.isDeleteTaskModalOpen}
            onClose={() => props.setIsDeleteTaskModalOpen(false)}
            entityText={''}
            title={'Remove task ?'}
            textBlocks={['Are you sure you want to remove task']}
            confirmButtonText={'Yes, remove'}
            onConfirmButtonClick={deleteManifestTask}
            loading={isEditingManifestLoading}
          />
          <Snackbar
            anchorOrigin={{
              vertical: deleteManifestTaskSuccessVertical,
              horizontal: deleteManifestTaskSuccessHorizontal,
            }}
            autoHideDuration={2500}
            open={isDeleteManifestTaskSuccessOpen}
            onClose={handleSnackbarClose}
            key={'deleteManifestTaskSuccess'}
          >
            <Alert icon={false} sx={snackbarSuccessAlert}>
              <Box sx={snackbarAlertContent}>
                <SuccessNotification />
                Task was removed
                <span onClick={handleSnackbarClose}>
                  <CloseIcon />
                </span>
              </Box>
            </Alert>
          </Snackbar>
        </>
      ) : null}

      {deleteManifestTaskErrorMessage ? (
        <Snackbar
          anchorOrigin={{
            vertical: deleteManifestTaskErrorVertical,
            horizontal: deleteManifestTaskErrorHorizontal,
          }}
          autoHideDuration={3500}
          open={isDeleteManifestTaskErrorOpen}
          onClose={handleSnackbarClose}
          key={'deleteManifestTaskError'}
        >
          <Alert icon={false} sx={snackbarErrorAlert}>
            <Box sx={snackbarAlertContent}>
              <ErrorNotification />
              {deleteManifestTaskErrorMessage}
              <span onClick={handleSnackbarClose}>
                <CloseIcon />
              </span>
            </Box>
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default ManifestDeleteTask;
