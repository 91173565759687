import {api} from './api';
import {
  ICreateClientArgs,
  IEditClientArgs,
  IGetClientById,
  IGetClientsResponse,
} from 'store/models/clients';

export const clients = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getClients: builder.query<
        IGetClientsResponse,
        {
          pageSize: number;
          pageNumber: number;
          search: string;
          status: {label: string; value: boolean; queryName: string};
          sortBy: {column: string | null; direction: string | null};
        }
      >({
        query: (clientsParams) => {
          const params = new URLSearchParams();

          if (clientsParams.search) {
            params.append('term', clientsParams.search);
          }

          if (clientsParams.status) {
            params.append(
              clientsParams.status.queryName,
              String(clientsParams.status.value),
            );
          }

          if (clientsParams.pageNumber) {
            params.append('pageNumber', String(clientsParams.pageNumber));
          }

          if (clientsParams.pageSize) {
            params.append('pageSize', String(clientsParams.pageSize));
          }

          if (clientsParams.sortBy?.column && clientsParams.sortBy.direction) {
            const column = clientsParams.sortBy?.column;
            const direction = clientsParams.sortBy.direction;
            params.append('orderBy', `${column}.${direction}`);
          }

          return {
            url: `clients?${params.toString()}`,
            method: 'get',
          };
        },
        providesTags: () => ['Clients'],
      }),
      createClient: builder.mutation<string, ICreateClientArgs>({
        query: (clientData) => ({
          url: 'clients',
          method: 'post',
          data: clientData,
        }),
        invalidatesTags: ['Clients'],
      }),
      getClientById: builder.query<IGetClientById, string>({
        query: (clientId: string) => ({
          url: `clients/${clientId}`,
          method: 'get',
        }),
        providesTags: () => ['ClientsEdit'],
      }),
      getBranchesToAddToClient: builder.query<
        {
          data: {
            id: string;
            name: string;
            address: string;
            companyId: string;
            companyName: string;
          }[];
        },
        void
      >({
        query: () => ({
          url: 'branches',
          method: 'get',
        }),
      }),
      addBranchToClient: builder.mutation<
        string,
        {clientId: string; branchId: string}
      >({
        query: (clientData) => ({
          url: 'clients/attach',
          method: 'patch',
          data: clientData,
        }),
        invalidatesTags: (_, error) => (error ? [] : ['ClientsEdit']),
      }),
      deleteBranchFromClient: builder.mutation<
        string,
        {clientId: string; branchId: string}
      >({
        query: (clientData) => ({
          url: 'clients/de-attach',
          method: 'patch',
          data: clientData,
        }),
      }),
      editClient: builder.mutation<string, IEditClientArgs>({
        query: (clientData) => ({
          url: 'clients',
          method: 'put',
          data: clientData,
        }),
        invalidatesTags: ['Clients'],
      }),
      clientResetPassword: builder.mutation<string, string>({
        query: (clientId) => ({
          url: 'clients/reset-password',
          method: 'post',
          data: {id: clientId},
        }),
      }),
      deleteClient: builder.mutation<string, string>({
        query: (clientId) => ({
          url: 'clients',
          method: 'delete',
          data: {clientId},
        }),
        invalidatesTags: ['Clients'],
      }),
      recoverClient: builder.mutation<void, string>({
        query: (clientId) => ({
          url: 'clients/restore',
          method: 'post',
          data: {userId: clientId},
        }),
        invalidatesTags: ['Clients'],
      }),
    };
  },
});

export const {
  useGetClientsQuery,
  useCreateClientMutation,
  useLazyGetClientByIdQuery,
  useLazyGetBranchesToAddToClientQuery,
  useAddBranchToClientMutation,
  useDeleteBranchFromClientMutation,
  useEditClientMutation,
  useClientResetPasswordMutation,
  useDeleteClientMutation,
  useRecoverClientMutation,
} = clients;
